import * as React from "react";
import { useSelector } from "react-redux";
import { TableCell, TableRow } from "@mui/material";

import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import PreferenceField from "fond/map/Field/PreferenceField";
import { getCurrentProject } from "fond/project";
import { Store } from "fond/types";
import { Actions } from "fond/utils/permissions";

import { PropertyNameCell } from "./Features.styles";

interface IProps {
  /**
   * The current feature properties being displayed
   */
  properties: { [key: string]: any };
  /**
   * Callback function that handles the change in value
   */
  onChange(values: { [key: string]: string | number }): void;
  /**
   * Callback function that handles the enter key press event
   */
  onEnterPress(): void;
}

const PreferencePathEdit: React.FC<IProps> = ({ properties, onChange, onEnterPress }: IProps) => {
  const project = useSelector((state: Store) => getCurrentProject(state.project));
  const canEdit = usePermissionCheck(Actions.PLANNER_PROJECT_EDIT, project?.Permission.Level);

  /**
   * Callback function for handling the checked change event
   */
  const handleOnChange = (value: string) => {
    onChange({ CostFactor: value });
  };

  return (
    <TableRow>
      <PropertyNameCell>Preference:</PropertyNameCell>
      <TableCell>
        <PreferenceField
          className="feature-popup__cost-input"
          value={properties.CostFactor}
          onChange={handleOnChange}
          onEnterPress={onEnterPress}
          disabled={!canEdit}
        />
      </TableCell>
    </TableRow>
  );
};

export default PreferencePathEdit;
