import { createContext, createRef, useEffect, useRef, useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import { Layout, Model } from "flexlayout-react";

import { useDeleteLayoutMutation, useGetLayoutQuery, useGetVersionStatusQuery } from "fond/api";
import mixpanel from "fond/mixpanel";
import { getCurrentProject } from "fond/project/redux";
import { LayoutDeleteLevel, Project, Store } from "fond/types";
import { makeUuid } from "fond/utils";
import { Actions as PermissionActions, permissionCheck } from "fond/utils/permissions";

import { defaultLayout, defaultMapSubLayout } from "./defaultLayout";
import { convertToModel } from "./widgets";

export const LayoutContext = createContext<{
  layoutRef?: any;
  model?: Model;
  mapModel?: Model;
  windowIds: string[];
  setWindowIds: React.Dispatch<React.SetStateAction<string[]>>;
  reset(): void;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
}>(undefined!);

interface IProps {
  children: React.ReactNode;
}

const LayoutProvider: React.FC<IProps> = ({ children }: IProps) => {
  const project = useSelector((state: Store): Project => getCurrentProject(state.project));
  const { data: layout, isLoading, isFetching } = useGetLayoutQuery(project?.ID);
  const versionId = useSelector((state: Store) => state.project.versionId);
  const { data: status } = useGetVersionStatusQuery(versionId, { skip: !versionId });

  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
  const userLayout = useRef<Model | undefined>(undefined);
  const mapSubLayout = useRef<Model | undefined>(undefined);

  const layoutRef = createRef<Layout>();
  const [windowIds, setWindowIds] = useState<string[]>([]);
  const [key, setKey] = useState<string>(makeUuid());
  const [deleteLayout] = useDeleteLayoutMutation();

  const showDesignPanel =
    !project?.HasCustomLayerConfig && !status?.HasSolution && permissionCheck(project?.Permission?.Level, PermissionActions.PROJECT_EDIT);

  /**
   * Callback function that triggers a reset of the layout to the default
   */
  const reset = () => {
    if (project) {
      deleteLayout({ projectId: project.ID, level: LayoutDeleteLevel.Project });
    }
    userLayout.current = convertToModel(defaultLayout, [], !!showDesignPanel);
    mapSubLayout.current = convertToModel(defaultMapSubLayout, []);
    setKey(makeUuid());
    mixpanel.track("Layout: Reverted layout back to default");
  };

  useEffect(() => {
    if (project && !isLoading && !isFetching && isFirstTimeLoading) {
      try {
        userLayout.current = convertToModel(defaultLayout, layout?.layouts, !!showDesignPanel);
        mapSubLayout.current = convertToModel(defaultMapSubLayout, layout?.maps);
      } catch (error) {
        // Reset layout to default if any issue loading it from db.
        mixpanel.track("Layout: there is any issue loading the layout", { layout });
        Sentry.captureException(error);
        reset();
      } finally {
        setIsFirstTimeLoading(false);
      }
    }
  }, [project, isLoading, isFetching]);

  return (
    <LayoutContext.Provider
      key={key}
      value={{
        layoutRef: layoutRef,
        windowIds: windowIds,
        setWindowIds: setWindowIds,
        reset: reset,
        model: userLayout.current,
        mapModel: mapSubLayout.current,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
