/* eslint-disable no-underscore-dangle */
import MapboxDraw, { DrawCustomModeThis, DrawPoint, modes } from "@mapbox/mapbox-gl-draw";
import constrainFeatureMovement from "@mapbox/mapbox-gl-draw/src/lib/constrain_feature_movement";
import moveFeatures from "@mapbox/mapbox-gl-draw/src/lib/move_features";
import { Feature, LineString, Point, Position } from "geojson";
import { cloneDeep, isEqual } from "lodash";
import mapboxgl from "mapbox-gl";

import snapTo, { withSnapping } from "fond/draw/modes/snap_to";
import { setIndex } from "fond/draw/modes/utils";
import { enumerate } from "fond/utils";

import simpleSelect from "./simple_select";

interface DrawCustomModeState {
  dragMoveLocation: mapboxgl.LngLat;
  lineLayerId: string;
  pointLayerId: string;
  poleDragInit: {
    connectedSpans: Feature<LineString>[];
    connectedIds: Set<string>;
    pole: Feature;
  } | null;
}

interface DrawCustomModeOptions {
  feature: Feature;
  lineLayerId: string;
  pointLayerId: string;
  pullInPole: boolean;
}

interface DrawCustomModeExtension {
  onDrag(this: DrawCustomModeThis, state: DrawCustomModeState, event: MapboxDraw.MapMouseEvent): void;
  dragMove(this: DrawCustomModeThis, state: DrawCustomModeState, event: MapboxDraw.MapMouseEvent): void;
  enter(
    draw: MapboxDraw,
    opts: DrawCustomModeOptions & {
      event: MapboxDraw.MapMouseEvent;
    }
  ): void;
}

const spanPoleSelect = withSnapping<DrawCustomModeState, DrawCustomModeOptions, DrawCustomModeExtension>({
  ...modes.simple_select,

  enter: function (mapboxDraw, opts) {
    if (["draw_spans", "draw_pole"].includes(mapboxDraw.getMode())) {
      // If we're in draw_spans or draw_pole mode, and we click an item, just
      // stay in that mode.
      return;
    }

    if (opts.pointLayerId == null || opts.lineLayerId == null) {
      throw new Error("Must provide pointLayerId and lineLayerId");
    }

    mapboxDraw.changeMode("span_pole_select", {
      ...opts,
      pullInPole: true,
    });

    // Now we've set up the mode, start the drag as if we'd started it when the
    // mode was already active (otherwise the user would have to click again).
    // We can't do this inside `onSetup` because it needs to be done after the
    // render, which Mapbox Draw executes only after onSetup is finished.
    mapboxDraw.ctx.events.getCurrentMode().mousedown(opts.event);
  },

  onSetup: function (opts) {
    const state = modes.simple_select.onSetup?.call(this, opts);

    if (opts.pointLayerId == null || opts.lineLayerId == null) {
      throw new Error("Must provide lineLayerId & pointLayerId");
    }
    state.pointLayerId = opts.pointLayerId;
    state.lineLayerId = opts.lineLayerId;

    if (opts.pullInPole && opts.feature) {
      if (opts.feature.geometry.type === "Point") {
        this._ctx.api.pullInPole(opts.feature);
      } else {
        this._ctx.api.pullInFeature(state.lineLayerId, opts.feature);
      }
    }
    return state;
  },

  onMouseDown: function (state, e) {
    if (e.featureTarget != null && (e.featureTarget as any).geometry.type === "LineString") {
      // We don't want to be able to move spans without moving poles.
      return;
    }

    if (e.featureTarget != null && !e.featureTarget.properties?.isFakePole) {
      const pole = this._ctx.api.getFeatureById(e.featureTarget.id).feature as Feature<Point>;

      // Note that we *must* cache the list of connected spans on mouse down --
      // it's not just a performance optimisation, it's necessary for
      // correctness. This is because if we're in the middle of dragging a pole
      // around and it snaps to another pole, we don't want the two poles to
      // become permanently connected while the user is still dragging. If the
      // user continues dragging, the poles should become un-snapped. The poles
      // only become permanently joined if the user mouse-ups while the poles
      // are on top of each other.
      // Ie.
      //
      // 1:
      //   o------o      o
      //
      // User drags the middle pole to the right-most pole
      // 2:
      //   o-------------o
      //
      // User (without letting go of the mouse button) drags back to initial position
      // 3:
      //   o------o      o

      const connectedSpans = [];
      const spansToAdd = [];

      // Find all spans connected to the pole
      for (let [span, alreadyExists] of this._ctx.api.iterFeatures(state.lineLayerId)) {
        if ((span as Feature<LineString>).geometry.coordinates.some((c) => isEqual(c, pole.geometry.coordinates))) {
          connectedSpans.push(span);
          if (!alreadyExists) {
            spansToAdd.push(span);
          }
        }
      }

      let connectedIds = new Set<string>();
      for (let f of connectedSpans) {
        connectedIds.add(f.id);
      }

      state.poleDragInit = {
        connectedSpans: connectedSpans,
        connectedIds: connectedIds,
        pole: cloneDeep(pole),
      };

      this._ctx.api.pullInFeatures(
        spansToAdd.map((span) => {
          return { layerId: state.lineLayerId, feature: span };
        })
      );
    }

    simpleSelect.onMouseDown?.call(this as any, state, e);
  },

  onMouseUp: function (state, e) {
    const selected = this.getSelected();
    if (selected.length > 0) {
      // TODO-MAG-1216 test dragging a pole a short amount then dragging it again.
      // If we snapped to a pole, make sure there's only one pole at the point.
      // If we didn't snap, then we don't need to do anything beyond what we've
      // already done in `dragMove`.
      if (e.closestCoord != null) {
        this._ctx.api.commitPole({
          selectedPole: selected[0].toGeoJSON(),
          originalPole: state.poleDragInit?.pole,
          connectedSpans: state.poleDragInit?.connectedSpans,
          snap: e.closestFeature != null ? { feature: e.closestFeature, coords: e.closestCoord } : null,
        });
      }
    }
    return simpleSelect.onMouseUp?.call(this as any, state, e);
  },

  onDrag: function (state, e) {
    const selected = this.getSelected();

    if (selected.length > 0) {
      const fl = this._ctx.api.getFeatureById(selected[0].id);

      // We don't support dragging spans -- we can only move spans by moving
      // the poles they're attached to.
      if (fl != null && fl.layerId === state.pointLayerId) {
        modes.simple_select.onDrag?.call(this as any, state, e);
      }
    }
  },

  dragMove: function (state, e) {
    // Dragging when drag move is enabled
    e.originalEvent.stopPropagation();
    const selected = this.getSelected() as DrawPoint[];

    const moveLines = (oldCoords: Position, newCoords: Position) => {
      if (state.poleDragInit?.connectedSpans) {
        for (let f of state.poleDragInit.connectedSpans) {
          if (f.id) {
            const feature: DrawPoint = this._ctx.store._features[f.id];
            for (let [i, c] of enumerate(feature.coordinates)) {
              if (isEqual(c, oldCoords)) {
                feature.incomingCoords(setIndex(feature.coordinates, i, newCoords) as Position);
                break;
              }
            }
          }
        }
      }
    };

    const oldCoords = selected[0].getCoordinates();
    let newCoords;
    if (e.closestFeature != null) {
      // If we snapped, then move to the exact coords we snapped to.
      selected[0].incomingCoords(e.closestCoord);
      newCoords = [e.lngLat.lng, e.lngLat.lat];
    } else {
      // Otherwise, adjust by delta.
      const delta = {
        lng: e.lngLat.lng - state.dragMoveLocation.lng,
        lat: e.lngLat.lat - state.dragMoveLocation.lat,
      };
      const constrainedDelta = constrainFeatureMovement(
        selected.map((f) => f.toGeoJSON()),
        delta
      );
      newCoords = [selected[0].coordinates[0] + constrainedDelta.lng, selected[0].coordinates[1] + constrainedDelta.lat];
      moveFeatures(this.getSelected(), constrainedDelta);
    }
    moveLines(oldCoords, newCoords);

    state.dragMoveLocation = e.lngLat;
  },

  onTrash: function (state): void {
    const selected = this.getSelected()[0];
    if (selected != null) {
      this._ctx.api.markFeatureDeleted(selected.id);
    }
  },

  onStop: function (state) {
    modes.simple_select.onStop?.call(this as any, state);
    state.poleDragInit = null;
  },

  snap: function (state, e) {
    const selected = this.getSelected();
    const excludeFeatureIds = state.poleDragInit != null ? state.poleDragInit.connectedIds : undefined;

    return snapTo(e, this._ctx, selected.length > 0 ? selected[0].id : undefined, {
      excludeFeatureIds: excludeFeatureIds,
    });
  },
});

export default spanPoleSelect;
