import * as React from "react";
import { useSelector } from "react-redux";
import { Close, Delete, Visibility, VisibilityOff } from "@mui/icons-material";
import { Divider, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDebouncedCallback } from "use-debounce";

import { hydrateGroup, selectDraftConfigEntities, selectVersionMlcId, useDeleteGroupConfigMutation, useUpdateGroupConfigMutation } from "fond/api";
import { setSelectedId } from "fond/redux/styles";
import { OnClickInput } from "fond/styleEditor/StyleHeader/styleHeader.styles";
import { GroupConfig } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";

import { Actions, Container, IconButton } from "../LayerHeader/layerHeader.styles";

interface IProps {
  groupConfig: GroupConfig;
}

export const GroupHeader: React.FC<IProps> = ({ groupConfig }: IProps) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const mapLayerConfigId = useSelector(selectVersionMlcId);
  const [updateGroupConfig] = useUpdateGroupConfigMutation();
  const [deleteGroupConfig] = useDeleteGroupConfigMutation();
  const draft = useSelector(selectDraftConfigEntities);

  const handleOnClose = () => {
    dispatch(setSelectedId(""));
  };

  const onChange = useDebouncedCallback((values: GroupConfig) => {
    const newGroup = hydrateGroup(draft, values);

    updateGroupConfig({ groupConfig: newGroup, mapLayerConfigId: mapLayerConfigId }).catch(() => {
      enqueueSnackbar("Failed to save draft changes", { variant: "error" });
    });
  }, 500);

  const handleOnRename = (name: string) => {
    onChange({
      ...groupConfig,
      Label: name,
    });
  };

  const handleOnDelete = () => {
    deleteGroupConfig({ groupConfigId: groupConfig.ID, mapLayerConfigId: mapLayerConfigId });
    dispatch(setSelectedId(""));
  };

  const handleOnVisibility = () => {
    onChange({
      ...groupConfig,
      IsVisible: !groupConfig.IsVisible,
    });
  };

  return (
    <Container>
      <OnClickInput value={groupConfig.Label} onCommit={handleOnRename} />
      <Divider orientation="vertical" />
      <Actions>
        <Tooltip title="Set default visibility">
          <IconButton data-testid="style-group-visibility" onClick={handleOnVisibility}>
            {groupConfig.IsVisible ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </Tooltip>
      </Actions>
      <Tooltip title="Delete">
        <IconButton onClick={handleOnDelete} disabled={groupConfig.Children.length > 0} data-testid="style-group-delete">
          <Delete />
        </IconButton>
      </Tooltip>
      <Divider orientation="vertical" />
      <IconButton onClick={handleOnClose}>
        <Close />
      </IconButton>
    </Container>
  );
};

export default GroupHeader;
