import * as React from "react";
import { SvgIcon as MuiSvgIcon, SvgIconProps } from "@mui/material";

interface IProps {
  className?: string;
  icon: "pin" | "polygon" | "points" | "unpin" | "ruler" | "line" | "lineCenter";
  iconProps?: SvgIconProps;
}

const SvgIcon: React.FC<IProps> = ({ className, icon, iconProps }: IProps) => {
  if (icon === "polygon") {
    return (
      <MuiSvgIcon viewBox="0 0 448 512" fontSize="small" className={className} {...iconProps}>
        <path
          fill="currentColor"
          d="M384 352c-.35 0-.67.1-1.02.1l-39.2-65.32c5.07-9.17 8.22-19.56 8.22-30.78s-3.14-21.61-8.22-30.78l39.2-65.32c.35.01.67.1 1.02.1 35.35 0 64-28.65 64-64s-28.65-64-64-64c-23.63 0-44.04 12.95-55.12 32H119.12C108.04 44.95 87.63 32 64 32 28.65 32 0 60.65 0 96c0 23.63 12.95 44.04 32 55.12v209.75C12.95 371.96 0 392.37 0 416c0 35.35 28.65 64 64 64 23.63 0 44.04-12.95 55.12-32h209.75c11.09 19.05 31.49 32 55.12 32 35.35 0 64-28.65 64-64 .01-35.35-28.64-64-63.99-64zm-288 8.88V151.12A63.825 63.825 0 0 0 119.12 128h208.36l-38.46 64.1c-.35-.01-.67-.1-1.02-.1-35.35 0-64 28.65-64 64s28.65 64 64 64c.35 0 .67-.1 1.02-.1l38.46 64.1H119.12A63.748 63.748 0 0 0 96 360.88zM272 256c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zM400 96c0 8.82-7.18 16-16 16s-16-7.18-16-16 7.18-16 16-16 16 7.18 16 16zM64 80c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zM48 416c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zm336 16c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16z"
        />
      </MuiSvgIcon>
    );
  }

  if (icon === "pin") {
    return (
      <MuiSvgIcon viewBox="0 0 24 24" className={className} {...iconProps}>
        <path fill="currentColor" d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12M8.8,14L10,12.8V4H14V12.8L15.2,14H8.8Z" />
      </MuiSvgIcon>
    );
  }

  if (icon === "unpin") {
    return (
      <MuiSvgIcon viewBox="0 0 24 24" className={className} {...iconProps}>
        <path
          fill="currentColor"
          d="M8,6.2V4H7V2H17V4H16V12L18,14V16H17.8L14,12.2V4H10V8.2L8,6.2M20,20.7L18.7,22L12.8,16.1V22H11.2V16H6V14L8,12V11.3L2,5.3L3.3,4L20,20.7M8.8,14H10.6L9.7,13.1L8.8,14Z"
        />
      </MuiSvgIcon>
    );
  }

  if (icon === "ruler") {
    return (
      <MuiSvgIcon viewBox="0 0 32 32" className={className} {...iconProps}>
        <path
          fill="currentColor"
          d="M 0 8 L 0 9 L 0 23 L 0 24 L 1 24 L 31 24 L 32 24 L 32 23 L 32 9 L 32 8 L 31 8 L 1 8 L 0 8 z M 2 10 L 5 10 L 5 17 L 7 17 L 7 10 L 9 10 L 9 14 L 11 14 L 11 10 L 13 10 L 13 17 L 15 17 L 15 10 L 17 10 L 17 14 L 19 14 L 19 10 L 21 10 L 21 17 L 23 17 L 23 10 L 25 10 L 25 14 L 27 14 L 27 10 L 30 10 L 30 22 L 2 22 L 2 10 z"
        />
      </MuiSvgIcon>
    );
  }

  if (icon === "lineCenter") {
    return (
      <MuiSvgIcon viewBox="0 0 24 24">
        <path d="M19 13H5v-2h14v2z" />
        <circle cx="12" cy="12" r="3" />
      </MuiSvgIcon>
    );
  }

  if (icon === "points") {
    return (
      <MuiSvgIcon viewBox="0 0 24 24" {...iconProps}>
        <circle cx="3" cy="10" r="3" />
        <circle cx="14" cy="14" r="3" />
        <circle cx="9" cy="5" r="3" />
      </MuiSvgIcon>
    );
  }

  if (icon === "line") {
    return (
      <MuiSvgIcon viewBox="0 0 24 24" {...iconProps}>
        <line x1="4" y1="20" x2="20" y2="4" strokeWidth={5} stroke="currentColor" strokeLinecap="round" strokeDasharray="4 1" />
      </MuiSvgIcon>
    );
  }

  if (icon === "line") {
    return (
      <MuiSvgIcon viewBox="0 0 24 24" {...iconProps}>
        <line x1="4" y1="20" x2="20" y2="4" strokeWidth={5} stroke="currentColor" strokeLinecap="round" />
      </MuiSvgIcon>
    );
  }

  return null;
};

export default SvgIcon;
