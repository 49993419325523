import { useMemo } from "react";
import * as React from "react";
import { ListItem, ListItemText } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

import { InferredIcon } from "fond/map/Legend";
import { Configuration } from "fond/types";
import { LayerConfig, SublayerConfig } from "fond/types/ProjectLayerConfig";

const CSTFeatureMap: Record<string, string> = {
  cost_to_serve_addresses_layer_AccCost: "AccCost",
  cost_to_serve_addresses_layer_IndivCost: "IndivCost",
  cost_to_serve_addresses_layer_SharedCost: "SharedCost",
};

const customStyles = (theme: Theme) => {
  return createStyles({
    root: {
      minWidth: 300,
      overflow: "auto",
      minHeight: 0,
      maxHeight: 300,
    },
    icon: {
      minWidth: 24,
    },
    item: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    text: {
      lineHeight: 1,
      fontSize: "0.75rem",
    },
    polygon: {
      color: theme.palette.secondary.main,
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  config: Configuration;
  feature: any;
  layerConfigs: Array<LayerConfig | SublayerConfig>;
  onClick(): void;
}

const ListItemLayer: React.FC<IProps> = ({ classes, config, feature, layerConfigs, onClick }: IProps) => {
  /**
   * Find the layer or sublayer the feature belongs to
   */
  const layerConfig = useMemo(() => {
    let result: LayerConfig | SublayerConfig | undefined;
    layerConfigs.forEach((item) => {
      if (item.Styles.includes(feature.layer.id)) {
        result = item;
      }
    });
    return result;
  }, [feature, layerConfigs]);

  /**
   * Generates a feature label specific to cost to serve features in the format:
   * "Individual cost: $12,230"
   */
  const costToServeLabel = (entity: LayerConfig | SublayerConfig) => {
    const parentLayer = layerConfigs.find((item) => item.ID === entity.ParentID);
    if (parentLayer) return `${parentLayer?.Label}: $${feature.properties[CSTFeatureMap[parentLayer.ID]].toLocaleString()} `;
    return "";
  };

  if (!layerConfig) return null;

  return (
    <ListItem key={feature.id} button onClick={onClick} className={classes.item} data-testid="feature-select-item">
      <InferredIcon entity={layerConfig} config={config} />
      <ListItemText disableTypography className={classes.text}>
        {layerConfig?.SubType === "COST_TO_SERVE"
          ? costToServeLabel(layerConfig)
          : `${layerConfig?.Label}${
              feature.properties.FondID || feature.properties.BiarriID ? ` (${feature.properties.FondID || feature.properties.BiarriID})` : ``
            }`}
      </ListItemText>
    </ListItem>
  );
};

export default withStyles(customStyles)(ListItemLayer);
