import { createSelector } from "@reduxjs/toolkit";
import { omit } from "lodash";

import { AccountBase, Store, UserBase, UserRole } from "../types";

import { selectCurrentAccount } from "./accountSlice";
import { apiSlice } from "./apiSlice";
import { selectUserMeAccountsResult } from "./userSlice";

export type UserAccountAllocationRequest = {
  ID: string;
  Role?: UserRole;
  License?: boolean;
};

export type UserAccountAllocationResponse = {
  ID: string;
  User: UserBase;
  Account: AccountBase;
  License: boolean;
  Role: UserRole;
};

export const allocationSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    updateAllocation: build.mutation<UserAccountAllocationResponse, UserAccountAllocationRequest>({
      query: (patchAllocation) => ({
        url: `/v2/allocations/${patchAllocation.ID}`,
        method: "PATCH",
        body: omit(patchAllocation, "ID"),
      }),
      invalidatesTags: (result, error, arg) => [{ type: "UserAccountAllocation" as const, id: arg.ID }],
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useUpdateAllocationMutation } = allocationSlice;
export const selectCurrentAllocation = createSelector([(state: Store) => state, selectUserMeAccountsResult], (state: Store, result) => {
  if (!result?.data) {
    return null;
  }
  const account = selectCurrentAccount(state);
  return account ? result.data.find((allocation) => allocation.Account.ID === account.ID) : undefined;
});

export const selectCurrentRole = createSelector(selectCurrentAllocation, (allocation) => allocation?.Role);
