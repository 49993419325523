import { useEffect } from "react";
import { useNavigate } from "react-router";

import { useGetReportQuery } from "fond/api";
import { FullReport } from "fond/types";

export const useReportData = (reportId?: string): { report?: FullReport; isLoading: boolean } => {
  const navigate = useNavigate();
  const { data: report, isError, isLoading } = useGetReportQuery(reportId ?? "", { skip: !reportId });

  useEffect(() => {
    if (reportId && isError) {
      navigate("/");
    }
  });

  return { report, isLoading };
};

export const useGenerateReportHandle = (report?: FullReport): void => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!report) return;
    if (report.Status === null || report.Status?.State !== "COMPLETE") {
      navigate(`/reports/${report.ID}/generate`);
    }
  });
};
