import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import NumberField from "./NumberField";
import PreferenceFieldButton, { trafficLightData, TrafficLightValue } from "./PreferenceFieldButton";

type Props = {
  value: string | number;
  onChange: (value: string) => void;
  onEnterPress: () => void;
  disabled: boolean;
  className?: string;
};

const PreferenceField: React.FC<Props> = (props: Props) => {
  const { value, disabled, onChange, onEnterPress } = props;

  const hasCustomValue = value != null && !Object.values(trafficLightData).some((trafficLight) => trafficLight.cost.toString() === value.toString());
  return hasCustomValue ? (
    <NumberField {...props} />
  ) : (
    <ToggleButtonGroup
      value={value}
      exclusive
      size="small"
      onChange={(_, buttonGroupValue) => {
        onChange(buttonGroupValue);
      }}
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          onEnterPress();
        }
      }}
    >
      {[TrafficLightValue.neutral, TrafficLightValue.avoid, TrafficLightValue.prefer].map((trafficLightValue) => (
        <PreferenceFieldButton key={trafficLightValue} trafficLightValue={trafficLightValue} disabled={disabled} value={value} />
      ))}
    </ToggleButtonGroup>
  );
};

export default PreferenceField;
