import { useState } from "react";
import * as React from "react";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { Email as EmailIcon } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button } from "@mui/material";

import { LoadingButton } from "ui";

import { TextField } from "fond/form/fields";
import { CognitoResponseCode, getCognitoResponseHelperText, isUserNotConfirmed } from "fond/utils";
import { compose, required, validEmailFormat } from "fond/utils/validation";
import { SystemErrorDialog } from "fond/widgets";

interface IFormData {
  Email: string;
}

const ForgotPasswordForm: React.FC = () => {
  const [linkSent, setLinkSent] = useState(false);
  const [cognitoResponseCode, setCognitoResponseCode] = useState(null);

  const handleOnSubmit = async ({ Email }: IFormData) => {
    return new Promise((resolve) => {
      Auth.forgotPassword(Email.toLowerCase())
        .then(() => {
          setLinkSent(true);
          resolve("success");
        })
        .catch((error) => {
          resolve({
            Email: getCognitoResponseHelperText(error.code),
          });
          setCognitoResponseCode(error.code);
        });
    });
  };

  const handleResendVerificationEmail = (email: string) => {
    Auth.resendSignUp(email.toLowerCase());
    setLinkSent(true);
  };

  return (
    <>
      <SystemErrorDialog
        open={cognitoResponseCode === CognitoResponseCode.NETWORK_ERROR || cognitoResponseCode === CognitoResponseCode.UNKNOWN_ERROR}
        networkError={cognitoResponseCode === CognitoResponseCode.NETWORK_ERROR}
        onClose={() => {
          setCognitoResponseCode(null);
        }}
      />

      <Form<IFormData>
        initialValues={{
          Email: "",
        }}
        onSubmit={handleOnSubmit}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box>
                {linkSent ? (
                  <Alert severity="success" sx={{ mt: 4 }} data-testid="reset-password-link-sent">
                    <AlertTitle>{`${isUserNotConfirmed(cognitoResponseCode) ? "Verification" : "Reset password"} email sent!`}</AlertTitle>
                    {`Click on the link we've emailed you to ${
                      isUserNotConfirmed(cognitoResponseCode) ? " verify your email." : " reset your password."
                    }`}
                  </Alert>
                ) : (
                  <>
                    <Box sx={{ mb: 2 }}>
                      <TextField
                        name="Email"
                        label="Email"
                        validate={compose(required, validEmailFormat)}
                        InputProps={{
                          startAdornment: <EmailIcon color="action" />,
                        }}
                      />
                    </Box>
                    {isUserNotConfirmed(cognitoResponseCode) && (
                      <Button
                        data-testid="resend-verification-email-button"
                        fullWidth
                        sx={{ mt: 1 }}
                        onClick={() => handleResendVerificationEmail(values.Email)}
                      >
                        Resend the verification email
                      </Button>
                    )}
                    <Box sx={{ mt: 4 }}>
                      <LoadingButton
                        loading={submitting}
                        data-testid="reset-password-button"
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Send password reset link
                      </LoadingButton>
                    </Box>
                  </>
                )}
              </Box>
            </form>
          );
        }}
      />

      <Box sx={{ mt: 4, textAlign: "right" }}>
        <Button color="primary" component={Link} to="/signin">
          Back to Log In
        </Button>
      </Box>
    </>
  );
};

export default ForgotPasswordForm;
