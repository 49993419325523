import * as React from "react";
import { useNavigate, useParams } from "react-router";
import { ExitToApp, SettingsBackupRestore } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import logoImage from "svg_icons/logo-white.svg";

import { LoadingButton } from "ui";

import { Container, IconButton, Title } from "./topBar.styles";

interface IProps {
  /**
   * Flag indicating if the current styles are being saved.
   */
  isSaving?: boolean;
  onPublish(): void;
  onRevertButtonClick: () => void;
}

const TopBar: React.FC<IProps> = ({ isSaving = false, onPublish, onRevertButtonClick }: IProps) => {
  const { uuid: projectId } = useParams<{ uuid: string }>();
  const navigate = useNavigate();

  return (
    <Container data-testid="styles-top-bar">
      <Box display="flex" alignItems="center" flexDirection="row" sx={{ flex: 1, overflow: "hidden" }}>
        <Box pt={0.5} pl={0.5}>
          <IconButton data-testid="home-button" onClick={() => navigate("/workspace")}>
            <img alt="app-logo" src={logoImage} height={34} width={124} />
          </IconButton>
        </Box>
        <Title>Styles</Title>
      </Box>
      <Box>
        <Tooltip title="Discard draft">
          <IconButton data-testid="styles-revert-button" onClick={onRevertButtonClick}>
            <SettingsBackupRestore />
          </IconButton>
        </Tooltip>
        <Tooltip title="Exit">
          <IconButton data-testid="styles-exit-button" onClick={() => navigate(`/project/${projectId}`)}>
            <ExitToApp />
          </IconButton>
        </Tooltip>
        <LoadingButton
          data-testid="styles-publish-button"
          size="small"
          variant="contained"
          color="primary"
          disabled={isSaving}
          loading={isSaving}
          onClick={onPublish}
        >
          Publish
        </LoadingButton>
      </Box>
    </Container>
  );
};

export default TopBar;
