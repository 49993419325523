/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { FormatListBulleted } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { Legend } from "fond/map/Legend";

import { ITabProps } from "./factory";

/**
 * Main widget component that determines the content rendered within the tab
 */
export const Widget: React.FC<ITabProps> = ({ node }: ITabProps) => {
  return <Legend />;
};

/**
 * Icon component used by the iconFactory to generate the tabs left icon adornment.
 */
export const Icon: React.FC<ITabProps> = ({ node }: ITabProps) => (
  <Tooltip title="Legend">
    <FormatListBulleted />
  </Tooltip>
);
