import * as React from "react";
import { DescriptionOutlined, FolderOutlined, Image, InsertDriveFileOutlined } from "@mui/icons-material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

const customStyles = (theme: Theme) => {
  return createStyles({});
};

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * The mimetype of the file whose icon is to be displayed.
   */
  mimeType: string;
}

const MimeTypeIcon: React.FC<IProps> = ({ classes, mimeType }: IProps) => {
  let type;
  let subtype;
  const splitMimeType = mimeType.split("/");
  if (splitMimeType.length === 2) {
    [type, subtype] = splitMimeType;
  }

  /**
   * TODO: Neaten up the implementation but keep it easy to add new mime type icons.
   *
   * Andrew has suggested keeping a lookup mapping and implement such that the changes may be contained within that mapping.
   * For example, using something like:
   *
   * const iconLookup = {
   *   "text/*": Description,
   *   "image/*": Image,
   *   "application/zip": InsertDriveFile,
   *   "application/*": Folder,
   *   "*": InsertDriveFile
   * }
   */
  switch (type) {
    case "text":
      switch (subtype) {
        default:
          return <DescriptionOutlined color="primary" />;
      }
    case "image":
      switch (subtype) {
        default:
          return <Image color="primary" />;
      }
    case "application":
      switch (subtype) {
        case "zip":
        case "x-zip-compressed": {
          return <FolderOutlined color="primary" />;
        }
        default:
          return <InsertDriveFileOutlined color="primary" />;
      }
    default:
      return <InsertDriveFileOutlined color="primary" />;
  }
};

MimeTypeIcon.displayName = "MimeTypeIcon";
export default withStyles(customStyles)(MimeTypeIcon);
