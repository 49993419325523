import * as React from "react";
import { Help } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";

interface IProps {
  handleClose: () => void;
}

const KnowledgeBase: React.FC<IProps> = ({ handleClose }: IProps) => {
  return (
    <MenuItem
      data-testid="knowledge-base-button"
      component="a"
      href="https://fondhelp.biarrinetworks.com"
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClose}
    >
      <ListItemIcon>
        <Help />
      </ListItemIcon>
      Knowledge Base
    </MenuItem>
  );
};

export default KnowledgeBase;
