import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";

import { CardBox, StyledButton, StyledIcon } from "./PaymentMethod.styles";

interface IProps {
  title: string;
  icon: React.ReactNode;
  children?: string | React.ReactNode;
  onChange?: () => void;
}

const PaymentMethodCard: React.FC<IProps> = ({ title, icon, children, onChange }: IProps) => {
  return (
    <Box>
      <CardBox>
        <Box mt={1.5} display="flex" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <StyledIcon>{icon}</StyledIcon>
            <Typography fontSize="1rem" fontWeight="bold" sx={{ textTransform: "capitalize" }}>
              {title.toLowerCase()}
            </Typography>
          </Stack>
          {onChange && <StyledButton onClick={onChange}>Change</StyledButton>}
        </Box>
        {children}
      </CardBox>
    </Box>
  );
};

export default PaymentMethodCard;
