import { createEntityAdapter } from "@reduxjs/toolkit";
import { sortBy } from "lodash";

import { apiSlice } from "fond/api/apiSlice";
import { MAP_ICON_HEIGHT, MAP_ICON_WIDTH } from "fond/constants";
import { BaseIcon, Icon } from "fond/types/icon";

type GetIconsResponse = {
  Items: Icon[];
  Warning: string; // We currently ignore this Warning (only used for debugging in console devtools).
};

type GetBaseIconsResponse = {
  Items: BaseIcon[];
  Warning: string; // We currently ignore this Warning (only used for debugging in console devtools).
};

export const iconsAdapter = createEntityAdapter<Icon>({
  selectId: (entity: Icon): string => entity.ID,
});
const iconInitialState = iconsAdapter.getInitialState();

/**
 * Icons API Slice
 */
export const iconSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getIcons: build.query<BaseIcon[], undefined>({
      query: () => `/v1/icons?width=32&height=32`,
      transformResponse: (response: GetBaseIconsResponse) => {
        return sortBy(response.Items, "Name");
      },
    }),
    getMapIcons: build.query({
      query: (versionId: string) => `/v1/versions/${versionId}/map-icons?width=${MAP_ICON_WIDTH}&height=${MAP_ICON_HEIGHT}`,
      /**
       * Response data is base 64 strings of all icons required on the map.
       * The ID is an icon name, but this may have to change in the future if we start supporting
       * svg templating on the map, because a single icon may result in multiple pngs (eg. different fill colours).
       */
      transformResponse: (response: GetIconsResponse) => {
        return iconsAdapter.setAll(iconInitialState, response.Items);
      },
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useGetIconsQuery, useGetMapIconsQuery } = iconSlice;
