import * as React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { Email, Person } from "@mui/icons-material";
import { Box } from "@mui/material";

import { TextField } from "fond/form/fields";
import { Store } from "fond/types";

interface IFormData {
  Email: string;
  GivenName: string;
  LastName: string;
}

const EditProfileForm: React.FC = () => {
  const user = useSelector((state: Store) => state.cognito.user);
  const userAttrs = useSelector((state: Store) => state.cognito.userAttrs);

  return (
    <Form<IFormData>
      initialValues={{
        Email: user?.username,
        GivenName: userAttrs.givenName,
        LastName: userAttrs.familyName,
      }}
      onSubmit={() => {}}
      render={() => {
        return (
          <Box sx={{ width: 450 }}>
            <Box>
              <TextField
                name="Email"
                label="Email"
                disabled
                InputProps={{
                  startAdornment: <Person />,
                }}
              />
            </Box>
            <Box mt={2}>
              <TextField
                name="GivenName"
                label="First Name"
                disabled
                InputProps={{
                  startAdornment: <Person />,
                }}
              />
            </Box>
            <Box mt={2}>
              <TextField
                name="LastName"
                label="Last Name"
                disabled
                InputProps={{
                  startAdornment: <Email />,
                }}
              />
            </Box>
          </Box>
        );
      }}
    />
  );
};

export default EditProfileForm;
