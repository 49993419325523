import * as React from "react";
import { useSelector } from "react-redux";

import { selectCurrentAccount, selectUserId } from "fond/api";
import { RECENT_PROJECTS_KEY } from "fond/constants";
import { useContents } from "fond/hooks/useContents";
import { Folder, ProjectPreview, Report } from "fond/types";
import { RecentlyViewed } from "fond/types/recent";
import { getItem } from "fond/utils/localStorage";

import { BaseProjectList } from "./BaseProjectList";

type RecentEntity = ProjectPreview | Report;
const isRecentEntityType = (item: Report | ProjectPreview | Folder): item is RecentEntity =>
  item.EntityType === "project" || item.EntityType === "report";

const wasRecentlyAccessed =
  (recentItems: RecentlyViewed[]) =>
  (item: Report | ProjectPreview | Folder): item is RecentEntity =>
    isRecentEntityType(item) && recentItems.some((recent) => recent.projectId === item.ID);
/**
 * Recent content component
 */
const RecentContent: React.FC = () => {
  const { items } = useContents();
  const userId = useSelector(selectUserId);
  const accountId = useSelector(selectCurrentAccount)?.ID;

  // Load recent projects from local storage
  let recentItemsFromStorage: RecentlyViewed[] = getItem(`${RECENT_PROJECTS_KEY}_${userId}_${accountId}`, []);

  // Get recently viewed project details
  const recentItems: (RecentEntity & { LastViewed?: string })[] = items.filter(wasRecentlyAccessed(recentItemsFromStorage)).map((recentItem) => ({
    ...recentItem,
    LastViewed: recentItemsFromStorage.find((recent) => recent.projectId === recentItem.ID)?.lastViewed,
  }));

  return <BaseProjectList items={recentItems} />;
};

export default RecentContent;
