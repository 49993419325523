import { useContext, useEffect, useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { AllGeoJSON } from "@turf/helpers";

import { selectVersionConfig } from "fond/api";
import { LayoutContext } from "fond/layout/LayoutProvider";
import { selectWidget } from "fond/layout/widgets";
import FeaturePopup from "fond/map/featureSelection/FeaturePopup";
import FeaturesPopup from "fond/map/featureSelection/FeaturesPopup";
import { MapContext } from "fond/map/MapProvider";
import CommentFeatureEditor from "fond/project/comments/CommentFeatureEditor";
import CommentPopup from "fond/project/comments/CommentPopup";
import { selectFeature } from "fond/project/redux";
import { getOne, selectComment, setFilters, setSearchText } from "fond/redux/comments";
import * as turf from "fond/turf";
import { EditMode, Store, Widget } from "fond/types";
import { LayerConfig, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { useAppDispatch } from "fond/utils/hooks";

interface RouteParams {
  /**
   * UUID of the current project
   */
  uuid: string;
  /**
   * The type of selection
   */
  type: "comment" | "feature";
  /**
   *  UUID of the selected comment
   */
  id: string;
}

interface IProps {
  layerConfigs: Array<LayerConfig | SublayerConfig>;
  editMode: EditMode;
  onClose(): void;
}

/**
 * A wrapper component for displaying the Feature/Comment related popups on the map &
 * monitoring / handling the URL changes relayed to popup selection.
 */
const FeatureHandler: React.FC<IProps> = ({ editMode, layerConfigs, onClose }: IProps) => {
  const { type, id, uuid } = useParams<keyof RouteParams>() as RouteParams;
  const { map } = useContext(MapContext);
  const layout = useContext(LayoutContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedComment = useSelector((state: Store) => state.project.selectedComment);
  const selectedFeature = useSelector((state: Store) => state.project.selectedFeature);
  const featureSelectionPopup = useSelector((state: Store) => state.project.featureSelectionPopup);
  const versionId = useSelector((state: Store) => state.project.versionId);
  const config = useSelector((state: Store) => selectVersionConfig(state, versionId));
  const editId = useSelector((state: Store) => state.comments.editId);
  const comment = useSelector((state: Store) => getOne(state)(id));
  const [init, setInit] = useState(false);

  /**
   * Monitor for changes that require the URL to be updated
   */
  useEffect(() => {
    if (!selectedComment && type === "comment" && id) {
      navigate(`/project/${uuid}`);
    }
  }, [selectedComment, selectedFeature, featureSelectionPopup]);

  /**
   * If the URL contains a comment ID when the comment is first loaded
   * then select the comment.
   */
  useEffect(() => {
    if (id && type === "comment" && comment && !init) {
      // We set the init value which avoids a race condition where
      // the component mounts prior to comments being loaded
      setInit(true);
      dispatch(selectComment({ commentID: id, features: comment.Features, showPopup: true }));

      if (comment.State === "resolved") {
        // By default resolved comments are not shown - change that
        dispatch(setFilters({ State: ["resolved"], Importance: [], Version: [] }));
      }

      if (comment.Type !== "project") {
        if (comment.Features.length > 0) {
          const bestPoint = turf.pointOnFeature(comment.Features[0] as AllGeoJSON);
          map?.jumpTo({
            center: bestPoint.geometry.coordinates as [number, number],
          });
        }
      } else {
        // For project comments we need to open the comment panel
        // to display the selected comment as a popup cannot be shown.
        dispatch(setSearchText(comment.ID));
        if (layout.model) selectWidget(layout.model, Widget.Comments);
      }
    }
  }, []);

  /**
   * Handles selecting a single feature to view the details of.
   * This is called when a user selects one of X layers to view from
   * the FeaturesPopup.
   */
  const onFeatureSelected = async (feature: mapboxgl.MapboxGeoJSONFeature) => {
    if (feature.properties?.commentID) {
      dispatch(
        selectComment({
          commentID: feature.properties?.commentID,
          features: [feature],
          point: featureSelectionPopup.lngLat,
          isFromFeaturesPopup: true,
        })
      );
    } else {
      dispatch(selectFeature(feature, featureSelectionPopup.lngLat, true));
    }
  };

  return (
    <>
      {selectedFeature && (
        <FeaturePopup
          /*
           * This is to prevent against the possibility of saving to the wrong feature.
           * If we’re editing feature A and we click on feature B, we want to save feature A
           * and then start editing feature B. But because of how some of the other bits are wired up,
           * without the key, we can update the props of the FeaturePopup before doing the save,
           * and end up saving feature B instead.
           * With the key, when we click on feature B, we don’t update the props on the FeatureEditor
           * for feature A, we instead force React to create a new component, avoiding the issue.
           */
          key={`${selectedFeature.layerId}-${selectedFeature.featureId}`}
          onClose={onClose}
        />
      )}
      {featureSelectionPopup && (
        <FeaturesPopup
          features={featureSelectionPopup.features}
          lngLat={featureSelectionPopup.lngLat}
          layerConfigs={layerConfigs}
          config={config}
          onClose={onClose}
          onFeatureClick={onFeatureSelected}
        />
      )}
      {/*
        The CommentPopup is shown only when a comment has been selected & flagged with showPopup.
        If editMode === "comment" it means we are currently drawing a feature and the popup should
        be hidden
      */}
      {selectedComment?.lngLat && selectedComment.showPopup && <CommentPopup onClose={onClose} hidden={editMode === "comment"} />}

      {/*
        If the user is editing a feature present the Comment Feature Editor to the user
      */}
      {editId && selectedComment?.commentID && <CommentFeatureEditor commentId={selectedComment?.commentID} />}
    </>
  );
};

export default FeatureHandler;
