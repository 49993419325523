import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Theme } from "@mui/material/styles";
import { WithStyles, withStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";

import mixpanel from "fond/mixpanel";
import { CTSType } from "fond/types";

const customStyles = (theme: Theme) => {
  return createStyles({
    select: {
      backgroundColor: theme.palette.biarri.primary.lightGrey,
      marginBottom: theme.spacing(1),
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  method: CTSType;
  onChangeMethod: (method: CTSType) => void;
}

const ReportCostMapToggle: React.FC<IProps> = ({ classes, method, onChangeMethod }: IProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as CTSType;
    if (value !== method) {
      onChangeMethod(value);
      mixpanel.track("Report", "Cost", "Cost map cost type toggled");
    }
  };

  return (
    <Select data-testid="displayed-cost-item" value={method} onChange={handleChange} margin="dense" size="small">
      <MenuItem value="SharedCost">Shared cost</MenuItem>
      <MenuItem value="IndivCost">Individual cost</MenuItem>
      <MenuItem value="AccCost">Accumulation cost</MenuItem>
    </Select>
  );
};

export default withStyles(customStyles)(ReportCostMapToggle);
