import { useEffect, useRef } from "react";
import * as React from "react";
import { usePrevious } from "react-use";
import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, OutlinedInput, Select, Switch } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import classNames from "classnames";
import _ from "lodash";

import { ValidationMessage } from "fond/form";
import { ChipInput, SpareWidget } from "fond/form/fields";
import { FieldState, FieldType } from "fond/types";
import { getIn } from "fond/utils";

const customStyles = (theme: Theme) => {
  const errorColor = theme.palette.error.main;
  const warningColor = theme.palette.warning.main;

  return createStyles({
    root: {
      display: "flex",
      marginBottom: theme.spacing(3),
    },
    chipsInput: {
      height: "40px",
    },
    warningColor: {
      color: warningColor,
    },
    warningBorder: {
      border: `solid 1px ${warningColor} !important`,
    },
    errorBorder: {
      border: `solid 1px ${errorColor} !important`,
    },
  });
};

export interface FieldProps extends WithStyles<typeof customStyles> {
  /**
   * If a value is not set use the default value instead.
   */
  defaultValue?: any;
  /**
   * Attributes applied to the internal input element.
   *
   * Currently the only fieldProps property we support is InputProps, and we
   * only support it for text fields. Generalise this as needed.
   */
  fieldProps?: {
    InputProps: any;
  };
  /**
   *
   * @default 0
   */
  focusNumber?: number;
  /**
   * The helper text content.
   */
  helpText: string;
  /**
   * We destructure `hook` into `[state, getState]` and use `state` to show the current value, and `setValue` to modify it.
   *
   * TODO: This logic to update should not reside in this file.  The <Field /> component should be agnostic and simply take a value
   * & pass back the new value within the onChange function.
   */
  hook: [any, any];
  /**
   * The label content.
   */
  label: string;
  options?: React.ReactElement[];
  path: string[];
  /**
   * If true, the label is displayed as required and the input element` will be required.
   */
  required?: boolean;
  /**
   * Type of the input element. It should be a valid HTML5 input type.
   */
  type: FieldType;
  /**
   * The text relating to the widget state (i.e. validation messages) that replace the helper text when required.
   */
  widgetMessage: string;
  /**
   * Props applied to the input element
   */
  widgetProps?: Record<string, unknown>;
  /**
   * Indicates the current widget state and is used to applied
   * appropriate visual styles to indicate that state (e.g. error / warning)
   */
  widgetState: FieldState;
  /**
   * If set the field input will have a max width applied to it.
   */
  width?: number;
  /**
   * The size of the text field
   */
  size?: "small" | "medium";
  /**
   * The field is disabled or not
   */
  disabled?: boolean;
}

/**
  Renders a field, which is a column containing a label on the top of the widget.
  Optionally, if `helpText` is provided it will appear under the field.

  `Field` is designed so that a number of `Field`s can modify different parts
  of a larger object.

  Usage:

  function MyComponent() {
    const [state, setValue] = useState({
      section1: {
        field1: "initial value",
        field2: "other initial value"
      }
    });
    const hook = [state, setValue];

    return <>
      <Field
        hook={hook}
        path={['section1', 'field1']},
        label="Field 1"
        type='text'
      />
      <Field
        hook={hook}
        path={['section1', 'field2']},
        label="Field 2"
        type='text'
      />
    </>;
  }

  Note how `path` and `hook` work together. We destructure `hook` into `[state,
  getState]` and use `state` to show the current value, and `setValue` to
  modify it. For example the Field with path `['section1', 'field1']` gets its
  value from `state.section1.field1` and updates by calling
  `setValue(['section1', 'field1'], <new value>)`.
*/
const Field: React.FC<FieldProps> = ({
  defaultValue,
  classes,
  hook: [state, setValue],
  path,
  label,
  helpText,
  type,
  options,
  widgetState,
  widgetMessage,
  widgetProps,
  width,
  size = "small",
  required = false,
  focusNumber = 0,
  disabled = false,
  fieldProps,
}: FieldProps) => {
  const inputRef = useRef<HTMLInputElement>();
  let value = getIn(state, path);
  const fieldTag = `field-${path.join("-")}`;

  /**
   * Attempt to reference the default value if a value does not exist.
   */
  if (value === undefined && defaultValue !== undefined) {
    value = defaultValue;
  }

  /**
   * We focus if `focusNumber` is a number that is greater than it was last
   * time we rendered.  This is slightly more awkward then just focusing if
   * `focus` is true when it was previously false, but this way we don't have
   * to rely on the store to be updated whenever a field is blurred.
   */
  const prevFocusNumber = usePrevious(focusNumber);
  useEffect(() => {
    const { current } = inputRef;

    if (current && focusNumber > (prevFocusNumber || 0)) {
      current.focus();
    }
  }, [focusNumber, prevFocusNumber]);

  const validationClasses = {
    // If the user is passing InputProp classes we need to make sure we include these with the validation classes
    ..._.get(fieldProps, ["InputProps", "classes"], {}),
    notchedOutline: classNames({
      [classes.errorBorder]: widgetState === "error",
      [classes.warningBorder]: widgetState === "warning",
    }),
  };

  const error = widgetState === "error";

  /**
   * Handles the setting of the value for TextInput
   */
  const handleOnTextInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(path, event.currentTarget.value);
  };

  /**
   * Handles the setting of the value for the Spare Ports and Fibres Widgets
   */
  const handleOnSpareChange = (val: { value?: number; AbsoluteValue?: string; PercentValue?: string }) => {
    setValue(path, val);
  };

  /**
   * Handles the setting of the value for the Chips input
   */
  const handleOnChipsChange = (values: number[]) => {
    // Sort ascending
    values.sort((a, b) => a - b);
    setValue(path, values);
  };

  const renderField = (): React.ReactElement | null => {
    switch (type) {
      case "text": {
        return (
          <FormControl fullWidth>
            <FormLabel required={required} error={error}>
              {label}
            </FormLabel>
            <Box maxWidth={width} sx={{ mt: 1 }}>
              <OutlinedInput
                error={error}
                fullWidth={!!width}
                size={size}
                margin="dense"
                value={value}
                onChange={handleOnTextInputChange}
                inputProps={{
                  "data-testid": "input",
                  "aria-label": label,
                  ...{ ...(fieldProps || {}).InputProps },
                }}
                inputRef={inputRef}
                {...widgetProps}
              />
            </Box>
            <ValidationMessage state={widgetState} message={!widgetState ? helpText : widgetMessage} />
          </FormControl>
        );
      }
      case "textarea": {
        return (
          <FormControl fullWidth>
            <FormLabel required={required} error={error}>
              {label}
            </FormLabel>
            <Box maxWidth={width} sx={{ mt: 1 }}>
              <OutlinedInput
                error={error}
                fullWidth={!width}
                size={size}
                multiline
                margin="dense"
                value={value}
                onChange={handleOnTextInputChange}
                inputProps={{
                  "data-testid": `${label}-input`,
                }}
                inputRef={inputRef}
              />
            </Box>
            <ValidationMessage state={widgetState} message={!widgetState ? helpText : widgetMessage} />
          </FormControl>
        );
      }
      case "spareports": {
        return (
          <SpareWidget
            label="Spare Ports"
            widgetState={widgetState}
            helpText={!widgetState ? helpText : widgetMessage}
            quantity="ports"
            value={value}
            onChange={handleOnSpareChange}
            {...widgetProps}
          />
        );
      }
      case "sparefibers": {
        return (
          <SpareWidget
            label="Spare Fibers"
            widgetState={widgetState}
            helpText={!widgetState ? helpText : widgetMessage}
            quantity="fibers"
            value={value}
            onChange={handleOnSpareChange}
            {...widgetProps}
          />
        );
      }
      case "dropdown": {
        return (
          <FormControl fullWidth>
            <FormLabel required={required} error={error}>
              {label}
            </FormLabel>
            <Box maxWidth={width} sx={{ mt: 1 }}>
              <Select
                fullWidth={!!width}
                data-testid="select"
                value={value}
                onChange={(event) => setValue(path, event.target.value)}
                // variant='outlined' on a Select does not work:
                // https://github.com/mui-org/material-ui/issues/14203
                // But this does what variant='outlined' should do.
                input={<OutlinedInput size={size} />}
                margin="dense"
                disabled={disabled}
              >
                {options &&
                  options.map((option: React.ReactElement, index: number) => {
                    return React.cloneElement(option, { key: option.key || index });
                  })}
              </Select>
            </Box>
            <ValidationMessage state={widgetState} message={!widgetState ? helpText : widgetMessage} />
          </FormControl>
        );
      }
      case "checkbox": {
        return (
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="input"
                  checked={value}
                  color="primary"
                  onChange={(event) => setValue(path, event.target.checked)}
                  {...widgetProps}
                />
              }
              label={label}
            />
            <ValidationMessage state={widgetState} message={!widgetState ? helpText : widgetMessage} />
          </FormControl>
        );
      }
      case "chips": {
        return (
          <FormControl fullWidth>
            <FormLabel required={required} error={error}>
              {label}
            </FormLabel>
            <Box sx={{ mt: 1 }}>
              <ChipInput className={classes.chipsInput} classes={validationClasses} values={value} onCommit={handleOnChipsChange} {...widgetProps} />
              <ValidationMessage state={widgetState} message={!widgetState ? helpText : widgetMessage} />
            </Box>
          </FormControl>
        );
      }
      case "switch": {
        return (
          <FormControl fullWidth>
            <Grid container alignItems="center">
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      data-testid="input"
                      checked={value}
                      color="primary"
                      onChange={(event) => setValue(path, event.target.checked)}
                      {...widgetProps}
                    />
                  }
                  label={label}
                />
              </Grid>
            </Grid>
            <ValidationMessage state={widgetState} message={!widgetState ? helpText : widgetMessage} />
          </FormControl>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Grid container className={classes.root} data-testid={fieldTag} direction="column">
      <Grid item data-testid="widget">
        {renderField()}
      </Grid>
    </Grid>
  );
};

export default withStyles(customStyles)(Field);
