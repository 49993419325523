/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { ListAlt } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { FeatureListTypes, FeaturesList } from "fond/project/features";

import { ITabProps } from "./factory";

/**
 * Main widget component that determines the content rendered within the tab
 */
export const Widget: React.FC<ITabProps> = ({ node }: ITabProps) => {
  return <FeaturesList type={FeatureListTypes.DESIGN} />;
};

/**
 * Icon component used by the iconFactory to generate the tabs left icon adornment.
 */
export const Icon: React.FC<ITabProps> = ({ node }: ITabProps) => (
  <Tooltip title="Features">
    <ListAlt />
  </Tooltip>
);
