import * as React from "react";
import { useState } from "react";
import { useForm } from "react-final-form";
import { Box, ToggleButtonGroup, Typography } from "@mui/material";
import { cyan } from "@mui/material/colors";
import { useDebouncedCallback } from "use-debounce";

import { PaymentFrequencies, PaymentFrequency } from "fond/types/stripe";

import { IFormData } from "../CheckoutForm";

import { BillingPeriodChip, BillingPeriodChipContainer, BillingPeriodToggleButton } from "./BillingPeriod.styles";

type BillingPeriodProps = {
  billingPeriod: PaymentFrequency;
};

const BillingPeriod: React.FC<BillingPeriodProps> = ({ billingPeriod }) => {
  const form = useForm<IFormData>();
  const [selected, setSelected] = useState<PaymentFrequency>(billingPeriod ?? "yearly");

  const handleFormUpdate = useDebouncedCallback((newValue: PaymentFrequency) => {
    form.change("billingPeriod", newValue);
  }, 500);

  const handleSelected = (_: React.MouseEvent<HTMLElement, MouseEvent>, newValue: PaymentFrequency | null) => {
    if (newValue !== null) {
      setSelected(newValue);
      handleFormUpdate(newValue);
    }
  };

  return (
    <Box paddingBottom={3}>
      <Typography fontWeight="bold" marginBottom={1}>
        Choose how you'll pay
      </Typography>
      <ToggleButtonGroup fullWidth exclusive value={selected} onChange={handleSelected}>
        {PaymentFrequencies.map((frequency) => (
          <BillingPeriodToggleButton key={frequency} value={frequency}>
            {frequency}
          </BillingPeriodToggleButton>
        ))}
      </ToggleButtonGroup>
      <BillingPeriodChipContainer>
        <div /> {/* no savings for monthly payment   */}
        <BillingPeriodChip label="Save 5%" size="small" sx={{ backgroundColor: cyan.A700 }} />
        <BillingPeriodChip label="Save 10%" size="small" color="success" />
      </BillingPeriodChipContainer>
    </Box>
  );
};

export default BillingPeriod;
