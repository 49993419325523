/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { useSelector } from "react-redux";
import { Comment } from "@mui/icons-material";
import { Box, Chip, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import { CommentsPanel } from "fond/project/comments";
import { getUnresolved } from "fond/redux/comments";
import { Store } from "fond/types";

import { ITabProps } from "./factory";

const useCustomStyles = makeStyles((theme: Theme) => ({
  chip: {
    marginLeft: theme.spacing(0.5),
    height: theme.spacing(2),
    "& .MuiChip-label": {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      lineHeight: 10,
    },
  },
}));

/**
 * Main widget component that determines the content rendered within the tab
 */
export const Widget: React.FC<ITabProps> = ({ node }: ITabProps) => <CommentsPanel />;

/**
 * Icon component used by the iconFactory to generate the tabs left icon adornment.
 */
export const Icon: React.FC<ITabProps> = ({ node }: ITabProps) => {
  const comments = useSelector((state: Store) => getUnresolved(state));

  if (comments.length > 0) {
    return (
      <Tooltip title={comments.length > 1 ? `${comments.length} unresolved comments` : comments.length === 1 ? "1 unresolved comment" : "Comments"}>
        <Comment />
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Comments">
      <Comment />
    </Tooltip>
  );
};

/**
 * Title component used by the titleFactory to generate the tabs title text.
 */
export const Title: React.FC<ITabProps> = ({ node }: ITabProps) => {
  const classes = useCustomStyles();
  const comments = useSelector((state: Store) => getUnresolved(state));

  return (
    <Box>
      {node.getName()}
      {comments.length > 0 && <Chip size="small" label={comments.length} color="secondary" className={classes.chip} />}
    </Box>
  );
};
