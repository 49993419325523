export const CTSTypeListOrdered = ["AccCost", "SharedCost", "IndivCost", "RollCost", "ElemCost"] as const;
export type CTSType = (typeof CTSTypeListOrdered)[number];
export type CTSReportType = "AccCost" | "SharedCost" | "IndivCost";
export type CTSCalculationMethod = "equal_counts" | "natural_breaks" | "equal_intervals";

export type CTSBins = {
  [key in CTSType]: CTSRange;
};

export type CTSRange = {
  [key in CTSCalculationMethod]: number[];
};

export interface CTSBinRangesResponse {
  LayerToCost: "service_location";
  BinRanges: CTSBins;
}
