import React, { useMemo, useRef, useState } from "react";
import { Box, Modal, SelectChangeEvent, Zoom } from "@mui/material";
import { AgCartesianSeriesOptions, AgCharts, AgSeriesTooltip, AgSeriesTooltipRendererParams } from "ag-charts-community";
import { AgChartsReact } from "ag-charts-react";
import chroma from "chroma-js";
import dayjs from "dayjs";
import { groupBy } from "lodash";

import mixpanel from "fond/mixpanel";
import { Schedules } from "fond/types";
import { formatCurrency } from "fond/utils/currency";

import ReportRevenueChartTile from "./ReportRevenueChartTile";

const colors: string[] = [
  "#00B0FF",
  "#FDD835",
  "#F06292",
  "#00C853",
  "#FFA000",
  "#E53935",
  "#FFCCBC",
  "#8BC34A",
  "#BA68C8",
  "#80DEEA",
  "#26A69A",
  "#0D47A1",
  "#8C9EFF",
  "#546E7A",
];
interface IProps {
  selected: string;
  data: any[];
  onVisualizationTypeChange(value: Schedules): void;
}

const groupByAddressType = (data: any[]): any[] => {
  const grouped = groupBy(data, "Phase");
  return Object.keys(grouped).map((key) => {
    const row = grouped[key];
    return row.reduce(
      (acc, { AddressType, Phase, ...rest }) => {
        acc[sanitize(AddressType)] = { ...rest };
        return acc;
      },
      { Phase: key }
    );
  });
};

const groupByTag = (data: any[]): any[] => {
  const grouped = groupBy(data, "Phase");
  return Object.keys(grouped).map((key) => {
    const row = grouped[key];
    // Tags can contain special symbols(e.g, &, .) that the AG grid chart couldn't handle.
    // To avoid that, when plotting, we simplify the tags to "Tag_1", "Tag_2", etc.
    return row.reduce(
      (acc, { Tag, Phase, ...rest }, index) => {
        acc[`Tag_${index}`] = { ...rest };
        return acc;
      },
      { Phase: key }
    );
  });
};

const seriesTooltip = (keys: string[]): AgSeriesTooltip<AgSeriesTooltipRendererParams> => ({
  renderer: (params) => {
    let value = { ...params.datum };
    keys.forEach((key) => {
      value = value[key];
    });

    return {
      title: params.title,
      content: `${params.datum.Phase}: ${formatCurrency(value, { notation: "compact" })}`,
    };
  },
});

const sanitize = (value: string) => value.replaceAll(" ", "");

const removeEntryAll = (list: string[]) => list.filter((item) => item !== "All");

const formatDataPhase = (data: any[]) =>
  data.map(({ Phase, ...rest }) => {
    if (Phase == null) return rest;

    const isValidDate = dayjs(Phase).isValid();
    const formattedPhase = isValidDate ? dayjs(Phase).format("YYYY-MM") : Phase;
    return { Phase: formattedPhase, ...rest };
  });

const ReportRevenueChart: React.FC<IProps> = ({ selected, data, onVisualizationTypeChange }) => {
  const chartRef = useRef<AgChartsReact>(null);
  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);
  const chartData = useMemo(() => {
    const formattedData = formatDataPhase(data);
    if (selected === "Cashflow") return formattedData;
    if (selected === "Cost") return groupByTag(formattedData);
    return groupByAddressType(formattedData);
  }, [data, selected]);

  const chartSeries = useMemo((): AgCartesianSeriesOptions[] => {
    const addressTypes: string[] = removeEntryAll([...new Set<string>(data?.map(({ AddressType }) => AddressType))]);
    const colorRange = addressTypes.length > colors.length ? chroma.scale(colors).colors(addressTypes.length) : colors;

    switch (selected) {
      case "Hhp": {
        return addressTypes.map((addressType, index) => ({
          type: "area",
          xKey: "Phase",
          yKey: `${sanitize(addressType)}.CumulativeTotal`,
          yName: addressType,
          tooltip: seriesTooltip([sanitize(addressType), "CumulativeTotal"]),
          stacked: true,
          fill: colorRange[index],
        }));
      }
      case "Hhc": {
        return addressTypes.map((addressType, index) => ({
          type: "area",
          xKey: "Phase",
          yKey: `${sanitize(addressType)}.CumulativeTotal`,
          yName: addressType,
          tooltip: seriesTooltip([sanitize(addressType), "CumulativeTotal"]),
          stacked: true,
          fill: colorRange[index],
        }));
      }
      case "Revenue": {
        return addressTypes.map((addressType, index) => ({
          type: "area",
          xKey: "Phase",
          yKey: `${sanitize(addressType)}.CumulativeNetRevenue`,
          yName: addressType,
          tooltip: seriesTooltip([sanitize(addressType), "CumulativeNetRevenue"]),
          stacked: true,
          fill: colorRange[index],
        }));
      }
      case "Cashflow": {
        return [
          {
            type: "line",
            xKey: "Phase",
            yKey: "CumulativeNetPosition",
            yName: "Cumulative Net Position",
            tooltip: seriesTooltip(["CumulativeNetPosition"]),
          },
        ];
      }
      case "Cost": {
        return removeEntryAll([...new Set<string>(data?.map(({ Tag }) => Tag))]).map((tag, index) => ({
          type: "area",
          xKey: "Phase",
          yKey: `Tag_${index}.CumulativeNetCost`,
          yName: tag,
          tooltip: seriesTooltip([`Tag_${index}`, "CumulativeNetCost"]),
          stacked: true,
          fill: colorRange[index],
        }));
      }
      default: {
        return [];
      }
    }
  }, [data, selected]);

  const yAxisTitle = useMemo((): string => {
    switch (selected) {
      case "Hhp":
        return "Cumulative total";
      case "Hhc":
        return "Cumulative total";
      case "Revenue":
        return "Cumulative net revenue";
      case "Cost":
        return "Cumulative net cost";
      case "Cashflow":
        return "Cumulative net position";
      default:
        return "";
    }
  }, [selected]);

  const downloadChart = () => {
    mixpanel.track("Report", "Revenue", "Downloaded revenue chart");
    AgCharts.download(chartRef.current!.chart);
  };

  const handleVisualizationTypeChange = (event: SelectChangeEvent) => {
    onVisualizationTypeChange(event.target.value as Schedules);
  };

  const toggleFullscreen = () => {
    setIsFullscreenOpen((prev) => !prev);
  };

  const chartTileProps = {
    ref: chartRef,
    visualizationType: selected,
    chartData: chartData,
    chartSeries: chartSeries,
    yAxisTitle: yAxisTitle,
    handleVisualizationTypeChange: handleVisualizationTypeChange,
    downloadChart: downloadChart,
    toggleFullscreen: toggleFullscreen,
  };

  return (
    <>
      <ReportRevenueChartTile {...chartTileProps} />
      <Modal open={isFullscreenOpen}>
        <Zoom in={isFullscreenOpen}>
          <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <Box width="90vw" height="45vw">
              <ReportRevenueChartTile {...chartTileProps} fullscreen />
            </Box>
          </Box>
        </Zoom>
      </Modal>
    </>
  );
};

export default ReportRevenueChart;
