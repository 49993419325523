import * as React from "react";
import { useState } from "react";
import { Button } from "@mui/material";

import { Modal } from "fond/widgets";

import PricingTable from "./PricingTable";

interface PricingTableProps {
  renderButton: (onClick: () => void) => React.ReactNode;
}

const PricingTableButton: React.FC<PricingTableProps> = ({ renderButton }) => {
  const [showTable, setShowTable] = useState(false);
  const onClick = () => {
    setShowTable(true);
  };

  const onClose = () => setShowTable(false);
  return (
    <>
      <Modal
        header="Planner Subscriptions"
        headerSubtitle="PRICING"
        variant="primary"
        open={showTable}
        content={<PricingTable />}
        onClose={onClose}
        actions={<Button onClick={onClose}>Close</Button>}
      />
      {renderButton(onClick)}
    </>
  );
};

export default PricingTableButton;
