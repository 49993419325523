import { useMemo, useState } from "react";
import * as React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { Alert, Box, Button, Typography } from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { selectFoldersForFolderMove, useMoveFolderMutation } from "fond/api";
import { HOME_FOLDER_ID, HOME_FOLDER_LABEL, HOME_FOLDER_OPTION } from "fond/constants";
import { Autocomplete } from "fond/form/fields";
import { AnyObject, Folder, HomeFolder, Store } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { required } from "fond/utils/validation";
import { Message, Modal } from "fond/widgets";

interface IFormData {
  FolderId: string;
}

interface IProps {
  /**
   * The Folder being moved
   */
  folder: Folder;
  /**
   * Callback function to handle the onClose of the modal
   */
  onClose(): void;
}

interface ConfirmationModalProps {
  isOpen: boolean;
  onConfirm(): Promise<void>;
  onClose(): void;
  folderName: string;
  isSaving: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, onConfirm, folderName, isSaving }: ConfirmationModalProps) => (
  <Modal
    open={isOpen}
    header="Move folder?"
    content={
      <Box>
        <Typography>
          Are you sure you wish to move the folder <strong>{folderName}</strong>?
        </Typography>
        <Typography>Moving a folder to a new location has the potential to change who can access & edit this folder and its contents.</Typography>
      </Box>
    }
    actions={
      <>
        <Button data-testid="move-folder-confirm-cancel-button" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
          Cancel
        </Button>
        <LoadingButton data-testid="move-folder-confirm-button" color="primary" onClick={onConfirm} loading={isSaving}>
          Confirm
        </LoadingButton>
      </>
    }
  />
);

const MoveFolderDialog: React.FC<IProps> = ({ folder, onClose }: IProps) => {
  const [confirmModal, showConfirmModal] = useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState<Pick<Folder, "ID" | "ParentID">>();
  const { enqueueSnackbar } = useSnackbar();
  const [moveFolder, { isLoading: isSaving, isError }] = useMoveFolderMutation();
  const folders = useSelector((state: Store) => selectFoldersForFolderMove(state, folder));
  const canMove = permissionCheck(folder.Permission.Level, Actions.FOLDER_MOVE);

  // folderOptions adds home route that allows user to move a project back to home screen
  // It sets the ID to "home" rather than null because TS will only allow for a string
  const folderOptions = useMemo(() => {
    const foldersExceptSelf = folders.filter((currFolder: Folder) => currFolder.ID !== folder.ID);
    return [HOME_FOLDER_OPTION, ..._.sortBy(foldersExceptSelf, ["Path", "Name"])];
  }, [folder.ID, folders]);

  /**
   * Note that we need to use closure to trigger the submit of the form.
   * This is due to the submit button existing in the modal actions rather than in the <form />.
   *
   * See https://final-form.org/docs/react-final-form/faq#how-can-i-trigger-a-submit-from-outside-my-form
   */
  let submit: (event?: Partial<Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">>) => Promise<AnyObject | undefined> | undefined;

  /**
   * Callback function for the Create button within the modal actions
   */
  const handleOnClick = (event: React.MouseEvent<EventTarget>) => {
    // Submits the React-Final-Form (which handles validation & calling onSubmit is validation passes)
    submit();
  };

  const confirmFolderMove = async () => {
    if (!selectedFolder) return;
    try {
      await moveFolder(selectedFolder).unwrap();
      enqueueSnackbar("The Folder has been moved");
    } catch {
      enqueueSnackbar("Folder move failed. Please try again...");
    } finally {
      showConfirmModal(false);
      onClose();
    }
  };

  /**
   * On submit function called when the form is submitted and valid
   */
  const handleOnSubmit = (values: IFormData) => {
    const folderId = values.FolderId;
    setSelectedFolder({ ID: folder.ID, ParentID: folderId === HOME_FOLDER_ID ? null : folderId });
    showConfirmModal(true);
  };

  return (
    <>
      <Modal
        open
        header="Move this folder"
        data-testid="move-folder-modal"
        content={
          <>
            {isError && (
              <Box mb={2}>
                <Message type="error">There was an issue moving this folder. Please try again.</Message>
              </Box>
            )}
            {!canMove ? (
              <Box mb={2}>
                <Alert severity="info">You do not have permission to move this folder.</Alert>
              </Box>
            ) : (
              <>
                <Box mb={2}>
                  <Alert severity="warning">
                    Moving a folder to a new location has the potential to change who can access & edit this folder and its contents.
                  </Alert>
                </Box>
                <Form<IFormData>
                  onSubmit={handleOnSubmit}
                  initialValues={{ FolderId: folder.ParentID || HOME_FOLDER_ID }}
                  render={({ handleSubmit }) => {
                    submit = handleSubmit;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Box>
                          <Autocomplete
                            name="FolderId"
                            required
                            label="Folder"
                            fullWidth
                            options={folderOptions}
                            getOptionLabel={(option) => option.Name}
                            getOptionValue={(option) => option?.ID || HOME_FOLDER_ID}
                            renderOption={(props, option: Folder | HomeFolder) => (
                              <li {...props} key={option.ID}>
                                <Box data-testid="move-folder-item" display="flex" flexDirection="column">
                                  <Typography>{option.Name}</Typography>
                                  <Typography variant="caption">
                                    {`${folder.Account.Name} > ${HOME_FOLDER_LABEL} ${option.Path.length ? " > " : ""} ${option.Path.join(" > ")}`}
                                  </Typography>
                                </Box>
                              </li>
                            )}
                            filterOptions={(options, state) =>
                              options.filter((option) => option.Name.toLowerCase().includes(state.inputValue.toLowerCase()))
                            }
                            validate={required}
                          />
                        </Box>
                      </form>
                    );
                  }}
                />
              </>
            )}
          </>
        }
        actions={
          <>
            <Button data-testid="move-folder-cancel-button" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
              Cancel
            </Button>
            {canMove && (
              <Button data-testid="move-folder-save-button" color="primary" variant="contained" onClick={handleOnClick}>
                Move
              </Button>
            )}
          </>
        }
        onClose={onClose}
      />
      <ConfirmationModal
        isOpen={confirmModal}
        folderName={folder.Name}
        onConfirm={() => confirmFolderMove()}
        onClose={() => showConfirmModal(false)}
        isSaving={isSaving}
      />
    </>
  );
};

export default MoveFolderDialog;
