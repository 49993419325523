import * as React from "react";
import { AddBox } from "@mui/icons-material";
import { Divider, IconButton, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { HelpIcon } from "fond/widgets";

import { create, useArchitectureEditorContext } from "./context";

const useCustomStyles = makeStyles((theme: Theme) => {
  return {
    archTitle: {
      fontSize: "1.3rem",
      marginRight: theme.spacing(1),
    },
    baseArchHeader: {
      display: "flex",
      flex: 1,
      margin: "20px 16px 16px",
    },
  };
});

const ArchitectureListHeader: React.FC = () => {
  const classes = useCustomStyles();
  const [, dispatch] = useArchitectureEditorContext();

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div className={classes.baseArchHeader}>
          <Typography variant="h4" className={classes.archTitle}>
            {/* Also known as "base architectures */}
            Available architectures
          </Typography>
          <HelpIcon helpText="These are all the architectures created by you and other users in your company’s FOND account. Changing these will not affect the architecture in any project." />
        </div>
        <IconButton
          color="primary"
          data-testid="create-architecture-button"
          onClick={() => {
            dispatch(create());
          }}
          style={{ padding: 6 }}
        >
          <AddBox style={{ fontSize: "3rem" }} />
        </IconButton>
      </div>
      <Divider component="div" />
    </div>
  );
};

export default ArchitectureListHeader;
