import { memo, MouseEventHandler } from "react";
import * as React from "react";
import { ICellRendererParams } from "@ag-grid-community/core";

import { Link } from "./styles";

interface IProps extends ICellRendererParams {
  href?: string;
  onClick?(data: any): (MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLSpanElement>) | undefined;
}

const LinkCellRenderer: React.FC<IProps> = memo(({ onClick, href, value, data }: IProps) => (
  <Link onClick={onClick?.(data)} href={href} color="primary">
    {value}
  </Link>
));

LinkCellRenderer.displayName = "LinkCellRenderer";

export default LinkCellRenderer;
