import * as React from "react";

import { PageContent } from "fond/widgets";

import { Navigation } from "../Navigation";

import SearchResultContainer from "./SearchResultContainer";

const SearchResults: React.FC = () => (
  <PageContent title="Search Results" mainContent={<SearchResultContainer />} navigation={<Navigation />} testId="search-results" />
);

export default SearchResults;
