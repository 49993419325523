import React, { useState } from "react";
import { ContactSupport } from "@mui/icons-material";
import { Box, Button, Checkbox, Divider, FormControlLabel, Typography } from "@mui/material";
import { FeatureCollection } from "geojson";

import { apiSlice, useGetVersionQuery } from "fond/api";
import { inputLayerGroups, LayerGroupIds, LayerIds } from "fond/layers";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";
import { useStackedNavigationContext } from "fond/widgets";
import StackedNavigationHeader from "fond/widgets/StackedNavigation/StackedNavigationHeader";

import { getArchitectureAddressTypes, getUnknownAddressTypes, MissingAddressTypeFieldAlert, UnknownAddressTypesAlert } from "../addressTypes";
import { beginLayerUpload, closeUploadPanel, dismissUploadError } from "../redux";
import { getCurrentProjectData, getCurrentProjectUploads } from "..";

import Upload from "./upload/Upload";
import UploadProgress from "./upload/UploadProgress";

import { Alert, AlertTitle, DefaultAlert } from "./InputDataPanel.styles";

const UploadAddresses: React.FC = () => {
  const { open, clear, goBack } = useStackedNavigationContext();
  const dispatch = useAppDispatch();
  const uploadPopupLayerGroupId = useAppSelector((state) => state.project.uploadPopupLayerGroupId);
  const inputLayerGroup = inputLayerGroups[uploadPopupLayerGroupId];
  const { projectId, versionId } = useAppSelector((state) => state.project);
  const dataLayers = useAppSelector((state) => getCurrentProjectData(state.project).layers);
  const upload = useAppSelector((state) => inputLayerGroup && getCurrentProjectUploads(state.project)[inputLayerGroup.id]);
  const { data: version } = useGetVersionQuery(versionId, { skip: !versionId });
  const defaultIsReplacing = useAppSelector((state) => state.project.isReplacing || false);

  const [isReplacing, setIsReplacing] = useState(defaultIsReplacing);
  const [convertToDualSided, setConvertToDualSided] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<{ [layerId: string]: File[] | null }>({});
  const [unknownAddressTypes, setUnknownAddressTypes] = useState<string[]>([]);
  const [addressTypeFieldMissing, setAddressTypeFieldMissing] = useState(false);
  const defaultFeatureCollection: FeatureCollection = {
    features: [],
    type: "FeatureCollection",
  };
  const [featureCollection, setFeatureCollection] = useState<FeatureCollection>(defaultFeatureCollection);
  const [selectedAddressTypeField, setSelectedAddressTypeField] = useState<string>(version?.AddressTypeField || "AddressType");

  const dataExists =
    inputLayerGroup &&
    inputLayerGroup.layers.some((layer) => {
      return (dataLayers[layer.id]?.features?.length ?? 0) > 0;
    });
  const hasSelectedFiles = Object.values(selectedFiles).some((v) => v != null);

  const architectureAddressTypes = version?.Architecture ? getArchitectureAddressTypes({ architecture: version.Architecture }) : null;
  const autoUpload = [LayerGroupIds.inExchange].includes(uploadPopupLayerGroupId);

  const onDismissUploadError = (layerId: string) => dispatch(dismissUploadError(layerId));
  const onSuccessfulUpload = () => {
    clear();
    if (inputLayerGroup.id === "spanPole") open("draw");
    dispatch(apiSlice.util.invalidateTags([{ type: "Version", id: versionId }]));
  };

  const onBeginUpload = (uploads: { [layerId: string]: File[] | null }, addressType: string) => {
    dispatch(beginLayerUpload(projectId, versionId, inputLayerGroup.id, uploads, convertToDualSided, addressType, onSuccessfulUpload));
  };

  const handleSingleUploadDrop = (files: File[]) => {
    const { layers } = inputLayerGroup;
    if (layers.length !== 1) {
      throw new Error("Should have exactly one layer if we get here");
    }
    onBeginUpload({ [layers[0].id]: files }, selectedAddressTypeField);
  };

  const identifyUnknownAddressTypes = (features: FeatureCollection, addressTypeField?: string) => {
    if (addressTypeField) setSelectedAddressTypeField(addressTypeField);
    if (architectureAddressTypes !== null) {
      const { unknownAddressTypes: addressTypes, addressTypeFieldPresent } = getUnknownAddressTypes({
        featureCollection: features,
        knownAddressTypes: architectureAddressTypes,
        addressTypeField: addressTypeField,
      });

      setUnknownAddressTypes(addressTypes);
      setAddressTypeFieldMissing(!addressTypeFieldPresent);
    } else {
      setUnknownAddressTypes([]);
      setAddressTypeFieldMissing(false);
    }
  };

  const handleBack = () => {
    dispatch(closeUploadPanel(inputLayerGroup.id));

    if (upload && upload.uploadComplete) {
      // If upload is completed but data is being processed, back button should navigate user back to root panel
      clear();
    } else {
      goBack();
    }
  };

  if (!upload && dataExists && !isReplacing) {
    return (
      <>
        <StackedNavigationHeader onBackButtonClick={handleBack} title="Upload" />
        <Box px={1}>
          <Typography fontWeight="500" mb={2}>
            {inputLayerGroup.name}
          </Typography>
          <Alert severity="info" sx={{ mb: 2 }}>
            <AlertTitle>Replace data</AlertTitle>
            <Typography variant="body3" component="p" mb={1}>
              You have already uploaded {inputLayerGroup.name} for this project.
            </Typography>
            <Typography variant="body3" component="p">
              If you like, you can replace them.
            </Typography>
          </Alert>
          <Divider />
          <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
            <Button color="primary" size="small" onClick={handleBack}>
              Cancel
            </Button>
            <Button variant="contained" size="small" onClick={() => setIsReplacing(true)} sx={{ ml: 1, px: 2 }}>
              Replace
            </Button>
          </Box>
        </Box>
      </>
    );
  }

  if (upload) {
    return (
      <>
        <StackedNavigationHeader onBackButtonClick={handleBack} title="Upload" />
        <Box px={1} mb={2}>
          <Typography fontWeight="500">{inputLayerGroup.name}</Typography>
        </Box>
        <UploadProgress upload={upload} onDismissUploadError={() => onDismissUploadError(inputLayerGroup.id)} inputLayer={inputLayerGroup.id} />
      </>
    );
  }

  return (
    <>
      <StackedNavigationHeader onBackButtonClick={handleBack} title="Upload" />
      <Box px={1}>
        {unknownAddressTypes.length > 0 && <UnknownAddressTypesAlert unknownAddressTypes={unknownAddressTypes} />}
        {addressTypeFieldMissing && (
          <MissingAddressTypeFieldAlert featureCollection={featureCollection} identifyUnknownAddressTypes={identifyUnknownAddressTypes} />
        )}
        {uploadPopupLayerGroupId === LayerGroupIds.spanPole && (
          <Box px={1}>
            <Typography variant="caption" sx={{ color: (theme) => theme.palette.biarri.secondary.grey }}>
              You may upload Aerial spans, Poles, or both
            </Typography>
          </Box>
        )}
        {inputLayerGroup &&
          inputLayerGroup.layers.map((layer) => (
            <Upload
              key={layer.id}
              layer={layer}
              autoUpload={autoUpload}
              onBeginUpload={handleSingleUploadDrop}
              onSelectFiles={(files: File[]) => {
                setSelectedFiles((prev) => ({ ...prev, [layer.id]: files }));
              }}
              onRemove={() => {
                setSelectedFiles((prev) => ({ ...prev, [layer.id]: null }));
                if (layer.id === LayerIds.inAddress) {
                  setUnknownAddressTypes([]);
                  setAddressTypeFieldMissing(false);
                }
              }}
              layerValidator={(features) => {
                if (layer.id === LayerIds.inAddress) {
                  identifyUnknownAddressTypes(features);
                }
                setFeatureCollection(features);
              }}
              convertToDualSidedField={
                layer.id === LayerIds.inStreet ? (
                  <Box my={1} p={1}>
                    <Typography fontWeight="500">Advanced control</Typography>
                    <FormControlLabel
                      control={<Checkbox checked={convertToDualSided} onChange={() => setConvertToDualSided((prev) => !prev)} />}
                      label={<Typography variant="body3">Convert to dual-sided</Typography>}
                    />
                    <Typography variant="caption" color={(theme) => theme.palette.biarri.secondary.grey} component="p" letterSpacing={0.53}>
                      FOND will approximate underground with streets and can convert streets centerlines to a dual-sided network.
                    </Typography>
                  </Box>
                ) : null
              }
            />
          ))}
        {uploadPopupLayerGroupId === LayerGroupIds.inAddress && (
          <DefaultAlert sx={{ mb: 1.5 }} severity="info" icon={<ContactSupport sx={{ fontSize: "inherit", color: "rgba(0, 0, 0, 0.56)" }} />}>
            <Box>
              <Typography variant="h7" component="p" letterSpacing={0.15}>
                You can control how each address point is served after the upload is complete.
              </Typography>
              <Button
                variant="text"
                sx={{ padding: 0, fontSize: 12, mt: 1 }}
                component="a"
                href="https://fondhelp.biarrinetworks.com/how-to-control-fiber-allocation-in-fond"
                target="_blank"
                rel="noreferrer"
              >
                Learn it here
              </Button>
            </Box>
          </DefaultAlert>
        )}
        {!autoUpload && (
          <Box>
            <Divider />
            <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
              <Button color="primary" size="small" onClick={handleBack}>
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                disabled={!hasSelectedFiles}
                onClick={() => onBeginUpload(selectedFiles, selectedAddressTypeField)}
                sx={{ ml: 1, px: 2 }}
                data-testid="upload-button"
              >
                Upload
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default UploadAddresses;
