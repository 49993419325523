import { useCallback } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";

import MapPopup from "fond/map/MapPopup";
import BaseComment from "fond/project/comments/Comment";
import { unselectComment } from "fond/project/redux";
import { getOne } from "fond/redux/comments";
import { Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";

const customStyles = (theme: Theme) => ({});

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * Flag indicating if the popup should be hidden from view.
   * This is used for editing a feature under the popup.
   */
  hidden: boolean;
  /**
   * Callback function for when the popup closes
   */
  onClose(): void;
}

/**
 * A popup that appears when the user clicks a comment on the map.
 */
const CommentPopup: React.FC<IProps> = ({ classes, hidden, onClose }: IProps) => {
  const dispatch = useAppDispatch();
  const { selectedComment } = useSelector((state: Store) => state.project);
  const comment = useSelector((state: Store) => getOne(state)(selectedComment.commentID));
  const draggedPopupPosition = useSelector((state: Store) => state.project.draggedPopupPosition);

  /**
   * Callback function called when the popup closes
   */
  const handleClose = useCallback(({ type }: { type: string }) => {
    if (type === "manual-close") {
      dispatch(unselectComment());
    }
  }, []);

  if (!comment) return null;

  return (
    <MapPopup
      className="comment-popup"
      lngLat={draggedPopupPosition || selectedComment.lngLat}
      onClose={handleClose}
      showCloseIcon={false}
      hidden={hidden}
    >
      <Box mx={-1.25} mt={-1.25} minWidth={350} maxWidth={350} key={comment.ID}>
        <BaseComment comment={comment} inPopup />
      </Box>
    </MapPopup>
  );
};

export default withStyles(customStyles)(CommentPopup);
