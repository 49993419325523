import * as React from "react";
import { PersonAddAlt1 as AddMemberIcon } from "@mui/icons-material";
import { Button } from "@mui/material";

import InviteMembersModal from "./InviteMembersModal";

const InviteMembersButton: React.FC = () => {
  const [isInviteMembersModalShown, setInviteMembersModalDisplay] = React.useState(false);
  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddMemberIcon />}
        onClick={() => setInviteMembersModalDisplay(true)}
        data-testid="invite-members-button"
      >
        Invite members
      </Button>
      <InviteMembersModal isOpen={isInviteMembersModalShown} onClose={() => setInviteMembersModalDisplay(false)} />
    </>
  );
};

export default InviteMembersButton;
