import * as React from "react";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { Typography, useTheme } from "@mui/material";

import { PopularLabelBox } from "./PricingTable.styles";

const PopularLabel: React.FC = () => {
  const theme = useTheme();
  return (
    <PopularLabelBox>
      <WhatshotIcon style={{ color: theme.palette.common.white }} />
      <Typography color={theme.palette.common.white} fontWeight="bold">
        POPULAR
      </Typography>
    </PopularLabelBox>
  );
};

export default PopularLabel;
