import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Divider, Link, Typography, useTheme } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useElements, useStripe } from "@stripe/react-stripe-js";

import { LoadingButton } from "ui";

import { useCreatePurchaseSummaryMutation, useGetProductsQuery, useGetSubscriptionTiersQuery } from "fond/api";
import { PRIVACY_URL, PRODUCT_CATEGORY_KEY, TOS_URL } from "fond/constants";
import { USFlagIcon } from "fond/svg_icons";
import { LineItem as LineItemType, PaymentFrequency, ProductCategories, PurchaseSummary } from "fond/types/stripe";
import { getCurrencyFromStripe } from "fond/utils/currency";
import { useQueryParams } from "fond/utils/hooks";
import { getLicenseLineItem, getSubscriptionLineItem } from "fond/utils/products";
import { BlockSpinner } from "fond/widgets";

import type { IFormData } from "../CheckoutForm";
import { ACCEPTED_COUNTRIES } from "../PaymentMethod/hooks/useAddressHandlers";

import BillingPeriod from "./BillingPeriod";
import LineItem from "./LineItem";
import PromotionCodeInput from "./PromotionCodeInput";
import PromotionCodeTag from "./PromotionCodeTag";

import { SummaryRow } from "../Checkout.styles";
import { CurrencyContainer, SpecialOffer, SpecialOfferDivider, SummaryCaption } from "./InvoiceSummary.styles";

export const BILLING_PERIOD_TO_NUMBER_OF_PAYMENTS: { [period in PaymentFrequency]: number } = {
  monthly: 12,
  quarterly: 4,
  yearly: 1,
};

interface InvoiceSummaryProps {
  isLoading: boolean;
  values: IFormData;
  updateField: <F extends keyof IFormData>(fieldName: F, value: IFormData[F]) => void;
  submitText?: string;
}

const InvoiceSummary: React.FC<InvoiceSummaryProps> = ({ isLoading, values, updateField, submitText }) => {
  const theme = useTheme();
  const [summary, setSummary] = useState<PurchaseSummary>();
  const [validCountry, setValidCountry] = useState(true);
  const discount = summary?.TotalDiscountAmounts?.[0]?.Discount;
  const [createPurchaseSummary, { isLoading: isPurchaseSummaryLoading }] = useCreatePurchaseSummaryMutation();
  const { data: subscriptionTiers } = useGetSubscriptionTiersQuery();
  const { data: products } = useGetProductsQuery();
  const productCategory = useQueryParams<ProductCategories>(PRODUCT_CATEGORY_KEY);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!values.country) {
      setValidCountry(true);
    } else {
      setValidCountry(ACCEPTED_COUNTRIES.includes(values.country));
    }
  }, [values.country]);

  useEffect(() => {
    async function updateSummary() {
      const subscriptionLineItem = getSubscriptionLineItem(products, values.billingPeriod, values.productId);
      const licenseLineItem = getLicenseLineItem(products, values.billingPeriod, values.numLicenses);
      const lineItems: LineItemType[] = [subscriptionLineItem, licenseLineItem].filter<LineItemType>((item): item is LineItemType => Boolean(item));

      if (!lineItems.length) {
        Sentry.captureMessage(`Failed to generate line items from form values ${values}`, "warning");
        return;
      }

      const purchaseSummaryRequest = {
        LineItems: lineItems,
        PromoCode: values.promoCode,
        Address: values.address,
      };

      try {
        const response = await createPurchaseSummary(purchaseSummaryRequest).unwrap();
        setSummary(response);
      } catch (err) {
        if (err instanceof Object && "message" in err && err.message === "Invalid coupon") {
          updateField("promoCode", "");
        }
      }
    }
    updateSummary();
  }, [
    createPurchaseSummary,
    products,
    subscriptionTiers,
    updateField,
    values.address,
    values.billingPeriod,
    values.numLicenses,
    values.productId,
    values.promoCode,
  ]);

  return isPurchaseSummaryLoading ? (
    <BlockSpinner containerProps={{ height: "100%" }} />
  ) : (
    <Box data-testid="checkout-summary">
      <Typography variant="h2" paddingBottom={3}>
        Summary
      </Typography>
      <SummaryRow>
        <Typography variant="h6">Currency</Typography>
        <CurrencyContainer>
          <USFlagIcon /> United States (USD)
          <Typography color={theme.palette.biarri.secondary.activeBlue} />
        </CurrencyContainer>
      </SummaryRow>
      <Divider sx={{ marginBottom: 3, marginTop: 1 }} />
      {summary?.Lines?.Data.map((lineItem) => <LineItem key={lineItem.Description} lineItem={lineItem} />)}

      <Divider />

      <SummaryRow paddingY={2}>
        <Typography>Subtotal</Typography>
        <Typography fontWeight="bold">{getCurrencyFromStripe(summary?.Subtotal ?? 0)}</Typography>
      </SummaryRow>

      {productCategory === "subscription" && ( // no free license if it's not a planner purchase
        <SpecialOffer paddingY={1}>
          <Typography variant="body1" paddingBottom={1}>
            Discounts
          </Typography>
          <SpecialOfferDivider />
          <Typography variant="body1" paddingTop={1}>
            1 Free license
          </Typography>
        </SpecialOffer>
      )}

      {values.promoCode && !!discount && (
        <Box marginBottom={2}>
          <SummaryRow>
            <PromotionCodeTag promoCode={values.promoCode} />
            <Typography>{getCurrencyFromStripe(-summary.TotalDiscountAmounts[0].Amount)}</Typography>
          </SummaryRow>
          <SummaryCaption variant="caption" color="grey">
            {discount.Coupon.AmountOff ? getCurrencyFromStripe(discount.Coupon.AmountOff) : `${discount.Coupon.PercentOff}%`} off
          </SummaryCaption>
        </Box>
      )}

      <Divider />

      <SummaryRow paddingY={2} color={theme.palette.biarri.secondary.activeBlue}>
        <Typography>Tax</Typography>
        <Typography>
          {getCurrencyFromStripe(summary?.TotalTaxAmounts.reduce((prevAmount, currentTaxAmount) => currentTaxAmount.Amount + prevAmount, 0) ?? 0)}
        </Typography>
      </SummaryRow>

      <Divider />
      <SummaryRow paddingY={2}>
        <Typography variant="h6">Total {values.billingPeriod} cost</Typography>
        <Typography variant="h6">{getCurrencyFromStripe(summary?.Total ?? 0)}</Typography>
      </SummaryRow>

      {values.billingPeriod !== "yearly" && (
        <SummaryCaption fontSize="0.75rem" paddingBottom={2}>
          {BILLING_PERIOD_TO_NUMBER_OF_PAYMENTS[values.billingPeriod]} equal payments over a 1-year subscription period.
        </SummaryCaption>
      )}

      <PromotionCodeInput promoCode={values.promoCode} />
      <BillingPeriod billingPeriod={values.billingPeriod} />
      <LoadingButton fullWidth disabled={isLoading || !stripe || !elements || !validCountry} id="submit" type="submit" loading={isLoading}>
        {submitText ?? `Pay ${getCurrencyFromStripe(summary?.Total ?? 0)}`}
      </LoadingButton>
      <Typography paddingTop={2}>
        By continuing, you agree to Biarri Networks{" "}
        <Link underline="always" color="inherit" target="_blank" rel="noopener noreferrer" href={TOS_URL}>
          Terms of service
        </Link>{" "}
        and confirm you have read our{" "}
        <Link underline="always" color="inherit" target="_blank" rel="noopener noreferrer" href={PRIVACY_URL}>
          Privacy policy
        </Link>
      </Typography>
    </Box>
  );
};

export default InvoiceSummary;
