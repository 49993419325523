import React from "react";
import { useSnackbar } from "notistack";

import { useDeleteProjectMutation, useDeleteReportMutation } from "fond/api";
import mixpanel from "fond/mixpanel";
import { Folder, Report } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";
import DataGridToolbar from "fond/widgets/DataGrid/DataGridToolbar";

import ProjectListToolbarActions from "./ProjectListToolbarActions";

interface IProps {
  /**
   * The currently selected entities within the project list.
   */
  selectedItems: Array<Folder | Project | Report>;
  /**
   * Callback function for indicating the user wants to clear the current project list selections.
   */
  onClear(): void;
  /**
   * Callback function for indicating the user has completed an action on the current selections.
   */
  onComplete(): void;
}

const ProjectListToolbar: React.FC<IProps> = ({ selectedItems, onClear, onComplete }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteProject] = useDeleteProjectMutation();
  const [deleteReport] = useDeleteReportMutation();

  /**
   * Callback function to bulk delete the select projects that the user has permission to delete.
   */
  const handleOnDelete = async () => {
    // Get only the projects and reports that the user has the permission to delete
    const projectsToDelete = selectedItems.filter(
      (item) => item.EntityType === "project" && permissionCheck(item.Permission.Level, Actions.PROJECT_DELETE)
    ) as unknown as Project[];
    const reportsToDelete = selectedItems.filter(
      (item) => item.EntityType === "report" && permissionCheck(item.Permission?.Level, Actions.REPORT_DELETE)
    ) as unknown as Report[];

    const deletePromises = [];
    if (projectsToDelete.length) {
      deletePromises.push(
        ...projectsToDelete.map((project) => {
          mixpanel.track("Deleted project", { projectId: project.ID });
          return deleteProject(project).unwrap();
        })
      );
    }
    if (reportsToDelete.length) {
      deletePromises.push(
        ...reportsToDelete.map((report) => {
          mixpanel.track("Report", "Delete", "Deleted report", { reportId: report.ID });
          return deleteReport(report).unwrap();
        })
      );
    }

    await Promise.allSettled(deletePromises)
      .then((results) => {
        const anyRequestFailed = results.some((result) => result.status === "rejected" || result.value.Message !== "OK");

        if (anyRequestFailed) {
          enqueueSnackbar("Some items failed to delete. Please try again...", { variant: "error" });
        } else if (projectsToDelete.length && reportsToDelete.length) {
          enqueueSnackbar("Projects and reports deleted successfully.");
        } else if (projectsToDelete.length) {
          enqueueSnackbar("Projects deleted successfully.");
        } else if (reportsToDelete.length) {
          enqueueSnackbar("Reports deleted successfully.");
        }
      })
      .catch((error) => {
        enqueueSnackbar("Some items failed to delete. Please try again...", { variant: "error" });
      });
    onComplete();
  };

  return (
    <DataGridToolbar
      selected={selectedItems.length}
      actions={<ProjectListToolbarActions selectedItems={selectedItems} onDelete={handleOnDelete} />}
      onClear={onClear}
    />
  );
};

export default ProjectListToolbar;
