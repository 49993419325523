import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Close } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";

import { selectCurrentRole } from "fond/api";
import { EXCEED_SUBSCRIPTION_QUOTA_KEY, EXPIRED_BANNER_KEY, SOON_TO_EXPIRED_BANNER_KEY } from "fond/constants";
import { useUserManagementBanner } from "fond/hooks/useUpgradePlanner";
import { Subscription } from "fond/types";
import { formatDateStringWithTimezone } from "fond/utils/dates";

import BonusBanner from "./BonusBanner";

import { Banner as StyledBanner, ManageAccountButton } from "./topbar.styles";

interface BannerContent {
  text: string;
  action: string;
}

const UserManagementBanner: React.FC = () => {
  const isAdmin = useSelector(selectCurrentRole) === "admin";
  const navigate = useNavigate();

  const getBannerContent = (subscription: Subscription, bannerType: "soon to expire" | "expired" | "quota exceeded"): BannerContent => {
    const subscriptionType = subscription.Planner ? "subscription" : "license";

    if (bannerType === "soon to expire") {
      return {
        text: `Your ${subscriptionType} is expiring on ${formatDateStringWithTimezone(subscription.EndTime)}.`,
        action: " Renew as soon as possible to continue using our premium features. ",
      };
    } else if (bannerType === "expired") {
      return {
        text: `Your ${subscriptionType} has expired.`,
        action: " Renew as soon as possible to continue using our premium features. ",
      };
    } else {
      return {
        text: "You have exceeded your total subscription quota.",
        action: " Upgrade your plan or purchase data bundles as soon as possible to continue using our premium features. ",
      };
    }
  };

  const [expanded, setExpanded] = useState(true);
  const { showBanner, subscription, bannerType } = useUserManagementBanner();

  if (!showBanner || !subscription || !bannerType) return <></>;

  const subscriptionType = subscription.Planner ? "subscription" : "license";
  const bannerContent = getBannerContent(subscription, bannerType);

  const handleClose = () => {
    if (!bannerType) return;

    if (bannerType === "soon to expire") {
      sessionStorage.setItem(SOON_TO_EXPIRED_BANNER_KEY, "true");
    } else if (bannerType === "expired") {
      sessionStorage.setItem(EXPIRED_BANNER_KEY, "true");
    } else if (bannerType === "quota exceeded") {
      sessionStorage.setItem(EXCEED_SUBSCRIPTION_QUOTA_KEY, "true");
    }
    setExpanded(false);
  };

  return expanded ? (
    <StyledBanner
      data-testid="user-management-banner-error"
      severity="error"
      action={
        <>
          {isAdmin && (
            <ManageAccountButton
              data-testid="manage-account-button"
              color="error"
              onClick={() => {
                handleClose();
                navigate("/settings/account", { state: { tab: subscriptionType } });
              }}
            >
              {subscriptionType === "subscription" ? "MANAGE SUBSCRIPTION" : "MANAGE ACCOUNT"}
            </ManageAccountButton>
          )}
          <IconButton onClick={handleClose} aria-label="Close Banner">
            <Close />
          </IconButton>
        </>
      }
    >
      <Typography>
        {bannerContent.text}
        {isAdmin ? bannerContent.action : " Contact your administrator for further assistance."}
        <BonusBanner />
      </Typography>
    </StyledBanner>
  ) : (
    <></>
  );
};

export default UserManagementBanner;
