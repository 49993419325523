import { ReactNode } from "react";
import * as React from "react";
import { Skeleton, SxProps, Typography } from "@mui/material";

import { ClickableCard, ClickableCardContent, IconContainer } from "./CardButton.styles";

interface CardButtonRequiredProps {
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  /**
   * The main title text typography.
   */
  title: string;
  /*
   * The subtitle text typography.
   */
  subtitle: string;
  /**
   * The icon element.
   */
  icon: ReactNode;
  /**
   * A callback on click.
   */
  onClick(): void;
}

interface CardButtonOptionalProps {
  /**
   * Additional styling props.
   */
  sx: SxProps;
  /**
   * Display the card button with active styles.
   */
  selected: boolean;
  /**
   * Disable the on click events.
   */
  disabled: boolean;
  /**
   * Display the loading indicator
   */
  loading: boolean;
}

interface CardButtonProps extends CardButtonRequiredProps, Partial<CardButtonOptionalProps> {}

/**
 * CardButton component.
 * A text field that sends an API request to update the project name on blur or Enter press.
 */
const CardButton: React.FC<CardButtonProps> = ({
  "data-testid": dataTestid = "card-button",
  sx = { height: 100, width: 300 },
  title,
  subtitle,
  icon,
  selected = false,
  disabled = false,
  loading = false,
  onClick,
}: CardButtonProps) => {
  const handleOnClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <>
      {loading && <Skeleton variant="rectangular" sx={sx} />}
      {!loading && (
        <ClickableCard data-testid={dataTestid} selected={selected} disabled={disabled} onClick={handleOnClick} sx={sx}>
          <IconContainer selected={selected} disabled={disabled}>
            {icon}
          </IconContainer>
          <ClickableCardContent>
            <Typography variant="h6" sx={{ fontSize: 16 }}>
              {title}
            </Typography>
            <Typography variant="content" sx={{ fontSize: 12 }}>
              {subtitle}
            </Typography>
          </ClickableCardContent>
        </ClickableCard>
      )}
    </>
  );
};

export default CardButton;
