import * as React from "react";

import { useBonusBanner } from "fond/hooks/useUpgradePlanner";
import { formatDateStringWithTimezone } from "fond/utils/dates";

const BonusBanner: React.FC = () => {
  const { showBanner, bonus } = useBonusBanner();

  return showBanner && bonus ? (
    <>
      If you renew before {formatDateStringWithTimezone(bonus.AvailableUntilTime)}, you will receive a {bonus.Percentage}% bonus on your current prem
      quota.
    </>
  ) : (
    <></>
  );
};

export default BonusBanner;
