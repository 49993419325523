import * as React from "react";
import { Link } from "@mui/material";

interface IProps {
  text?: string;
}

const SupportLink: React.FC<IProps> = ({ text = "Contact us" }: IProps) => {
  return (
    <Link color="primary" href="mailto:fondhelp@biarrinetworks.com" target="_blank" rel="noopener noreferrer">
      {text}
    </Link>
  );
};

export default SupportLink;
