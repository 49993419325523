import { AsyncOperationState, Attribute } from "fond/types";
import { AddressCollection, ParcelCollection, PoleCollection, SpanCollection, StreetCollection } from "fond/types/geojson";

import { Icon } from "./icon";
import { BasicGeometryType, FilterConfiguration } from "./ProjectLayerConfig";
import { PresignedPostFields } from "./request";

/*
 * A Layer resource response as formed by the Service API.
 */
export type Layer = {
  ID: string;
  LayerKey: string;
  Attributes: Attribute[];
  Version: string;
  Geometry: null | "Null" | "Point" | "MultiPoint" | "LineString" | "MultiLineString" | "Polygon" | "MultiPolygon";
  Configuration: LayerConfiguration;
  Type: "Layer" | "CommentLayer";
  Url?: undefined | string;
  Fields?: undefined | PresignedPostFields;
};

export type LayerClassification = {
  Label: string;
  FilterConfiguration: Partial<FilterConfiguration>;
  GeometryType: Layer["Geometry"];
};

export type LayerConfiguration = {
  ID: string;
  LayerKey: string;
  Geometry: null | BasicGeometryType;
  Group: string;
  IsVisible: boolean;
  Label: string;
};

export enum ImportStatus {
  STARTING = "STARTING",
  PENDING_UPLOAD = "PENDING_UPLOAD",
  CONVERTING = "CONVERTING",
  IMPORTING = "IMPORTING",
  BUILDING_TILES = "BUILDING_TILES",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
}

export type LayerImportStatus = {
  ID: string;
  Version: { ID: string };
  LayerKey: string;
  ImportStatus: {
    Status: ImportStatus;
    StartedBy: string;
    StartedAt: string;
    EndedAt: null | string;
    Message: null | string;
  };
};

export type _Layer = {
  children: _Layer[];
  filter?: string[];
  icon?: Icon;
  id: string;
  isVisible: boolean;
  nameSingular: string;
};

export type LayerGroup = {
  id: string;
  groupName: string;
  layers: _Layer[];
};

export type LayerShape = {
  id: string;
  name: string;
  geometryType: string;
};

export type LayerGroupShape = {
  id: string;
  name: string;
  layers: LayerShape[];
};

export interface BaseDataLayers {
  "input_layers/demand": AddressCollection;
  "input_layers/parcel": ParcelCollection;
  "input_layers/ug_path": StreetCollection;
  "input_layers/aer_path": SpanCollection;
  "input_layers/aer_points": PoleCollection;
}

export type BaseDataLayerName = keyof BaseDataLayers;

export type LayerPropertySchema = {
  name: string;
  type?: "INTEGER" | "INTEGER64" | "REAL";
};

export type LayerPropertiesSchema = {
  [layerId: string]: LayerPropertySchema[];
};

export type LayerFeatureTotals = {
  [key: string]: {
    count: number | null;
    length: number | null;
    loadSubLayerStatus?: AsyncOperationState;
  };
};
