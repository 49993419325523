import { useEffect, useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { usePrevious } from "react-use";
import { Box } from "@mui/material";

import {
  injectIntoVersionConfig,
  layersSlice,
  selectAllVersionGroupConfigs,
  selectAllVersionLayers,
  selectCurrentVersionStatus,
  useGetIconsQuery,
  useGetVersionQuery,
  useGetVersionsQuery,
  useLazyGetVersionCostBinRangesQuery,
  versionsSlice,
} from "fond/api";
import { AsyncOperationState } from "fond/async/redux";
import useEntityViews from "fond/hooks/useEntityViews";
import ImportModal from "fond/import/modal";
import { commentsConfig } from "fond/layers";
import LayoutProvider from "fond/layout/LayoutProvider";
import MapProvider from "fond/map/MapProvider";
import ProjectMapPage from "fond/project/ProjectMapPage";
import ProjectMenu from "fond/project/projectMenu/ProjectMenu";
import { closeImportModal, closeProject, dismissLoadProjectError, loadProject, Modals, setLayersVisibility, setVersion } from "fond/project/redux";
import { getComments } from "fond/redux/comments";
import TopBar from "fond/topBar/TopBar";
import { Store } from "fond/types";
import { costToServeRanges } from "fond/utils/costToServeTransformers";
import { useAppDispatch } from "fond/utils/hooks";
import { BlockSpinner, ErrorModal } from "fond/widgets";

const ProjectPage: React.FC = () => {
  useEntityViews();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { uuid: projectId } = useParams<"uuid">();
  const currentStatus = useSelector((state: Store) => selectCurrentVersionStatus(state));
  const versionId = useSelector((state: Store) => state.project.versionId);
  const previousVersionId = usePrevious(versionId);
  const loadProjectStatus = useSelector((state: Store) => state.project.loadProjectStatus);
  const loadDataStatus = useSelector((state: Store) => state.project.loadDataStatus);
  const modal = useSelector((state: Store) => state.project.modal);
  const [loaded, setLoaded] = useState(false);
  const { data: versions, isError: isErrorVersions, refetch: refetchVersions } = useGetVersionsQuery(projectId as string, { skip: !projectId });
  const { data: version } = useGetVersionQuery(versionId, { skip: !versionId });
  const [getCostBinRanges] = useLazyGetVersionCostBinRangesQuery();
  const { isSuccess: iconsLoaded } = useGetIconsQuery(undefined);
  const layerConfigs = useSelector((state: Store) => selectAllVersionLayers(state, versionId));
  const groupConfigs = useSelector((state: Store) => selectAllVersionGroupConfigs(state, versionId));

  const [triggerLayerRequest, { isSuccess: layersLoaded }] = layersSlice.endpoints.getLayers.useLazyQuery();
  const [triggerVersionRequest, { isSuccess: versionDataLoaded }] = versionsSlice.endpoints.getVersion.useLazyQuery();
  const [triggerConfigRequest, { isSuccess: configDataLoaded }] = versionsSlice.endpoints.getVersionConfig.useLazyQuery();

  const allLoaded = loaded && layersLoaded && iconsLoaded && versionDataLoaded && configDataLoaded;

  const MAX_DISPLAY_BINS_COUNT = 5;

  useEffect(() => {
    return () => {
      dispatch(closeProject());
    };
  }, [dispatch]);

  useEffect(() => {
    // Request all the prerequisite data for loading map content
    if (version) {
      triggerLayerRequest(version.ID);
      triggerVersionRequest(version.ID);
      triggerConfigRequest(version.ID)
        .unwrap()
        .then(async (rootConfiguration) => {
          // Inject the comments Group, Layer and Styles into the projects version configuration
          await injectIntoVersionConfig(dispatch, version.ID, commentsConfig);
        });
    }
  }, [dispatch, triggerConfigRequest, triggerLayerRequest, triggerVersionRequest, version]);

  /**
   * Once a design is complete add the cost to serve layers
   */
  useEffect(() => {
    if (version && configDataLoaded && currentStatus?.Status === "Complete") {
      getCostBinRanges(version.ID).then((result) => {
        if (result.data) {
          const transform = costToServeRanges(result.data, MAX_DISPLAY_BINS_COUNT);
          // Only add the layers if the bin ranges contained a value layer configuration
          // e.g. costs & ranges were generated during the design.
          if (transform.some((entity) => entity.Type === "LAYER")) {
            injectIntoVersionConfig(dispatch, version.ID, transform);
          }
        }
      });
    }
  }, [currentStatus?.Status, configDataLoaded, dispatch, version, getCostBinRanges]);

  /**
   * When the layers and group load we inject any missing items into
   * the layer visibility record to allow for correct layer visibility toggling.
   */
  useEffect(() => {
    if (layerConfigs && groupConfigs) {
      dispatch(setLayersVisibility({ projectId, layerConfigs, groupConfigs }));
    }
  }, [dispatch, layerConfigs, groupConfigs, projectId]);

  /**
   * View the latest version whenever the versions change.
   */
  useEffect(() => {
    if (projectId && versions && versions.ids?.length > 0) {
      dispatch(setVersion(versions.ids[0]));
    }
  }, [dispatch, projectId, versions]);

  /**
   * Whenever the version changes, reload the project and comments.
   *
   * This is necessary for planner projects where version-state is stored in a project-scoped manner.
   */
  useEffect(() => {
    if (projectId && versionId) {
      const forceRefetch = versionId !== previousVersionId;
      const load = async () => {
        if (loaded) {
          // No need to fetch comments again.
          await dispatch(loadProject({ uuid: projectId, forceRefetch: forceRefetch }));
        } else {
          await Promise.all([dispatch(loadProject({ uuid: projectId, forceRefetch: forceRefetch })), dispatch(getComments(projectId))]);
          setLoaded(true);
        }
      };
      load();
    }
  }, [dispatch, loaded, previousVersionId, projectId, versionId]);

  /**
   * Callback for handling retry after an error during loading
   */
  const handleOnError = () => {
    refetchVersions();
    dispatch(dismissLoadProjectError(navigate));
  };

  // If project could not be found or there are no versions we redirect
  if (loadProjectStatus === AsyncOperationState.notFound || (versions && versions.ids?.length === 0)) {
    return <Navigate to="/workspace/" />;
  }

  return (
    <MapProvider>
      <Box display="flex" height="100%" width="100%" flexDirection="column">
        {(loadProjectStatus === AsyncOperationState.failure || isErrorVersions) && (
          <ErrorModal message="There was a problem loading the project. Please try again." onClose={handleOnError} />
        )}
        {loadDataStatus === AsyncOperationState.failure && (
          <ErrorModal message="There was a problem loading the project data. Please try again." onClose={handleOnError} />
        )}
        {allLoaded ? (
          <LayoutProvider>
            <Box>
              <TopBar />
              <ProjectMenu />
            </Box>
            <ProjectMapPage />
          </LayoutProvider>
        ) : (
          <Box display="flex" height="100%">
            <BlockSpinner />
          </Box>
        )}
        {modal === Modals.import && <ImportModal onClose={() => dispatch(closeImportModal())} />}
      </Box>
    </MapProvider>
  );
};

export default ProjectPage;
