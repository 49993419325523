import * as React from "react";

import { LayerConfig, LayerStyle, SublayerConfig } from "../types/ProjectLayerConfig";

import SourceAndLayers from "./SourceAndLayers";

interface VectorTileLayerProps {
  /**
   * A unique identifier for this source.
   */
  sourceKey: string;
  /**
   * The URL of the underlying vector tiles.
   *
   * Should generally be in the form http://test.com/tiles/{z}/{x}/{y}
   */
  tileURL: string;
  /**
   * Layer and sublayer configurations for this source.
   */
  layerConfigs: Array<LayerConfig | SublayerConfig>;
  /**
   * Styles for this source.
   */
  styles: LayerStyle[];
  /**
   * A mapping of layer & sublayer IDs to booleans designating whether the
   * particular layers should be shown or not. Any keys that don't correspond to the layer ID
   * or any of the sublayer IDs will be ignored.
   */
  layerVisibilities?: { [layerId: string]: boolean };
  /**
   * Callback function for determining if a layer is visible.
   */
  isVisible: (id: string, layerView: { [key: string]: boolean }) => boolean;
}

/**
 * Include a single vector tile source in the map.
 */
const VectorTileLayer: React.FC<VectorTileLayerProps> = ({
  sourceKey,
  tileURL,
  layerConfigs,
  styles,
  layerVisibilities,
  isVisible,
}: VectorTileLayerProps) => {
  return (
    <SourceAndLayers
      key={sourceKey}
      sourceId={sourceKey}
      source={{
        type: "vector",
        tiles: [tileURL],
        promoteId: "id",
        // The backend only stores tiles up to a maximum zoom level of 18, so avoid making
        // requests beyond that zoom level.
        maxzoom: 18,
      }}
      layers={layerConfigs}
      styles={styles}
      layerVisibilities={layerVisibilities}
      isVisible={isVisible}
    />
  );
};

export default VectorTileLayer;
