import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { AccountBox, Person, StackedBarChart, SupervisedUserCircle } from "@mui/icons-material";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { selectCurrentAllocation, selectCurrentSubscription, useGetAccountSubscriptionsQuery } from "fond/api";
import { Store, Subscription, UserRoles } from "fond/types";
import { NavigationContainer, NavigationItem, SupportLink } from "fond/widgets";

import NavigationPopup from "./NavigationPopup";

const SettingsNavigation: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const allocation = useSelector(selectCurrentAllocation);
  const role = UserRoles[allocation?.Role ?? "member"];
  const accountId = allocation?.Account.ID;

  useGetAccountSubscriptionsQuery(accountId ?? skipToken, { skip: role < UserRoles.admin });
  const subscription = useSelector(
    (state: Store): Subscription | null => (accountId && role >= UserRoles.admin && selectCurrentSubscription(state, accountId)) || null
  );

  return (
    <NavigationContainer testId="settings-navigation">
      <NavigationItem
        onClick={() => {
          navigate("/settings/profile");
        }}
        testId="settings-navigation-item-profile"
        icon={<Person />}
        title="Profile"
        selected={pathname === "/settings/profile"}
      />

      {role > UserRoles.member && (
        <>
          <NavigationItem
            onClick={() => {
              navigate("/settings/account");
            }}
            testId="settings-navigation-item-account"
            icon={<AccountBox />}
            title="Account"
            selected={pathname === "/settings/account"}
          />

          <NavigationItem
            onClick={() => {
              navigate("/settings/users");
            }}
            testId="settings-navigation-item-users"
            icon={<SupervisedUserCircle />}
            title="User Management"
            selected={pathname === "/settings/users"}
          />
          <NavigationItem
            onClick={() => {
              navigate("/settings/insights");
            }}
            testId="settings-navigation-item-insights"
            icon={<StackedBarChart />}
            title="Insights"
            selected={pathname === "/settings/insights"}
            disabled={!subscription}
            disabledInfo={
              <NavigationPopup
                title="Upgrade to unlock"
                description={
                  <>
                    Only accounts with a Planner subscription can view this page, <SupportLink text="contact us" /> to upgrade.
                  </>
                }
              />
            }
          />
        </>
      )}
    </NavigationContainer>
  );
};

export default SettingsNavigation;
