import TextField from "@mui/material/TextField";
import { createStyles, withStyles } from "@mui/styles";
import classNames from "classnames";

const styles = () =>
  createStyles({
    root: {
      width: 130,
    },
    input: {
      // We want to use <input type="number" /> but we don't want the spinners
      // that appear by default, because we have a pencil icon where they would
      // appear.
      "-moz-appearance": "textfield",
      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  });

interface Props {
  autoFocus?: boolean;
  error?: boolean;
  helperText?: string | null;
  FormHelperTextProps?: any;
  min?: number;
  value: string | number;
  onChange: (value: string) => any;
  onEnterPress: () => any;
  disabled?: boolean;
  className?: any;
  classes: {
    root: string;
    input: string;
  };
  inputProps?: any;
}

const NumberField = (props: Props) => {
  const { value, onChange, onEnterPress, classes, className, disabled, inputProps, ...rest } = props;

  return (
    <TextField
      type="number"
      size="small"
      variant="outlined"
      className={classNames(classes.root, className)}
      inputProps={{
        className: classes.input,
        ...inputProps,
      }}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          onEnterPress();
        }
      }}
      disabled={disabled}
      {...rest}
    />
  );
};

export default withStyles(styles)(NumberField);
