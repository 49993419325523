import React from "react";
import { Typography } from "@mui/material";

import ForgotPasswordForm from "fond/forgotPassword/ForgotPasswordForm";
import SignUpAdornment from "fond/signIn/SignUpAdornment";
import { PageCentred } from "fond/widgets/PageCentred";

import { useUpdateDocTitle } from "../hooks/useUpdateDocTitle";

/**
 * The top level component for the forgotpassword route. Presents the ForgotPasswordForm
 * in a SignInContainer.
 */
const ForgotPassword: React.FC = () => {
  useUpdateDocTitle("Forgot password - FOND");
  return (
    <PageCentred
      title="Reset your password"
      description={
        <Typography sx={{ mr: 1 }}>Enter the email address you use to sign into FOND and we’ll send you a password reset link.</Typography>
      }
      rightAdornment={<SignUpAdornment />}
    >
      <ForgotPasswordForm />
    </PageCentred>
  );
};

export default ForgotPassword;
