import { useCallback } from "react";
import * as React from "react";
import { Box, List } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import classNames from "classnames";
import mapboxgl from "mapbox-gl";

import { unselectFeature } from "fond/project/redux";
import { Configuration } from "fond/types";
import { LayerConfig, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { useAppDispatch } from "fond/utils/hooks";

import MapPopup from "../MapPopup";

import ListItemComment from "./ListItemComment";
import ListItemLayer from "./ListItemLayer";

const customStyles = (theme: Theme) => {
  return createStyles({
    root: {
      minWidth: 300,
      overflow: "auto",
      minHeight: 0,
      maxHeight: 300,
    },
    icon: {
      minWidth: 24,
    },
    item: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    text: {
      lineHeight: 1,
      fontSize: 12,
    },
    polygon: {
      color: theme.palette.secondary.main,
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  config: Configuration;
  features: mapboxgl.MapboxGeoJSONFeature[];
  lngLat: mapboxgl.LngLatLike;
  layerConfigs: Array<LayerConfig | SublayerConfig>;
  onClose(): void;
  onFeatureClick(feature: mapboxgl.MapboxGeoJSONFeature): void;
}

/**
 * Generate a unique composed key for the feature
 * @param feature
 * @returns unique id
 */
const featureKey = (feature: mapboxgl.MapboxGeoJSONFeature) => {
  return `${feature.id}-${feature.layer.id}`;
};

/**
 * Provides the user with a selection of features to select from
 * For example, this is used for feature selection when the user
 * clicks on the map & there are stacked features or features in close proximity.
 */
const FeaturesPopup: React.FC<IProps> = ({ classes, config, features, layerConfigs, lngLat, onClose, onFeatureClick }: IProps) => {
  const dispatch = useAppDispatch();

  /**
   * Opens the individual feature once a selection has been made
   */
  const handleOnClick = (feature: mapboxgl.MapboxGeoJSONFeature) => () => {
    onFeatureClick(feature);
  };

  /**
   * Callback function called when the popup closes
   */
  const handleClose = useCallback(({ type }: { type: string }) => {
    if (type === "manual-close") {
      dispatch(unselectFeature());
    }
  }, []);

  return (
    <MapPopup lngLat={lngLat} onClose={handleClose} title="Select a Feature">
      <Box className={classNames(classes.root, "customScrollbars")} data-testid="feature-selections">
        <List dense>
          {features.map((feature) =>
            feature.properties?.commentID ? (
              <ListItemComment key={featureKey(feature)} commentID={feature.properties?.commentID} onClick={handleOnClick(feature)} />
            ) : (
              <ListItemLayer
                key={featureKey(feature)}
                feature={feature}
                layerConfigs={layerConfigs}
                config={config}
                onClick={handleOnClick(feature)}
              />
            )
          )}
        </List>
      </Box>
    </MapPopup>
  );
};

export default withStyles(customStyles)(FeaturesPopup);
