import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";

import { Tree } from "ui";
import { DraggingPosition, InteractionMode, TreeEnvironmentRef, TreeItem, TreeItemIndex } from "ui/Tree";

import {
  draftSlice,
  selectAllDraftConfigsInOrder,
  selectAllDraftGroups,
  selectAllDraftStyles,
  selectDraftData,
  selectVersionMlcId,
  useUpdateGroupConfigMutation,
  useUpdateLayerConfigMutation,
  useUpdateStyleConfigMutation,
  useUpdateSublayerConfigMutation,
} from "fond/api";
import { getInitialLayerToggles } from "fond/layers/functions";
import { DropContainerType, DropResult, setSelectedId } from "fond/redux/styles";
import { Configuration, GroupConfig, GroupConfigHydrated, MapLayerConfig, Store } from "fond/types";
import { LayerConfig, LayerConfigHydrated, LayerStyle, SublayerConfig, SublayerConfigHydrated } from "fond/types/ProjectLayerConfig";
import { pickIDs } from "fond/utils";
import { handleLayersOrderChange, isVisible } from "fond/utils/configurations";
import { useAppDispatch } from "fond/utils/hooks";
import { BlockSpinner } from "fond/widgets";

import ItemTitle from "./ItemTitle";

import "react-complex-tree/lib/style-modern.css";
import { Container } from "./sortableList.styles";

export type TreeItemType = MapLayerConfig | LayerConfig | SublayerConfig | LayerStyle | GroupConfig;

const SortableList: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedId = useSelector((state: Store) => state.styles.settings.selectedId);
  const [focusedItem, setFocusedItem] = useState<TreeItemIndex | undefined>();
  const [expandedItems, setExpandedItems] = useState<TreeItemIndex[] | undefined>();

  const mapLayerConfigId = useSelector(selectVersionMlcId);
  const [updateGroupConfig] = useUpdateGroupConfigMutation();
  const [updateLayerConfig] = useUpdateLayerConfigMutation();
  const [updateSublayerConfig] = useUpdateSublayerConfigMutation();
  const [updateStyleConfig] = useUpdateStyleConfigMutation();
  const updateCachedDraft = (callback: (draft: MaybeDrafted<Configuration>) => void) => {
    dispatch(draftSlice.util.updateQueryData("getDraft", mapLayerConfigId, callback));
  };

  const environment = useRef<TreeEnvironmentRef<TreeItemType> | undefined>(undefined);
  const config = useSelector((state: Store) => selectDraftData(state));
  const layerConfigs = useSelector((state: Store) => selectAllDraftConfigsInOrder(state));
  const groupConfigs = useSelector((state: Store) => selectAllDraftGroups(state));
  const styles = useSelector((state: Store) => selectAllDraftStyles(state));

  const { Data, ...mlc } = config;

  /**
   * Returns a TreeItemIndex[] of all parent TreeItems
   */
  const getParents = useCallback((index: TreeItemIndex) => {
    const parents: TreeItemIndex[] = [];

    const add = (parentIndex: TreeItemIndex) => {
      const parent = getParent(parentIndex);
      if (parent) {
        parents.push(parent.index);
        add(parent.index);
      }
    };

    add(index);
    return parents;
  }, []);

  /**
   * On initial load expand the groupConfigs by default
   */
  useEffect(() => {
    if (!expandedItems && mlc.ID && layerConfigs && groupConfigs && styles) {
      setExpandedItems(pickIDs(groupConfigs));
    }
  }, [expandedItems, mlc, layerConfigs, groupConfigs, styles]);

  useEffect(() => {
    if (selectedId) {
      setExpandedItems((indexes) => [...(indexes || []), ...getParents(selectedId)]);
    }
  }, [selectedId, getParents]);

  /**
   * Generate the data source for the tree based on the collection of configuration sources
   */
  const treeItems = useMemo(() => {
    if (mlc.ID && layerConfigs && groupConfigs && styles) {
      const items: Record<TreeItemIndex, TreeItem<TreeItemType>> = {
        root: {
          index: "root",
          canMove: true,
          isFolder: true,
          children: mlc.MapChildren,
          data: mlc,
          canRename: true,
        },
      };

      const add = (item: GroupConfig | LayerConfig | SublayerConfig | LayerStyle) => {
        let children: string[] = [];

        if (item.Type === "GROUP") children = item.Children as any[];
        if (item.Type === "LAYER") children = [...item.Styles, ...item.Children] as string[];
        if (item.Type === "SUBLAYER") children = item.Styles as string[];
        if (item) {
          items[item.ID as TreeItemIndex] = {
            index: item.ID,
            canMove: true,
            canRename: false,
            children: children,
            isFolder: ["GROUP", "LAYER", "SUBLAYER"].includes(item.Type),
            data: item,
          };
        }

        children.forEach((id) => {
          const child = Data.entities[id];
          if (child) add(child);
        });
      };

      Object.values(Data.entities).forEach((entity: any) => {
        add(entity);
      });

      return items;
    }

    return undefined;
  }, [mlc, layerConfigs, groupConfigs, styles, Data]);

  /**
   * Use the `isVisible` properties of the items in the layer config to determine
   * visibility status for all layers based on hierarchy.
   */
  const layerView: Record<string, boolean> = useMemo(() => {
    if (layerConfigs && groupConfigs) {
      return getInitialLayerToggles({
        layers: layerConfigs,
        groups: groupConfigs,
      });
    }
    return {};
  }, [layerConfigs, groupConfigs]);

  /**
   * Returns the TreeItem's parent TreeItem
   */
  const getParent = (childIndex: TreeItemIndex): TreeItem<TreeItemType> | undefined => {
    if (environment.current?.items) {
      return Object.entries(environment.current?.items).find(([index, item]) => {
        return item.children?.includes(childIndex);
      })?.[1];
    }
    return undefined;
  };

  /**
   * Returns a TreeItem based on its index
   */
  const getItemByIndex = (itemIndex: TreeItemIndex): TreeItem<TreeItemType> | undefined => {
    if (environment.current?.items) {
      return Object.entries(environment.current?.items).find(([index, item]) => {
        return item.index === itemIndex;
      })?.[1];
    }
    return undefined;
  };

  /**
   * Determines if the items being dragged can be dropped into the target location.
   * Rules:
   * 1) STYLE & SUBLAYER are only allowed to be reordered within the same parent item;
   * 2) LAYER can only be reordered within a GROUP or root;
   * 3) LAYER can be dropped into groups or root;
   * 4) GROUP can only be reordered within the root;
   * 5) GROUP and LAYER can be dropped into the root
   */
  const canDropAt = (items: TreeItem<TreeItemType>[], target: DraggingPosition): boolean => {
    let canDrop: boolean[] = [];

    for (const item of items) {
      if (target.targetType === "between-items") {
        if (item.data.Type === "STYLE" || item.data.Type === "SUBLAYER") {
          if (target.parentItem === getParent(item.index)?.index) {
            canDrop.push(true);
          }
        } else if (item.data.Type === "LAYER") {
          const parent = getItemByIndex(target.parentItem);
          canDrop.push(parent?.data.Type === "GROUP" || parent?.data.Type === "MapLayerConfig");
        } else if (item.data.Type === "GROUP") {
          const parent = getItemByIndex(target.parentItem);
          canDrop.push(parent?.data.Type === "MapLayerConfig");
        }
      } else if (target.targetType === "root") {
        if (item.data.Type === "GROUP" || item.data.Type === "LAYER") {
          canDrop.push(true);
        }
      } else if (target.targetType === "item") {
        if (item.data.Type === "LAYER") {
          const parent = getItemByIndex(target.targetItem);
          canDrop.push(parent?.data.Type === "GROUP");
        }
      }
    }

    return canDrop.length > 0 && !canDrop.some((action) => action === false);
  };

  /**
   * When an item is dropped we generate a DropResult which updates the required
   * entities for the item being dropped as well as the original & new entity the
   * item is being dropped into.
   */
  const handleOnDrop = (items: TreeItem<TreeItemType>[], target: DraggingPosition) => {
    for (const item of items) {
      let result: DropResult | undefined;

      const parent = getParent(item.index);

      if (parent) {
        const parentType = parent.data.Type as DropContainerType;
        if (target.targetType === "item" || target.targetType === "root") {
          if (target.targetItem === parent.index) {
            // Nothing to do here
          } else {
            const targetItem = getItemByIndex(target.targetItem);

            if (targetItem) {
              const targetItemType = targetItem.data.Type as DropContainerType;
              result = {
                id: item.index,
                source: { index: parent.children?.findIndex((childIndex) => childIndex === item.index) || 0, type: parentType },
                destination: { id: target.targetItem, type: targetItemType, index: targetItem.children?.length },
              };
            }
          }
        } else {
          const newParent = environment.current?.items[target.parentItem];

          if (target.parentItem === item.index) {
            // Trying to drop inside itself
            return;
          }

          if (target.parentItem === parent.index) {
            // Reordering within the same parent
            const isOldItemPriorToNewItem = ((newParent?.children ?? []).findIndex((child) => child === item.index) ?? Infinity) < target.childIndex;
            result = {
              id: item.index,
              source: { index: parent.children?.findIndex((childIndex) => childIndex === item.index) || 0, type: parentType },
              destination: { id: target.parentItem, type: parentType, index: target.childIndex - (isOldItemPriorToNewItem ? 1 : 0) },
            };
          } else {
            const newTargetParent = getItemByIndex(target.parentItem);

            if (newTargetParent) {
              const newTargetParentType = newTargetParent.data.Type as DropContainerType;

              result = {
                id: item.index,
                source: { index: parent.children?.findIndex((childIndex) => childIndex === item.index) || 0, type: parentType },
                destination: { id: target.parentItem, type: newTargetParentType, index: target.childIndex },
              };
            }
          }
        }
      }

      if (result) {
        handleLayersOrderChange(result, config, updateCachedDraft, {
          updateGroupConfig: (groupConfig: GroupConfigHydrated) => updateGroupConfig({ mapLayerConfigId, groupConfig }),
          updateLayerConfig: (layerConfig: LayerConfigHydrated) => updateLayerConfig({ mapLayerConfigId, layerConfig }),
          updateSublayerConfig: (sublayer: SublayerConfigHydrated) => updateSublayerConfig({ mapLayerConfigId, sublayer }),
          updateStyleConfig: (style: LayerStyle) => updateStyleConfig({ mapLayerConfigId, style }),
        });
      }
    }
  };

  const getItemTitle = ({ data }: TreeItem<TreeItemType>) => {
    if (data.Type === "STYLE") return data.Name;
    if (data.Type === "MapLayerConfig") return "";
    return data.Label;
  };

  const getItemVisibility = ({ data }: TreeItem<TreeItemType>): boolean => {
    return isVisible(config, { id: data.ID, layerView: layerView });
  };

  return (
    <Container data-testid="sortable-style-list">
      {treeItems ? (
        <Tree<TreeItemType>
          id="style-tree"
          mode="controlled"
          ref={environment}
          canDragAndDrop
          canDropOnFolder
          canDropOnNonFolder={false}
          canDropAt={canDropAt}
          canReorderItems
          defaultInteractionMode={InteractionMode.ClickArrowToExpand}
          getItemClass={(item) => `rct-tree-item-button-${item.data?.Type}`}
          getItemTitle={getItemTitle}
          items={treeItems}
          onCollapseItem={(item) => setExpandedItems(expandedItems?.filter((expandedItemIndex) => expandedItemIndex !== item.index))}
          onDrop={handleOnDrop}
          onExpandItem={(item) => setExpandedItems([...(expandedItems || []), item.index])}
          onFocusItem={(item) => setFocusedItem(item.index)}
          onSelectItems={(items) => dispatch(setSelectedId(items[0] as string))}
          renderItemTitle={(props) => <ItemTitle {...props} getItemVisibility={getItemVisibility} config={config} />}
          viewState={{
            focusedItem: focusedItem,
            expandedItems: expandedItems,
            selectedItems: [selectedId],
          }}
        />
      ) : (
        <BlockSpinner />
      )}
    </Container>
  );
};

export default SortableList;
