import * as React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { alpha, Divider, FormControl, FormHelperText, FormLabel, List, ListItem, ListItemIcon, ListItemText, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export type ListFieldProps = {
  name: string;
  options: {
    value: string;
    icon: React.ReactNode;
    label?: string;
    description?: string;
  }[];
  fieldProps?: Partial<FieldProps<any, any>>;
  validate?: any;
};

const ListField: React.FC<ListFieldProps> = (props: ListFieldProps) => {
  const { name, options, fieldProps, validate, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input, meta }) => <ListFieldWrapper input={input} meta={meta} name={name} options={options} {...rest} />}
      validate={validate}
      {...fieldProps}
    />
  );
};

type ListFieldWrapperProps = Pick<ListFieldProps, "options"> & FieldRenderProps<string, HTMLElement>;

const useCustomStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
  },
  listItem: {
    boxShadow: theme.shadows[1],
    border: `1.5px solid ${alpha(theme.palette.common.black, 0.05)}`,
    borderRadius: 4,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    cursor: "pointer",

    "&:last-child": {
      marginBottom: theme.spacing(0.5),
    },

    "&:hover": {
      background: alpha(theme.palette.primary.main, 0.05),
    },

    "&.MuiListItem-root.Mui-selected": {
      background: alpha(theme.palette.primary.main, 0.05),
      borderColor: theme.palette.primary.main,
    },

    "&.MuiListItem-root.Mui-selected .MuiListItemIcon-root": {
      background: theme.palette.primary.main,
    },
    "&.MuiListItem-root.Mui-selected .MuiListItemIcon-root .MuiSvgIcon-root": {
      fill: theme.palette.common.white,
    },
  },
  listItemIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 36,
    width: 43,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    marginRight: theme.spacing(1.75),
  },
  listItemText: {
    margin: 0,
    "& .MuiTypography-root": {
      fontSize: 12,
    },
    "& .MuiListItemText-primary": {
      fontWeight: 500,
    },
  },
}));

const ListFieldWrapper: React.FC<ListFieldWrapperProps> = ({
  input: { name, onChange, value, ...restInput },
  label,
  meta,
  options,
  disabled,
  required,
  helperText,
}: ListFieldWrapperProps) => {
  const hasError = meta.error && meta.touched;
  const classes = useCustomStyles();

  const handleOnClick = (newValue: string) => (event: React.MouseEvent<HTMLLIElement>) => {
    onChange(newValue);
  };

  return (
    <FormControl fullWidth data-testid={`${name}-form-field`}>
      {(label || required) && (
        <FormLabel required={required} error={hasError}>
          {label}
        </FormLabel>
      )}

      <List className={classes.root}>
        {options.map(({ value: listValue, icon, label: primary, description }, index) => (
          <React.Fragment key={listValue}>
            <ListItem className={classes.listItem} data-testid="layer-list-item" onClick={handleOnClick(listValue)} selected={value === listValue}>
              <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
              <ListItemText primary={primary} secondary={description} className={classes.listItemText} />
            </ListItem>
            {index + 1 !== options.length && <Divider />}
          </React.Fragment>
        ))}
      </List>

      {(hasError || helperText) && <FormHelperText error={hasError}>{meta.touched ? meta.error : helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ListField;
