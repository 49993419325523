import { useEffect } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, List } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import classNames from "classnames";
import _ from "lodash";
import { useSnackbar } from "notistack";

import { uploadAttachmentAbort, uploadAttachments } from "fond/redux/attachments";
import { AppThunkDispatch, Store } from "fond/types";

import AttachmentUploadListItem from "./AttachmentUploadListItem";
import AttachmentUploadTotalProgress from "./AttachmentUploadTotalProgress";

const customStyles = (theme: Theme) => {
  return createStyles({
    container: {
      height: "fit-content",
      display: "flex",
      justifyContent: "space-around",
    },
    fileList: {
      width: "100%",
      maxHeight: 450,
      overflowY: "auto",
      padding: 0,
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /*
   * The list of files requiring upload.
   */
  files: File[];
  /**
   * Callback function that returns true / false whenever upload move between being active (true) and failed, cancelled or completed (false).
   * Note that uploads can be retried on failure or cancellation. An upload flow may move into progress many times.
   */
  onIsUploading(isUploading: boolean): void;
  /**
   * The unique identifier for a feature belonging to a layer on the map.
   */
  featureId?: string | null;
}

const AttachmentUpload: React.FC<IProps> = ({ classes, files, onIsUploading, featureId }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch: AppThunkDispatch = useDispatch();

  const { uploadStatus } = useSelector((state: Store) => state.attachments);
  const { projectId } = useSelector((state: Store) => state.project);

  /*
   * Start the upload automatically.
   */
  useEffect(() => {
    startUpload(files);
  }, []);

  /*
   * Upload controls.
   */
  const startUpload = (targetFiles: File[]) => {
    onIsUploading(true);
    dispatch(uploadAttachments(projectId, targetFiles, featureId))
      .catch(() => enqueueSnackbar("Some attachments failed to upload."))
      .finally(() => onIsUploading(false));
  };
  const abortUpload = (file: File) => dispatch(uploadAttachmentAbort(file));
  const retryUpload = (file: File) => startUpload([file]);

  return (
    <Box className={classes.container}>
      <AttachmentUploadTotalProgress data-testid="attachment-upload-total-progress" files={files} fileUploadStatus={uploadStatus} />
      <List className={classNames(classes.fileList, "customScrollbars")}>
        {_.sortBy(files, "size").map((file: File) => (
          <AttachmentUploadListItem
            data-testid="attachment-upload-list-item"
            key={file.name}
            file={file}
            progress={uploadStatus[file.name]?.progress || 0}
            error={uploadStatus[file.name]?.isError || uploadStatus[file.name]?.isAborted}
            onAbort={abortUpload}
            onRetry={retryUpload}
          />
        ))}
      </List>
    </Box>
  );
};

AttachmentUpload.displayName = "AttachmentUpload";
export default withStyles(customStyles)(AttachmentUpload);
