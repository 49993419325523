/* eslint-disable no-underscore-dangle */
import MapboxDraw, { DrawCustomModeThis, modes } from "@mapbox/mapbox-gl-draw";
import * as Constants from "@mapbox/mapbox-gl-draw/src/constants";
import { isEscapeKey } from "@mapbox/mapbox-gl-draw/src/lib/common_selectors";

import { prepareFeatureForDraw } from "fond/utils/geojson";

import snapTo, { withSnapping } from "./snap_to";

interface DrawCustomModeState {
  point: MapboxDraw.DrawFeature;
}

interface DrawCustomModeOptions {
  pointLayerId: string;
}

const onCreatePoint = function (this: DrawCustomModeThis, state: DrawCustomModeState, e: MapboxDraw.MapMouseEvent | MapboxDraw.MapTouchEvent) {
  const feature = prepareFeatureForDraw(
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [e.lngLat.lng, e.lngLat.lat],
      },
      properties: {},
    },
    this.layerId
  );
  this.addFeature(this.newFeature(feature));
  this.map.fire("draw.create", { features: [feature] });
  this._ctx.api.pullInFeature(this.layerId, feature);
  this._ctx.api.commitPole({
    selectedPole: feature,
    snap: e.closestFeature != null ? { feature: e.closestFeature, coords: e.closestCoord } : null,
  });
};

const DrawPole = withSnapping<DrawCustomModeState, DrawCustomModeOptions, unknown>({
  ...modes.draw_point,

  onSetup: function (opts) {
    if (opts == null || opts.pointLayerId == null) {
      throw new Error("draw_poles requires a pointLayerId");
    }
    this.layerId = opts.pointLayerId;

    // Identical to the Mapbox Draw draw_point onSetup except we pass the
    // feature through `prepareFeatureForDraw`.
    const point = this.newFeature(
      prepareFeatureForDraw(
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: [],
          },
        },
        opts.pointLayerId
      )
    );

    this.addFeature(point);
    this.clearSelectedFeatures();
    this.updateUIClasses({ mouse: Constants.cursors.ADD });
    this.activateUIButton(Constants.types.POINT);

    this.setActionableState({
      trash: true,
      combineFeatures: false,
      uncombineFeatures: false,
    });

    return { point: point };
  },
  onClick: onCreatePoint,

  onTap: function (state, e) {
    onCreatePoint.call(this, state, e);
  },

  onKeyUp: function (state, e): void {
    // Switch back to select mode when esc is pushed
    if (isEscapeKey(e)) {
      this.changeMode("simple_select");
    }
  },

  snap: function (this: DrawCustomModeThis, state, e) {
    if (e.point) {
      return snapTo(e, this._ctx, state.point.id);
    } else {
      return e;
    }
  },
});

export default DrawPole;
