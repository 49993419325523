import * as React from "react";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router";

import { selectFolderById, useGetFoldersQuery } from "fond/api";
import { FoldersProjectList, Navigation } from "fond/projects";
import { Store } from "fond/types";
import { PageContent } from "fond/widgets";

import NewItemButton from "../MainWorkspace/NewItemButton";

interface RouteParams {
  folderId: string;
}

/**
 * A top level component used for displaying project.
 */
const Folders: React.FC = () => {
  const filters = useSelector((state: Store) => state.projects.filters);
  const { isFetching: isFoldersFetching } = useGetFoldersQuery(undefined);
  const { folderId } = useParams<keyof RouteParams>();

  const currentFolder = useSelector((state: Store) => (folderId ? selectFolderById(state, folderId) : undefined));

  /**
   * If folderId is not null but currentFolder is null, it indicates that the current user has no access to the folder,
   * redirect to projects landing page.
   */
  if (folderId && !currentFolder && !isFoldersFetching) {
    return <Navigate to="/workspace/" />;
  }

  return (
    <PageContent
      title={currentFolder?.Name ?? ""}
      action={filters === "workspace" && currentFolder && <NewItemButton />}
      mainContent={<FoldersProjectList />}
      navigation={<Navigation />}
      testId="folders-content"
    />
  );
};

export default Folders;
