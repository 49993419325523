import * as React from "react";
import { AddComment } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

interface IProps {
  /**
   * Callback function fired when popup calls addNewComment
   */
  addComment(): void;
}

const AddQuickFeatureComment: React.FC<IProps> = ({ addComment }: IProps) => {
  return (
    <Tooltip title="Add comment" arrow>
      <IconButton size="small" color="primary" onClick={addComment} data-testid="add-comment-button">
        <AddComment />
      </IconButton>
    </Tooltip>
  );
};

export default AddQuickFeatureComment;
