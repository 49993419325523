import { useContext, useEffect, useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { Alert, Box } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { getUserPreferenceValue, useCopyProjectMutation, useUpdateUserPreferencesMutation } from "fond/api";
import { Layout } from "fond/layout";
import { LayoutContext } from "fond/layout/LayoutProvider";
import mixpanel from "fond/mixpanel";
import { load as loadPage, Pages } from "fond/page/redux";
import { getCurrentProject, Modals } from "fond/project/redux";
import { FirstProjectTour, LayoutTour, ProjectPageImprovementsTour } from "fond/tours";
import { Project, Store, UserPreferenceKey, UserPreferenceSubKey } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { SupportLink } from "fond/widgets";

import "./Project.scss";

const ProjectMapPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const project = useSelector((state: Store): Project => getCurrentProject(state.project));
  const [copyProject] = useCopyProjectMutation();
  const [updatePreference] = useUpdateUserPreferencesMutation();
  const showLayoutTour = useSelector((state: Store) => getUserPreferenceValue(state, UserPreferenceKey.UI_TOUR, UserPreferenceSubKey.DYNAMIC_LAYOUT));
  const showFirstProjectTour = useSelector((state: Store) =>
    getUserPreferenceValue(state, UserPreferenceKey.UI_TOUR, UserPreferenceSubKey.PROJECT_INTRO)
  );
  const showProjectPageImprovementsTour = useSelector((state: Store) =>
    getUserPreferenceValue(state, UserPreferenceKey.UI_TOUR, UserPreferenceSubKey.COST_TO_SERVE_STACKED_NAVIGATION)
  );
  const modal = useSelector((state: Store) => state.project.modal);
  const { model, mapModel } = useContext(LayoutContext);
  const modelLoaded = !!(model && mapModel);

  useEffect(() => {
    dispatch(loadPage(Pages.map));
    mixpanel.track("Entered project map page");
  }, []);

  /**
   * Display of UI tours causes an issue when import modal
   * is active. This will be used to create additional
   * condition to only display UI tours when import modal is hidden
   */
  const isImportModalDisplayed = modal === Modals.import;

  /**
   * Callback function to dispatch the copy action
   */
  const handleOnCopy = () => {
    setSaving(true);
    enqueueSnackbar("Copying project.", {
      autoHideDuration: null,
      key: `project_copy_${project.ID}`,
    });

    copyProject({ projectId: project.ID, folderId: null })
      .unwrap()
      .then(() => {
        mixpanel.track("Copied project", { projectId: project.ID });
        enqueueSnackbar(`Created project 'Copy of ${project.ProjectName}'.`);
      })
      .catch(() => {
        enqueueSnackbar("Project copy failed. Please try again...");
      })
      .finally(() => {
        setSaving(false);
        closeSnackbar(`project_copy_${project.ID}`);
      });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      position="relative"
      onDragOver={(event) => {
        // Add onDragOver event handler here to set the cursor for PC users when dragging
        // No effect for Mac users
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
      }}
    >
      {project && project.WriteLocked === "tool_support_dropped" && (
        <Alert
          severity="warning"
          action={
            <LoadingButton variant="outlined" color="inherit" loading={saving} onClick={handleOnCopy}>
              Copy Project
            </LoadingButton>
          }
        >
          This project was generated with an older version of FOND and has been converted to read only mode.
          <br />
          You can still download this project or make a new copy. The new project copy will be automatically upgraded, and will be editable. Please
          see our{" "}
          <a href="https://fondhelp.biarrinetworks.com/what-is-a-legacy-project" target="_blank" rel="noreferrer">
            knowledge base
          </a>{" "}
          for more information.
        </Alert>
      )}

      {project && project.Deleted && (
        <Alert severity="error" variant="filled">
          This project is marked as deleted and does not appear in the projects list. It will soon be permanently removed from the system. If you want
          to undo this, please <SupportLink />
        </Alert>
      )}

      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box position="relative" flexGrow={1}>
          <Layout />
        </Box>
      </Box>
      {!isImportModalDisplayed && showLayoutTour && modelLoaded && (
        <LayoutTour
          onComplete={() => {
            updatePreference({ Key: UserPreferenceKey.UI_TOUR, Subkey: UserPreferenceSubKey.DYNAMIC_LAYOUT, Value: false });
          }}
        />
      )}
      {!isImportModalDisplayed && showFirstProjectTour && modelLoaded && (
        <FirstProjectTour
          onComplete={() => {
            updatePreference({ Key: UserPreferenceKey.UI_TOUR, Subkey: UserPreferenceSubKey.PROJECT_INTRO, Value: false });
          }}
        />
      )}
      {!isImportModalDisplayed && showProjectPageImprovementsTour && modelLoaded && (
        <ProjectPageImprovementsTour
          onComplete={() => {
            updatePreference({ Key: UserPreferenceKey.UI_TOUR, Subkey: UserPreferenceSubKey.COST_TO_SERVE_STACKED_NAVIGATION, Value: false });
          }}
        />
      )}
    </Box>
  );
};

export default ProjectMapPage;
