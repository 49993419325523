import * as React from "react";
import { TooltipRenderProps } from "react-joyride";
import { Box, Button, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useCustomStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
    maxWidth: 500,
  },
}));

const Tooltip: React.FC<TooltipRenderProps> = ({
  backProps,
  closeProps,
  continuous,
  index,
  isLastStep,
  primaryProps,
  tooltipProps,
  skipProps,
  size,
  step,
}: TooltipRenderProps) => {
  const classes = useCustomStyles();

  return (
    <Box {...tooltipProps} className={classes.root} data-testid="tour">
      {step.title && (
        <Typography variant="h6" gutterBottom data-testid="tour-title">
          {step.title}
        </Typography>
      )}
      {typeof step.content === "string" ? <Typography variant="body2">{step.content}</Typography> : <Box>{step.content}</Box>}

      <Box sx={{ mt: 2, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Box>
          {continuous && step.showSkipButton && (
            <Button sx={{ mr: 1 }} {...skipProps} color="primary" variant="outlined" size="small" data-testid="tour-skip">
              Skip
            </Button>
          )}
        </Box>
        <Box>
          {index > 0 && (
            <Button {...backProps} size="small" data-testid="tour-back">
              Back
            </Button>
          )}

          {continuous && (
            <Button {...primaryProps} color="primary" variant="contained" size="small" data-testid="tour-next">
              {`${isLastStep ? "I'm Done" : "Next"} ${step.showProgress ? `${index + 1}/${size}` : ""}`}
            </Button>
          )}

          {!continuous && (
            <Button {...closeProps} color="primary" variant="contained" size="small" data-testid="tour-close">
              Close
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Tooltip;
