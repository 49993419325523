import * as React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { FormControl, FormLabel, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import { DatePicker, DatePickerProps as MuiDatePickerProps } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { FieldValidator } from "final-form";

import { FormHelperText } from "./common.styles";

export type DatePickerProps = Partial<Omit<MuiDatePickerProps<Dayjs>, "onChange" | "defaultValue">> &
  MuiTextFieldProps & {
    defaultValue?: string;
    fieldProps?: Partial<FieldProps<string, DatePickerWrapperProps, HTMLElement, string>>;
    /**
     * Flag indicating if the validate error text should be shown
     */
    hideError?: boolean;
    labelClasses?: Partial<ClassNameMap<string>>;
    name: string;
    validate?: FieldValidator<string>;
  };

const DatePickerField: React.FC<DatePickerProps> = (props: DatePickerProps) => {
  const { defaultValue, name, hideError, fieldProps, validate, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input, meta }) => <DatePickerWrapper input={input} meta={meta} name={name} hideError={hideError} {...rest} />}
      validate={validate}
      defaultValue={defaultValue}
      {...fieldProps}
    />
  );
};

type DatePickerWrapperProps = Partial<Omit<DatePickerProps, "onChange" | "defaultValue">> & FieldRenderProps<string, HTMLElement>;

const DatePickerWrapper: React.FC<DatePickerWrapperProps> = ({
  input: { name, onChange, value },
  meta,
  label,
  labelClasses,
  className,
  required,
  helperText,
  hideError = false,
  fullWidth = true,
  size,
  ...rest
}) => {
  const hasError = (meta.error || meta.submitError) && meta.touched;
  const helperTextId = `${name}-helper-text`;

  return (
    <FormControl fullWidth={fullWidth} data-testid={`${name}-field`} className={className}>
      {(label || required) && (
        <FormLabel required={required} error={hasError} classes={labelClasses}>
          {label}
        </FormLabel>
      )}
      <DatePicker
        name={name}
        value={dayjs(value)}
        onChange={(newValue) => onChange(dayjs(newValue).toString())}
        slotProps={{ textField: { size } }}
        sx={{ mt: 1 }}
        {...rest}
      />
      {((hasError && !hideError) || helperText) && (
        <FormHelperText id={helperTextId} error={hasError} data-testid={helperTextId}>
          {meta.touched ? meta.error || meta.submitError : helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default DatePickerField;
