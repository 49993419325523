import { LayoutDeleteLevel, LayoutNode, LayoutNodeResponse } from "fond/types";

import { apiSlice } from "./apiSlice";

export type LayoutResponse = {
  Layouts: LayoutNodeResponse[];
  Maps: LayoutNodeResponse[];
};

export type LayoutUpdateRequestBody = {
  ProjectID: string;
  Layouts: LayoutNode[];
  Maps: LayoutNode[];
};

/**
 * Layout API Slice
 */
export const layoutSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getLayout: build.query({
      query: (projectId: string) => (projectId ? `/v2/layouts?project_id=${projectId}` : `/v2/layouts`),
      providesTags: (result, error, arg) =>
        result
          ? [
              { type: "Layouts", id: "Layouts" },
              { type: "Layouts", id: arg },
            ]
          : [],
    }),
    updateLayout: build.mutation({
      query: (data: LayoutUpdateRequestBody) => ({
        url: `/v2/layouts`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Layouts", id: "Layouts" },
        { type: "Layouts", id: arg.ProjectID },
      ],
    }),
    deleteLayout: build.mutation<undefined, { projectId: string; level: LayoutDeleteLevel }>({
      query: (params) => ({
        url: `/v2/layouts?project_id=${params.projectId}&level=${params.level.toString()}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Layouts", id: "Layouts" },
        { type: "Layouts", id: arg.projectId },
      ],
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useGetLayoutQuery, useUpdateLayoutMutation, useDeleteLayoutMutation } = layoutSlice;
