/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogActionsProps as ActionProps,
  DialogContent,
  DialogContentProps as ContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps as TitleProps,
  PaperProps,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import modalBg from "svg_icons/modal-header-bg.svg";

const PRIMARY_MODAL_WIDTH = 894;
const SECONDARY_MODAL_WIDTH = 529;

type Variant = "primary" | "secondary" | "default";
interface StyleProps {
  variant?: Variant;
}

const useCustomStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: ({ variant }) => ({
    "& > div > div": {
      width: variant === "primary" ? PRIMARY_MODAL_WIDTH : SECONDARY_MODAL_WIDTH,
      backgroundColor: () => {
        if (variant === "secondary") return theme.palette.background.dialog;

        // if variant is "primary" or "default"
        return theme.palette.common.white;
      },
      maxWidth: "none !important",
    },
  }),
  header: {
    backgroundImage: ({ variant }) => (variant !== "default" ? `url(${modalBg})` : theme.palette.common.white),
    backgroundSize: "cover",
    padding: ({ variant }) => (variant === "primary" ? "3rem 4rem" : "2.25rem 2.5rem 1.5rem"),
  },
  headerContent: {
    display: "inline-flex",
    alignItems: "center",
  },
  headerIcon: ({ variant }) =>
    variant === "primary"
      ? {
          height: 40,
          width: 40,
          borderRadius: "50%",
          background: theme.palette.secondary.main,
          textAlign: "center",
          marginRight: 15,

          "& > svg": {
            color: theme.palette.common.white,
            height: 40,
            lineHeight: "40px",
          },
        }
      : {
          marginRight: 15,
          "& > svg": {
            color: theme.palette.secondary.main,
            height: 40,
            lineHeight: "40px",
          },
        },
  headerTitleWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    padding: 0,
    fontWeight: "700",
  },
  subtitle: {
    fontSize: 10,
    lineHeight: "16px",
    letterSpacing: 2.5,
    color: theme.palette.secondary.main,
  },
  content: {
    padding: "2px 3rem",
  },
  actions: {
    padding: "1.5rem 1.75rem",
  },
}));

interface IProps extends Omit<DialogProps, "content"> {
  /**
   * Dialog actions content
   */
  actions?: React.ReactNode;
  /**
   * className applied to the root dialog
   */
  className?: string;
  variant?: Variant;
  /**
   * Dialog content
   */
  content?: React.ReactNode;
  /**
   * Dialog header content
   */
  header?: React.ReactNode;
  headerIcon?: React.ReactNode;
  headerSubtitle?: string | null;
  /**
   * Mui DialogActionProps
   */
  DialogActionsProps?: ActionProps;
  /**
   * Mui DialogContentProps
   */
  DialogContentProps?: ContentProps;
  /**
   * Mui DialogTitleProps
   */
  DialogTitleProps?: TitleProps;
  /**
   * Mui component used to render the body of the dialog.
   */
  PaperProps?: PaperProps;
  /**
   * Flag that controls if the onClose should be disabled when clicking outside the open modal. The native modal
   * prop disableBackdropClick is removed in mui@v5, You can find more details about this breaking change in
   * [the migration guide](https://mui.com/guides/migration-v4/#modal). This prop is now used as a custom prop.
   */
  disableBackdropClick?: boolean;
  /**
   * Callback function fired when the modal closes
   */
  onClose?(event: React.ChangeEvent, reason?: "backdropClick" | "escapeKeyDown"): void;
}

const Modal: React.FC<IProps> = ({
  actions,
  className,
  variant = "secondary",
  content = null,
  DialogActionsProps,
  DialogContentProps,
  DialogTitleProps,
  disableBackdropClick = false,
  header = null,
  headerIcon = null,
  headerSubtitle = null,
  open,
  onClose,
  ...dialogProps
}: IProps) => {
  const classes = useCustomStyles({ variant });

  const handleOnClose = (event: React.ChangeEvent, reason?: "backdropClick" | "escapeKeyDown") => {
    if (!disableBackdropClick || reason !== "backdropClick") {
      onClose?.(event, reason);
    }
  };

  return (
    <Dialog {...dialogProps} open={open} className={classNames(classes.root, className)} onClose={handleOnClose}>
      <Box className={classes.header}>
        <Box className={classes.headerContent}>
          {headerIcon && <Box className={classes.headerIcon}>{headerIcon}</Box>}
          <Box className={classes.headerTitleWrapper}>
            <DialogTitle className={classes.title} {...DialogTitleProps}>
              {header}
            </DialogTitle>
            {headerSubtitle && <span className={classes.subtitle}>{headerSubtitle}</span>}
          </Box>
        </Box>
      </Box>
      <DialogContent
        {...DialogContentProps}
        className={classNames(DialogContentProps?.className, "customScrollbars", classes.content, { greyBackground: variant === "secondary" })}
      >
        {content}
      </DialogContent>
      <DialogActions className={classes.actions} {...DialogActionsProps}>
        {actions}
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
