import { useSelector } from "react-redux";

import { selectCurrentAccount } from "fond/api";
import { ACCOUNT_KEY, SEARCH_KEY } from "fond/constants";
import { Navigation } from "fond/projects";
import SearchResults from "fond/projects/GlobalSearch/SearchResults";
import { useQueryParams } from "fond/utils/hooks";
import { PageContent } from "fond/widgets";

import SharedContentList from "./SharedContentList";

const SharedWithMe: React.FC = () => {
  const searchQuery = useQueryParams(SEARCH_KEY);
  const selectedAccount = useQueryParams(ACCOUNT_KEY) || "";
  const currentAccountId = useSelector(selectCurrentAccount)?.ID;

  return searchQuery ? (
    <SearchResults />
  ) : (
    <PageContent
      title="Shared with me"
      subTitle={selectedAccount && `BY ${selectedAccount === currentAccountId ? "TEAM MEMBERS" : "OTHER USERS"}`}
      mainContent={<SharedContentList />}
      testId="filter-shared"
      navigation={<Navigation />}
    />
  );
};

export default SharedWithMe;
