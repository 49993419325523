import { useCallback, useEffect, useRef, useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { Box, Button, Divider, Menu, PopoverOrigin, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { selectFolderById, useGetAccountModulesQuery, useGetFoldersQuery, useGetProjectQuery, useGetVersionQuery } from "fond/api";
import { useFeatureFlag } from "fond/featureFlags";
import { getCurrentProject } from "fond/project";
import { Bom, Files } from "fond/project/projectMenu/attachment";
import BulkAttachment from "fond/project/projectMenu/attachment/BulkAttachment";
import { KnowledgeBase, Support } from "fond/project/projectMenu/help";
import {
  Close,
  Copy,
  ExportMenuItem,
  ImportMenuItem,
  Move,
  Rename,
  Settings,
  Share as ShareMenuItem,
  StyleEditor,
} from "fond/project/projectMenu/project";
import { Layout, MapAppearance } from "fond/project/projectMenu/view";
import { Store } from "fond/types";
import { accountModuleCheck, Actions } from "fond/utils/permissions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.biarri.secondary.darkCarbon,
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  button: {
    minWidth: 0,
    borderRadius: 0,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "#516b7a",
    },
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
  menu: {
    pointerEvents: "none",
    "& .MuiMenuItem-root": {
      pointerEvents: "auto",
    },
  },
  menuList: {
    padding: 0,
  },
}));

const ProjectMenu: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);
  const projectId = useSelector((state: Store): string => getCurrentProject(state.project)?.ID);
  const { data: project } = useGetProjectQuery(projectId);
  const versionId = useSelector((state: Store) => state.project.versionId);
  const { data: version } = useGetVersionQuery(versionId, { skip: !versionId });
  const architecture = version?.Architecture ?? null;
  const currentFolder = useSelector((state: Store) => (project?.FolderID ? selectFolderById(state, project.FolderID) : undefined));
  const { isFetching: isFolderFetching } = useGetFoldersQuery(undefined);
  const permissionLevel = project?.Permission.Level;
  const hasCustomLayerConfig = useSelector((state: Store) => getCurrentProject(state.project)?.HasCustomLayerConfig);
  const anchorOrigin: PopoverOrigin = {
    vertical: "bottom",
    horizontal: "left",
  };

  /**
   * Callback function that opens the menu.
   */
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
    setOpen(true);
  };

  /**
   * Function that closes clear the timeout.
   */
  const clear = useCallback(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(null);
  }, [timeoutId]);

  /**
   * Callback function that closes the menu.
   */
  const handleClose = useCallback(() => {
    setMenuAnchorEl(null);
    setOpen(false);
    clear();
  }, [clear]);

  /**
   * Callback function that starts the timeout when a mouse leave event is triggered. This is to support the behavior that the menu will be closed
   * after the set time(2s) when the user moves away from the menu system.
   */
  const handleMouseLeave = () => {
    if (open) {
      clear();
      const newTimeoutId = setTimeout(() => {
        handleClose();
      }, 2000);
      setTimeoutId(newTimeoutId);
    }
  };

  /**
   * Callback function that clears the timeout when a mouse enter event is triggered. This is to support the behavior that the menu will be closed
   * after the set time(2s) when the user moves away from the menu system.
   */
  const handleMouseEnter = () => {
    if (open) {
      clear();
    }
  };

  /**
   * As we disabled the menu focus trap (with pointerEvents: "none") to allow users to focus on other menus while one menu is opened,
   * we can no longer listen to dropbackClick events to close the menu. This useEffect detects any mouseclick event outside the menu element
   * and triggers a menu close action.
   */
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    /**
     * Close the menu if clicked outside
     */
    const handleClickOutside = () => {
      if (menuRef.current && window.event?.target instanceof Element && !menuRef.current.contains(window.event?.target)) {
        handleClose();
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose, menuRef]);

  const { value: attachmentsBulkImportFeatureFlagEnabled } = useFeatureFlag("attachments_bulk_import");
  const { data: accountModules } = useGetAccountModulesQuery(project?.Account.ID ?? skipToken);
  const canPerformAttachmentsBulkImport = accountModuleCheck(accountModules, Actions.ATTACHMENTS_BULK_IMPORT);

  /**
   * Definition of the menu items.
   */
  const menus = [
    {
      id: "project-menu-project",
      title: "Project",
      children: (
        <>
          <ShareMenuItem handleClose={handleClose} />
          <Divider style={{ margin: 0 }} />
          {project?.HasCustomLayerConfig && <ImportMenuItem handleClose={handleClose} />}
          <ExportMenuItem handleClose={handleClose} />
          <Divider style={{ margin: 0 }} />
          {project && <Copy project={project} handleClose={handleClose} />}
          {project && permissionLevel && <Rename project={project} permissionLevel={permissionLevel} handleClose={handleClose} />}
          {project && <Move project={project} isFetching={isFolderFetching} handleClose={handleClose} />}
          <Divider style={{ margin: 0 }} />
          {permissionLevel && <Settings permissionLevel={permissionLevel} handleClose={handleClose} />}
          <Divider style={{ margin: 0 }} />
          {permissionLevel && hasCustomLayerConfig && (
            <>
              <StyleEditor permissionLevel={permissionLevel} handleClose={handleClose} />
              <Divider style={{ margin: 0 }} />
            </>
          )}
          <Close currentFolderId={currentFolder?.ID} />
        </>
      ),
    },
    {
      id: "project-menu-view",
      title: "View",
      children: (
        <>
          <MapAppearance parentMenuOpen={Boolean(menuAnchorEl?.id === "project-menu-view")} handleClose={handleClose} />
          <Divider style={{ margin: 0 }} />
          <Layout parentMenuOpen={Boolean(menuAnchorEl?.id === "project-menu-view")} handleClose={handleClose} />
        </>
      ),
    },
    {
      id: "project-menu-attachment",
      title: "Attachment",
      children: (
        <>
          {project && <Bom project={project} architecture={architecture} handleClose={handleClose} />}
          {permissionLevel && <Files permissionLevel={permissionLevel} handleClose={handleClose} />}
          {attachmentsBulkImportFeatureFlagEnabled && canPerformAttachmentsBulkImport && permissionLevel && (
            <BulkAttachment permissionLevel={permissionLevel} handleClose={handleClose} />
          )}
        </>
      ),
    },
    {
      id: "project-menu-help",
      title: "Help",
      children: (
        <>
          <Support handleClose={handleClose} />
          <KnowledgeBase handleClose={handleClose} />
        </>
      ),
    },
  ];

  return (
    <div className={classes.root} data-testid="project-menu-bar">
      <Box width="100%" display="flex" justifyContent="space-between" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Box ml={1.5} display="flex" alignItems="center" flexDirection="row" ref={menuRef}>
          {menus.map((menuItem) => (
            <Box key={menuItem.id}>
              <Button
                id={menuItem.id}
                aria-controls={menuItem.id}
                aria-haspopup="true"
                data-testid={`${menuItem.id}-button`}
                onClick={handleMenuClick}
                onMouseEnter={open ? handleMenuClick : undefined}
                size="small"
                className={classes.button}
              >
                {menuItem.title}
              </Button>
              <Menu
                data-testid={menuItem.id}
                open={Boolean(menuAnchorEl?.id === menuItem.id)}
                anchorEl={menuAnchorEl}
                keepMounted
                anchorOrigin={anchorOrigin}
                className={classes.menu}
                classes={{ list: classes.menuList }}
                disablePortal
              >
                <div key={menuItem.id}>{menuItem.children}</div>
              </Menu>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default ProjectMenu;
