/* eslint-disable react/jsx-props-no-spreading */
import { useRef } from "react";
import * as React from "react";
import { FieldArray } from "react-final-form-arrays";
import { Close, GroupOutlined as Group, PersonOutline as Person, Undo } from "@mui/icons-material";
import { Box, ChipProps, IconButton, Table, TableBody, TableCell, TableHead, Tooltip } from "@mui/material";
import { blue, green, red } from "@mui/material/colors";

import { Account, AccountBase, ExtendedPermission, PermissionLevel, ResourceEntity, User, UserShareSuggestion } from "fond/types";
import { allowEditPermission, allowEditPermissionLevel, allowRemoval, isInherited, isNew, isNewInheritOverride } from "fond/utils/permissions";

import { SelectField } from "..";

import AddPermission from "./AddPermission";
import PermissionUserCell from "./PermissionUserCell";

import { Check, Chip, TableRow } from "./PermissionsField.styles";

type Option = string | UserShareSuggestion | AccountBase;

type UndoButtonProps = {
  onClick: () => void;
};

const UndoButton = ({ onClick }: UndoButtonProps) => {
  return (
    <IconButton size="small" className="undo-button" name="undo" onClick={onClick} data-testid="undo-delete-share-permission">
      <Undo />
    </IconButton>
  );
};

type DeleteButtonProps = {
  onClick: () => void;
};

const DeleteButton = ({ onClick }: DeleteButtonProps) => {
  return (
    <IconButton size="small" className="delete-button" name="delete" onClick={onClick} data-testid="delete-share-permission">
      <Close />
    </IconButton>
  );
};

interface IProps {
  /**
   * The Name of the form value.
   */
  name: string;
  /**
   * The PermissionLevel of the current logged in user
   */
  currentPermission?: PermissionLevel;
  /**
   * The Email / Username of the current logged in user
   */
  currentUsername: string;
  /**
   * The form field placeholder value
   */
  placeholder?: string;
  /**
   * Field Array push callback that allows a new user
   * to be added to the array of values.
   */
  push(
    fieldName: string,
    value: Omit<ExtendedPermission, "Identity"> &
      (
        | { Identity: Pick<Account, "ID"> }
        | {
            Identity: Pick<User, "Email"> & {
              ID: string | undefined;
            };
          }
      )
  ): void;
  /**
   * The Resource the permission relates to
   */
  resource: ResourceEntity;
  /**
   * A list of suggested users and groups
   */
  suggestions?: Array<Option>;
  /**
   * The current fields value.
   */
  values: ExtendedPermission[];
}

const labels: { [key: string]: string } = {
  manage: "Manager",
  write: "Contributor",
  read: "Collaborator",
  view: "Viewer",
  deny: "Denied",
};

const PermissionsField: React.FC<IProps> = ({
  name,
  currentPermission,
  currentUsername,
  placeholder = "Enter an email to share with",
  push,
  resource,
  suggestions = [],
  values,
}: IProps) => {
  const listRef = useRef<HTMLDivElement>(null);

  /**
   * Handles the removal of permissions.  If a permission has overriden inheritance
   * it cannot be removed, and will instead revert to the inherited permission.
   */
  const handleOnRemove = (
    permission: ExtendedPermission,
    rowIndex: number,
    remove: (rowIndex: number) => void,
    update: (rowIndex: number, value: any) => void
  ) => {
    if (!permission.Inherited) {
      if (!permission.New) {
        // We don't remove existing permissions - we instead flag it for removal
        update(rowIndex, {
          ...values[rowIndex],
          Remove: true,
        });
      } else {
        remove(rowIndex);
      }
    } else {
      update(rowIndex, {
        ...values[rowIndex],
        Level: "inherited",
        Revert: !values[rowIndex].ReadOnly,
      });
    }
  };

  /**
   * Handles undoing the remove for permissions that existed prior to editing.
   */
  const handleOnUndo = (rowIndex: number, update: (rowIndex: number, value: any) => void) => {
    update(rowIndex, {
      ...values[rowIndex],
      Remove: false,
    });
  };

  /**
   * Callback function for adding new users to the permission list
   */
  const handleOnAdd = () => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight - listRef.current.clientHeight;
    }
  };

  /**
   * Determines the Select options to render & which are disabled based on permissions
   */
  const getOptions = (permission: string | ExtendedPermission | undefined, isGuest = false) => {
    const labelKeys = Object.keys(labels);
    const enabledOptions = isGuest ? labelKeys.filter((key) => key !== "manage" && key !== "write") : labelKeys;
    const options: Array<{
      value: string;
      displayValue: string;
      inheritValue?: PermissionLevel;
      disabled: boolean;
    }> = enabledOptions.map((key: string) => ({
      value: key,
      displayValue: labels[key],
      disabled: !allowEditPermissionLevel(currentPermission, key as PermissionLevel),
    }));

    if (permission && typeof permission !== "string" && permission.Inherited) {
      options.unshift({
        value: "inherited",
        displayValue: `${labels[permission.Inherited]} (Inherited)`,
        inheritValue: permission.Inherited,
        disabled: !allowEditPermissionLevel(currentPermission, permission.Inherited, true),
      });
    }
    return options;
  };

  /**
   * Determines the current permissions status & provides a <Chip /> to indicate it.
   */
  const getTag = (permission: ExtendedPermission, initialValue?: PermissionLevel | "inherited"): JSX.Element | null => {
    const common: Pick<ChipProps, "size" | "variant"> & { "data-testid": string } = {
      size: "small",
      variant: "outlined",
      "data-testid": "permission-chip",
    };

    if (isNew(permission)) {
      return <Chip label="new" {...common} textColor={green[500]} borderColor={green[500]} />;
    } else if (permission.Remove) {
      return <Chip label="remove" {...common} textColor={red[500]} borderColor={red[500]} />;
    } else if (permission.Level !== initialValue) {
      return <Chip label="modified" {...common} color="primary" />;
    } else if (isInherited(permission) && !permission.Revert) {
      return (
        <Tooltip title="This item has permissions inherited from its parent folder.">
          <Chip label="inherited" {...common} />
        </Tooltip>
      );
    }

    return null;
  };

  /**
   * Determines the current permissions icon.
   */
  const getIcon = (permission: ExtendedPermission): JSX.Element | null => {
    if (permission.IdentityType === "user") {
      return <Person color="action" data-testid="share-permission-icon-user" />;
    }

    if (permission.IdentityType === "account") {
      return <Group color="action" data-testid="share-permission-icon-account" />;
    }

    return null;
  };

  const renderGuestChip = (permission: ExtendedPermission): JSX.Element | null => {
    if (permission.Guest) {
      const guestChip = <Chip data-testid="guest-chip" label="Guest" size="small" variant="outlined" textColor={blue[500]} borderColor={blue[500]} />;
      if (!isNew(permission)) {
        return (
          <Tooltip title="This user is not part of this account and cannot be granted permissions higher than collaborator">{guestChip}</Tooltip>
        );
      }
      return guestChip;
    }

    return null;
  };

  return (
    <Box>
      <AddPermission
        name={name}
        push={push}
        placeholder={placeholder}
        suggestions={suggestions}
        resource={resource}
        values={values}
        options={getOptions(currentPermission, false)}
        onAdd={handleOnAdd}
      />

      <Box ref={listRef} className="customScrollbars" maxHeight={350} overflow="auto" mt={2}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>People and groups</TableCell>
              <TableCell />
              <TableCell />
              <TableCell align="right">Permission</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody data-testid="share-permissions-table">
            <FieldArray name={name}>
              {({ fields, meta: { initial: initialValues, submitError, ...rest } }) => {
                return fields.map((fieldName, index) => {
                  const permission = values[index];
                  if (!permission) return null;

                  const initial = (initialValues as ExtendedPermission[]).find((perm) => perm.ID === permission.ID);
                  const disabled = permission.Remove || permission.Submitted || !allowEditPermission(permission, currentUsername, currentPermission);

                  const getActionButton = () => {
                    if (permission.Remove) {
                      return <UndoButton onClick={() => handleOnUndo(index, fields.update)} />;
                    }
                    if (permission.Submitted) {
                      return <Check />;
                    }
                    if (isNew(permission) || isNewInheritOverride(permission) || allowRemoval(permission, currentUsername, currentPermission)) {
                      return <DeleteButton onClick={() => handleOnRemove(permission, index, fields.remove, fields.update)} />;
                    }
                    return null;
                  };

                  return (
                    <TableRow key={fieldName} data-testid="share-permission-row" submitted={permission.Submitted}>
                      <TableCell
                        data-testid="share-permission-user-type"
                        style={{ paddingLeft: 0, paddingRight: 0, verticalAlign: "baseline" }}
                        width={24}
                        align="center"
                      >
                        {getIcon(permission)}
                      </TableCell>
                      <PermissionUserCell permission={permission} submitError={submitError?.[index]} />
                      <TableCell align="right" width={75}>
                        {renderGuestChip(permission)}
                      </TableCell>
                      <TableCell width={75}>{getTag(permission, initial?.Level)}</TableCell>
                      <TableCell align="right" width={175} padding="none">
                        <SelectField
                          margin="none"
                          name={`${fieldName}.Level`}
                          data-testid="share-permission-level-dropdown"
                          variant="standard"
                          options={getOptions(permission, permission.Guest)}
                          disabled={disabled}
                          menuStyle={{ fontSize: 16 }}
                        />
                      </TableCell>
                      <TableCell data-testid="delete-button" align="right" width={30} padding="none">
                        {getActionButton()}
                      </TableCell>
                    </TableRow>
                  );
                });
              }}
            </FieldArray>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default PermissionsField;
