/* eslint-disable no-underscore-dangle */
import { modes } from "@mapbox/mapbox-gl-draw";

import snapTo, { withSnapping } from "fond/draw/modes/snap_to";
import { inputLayerGroups } from "fond/layers";
import { Project } from "fond/types";
import { LayerConfig, SublayerConfig } from "fond/types/ProjectLayerConfig";

import simpleSelect from "./simple_select";
import { canMoveFeature, getBaseLineStringStyles, getBasePointStyles, getQueryLayerIds, getSnappableStyleIds } from "./utils";

interface DrawCustomModeState {
  layerConfigs?: Array<LayerConfig | SublayerConfig>;
  project?: Project;
}

interface DrawCustomModeOptions {
  layerConfigs?: Array<LayerConfig | SublayerConfig>;
  project?: Project;
}

interface DrawCustomModeExtension {
  enter(
    draw: MapboxDraw,
    opts: {
      event: MapboxDraw.MapMouseEvent;
      feature: Feature;
      layerConfigs: Array<LayerConfig | SublayerConfig>;
      layerId: string;
      project: Project;
    }
  ): void;
}

/**
 * An custom version of the Simple Select mode specific to moving Hub locations
 */
const hubSelect = withSnapping<DrawCustomModeState, DrawCustomModeOptions, DrawCustomModeExtension>({
  ...modes.simple_select,

  onSetup: function (opts) {
    const state = modes.simple_select.onSetup?.call(this, opts);

    return {
      ...state,
      project: opts.project,
      layerConfigs: opts.layerConfigs,
    };
  },

  enter: function (mapboxDraw, opts) {
    if (!canMoveFeature(opts.feature)) {
      return;
    }

    mapboxDraw.ctx.map.setFeatureState({ source: opts.layerId, id: opts.feature.id }, { isEditing: true });
    mapboxDraw.add({
      type: "FeatureCollection",
      features: [opts.feature],
    });
    mapboxDraw.changeMode("hub_select", {
      project: opts.project,
      layerConfigs: opts.layerConfigs,
    });

    // Now we've set up the mode, start the drag as if we'd started it when the
    // mode was already active (otherwise the user would have to click again).
    // We can't do this inside `onSetup` because it needs to be done after the
    // render, which Mapbox Draw executes only after onSetup is finished.
    mapboxDraw.ctx.events.getCurrentMode().mousedown(opts.event);
  },

  onMouseDown: function (state, e) {
    if (e.featureTarget != null && !canMoveFeature(e.featureTarget)) {
      return;
    }
    simpleSelect.onMouseDown.call(this as any, state, e);
  },

  onClick: function (state, e) {
    if (e.featureTarget != null && !canMoveFeature(e.featureTarget)) {
      return;
    }
    modes.simple_select.onClick?.call(this, state, e);
  },

  onTap: function (state, e) {
    if (e.featureTarget != null && !canMoveFeature(e.featureTarget)) {
      return;
    }
    modes.simple_select.onTap?.call(this, state, e);
  },

  snap: function (state, event) {
    const hubsLayerGroup = inputLayerGroups.hub;

    if (event.point && state.layerConfigs) {
      const selected = this.getSelected();
      let { snappableLayerIds } = hubsLayerGroup;
      let buffer;

      if (event.originalEvent.buttons === 0) {
        // While *not* dragging we don't want to snap to cables, and we want a small buffer so we only highlight
        // hubs very near the mouse pointer to indicate potential selection.
        buffer = 5;
        // We also don't want to highlight cables as they are not selectable in this mode.
        snappableLayerIds = snappableLayerIds.filter((layerId) => !layerId.includes("cable"));
      } else {
        // If we are dragging we want to include cables and we also want a large radius so that the hub will always
        // be snapping to something, cable or hub.
        buffer = 500;
      }

      const basePointStyles = getBasePointStyles(state.layerConfigs, hubsLayerGroup.layers);
      const baseLineStringStyles = getBaseLineStringStyles(state.layerConfigs, hubsLayerGroup.layers);
      const overrideQueryLayerIds = getQueryLayerIds(basePointStyles, baseLineStringStyles);
      const snappableStyleIds = getSnappableStyleIds(state.layerConfigs, snappableLayerIds);

      return snapTo(
        event,
        this._ctx,
        // If a feature is selected pass it's ID so we don't try snap it to itself.
        selected.length > 0 ? selected[0].id : undefined,
        {
          overrideQueryLayerIds: [...overrideQueryLayerIds, ...(snappableStyleIds || snappableLayerIds)],
          buffer: buffer,
        }
      );
    } else {
      return event;
    }
  },
});

export default hubSelect;
