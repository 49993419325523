/* eslint-disable no-underscore-dangle */
import { DrawCustomMode, DrawCustomModeThis, DrawFeature } from "@mapbox/mapbox-gl-draw";
import CheapRuler, { Line as RulerLine, Point as RulerPoint } from "cheap-ruler";
import { Feature, GeoJsonProperties, Point } from "geojson";
import _ from "lodash";
import mapboxgl, { PointLike } from "mapbox-gl";

import { LayerIds } from "fond/layers";
import * as turf from "fond/turf";

/**
 * Takes a Mapbox Draw event and checks if the event's cursor position is
 * sufficiently close to a point or line to snap to the point or line. If so,
 * modifies the event to refer to the snapped-to point.
 *
 * @param {Object} evt An event received by Mapbox Draw. Not exactly sure
 *   what its type is, probably a Mapbox internal type. In particular
 *   it has a `.point` property with `x` and `y` properties representing
 *   the coordinates of the mouse cursor, and a `.lngLat` property with
 *   `lng` and `lat` coordinates.
 * @param {Object} ctx The Mapbox Draw `ctx` object.
 * @param {String | undefined | null | number} id The id of the currently-selected feature. This is used
 *   to avoid having features snap to themselves.
 * @param {Object} excludeFeatureIds (optional) -- if provided, don't
 *   allow any of the specified IDs to be snap targets.
 *
 * Additionally, snapping is configured via the `snapping` parameter passed to
 * Mapbox Draw itself. Note that if the `snapping` parameter is not present,
 * snapping is completely disabled. The `snapping` parameter itself has the
 * following parameters:
 *
 * - `queryLayerIds`: this is a list of the layer IDs of features we might want to snap to.
 *     It may be a mix of non-editing layers (eg. 'inSpan') and editing layers
 *     (eg. 'gl-draw-point.hot'). They're layer IDs, not styles, so the option is misnamed
 *     just because it was named like this in the original third-party snapping code
 *     we started with, but we should probably fix that some time.
 * - `snappableLayerIds`: a list specifically of just the non-editing layers (eg.
 *     ['inSpan', 'inPole']). Note that we have special logic for preferring to snap
 *     to a point rather than a line, and taking advantage of that logic requires placing
 *     the point layer after the line layer in the array. Ie. ['inSpan', 'inPole'] will
 *     bias snapping towards poles over spans but ['inPole', 'inSpan'] will not.
 *  - `pointStyle`: a Mapbox style; if we are snapping to a point feature, apply
 *     this style to that feature.
 *  - `lineStyle`: a Mapbox style; if we are snapping to a line string feature, apply
 *     this style to that feature.
 *  - Note that `pointStyle` and `lineStyle` are not directly used in snap_to.js; they are
 *    used in `draw/index.js`.
 *
 * We additionally use the Mapbox Draw's top-level `clickBuffer` option (which
 * is a number of pixels; Mapbox Draw's default is 2, if it's somehow
 * nonexistent we will use a default of 5) to determine how close a feature
 * needs to be to the mouse cursor to be a candidate for snapping.
 * Specifically, if the cursor is at (x, y), then a feature is candidate for
 * snapping if its x-coordinate is between (x - buffer) and (x + buffer) and
 * its y-coordinate is between (y - buffer) and (y + buffer). All these
 * quantities are in pixels.
 *
 * @return {Object} The same object as the `evt` argument. If snapping was performed,
 *   the event's `.point` and `.lngLat` properties will be modified, and `.closestFeature`
 *   and `.closestCoord` properties will be added.
 *
 * The function does the following (note that the fact that this function does so many things
 * is a result of it being largely copied from https://github.com/andrewharvey/mapbox-gl-draw ; it
 * would be nice to split it up)
 *
 * 1. Creates a bounding box centered at the mouse's current location,
 *    extending `buffer` pixels in each direction.
 * 2. Finds all features intersecting with the bounding box, using
 *    Mapbox's `queryRenderedFeatures` function. Importantly, we don't
 *    directly use the feature returned by `queryRenderedFeatures`, but
 *    instead we find the original feature by looking it up in `ctx.store._features`
 *    by its id. This is because `queryRenderedFeatures` does not return
 *    correct coordinates for the features it returns (issues are referenced
 *    in the body of the function).
 * 3. If features are found:
 *    1. Uses functions from the `cheapRuler` module to determine which is
 *       closest to the mouse cursor.
 *    2. If the closest feature is a line string, we redo the calculations more precisely.
 *       We also bias towards snapping to a vertex, even if the vertex isn't precisely
 *       the closest point to the mouse cursor.
 *    3. Sets up layers and filters to highlight the feature being snapped to.
 *    4. Updates the `evt` argument to refer to the snapped point.
 */
export default function snapTo(
  evt: MapboxDraw.MapMouseEvent | MapboxDraw.MapTouchEvent,
  ctx: DrawCustomModeThis["_ctx"],
  id: Feature["id"] = undefined,
  { excludeFeatureIds = new Set(), overrideQueryLayerIds, buffer = ctx.options.clickBuffer }: any = {}
): MapboxDraw.MapMouseEvent | MapboxDraw.MapTouchEvent {
  if (ctx.map === null || ctx.options.snapping == null) {
    return evt;
  }

  // ctx.options.clickBuffer may be null in which case we want to default to 5.
  buffer = buffer || 5;

  const box: [PointLike, PointLike] = [
    [evt.point.x - buffer, evt.point.y - buffer],
    [evt.point.x + buffer, evt.point.y + buffer],
  ];

  const evtCoords = evt.lngLat.toArray !== undefined ? evt.lngLat.toArray() : undefined;
  const projectedEvtCoords = ctx.map.project(evtCoords as mapboxgl.LngLatLike);

  // If a point's distance from the mouse is less than the closest line's
  // distance from the mouse plus `pointBiasDist`, pretend the point was
  // closer.
  const pointBiasDist = 5;

  let closestDistance = null;
  let closestCoord: mapboxgl.LngLatLike | null = null;
  let closestFeature = null;

  const queryLayerIds = overrideQueryLayerIds || ctx.options.snapping.queryLayerIds;
  const { snappableLayerIds, snappableStyleIds } = ctx.options.snapping;

  // Usually the same as snapLayers, but for underground path, we add an extra
  // "layer" including all the vertices in the matched LineStrings, so we can
  // preference snapping to vertices above snapping to arbitrary points along
  // the lines.
  let snapLayersPlusPoints = snappableLayerIds;
  const ruler = new CheapRuler(evt.lngLat.lng);

  let matchingFeaturesByLayer: { [key: string]: Feature[] } = {};
  for (let layerId of snappableLayerIds) {
    matchingFeaturesByLayer[layerId] = [];
  }
  if (_.includes(snappableLayerIds, LayerIds.inStreet)) {
    snapLayersPlusPoints = [...snappableLayerIds, "inStreet_points"];
    matchingFeaturesByLayer.inStreet_points = [];
  }

  const featureIds = new Set();
  for (let feature of ctx.map.queryRenderedFeatures(box, { layers: queryLayerIds })) {
    // Use the id of the feature returned by `queryRenderedFeatures` to find
    // the exact coordinates of the feature, which we saved to the
    // store/context. We have to do this because Mapbox does not return the
    // correct coordinates from `queryRenderedFeatures`
    // - https://github.com/mapbox/mapbox-gl-js/issues/4733
    // - https://github.com/mapbox/mapbox-gl-js/issues/5639

    // Note also that queryRenderedFeatures can return the same feature
    // multiple times, so we keep a set of all the feature ids we've
    // encountered so we don't process the same feature multiple times
    // ourselves.

    const featureId = feature.properties?.id;
    const featureLookupItem = ctx.api.getFeatureById(featureId);

    if (
      featureLookupItem != null &&
      !excludeFeatureIds.has(featureId) &&
      // We haven't already processed this feature.
      !featureIds.has(featureId) &&
      // The queried feature isn't the same as the feature that we are snapping.
      featureId !== id &&
      // If we have moved a feature, we will have two features with the same
      // ID -- the hidden one and the real one. We want to make sure to only
      // consider the real one.  We do this by excluding features whose layer
      // id is one of the "real" layer ids.  Eg. `snappableLayerIds` will be
      // something like ['inSpan', 'inPole'], and a hidden feature
      // will have a layer of 'inSpan' or 'inPole' whereas the "real" feature
      // will have one of the Mapbox Draw layers. And all this only matters if
      !(featureLookupItem.isEditing && _.includes(snappableStyleIds, feature.layer.id))
    ) {
      featureIds.add(featureId);

      matchingFeaturesByLayer[featureLookupItem.layerId].push(featureLookupItem.feature);
      if (featureLookupItem.layerId === LayerIds.inStreet) {
        // For streets, add a Point feature for each vertex in the LineString
        // so we can preference snapping to vertices over lines using the same
        // logic we use for preferencing to snap to poles over strands.
        for (let point of (featureLookupItem.feature as Feature<LineString, GeoJsonProperties>).geometry.coordinates) {
          matchingFeaturesByLayer.inStreet_points.push(
            turf.feature<Point>(
              {
                type: "Point",
                coordinates: point,
              },
              {
                // We don't want to return this feature as the closest feature
                // because it's not a real feature; instead keep a link to the
                // actual inStreet LineString so we can return that.
                parent: featureLookupItem.feature,
              },
              { id: featureId }
            )
          );
        }
      }
    }
  }

  for (let layerId of snapLayersPlusPoints) {
    for (let feature of matchingFeaturesByLayer[layerId]) {
      let coords: number[];
      if (feature.geometry.type === "LineString") {
        coords = ruler.pointOnLine(feature.geometry.coordinates as RulerLine, evtCoords as RulerPoint).point;
      } else if (feature.geometry.type === "Point") {
        coords = feature.geometry.coordinates;
      } else {
        throw new Error("Unrecognised feature geometry type");
      }

      const dist = ruler.distance(coords as RulerPoint, evtCoords as RulerPoint);

      let isClosest = false;
      if (closestDistance === null || dist < closestDistance) {
        isClosest = true;
      } else if (
        closestFeature != null &&
        closestCoord !== null &&
        closestFeature.geometry.type === "LineString" &&
        feature.geometry.type === "Point"
      ) {
        // We bias towards selecting points over lines.
        //
        // Picture:
        //
        // o------------o
        //             x
        //
        // If the 'o's are poles, the hyphens are a span and the cursor is at
        // the x, the closest feature is the span, but we are more likely to
        // want to select the pole.
        //
        // The same logic applies to the vertices of LineStrings for
        // underground path, even though the vertices are not distinct
        // features.
        //
        // So if the current feature being considered is a point, but the
        // closest feature so far is from a line, we consider the current
        // feature to be the closest if it's within a few pixels from the
        // previously-closest point. (In the picture above the previously-closest
        // point will be the point on the span directly above the mouse cursor.)
        //
        // Also note that this logic relies on points being processed after
        // lines, which should be setup by putting the point layer after the
        // line layer in `snapLayers`.
        //
        // Also, a better way to do this would probably be to process the
        // points first, and then skip processing lines completely if a
        // close-enough point is found.
        const projectedPoint1 = ctx.map.project([coords[0], coords[1]]);
        const projectedDist = Math.sqrt((projectedPoint1.x - projectedEvtCoords.x) ** 2 + (projectedPoint1.y - projectedEvtCoords.y) ** 2);

        const projectedClosestPoint = ctx.map.project(closestCoord);
        const projectedClosestDist = Math.sqrt(
          (projectedClosestPoint.x - projectedEvtCoords.x) ** 2 + (projectedClosestPoint.y - projectedEvtCoords.y) ** 2
        );

        if (projectedDist - projectedClosestDist < pointBiasDist) {
          isClosest = true;
        }
      }
      if (isClosest) {
        if (feature.properties != null && feature.properties.parent != null) {
          closestFeature = feature.properties.parent;
        } else {
          closestFeature = feature;
        }
        closestCoord = [coords[0], coords[1]];
        closestDistance = dist;
      }
    }
  }

  if (closestFeature != null && closestCoord) {
    evt.closestCoord = closestCoord;
    evt.closestFeature = closestFeature;
    evt.lngLat.lng = closestCoord[0];
    evt.lngLat.lat = closestCoord[1];
    evt.point = ctx.map.project(closestCoord);

    // Actually set the hover styles to highlight the closest element.
    const lineFilter = ["all", ["==", "$type", "LineString"], ["==", "id", closestFeature.id]];
    const pointFilter = [
      "all",
      [
        "in",
        "$type",
        "Point",
        // By including 'LineString' here, when the user is hovering over a
        // line string, we also highlight the vertices of the line string.
        "LineString",
      ],
      ["==", "id", closestFeature.id],
    ];
    for (let layerId of ["mapbox-gl-draw-cold", ...snappableLayerIds]) {
      ctx.map.setFilter(`gl-draw-line-snap-${layerId}`, lineFilter);
      ctx.map.setFilter(`gl-draw-point-snap-${layerId}`, pointFilter);
    }
  } else {
    clearSnapStyling(ctx);
  }

  return evt;
}

/**
 * Turn off the snapping layers (ie. the layers that highlight the objects
 * currently being snapped to).
 */
export function clearSnapStyling(ctx: DrawCustomModeThis["_ctx"]): void {
  const matchNothing = ["any"];

  for (let layerId of ["mapbox-gl-draw-cold", ...ctx.options.snapping.snappableLayerIds]) {
    for (let snapLayerId of [`gl-draw-line-snap-${layerId}`, `gl-draw-point-snap-${layerId}`]) {
      if (ctx.map.getLayer(snapLayerId) != null) {
        ctx.map.setFilter(snapLayerId, matchNothing);
      }
    }
  }
}

export interface DrawCustomModeWithSnapping<S, O> extends DrawCustomMode<S, O> {
  dragMove?(this: DrawCustomModeThis, state: S, event: MapboxDraw.MapMouseEvent): void;
  snap(this: DrawCustomModeThis, state: S, event: MapboxDraw.MapMouseEvent): void;
  clickOnFeature?(this: DrawCustomModeThis, state: S, event: MapboxDraw.MapMouseEvent): void;
}

/**
 * Takes a mode and returns a new mode that mixes in snapping functionality.
 * The input mode must have a `snap` function, taking `state` and and event,
 * which should look something like the following:

  snap: function(state, event) {
    if (e.point) {
      return snapTo(event, this._ctx, <... mode-specific snapTo arguments ...>);
    }
    else {
      return e;
    }
  }

 * For each mouse event (onMouseMove, onMouseUp, etc), the event object the new
 * mode will see will be the return value of the `snap` function.
 *
 * The returned mode will also bypass the original mode's callbacks for events
 * whose `.featureTarget`s refer to deleted features. This functionality may be
 * more appropriate in a different wrapper.
 *
 * See draw_pole.js for a simple example.
 *
 * @param mode a Mapbox Draw mode
 * @returns a new Mapbox Draw mode
 */
export function withSnapping<S, O, T>(mode: DrawCustomModeWithSnapping<S, O> & T): DrawCustomModeWithSnapping<S, O> & T {
  if (mode.snap == null) {
    throw new Error("mode must have a `snap` function");
  }

  const newMode: DrawCustomModeWithSnapping<S, O> & T = {
    ...mode,

    onMouseOut: function (state, e) {
      clearSnapStyling(this._ctx);
      if (mode.onMouseOut != null) {
        mode.onMouseOut.call(this, state, e);
      }
    },

    onStop: function (state) {
      clearSnapStyling(this._ctx);
      if (mode.onStop != null) {
        mode.onStop.call(this, state);
      }
    },

    clickOnFeature: function (state, e) {
      const { properties, source } = e.featureTarget as any;

      // If a source is defined only allow features defined as selectable to be selected.
      if (!source || ["mapbox-gl-draw-cold", "mapbox-gl-draw-hot", ...this._ctx.options.snapping.selectableLayerIds].includes(source)) {
        this.setSelected([properties?.id]);
        this.updateUIClasses({ mouse: "move" });
      }
    },
  };

  const events = ["onMouseDown", "onMouseMove", "onMouseUp", "onDrag", "dragMove", "onTap", "onClick"] as const;
  for (let event of events) {
    newMode[event] = function (this: DrawCustomModeThis & { snap(state: S, e: MapboxDraw.MapMouseEvent | MapboxDraw.MapTouchEvent): any }, state, e) {
      // Always call `this.snap` even if the particular event handler doesn't
      // exist; this way we still get highlighting of features even if the mode
      // doesn't need any particular onMouseMove logic, for example.
      const snappedEvent = this.snap(state, e);
      if (e.featureTarget != null && this._ctx.api.isDeletedFeature(e.featureTarget.id)) {
        (e.featureTarget as DrawFeature | null) = null;
      }

      if (mode[event] != null) {
        (mode[event] as (...args: any[]) => any)?.call(this, state, snappedEvent);
      }
    };
  }

  return newMode;
}
