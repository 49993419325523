import { useMemo, useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { selectCurrentAccount, useGetAccountSubscriptionsQuery, useGetVersionStatusQuery } from "fond/api";
import { canUpdateDesign } from "fond/project/redux";
import { run } from "fond/project/solve/redux";
import { Store, SubscriptionStatus } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";

const DesignNeedsUpdating: React.FC = () => {
  const dispatch = useAppDispatch();
  const [snackOpen, setSnackOpen] = useState(true);
  const versionId = useSelector((state: Store): string => state.project.versionId);
  const { data: versionStatus } = useGetVersionStatusQuery(versionId, { skip: !versionId });
  const canUpdate = useSelector((state: Store) => canUpdateDesign(state.project, versionStatus, null));

  const selectedAccount = useSelector(selectCurrentAccount);
  const { data: subscriptions } = useGetAccountSubscriptionsQuery(selectedAccount?.ID ?? skipToken);

  const activeSubscription = useMemo(() => subscriptions?.find((sub) => sub.Status === SubscriptionStatus.Active && !!sub.Planner), [subscriptions]);
  const hasQuota = activeSubscription?.Planner && activeSubscription.Planner.QuotaUsage < activeSubscription.Planner.Quota;

  if (!snackOpen) {
    return null;
  }

  if (canUpdate && hasQuota) {
    return (
      <div data-testid="design-needs-updating">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          message="You have made changes that may affect your design. You can apply them when you're ready."
          action={[
            <Button
              key="update"
              color="primary"
              size="small"
              className="apply-changes"
              onClick={() => {
                setSnackOpen(false);
                dispatch(run(versionId, versionStatus?.WorkflowQuality || "express"));
              }}
            >
              Update design
            </Button>,
            <IconButton
              key="close"
              color="inherit"
              onClick={() => {
                setSnackOpen(false);
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>,
          ]}
          open
          onClose={(event, reason) => {
            if (reason !== "clickaway") {
              setSnackOpen(false);
            }
          }}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default DesignNeedsUpdating;
