import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { usePrevious } from "react-use";
import { InfoOutlined, Lock } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import classNames from "classnames";

import Architecture from "fond/architecture/Architecture";
import { LayerGroupIds } from "fond/layers";
import mixpanel from "fond/mixpanel";
import {
  cancelLayerOperation,
  closeArchitectureModal,
  closeUploadPanel,
  confirmLayerOperation,
  getAccordionStepClassSets,
  getProjectArchitecturePanelIndex,
  isSolveActive as getIsSolveActive,
  Modals,
  openArchitectureModal,
} from "fond/project/redux";
import { Architecture as _Architecture, Project, Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import * as localStorage from "fond/utils/localStorage";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { ConfirmModal, NonIdealState, SpinningCircle, StackedNavigation } from "fond/widgets";

import { useGetVersionStatusQuery } from "../../api";
import SolvePanel from "../solve/SolvePanel";
import { getCurrentProject } from "..";

import ArchitecturePanel from "./ArchitecturePanel";
import AreaSelect from "./AreaSelectPanel";
import Draw from "./DrawPanel";
import ImportData from "./ImportDataPanel";
import InputDataPanel from "./InputDataPanel";
import InputMethodsPanel from "./InputMethodsPanel";
import PolygonSelect from "./PolygonSelectPanel";
import UploadPanel from "./UploadPanel";

import "./AutoDesignPanel.scss";

type IProps = {
  /**
   * The project currently being viewed / edited
   */
  project: Project;
  /**
   * The current architecture.
   */
  architecture: _Architecture | null;
  /**
   * Flag indicating if the project is read only
   */
  readOnly: boolean;
};

export const autoDesignStackedNavigationPanels: Array<{ id: string; name: string; component: React.ReactNode; useDefaultHeader?: boolean }> = [
  {
    id: "addresses",
    name: "Addresses",
    component: <InputMethodsPanel layerGroupId={LayerGroupIds.inAddress} />,
  },
  {
    id: "undergroundPath",
    name: "Underground Path",
    component: <InputMethodsPanel layerGroupId={LayerGroupIds.inStreet} />,
  },
  {
    id: "aerialSpanAndPoles",
    name: "Aerial Span and Poles",
    component: <InputMethodsPanel layerGroupId={LayerGroupIds.spanPole} />,
  },
  {
    id: "centralOffice",
    name: "Central Office",
    component: <InputMethodsPanel layerGroupId={LayerGroupIds.inExchange} />,
  },
  {
    id: "area-select",
    name: "Area Select",
    component: <AreaSelect />,
    useDefaultHeader: false,
  },
  {
    id: "upload",
    name: "Upload",
    component: <UploadPanel />,
    useDefaultHeader: false,
  },
  {
    id: "draw",
    name: "Draw on Map",
    component: <Draw />,
    useDefaultHeader: false,
  },
  {
    id: "draw-polygon",
    name: "Draw Polygon",
    component: <PolygonSelect />,
    useDefaultHeader: false,
  },
  {
    id: "import-data",
    name: "Import data",
    component: <ImportData />,
  },
  {
    id: "use-existing-polygon",
    name: "Use existing polygon",
    component: <PolygonSelect />,
    useDefaultHeader: false,
  },
];

/**
 * Current behaviour:
 *
 * It's not possible to enter a step beyond the current step.
 *
 * "Active" means the currently-opened step
 * "Current" means the furthest step which the user has not yet completed
 *
 * The colour of a step is determined by the following rules. If a step matches
 * more than one description, the first is used.
 * 1. Active step is blue ("active colour")
 * 2. Completed step is green ("completed colour")
 * 3. Current step is white-ish with a blue border ("current colour")
 * 3. Disabled step is grey
 */

/*
 * Upload behaviour:
 *
 * - If no file is uploaded, go straight to the upload form.
 * - If there is a file uploaded but no design exists, go to "file exists" form.
 * - If there is a file uploaded and a design exists, the "confirm replace design" modal.
 *   If the user accepts, go straighht to the upload form (the "file exists" form would be
 *   redundant in this case).
 */

const AutoDesignPanel: React.FC<IProps> = ({ project, architecture, readOnly }: IProps) => {
  const dispatch = useAppDispatch();
  const HasCustomLayerConfig = useSelector((state: Store) => getCurrentProject(state.project)?.HasCustomLayerConfig);
  const modal = useSelector((state: Store) => state.project.modal);
  const confirmMessage: "replaceDesign" | "replaceLayer" | "unsavedEdits" = useSelector((state: Store) =>
    state.project.confirm != null ? state.project.confirm.message : null
  );
  const isSolveActive = useSelector((state: Store) => getIsSolveActive(state));
  const { Permission: projectPermission } = project;
  const versionId = useSelector((state: Store) => state.project.versionId);
  const { data: versionStatus } = useGetVersionStatusQuery(versionId, { skip: !versionId });
  const stepClassSets = getAccordionStepClassSets(architecture, versionStatus);
  const previousArchitecture = usePrevious(architecture);
  const accordionLocalStorageKey = `state.project.versions[${versionId}].accordion`;

  const [activeIndex, setActiveIndex] = useState(
    localStorage.getItem(accordionLocalStorageKey)?.activeIndex ??
      (project && versionStatus ? getProjectArchitecturePanelIndex(architecture, versionStatus) : 0)
  );
  const setAccordionIndex = (index: number) => {
    setActiveIndex(index);
    localStorage.setItem(accordionLocalStorageKey, { activeIndex: index });
  };

  useEffect(() => {
    // When the user first applies an architecture to a project we move to the next step.
    // We don't ever move between steps after that.
    if (!previousArchitecture && !!architecture && activeIndex === 0) {
      setAccordionIndex(1);
    }
  }, [architecture]);

  const handleOnClickIndex = (index: number) => {
    if (index !== activeIndex) {
      const maxIndex = getProjectArchitecturePanelIndex(architecture, versionStatus);
      if (index <= maxIndex) {
        setAccordionIndex(index);
      }
    }
  };

  const handleOnChangeArchitectureClick = () => dispatch(openArchitectureModal());
  const handleOnConfirmLayerOperation = () => dispatch(confirmLayerOperation());
  const handleOnCancelLayerOperation = () => dispatch(cancelLayerOperation());
  const handleOnArchitectureModalClose = () => dispatch(closeArchitectureModal());
  const handleOnCloseUploadPanel = () => dispatch(closeUploadPanel()); // TODO-devex-5276

  if (HasCustomLayerConfig) {
    return <NonIdealState size="small" icon={<InfoOutlined />} description={<>There are no design tools available.</>} />;
  }

  const accordionPanes = [
    {
      header: "Architecture",
      id: "architecture",
      content: (
        <div style={{ textAlign: "left" }}>
          <ArchitecturePanel architecture={architecture} readOnly={readOnly} onChangeClick={handleOnChangeArchitectureClick} />
        </div>
      ),
    },
    {
      header: "Input data",
      id: "inputData",
      content: <InputDataPanel readOnly={readOnly} />,
    },
    // The solve tab pane includes the solve panel. This naming is a little odd and could be improved.
    {
      header: "Design",
      id: "design",
      content: (
        <div>
          <SolvePanel readOnly={readOnly} />
        </div>
      ),
    },
  ];

  const confirmModals: { [key: string]: { header: string; message: string; confirmText?: string } } = {
    replaceDesign: {
      header: "Remove current design?",
      message:
        "There is already a design present in this project. Changing the input data or architecture will remove it. Are you sure you want to do this?",
    },
    replaceLayer: {
      header: "Replace data?",
      message: "You have already uploaded data for this layer. Proceeding with Area Select will replace it. Are you sure you want to do this?",
    },
    unsavedEdits: {
      header: "Discard edits?",
      message: "You have unsaved changes to the layer you are currently editing. Proceeding will discard them. Are you sure you want to do this?",
      confirmText: "Discard",
    },
  };

  const confirmModal = modal === Modals.confirm ? confirmMessage && confirmModals[confirmMessage] : null;

  return (
    <div className="auto-design-panel" data-testid="auto-design-panel">
      {confirmModal ? (
        <ConfirmModal
          className="confirm-remove-current-design"
          open
          header={confirmModal.header}
          confirmText={confirmModal.confirmText}
          content={
            <div className="content">
              <Typography variant="body2">{confirmModal.message}</Typography>
            </div>
          }
          onConfirm={() => {
            mixpanel.track("Accepted confirm override");
            handleOnConfirmLayerOperation();
          }}
          onCancel={() => {
            mixpanel.track("Cancelled confirmed override");
            handleOnCancelLayerOperation();
          }}
        />
      ) : (
        <>
          {modal === Modals.architecture && (
            <Architecture onClose={() => handleOnArchitectureModalClose()} project={project} architecture={architecture} />
          )}
        </>
      )}

      {!permissionCheck(projectPermission.Level, Actions.PROJECT_EDIT) ? ( // if user has access and no license, more granular "locks" are applied per component
        <NonIdealState size="small" icon={<Lock />} title="View only access" description="You only have view access to this project" />
      ) : (
        <Box className="auto-design-panel__inner">
          <StackedNavigation
            rootComponent={
              <div className={classNames("accordion", "customScrollbars")}>
                {accordionPanes.map((pane, i) => {
                  return (
                    <Accordion key={`${pane.id}_title`} data-testid={`accordion-pane-${pane.id}`} expanded={activeIndex === i} elevation={0}>
                      <AccordionSummary
                        onClick={() => handleOnClickIndex(i)}
                        style={{
                          minHeight: "auto",
                          margin: 0,
                        }}
                        className={classNames("title", { ...stepClassSets[i] }, { active: activeIndex === i })}
                      >
                        <Box p="5px">
                          <div className="workflow-step-number-circle">
                            <div className="workflow-step-number-circle__inner">
                              <div className="workflow-step-number-circle__number">{i + 1}</div>
                              {i === 2 && isSolveActive && (
                                <div className="workflow-step-number-circle__spinning-circle-container">
                                  <SpinningCircle />
                                </div>
                              )}
                            </div>
                          </div>
                        </Box>
                        <Typography variant="body1" className="accordion-panel__title">
                          {pane.header}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails key={`${pane.id}_content`} className="accordion-panel__content" style={{ margin: 0 }}>
                        {pane.content}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            }
            screens={autoDesignStackedNavigationPanels}
          />
        </Box>
      )}
    </div>
  );
};

export default AutoDesignPanel;
