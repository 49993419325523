import * as React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";

import { Subscription } from "fond/types";

import InsightsTotalPrems from "../InsightsCard/InsightsTotalPrems";
import InsightsOverview from "../InsightsOverview/InsightsOverview";

interface InsightsPageProps {
  subscription: Subscription;
}

const InsightsPage: React.FC<InsightsPageProps> = ({ subscription }: InsightsPageProps) => {
  return (
    <Box>
      <Grid container pb={4} spacing={4}>
        <Grid item xs={4}>
          {subscription.Planner && <InsightsTotalPrems subscription={subscription.Planner} />}
        </Grid>
      </Grid>
      <Typography variant="h2">Analytics</Typography>
      <Box pt={4}>
        <Paper elevation={1} data-testid="insights-analytics">
          <InsightsOverview subscription={subscription} />
        </Paper>
      </Box>
    </Box>
  );
};

export default InsightsPage;
