import React, { useState } from "react";
import { Delete, Edit, Redo, Share as ShareIcon } from "@mui/icons-material";
import { ListItemIcon, Menu, MenuItem, MenuProps, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import { useUpdateReportMutation } from "fond/api";
import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import mixpanel from "fond/mixpanel";
import Share from "fond/share/Share";
import { Report } from "fond/types";
import { Actions } from "fond/utils/permissions";
import { required } from "fond/utils/validation";
import MoveReportDialog from "fond/views/Report/MoveReportDialog";
import RemoveReportDialog from "fond/views/Report/RemoveReportDialog";
import { RenameModal } from "fond/widgets";

import StarMenuItem from "./StarMenuItem";

interface IProps extends Pick<MenuProps, "anchorEl"> {
  report: Report;
  onMenuClose: () => void;
}

type ReportModal = "delete" | "copy" | "move" | "rename" | "share";
const ReportRowMenu: React.FC<IProps> = ({ report, onMenuClose, anchorEl }) => {
  const [showModal, setShowModal] = useState<ReportModal>();
  const { enqueueSnackbar } = useSnackbar();
  const [updateReport] = useUpdateReportMutation();

  const canDeleteReport = usePermissionCheck(Actions.REPORT_DELETE, report.Permission?.Level);
  const canMoveReport = usePermissionCheck(Actions.REPORT_MOVE, report.Permission?.Level);
  const canRenameReport = usePermissionCheck(Actions.REPORT_RENAME, report.Permission?.Level);
  const canShareReport = usePermissionCheck(Actions.REPORT_SHARE_VIEW, report.Permission?.Level);

  /**
   * Generic Callback function that updates any of the modal open states and closes the menu
   */
  const openModal = (modalType: ReportModal) => () => {
    onMenuClose();
    setShowModal(modalType);
  };

  const closeModal = () => {
    setShowModal(undefined);
  };

  const handleRenameSubmit = async (value: { name: string }) => {
    if (value.name !== report.Name) {
      try {
        mixpanel.track("Report", "Report Row", "Renamed report", { reportId: report.ID });
        await updateReport({ ID: report.ID, Name: value.name }).unwrap();
      } catch {
        enqueueSnackbar("Report rename failed. Please try again...", { variant: "error" });
      } finally {
        closeModal();
      }
    }
  };

  const resourceName = report.Name.replace(/[^\w\s]/g, "").replace(/\s/g, "");

  return (
    <Menu
      data-testid={`project-list-item-menu-${resourceName}`}
      anchorEl={anchorEl}
      keepMounted
      open={!!anchorEl}
      onClose={onMenuClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem aria-label="Rename Report" data-testid="rename-report-button" onClick={openModal("rename")} disabled={!canRenameReport}>
        <ListItemIcon>
          <Edit fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Rename</Typography>
      </MenuItem>

      <MenuItem aria-label="Move Report" data-testid="move-report-button" onClick={openModal("move")} disabled={!canMoveReport}>
        <ListItemIcon>
          <Redo fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Move</Typography>
      </MenuItem>

      <MenuItem aria-label="Share Report" data-testid="share-report-button" onClick={openModal("share")} disabled={!canShareReport}>
        <ListItemIcon>
          <ShareIcon />
        </ListItemIcon>
        <Typography variant="inherit">Share</Typography>
      </MenuItem>

      <MenuItem aria-label="Delete Report" data-testid="delete-report-button" onClick={openModal("delete")} disabled={!canDeleteReport}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <Typography variant="inherit">Delete</Typography>
      </MenuItem>
      <StarMenuItem onMenuClose={onMenuClose} resource={report} />
      {showModal === "delete" && <RemoveReportDialog report={report} closeModalCallback={closeModal} />}
      {showModal === "move" && <MoveReportDialog report={report} onClose={closeModal} />}
      {showModal === "rename" && (
        <RenameModal
          entity={report}
          title="Rename report"
          defaultValue={report.Name || "Untitled report"}
          onRename={(formData) => handleRenameSubmit(formData)}
          onClose={closeModal}
          onValidate={required}
        />
      )}
      {showModal === "share" && <Share resource={report} onClose={closeModal} />}
    </Menu>
  );
};

export default ReportRowMenu;
