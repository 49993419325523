import * as React from "react";
import { useCallback, useState } from "react";
import { ColorResult, SketchPicker } from "react-color";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { FormControl, FormHelperText, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDebouncedCallback } from "use-debounce";

export type ColorProps = {
  name: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  validate?: any;
};

const useCustomStyles = makeStyles<Theme, { color: string }>((theme: Theme) => ({
  picker: {
    padding: "0 !important",
    boxShadow: "none !important",
  },
}));

const ColorField: React.FC<ColorProps> = (props: ColorProps) => {
  const { fieldProps, name, validate, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input, meta }) => <ColorWrapper input={input} meta={meta} name={name} {...rest} key={input.value} />}
      validate={validate}
      {...fieldProps}
    />
  );
};

type ColorWrapperProps = FieldRenderProps<string, HTMLElement>;

const ColorWrapper: React.FC<ColorWrapperProps> = ({ input: { name, onChange, value, ...restInput }, helperText }: ColorWrapperProps) => {
  const [color, setColor] = useState(value);
  const classes = useCustomStyles({ color: value });

  const onChangeDebounce = useDebouncedCallback((updatedColor: string) => {
    onChange(updatedColor);
  }, 500);

  const handleOnChange = useCallback(({ rgb: { r, g, b, a } }: ColorResult) => {
    const newColor = `rgba(${r},${g},${b},${a})`;
    setColor(newColor);
    onChangeDebounce(newColor);
  }, []);

  return (
    <FormControl data-testid={`${name}-color-field`} {...restInput}>
      <SketchPicker color={color} width="160" onChange={handleOnChange} presetColors={[]} className={classes.picker} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ColorField;
