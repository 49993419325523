import * as React from "react";

import { SEARCH_KEY } from "fond/constants";
import { useNoAccountRedirect } from "fond/hooks/useNoAccountRedirect";
import { Navigation } from "fond/projects";
import SearchResults from "fond/projects/GlobalSearch/SearchResults";
import StarredContent from "fond/projects/StarredContent";
import { useQueryParams } from "fond/utils/hooks";
import { PageContent } from "fond/widgets";

/**
 * A top level component used for displaying starred projects.
 */
const Starred: React.FC = () => {
  useNoAccountRedirect();

  // Global search results page
  const searchQuery = useQueryParams(SEARCH_KEY);
  if (searchQuery) {
    return <SearchResults />;
  }

  return <PageContent title="Starred" mainContent={<StarredContent />} navigation={<Navigation />} testId="filter-starred" />;
};

export default Starred;
