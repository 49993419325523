import React, { useState } from "react";
import EditAttributesIcon from "@mui/icons-material/EditAttributes";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Box, Button, ListItemIcon, MenuItem, Typography } from "@mui/material";

import { UserAccountAllocationResponse } from "fond/api";
import theme from "fond/theme";
import { Modal } from "fond/widgets";

import EditUserForm from "./EditUserForm";

interface IProps {
  userAccountAllocation: UserAccountAllocationResponse;
}

const EditUserAction: React.FC<IProps> = ({ userAccountAllocation }) => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  const editUserFormId = "edit-user-form";
  return (
    <>
      <MenuItem aria-label="Manage user" data-testid="edit-user" onClick={() => setOpen(true)}>
        <ListItemIcon>
          <EditAttributesIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Manage user</Typography>
      </MenuItem>
      <Modal
        open={open}
        onClose={closeModal}
        data-testid="edit-user-modal"
        header="User Details"
        content={
          <>
            <Box display="flex" alignItems="center" gap="1rem" mb="10px">
              {/* <Avatar name={name} variant="circular" /> // TODO add when information is available */}
              <Avatar sx={{ bgcolor: theme.palette.biarri.primary.red }}>
                <PersonIcon />
              </Avatar>
              <Box>
                <Typography variant="h6" data-testid="edit-user-email">
                  {userAccountAllocation.User.Email}
                </Typography>
                {/* <UserSubtext variant="subtitle1">{user.Email}</UserSubtext> // TODO add when name is added */}
              </Box>
            </Box>
            {/* <UserLastActivity>last activity date: to be added</UserLastActivity> // TODO add when information is available */}
            <EditUserForm formId={editUserFormId} userAccountAllocation={userAccountAllocation} />
          </>
        }
        actions={
          <>
            <Button data-testid="cancel-button" color="primary" onClick={closeModal}>
              Cancel
            </Button>
            <Button data-testid="apply-button" form={editUserFormId} color="primary" type="submit" variant="contained" onClick={closeModal}>
              Apply
            </Button>
          </>
        }
      />
    </>
  );
};

export default EditUserAction;
