import * as React from "react";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { WorkflowQuality } from "fond/types";
import { formatDuration } from "fond/utils/dates";

const qualitySpecification = {
  express: { name: "Express", maxDurationMinutes: 20, description: "Optimized for speed" },
  balanced: { name: "Balanced", maxDurationMinutes: 60, description: "Balanced quality and time" },
  extended: { name: "Extended", maxDurationMinutes: 200, description: "Extra time may result in better quality" },
};

interface QualityRadioProps {
  value: WorkflowQuality;
  secondaryLabel?: string;
}

const QualityRadio: React.FC<QualityRadioProps> = ({ value, secondaryLabel }) => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio sx={{ py: 0.75 }} />}
      aria-label={qualitySpecification[value].name}
      label={
        <Stack alignItems="center" direction="row" spacing={0.5} flexWrap="wrap">
          <Typography>{qualitySpecification[value].name}</Typography>
          {secondaryLabel && (
            <Typography variant="body3" color="biarri.secondary.grey">
              {secondaryLabel}
            </Typography>
          )}
        </Stack>
      }
    />
  );
};

/**
 * Component to select the quality of design generation.
 *
 * @param quality The current quality the design generation.
 * @param onChange Callback to update the quality of design generation.
 * @param disabled Whether the selection is disabled.
 */
interface IProps {
  quality: WorkflowQuality;
  onChange: (value: WorkflowQuality) => void;
  disabled?: boolean;
}

const SolveQuality: React.FC<IProps> = ({ quality, onChange, disabled }: IProps) => {
  const theme = useTheme();

  const handleQuality = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuality = event.target.value as WorkflowQuality;
    if (newQuality !== quality) {
      onChange(newQuality);
    }
  };

  return (
    <Accordion data-testid="design-quality-accordion" disableGutters sx={{ backgroundColor: theme.palette.biarri.secondary.whiteSmoke }}>
      <AccordionSummary expandIcon={<SettingsIcon />}>
        <Stack direction="column">
          <Typography variant="subtitle2" align="left">
            Design quality
          </Typography>
          <Typography data-testid="design-quality" variant="heading" align="left" color="primary">
            {qualitySpecification[quality].name}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Paper variant="outlined" sx={{ mx: theme.spacing(2), mb: theme.spacing(2), padding: theme.spacing(2) }}>
          <Stack direction="column" spacing={2}>
            <Typography variant="h7" align="left">
              Optimize design quality by adjusting solver time limit.
            </Typography>
            <FormControl disabled={disabled}>
              <RadioGroup data-testid="design-quality-options" value={quality} onChange={handleQuality} name="radio-buttons-group">
                <QualityRadio value="express" secondaryLabel="(Recommended)" />
                <QualityRadio value="balanced" />
                <QualityRadio value="extended" />
              </RadioGroup>
            </FormControl>
            <Stack direction="column" spacing={1}>
              <Typography variant="body3" fontWeight="bold" align="left">
                {qualitySpecification[quality].description}
              </Typography>
              <Stack alignItems="center" direction="row" spacing={0.5}>
                <AccessTimeFilledIcon color="disabled" fontSize="small" />
                <Typography data-testid="design-maximum-duration" variant="body3" color={theme.palette.biarri.secondary.grey}>
                  Maximum duration: {formatDuration(qualitySpecification[quality].maxDurationMinutes, "minute")}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

export default SolveQuality;
