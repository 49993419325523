import { useEffect, useState } from "react";
import * as React from "react";
import { Info } from "@mui/icons-material";
import { Box, Button } from "@mui/material";

import { BlockSpinner, NonIdealState } from "fond/widgets";

/**
 * A generic loading page for the Layout Widets when they are opened
 * within a floating window.
 *
 * If a floating window is manually refreshed (f5) this page will show.
 */
const Popout: React.FC = () => {
  const [loaded, setLoaded] = useState(false);

  /**
   * When the floating window loads its content it removes the
   * "root" DOM element, replacing this component with the widget content.
   *
   * If that has not occurred by the timeout we show the non ideal state.
   *
   */
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  });

  const handleClose = () => {
    window.close();
  };

  return (
    <Box height="100%" display="flex">
      {loaded ? (
        <NonIdealState
          size="small"
          icon={<Info />}
          description="The panel has been docked - you can close this floating window."
          action={
            <Box gap={8} display="flex">
              <Button color="primary" size="small" variant="outlined" onClick={handleClose}>
                Close
              </Button>
            </Box>
          }
        />
      ) : (
        <BlockSpinner />
      )}
    </Box>
  );
};

export default Popout;
