import { ContactSupport, MotionPhotosOff } from "@mui/icons-material";
import { Box, CSSObject, styled } from "@mui/material";
import Color from "color";

export const Container = styled(Box)(({ theme }) => ({
  position: "relative",
  width: 24,
  height: 24,
  minWidth: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const Line = styled(Box, {
  shouldForwardProp: (prop) => prop !== "dashed",
})<{ color?: string; opacity?: number; dashed?: boolean; gap?: number }>(({ theme, color, opacity, dashed = false, gap = 0 }) => {
  const lineColor = color || theme.palette.biarri.primary.midnight;
  return {
    width: gap > 0 ? 1 : 3,
    height: theme.spacing(2),
    transform: gap === 0 ? "rotate(45deg)" : undefined,
    opacity: opacity !== null ? opacity : 1,
    backgroundColor: !dashed ? lineColor : undefined,
    backgroundImage: dashed
      ? `linear-gradient(0deg, transparent 25%, ${lineColor} 25%, ${lineColor} 50%, transparent 50%, transparent 75%, ${lineColor} 75%, ${lineColor} 100%)`
      : undefined,
    backgroundSize: `8px 8px`,
    borderRadius: theme.shape.borderRadius,
  };
});

export const Swab = styled(Box, {
  shouldForwardProp: (prop) => prop !== "strokeColor",
})<{
  color: string;
  strokeColor?: string;
  opacity?: number;
  strokeOpacity?: number;
  strokeWidth?: number;
  dashed?: boolean;
}>(({ theme, color, strokeColor = "#FFF", strokeOpacity, strokeWidth = 1, opacity, dashed }) => {
  const opacityColor = opacity !== null && opacity !== undefined ? Color(color).alpha(opacity).toString() : color;
  const strokeOpacityColor: string | undefined =
    strokeOpacity !== null && strokeOpacity !== undefined ? Color(strokeColor).alpha(strokeOpacity).toString() : strokeColor;
  return {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: opacityColor,
    borderRadius: theme.shape.borderRadius,
    border: `${strokeWidth}px ${dashed ? "dashed" : "solid"} ${strokeOpacityColor || opacityColor}`,
    backgroundClip: "content-box",
  };
});

/**
 * When the icon is made from all polygon styles
 */
export const AllFillStack = styled(Box)<{ count: number }>(({ theme, count }) => {
  const common: CSSObject = {
    position: "relative",
    width: 24,
    height: 24,
    display: "block",
    "& div": {
      transform: "scale(0.6)",
    },
  };

  if (count >= 3) {
    return {
      ...common,
      "& div:nth-of-type(1)": {
        transform: "scale(0.7)",
        position: "absolute",
        left: -3,
        bottom: -4,
        zIndex: 3,
      },
      "& div:nth-of-type(2)": {
        transform: "scale(0.7)",
        position: "absolute",
        left: 0,
        bottom: 0,
        zIndex: 2,
      },
      "& div:nth-of-type(3)": {
        transform: "scale(0.7)",
        position: "absolute",
        left: 4,
        bottom: 3,
      },
    };
  }

  if (count === 2) {
    return {
      ...common,
      "& div:nth-of-type(1)": {
        transform: "scale(0.7)",
        position: "absolute",
        left: -3,
        bottom: -4,
        zIndex: 2,
      },
      "& div:nth-of-type(2)": {
        transform: "scale(0.7)",
        position: "absolute",
        left: 4,
        bottom: 3,
        zIndex: 1,
      },
    };
  }

  return {
    ...common,
  };
});

export const CircleStack = styled(Box)(({ theme }) => ({
  position: "relative",
  width: 24,
  height: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& > div": {
    position: "absolute",
    transform: "scale(0.5)",
  },
  "& div:nth-of-type(1)": {
    top: -2,
    left: 5,
  },
  "& div:nth-of-type(2)": {
    top: 4,
    right: 2,
  },
  "& div:nth-of-type(3)": {
    bottom: 5,
    right: 5,
  },
}));

export const CommentIcon = styled(Box)(({ theme }) => ({
  "& svg": {
    width: 12,
    height: 12,
  },
}));

export const NullGeomIcon = styled(MotionPhotosOff)(({ theme }) => ({
  width: 18,
  height: 18,
}));

export const UnknownGeomIcon = styled(ContactSupport)(({ theme }) => ({
  width: 18,
  height: 18,
}));
