import { useEffect, useState } from "react";
import * as React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { FormControl, FormHelperText, Slider, SliderProps as MuiSliderProps } from "@mui/material";

export type SliderProps = Partial<Omit<MuiSliderProps, "onChange">> & {
  name: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  validate?: any;
};

const SliderField: React.FC<SliderProps> = (props: SliderProps) => {
  const { color, defaultValue, fieldProps, name, validate, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input, meta }) => <SliderWrapper input={input} meta={meta} name={name} color={color} {...rest} />}
      validate={validate}
      defaultValue={defaultValue}
      {...fieldProps}
    />
  );
};

type SliderWrapperProps = Partial<Omit<MuiSliderProps, "onChange">> & FieldRenderProps<number, HTMLElement>;

const SliderWrapper: React.FC<SliderWrapperProps> = ({
  color,
  input: { name, onChange, value, ...restInput },
  disabled,
  helperText,
  fullWidth,
  min,
  max,
  marks,
  size,
  step,
  sx,
  track,
  valueLabelDisplay,
  valueLabelFormat,
}: SliderWrapperProps) => {
  const [displayValue, setDisplayValue] = useState<number | number[]>(value);

  useEffect(() => {
    if (value !== displayValue) {
      setDisplayValue(value);
    }
  }, [value]);

  const handleOnChangeCommitted = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    onChange(newValue);
  };

  const handleOnChange = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    setDisplayValue(newValue);
  };

  return (
    <FormControl data-testid={`${name}-slider-field`} fullWidth={fullWidth}>
      <Slider
        value={displayValue}
        name={name}
        onChange={handleOnChange}
        // Note that we use onChangeCommitted rather than onChange to avoid rapid form updates
        onChangeCommitted={handleOnChangeCommitted}
        disabled={disabled}
        color={color}
        size={size}
        min={min}
        max={max}
        step={step}
        sx={sx}
        marks={marks}
        valueLabelDisplay={valueLabelDisplay}
        valueLabelFormat={valueLabelFormat}
        track={track}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SliderField;
