import { FC, useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuList } from "@mui/material";

import { UserAccountAllocationResponse } from "fond/api";

import EditUserAction from "./EditUserAction";

interface IProps {
  userAccountAllocation: UserAccountAllocationResponse;
}

const UserRowAction: FC<IProps> = ({ userAccountAllocation }) => {
  const ref = useRef(null);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <IconButton ref={ref} aria-haspopup="true" data-testid="user-row-action-button" onClick={() => setShowMenu(true)} size="small">
        <MoreVertIcon />
      </IconButton>
      <Menu
        data-testid="user-row-action-menu"
        anchorEl={ref.current}
        open={showMenu}
        onClose={() => setShowMenu(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList>
          <EditUserAction userAccountAllocation={userAccountAllocation} />
          {/* TODO(FND-31) */}
          {/* <MenuItem aria-label="Upgrade" data-testid="upgrade">
            <ListItemIcon>
              <UpgradeIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Upgrade</Typography>
          </MenuItem> */}
        </MenuList>
      </Menu>
    </>
  );
};

export default UserRowAction;
