/**
 * This is a copy of the Mapbox Draw events.js file
 * (https://github.com/mapbox/mapbox-gl-draw/blob/master/src/events.js) except:
 *
 * 1. It exposes an additional `getCurrentMode()` method that returns
 *    the actual current mode object, rather than just the name. This allows
 *    us to do things like `draw.ctx.events.getCurrentMode().mousedown(...)`.
 * 2. It supports an additional option `existingFeatureLayerIds` to Mapbox Draw.
 *    If provided, this option should be a list of layer IDs; features matching
 *    these layers will be interactable. Previously only features specified using
 *    the `styles` parameter could be interactable, but it was not possible to
 *    provide an existing layer as a `styles` parameter, since Mapbox Draw tries
 *    to create layers for all the items in the `styles` parameter, causing
 *    an error for layers that already exist.
 */

import * as Constants from "@mapbox/mapbox-gl-draw/src/constants";
import isClick from "@mapbox/mapbox-gl-draw/src/lib/is_click";
import isTap from "@mapbox/mapbox-gl-draw/src/lib/is_tap";
import mapEventToBoundingBox from "@mapbox/mapbox-gl-draw/src/lib/map_event_to_bounding_box";
import setupModeHandler from "@mapbox/mapbox-gl-draw/src/lib/mode_handler";
import sortFeatures from "@mapbox/mapbox-gl-draw/src/lib/sort_features";
import StringSet from "@mapbox/mapbox-gl-draw/src/lib/string_set";
import objectToMode from "@mapbox/mapbox-gl-draw/src/modes/object_to_mode";

const META_TYPES = [Constants.meta.FEATURE, Constants.meta.MIDPOINT, Constants.meta.VERTEX];

/**
 * Copy of the Mapbox Draw featuresAt function except for supporing
 * existingFeatureLayerIds.
 */
function featuresAt(event, bbox, ctx, buffer) {
  if (ctx.map === null) return [];

  const box = event ? mapEventToBoundingBox(event, buffer) : bbox;

  const queryParams = {};
  if (ctx.options.styles) {
    queryParams.layers = [
      ...ctx.options.styles.map((s) => s.id),
      // Our modification to support `existingFeatureLayerIds`
      ...(ctx.options.existingFeatureLayerIds || []),
    ];
  }

  const features = ctx.map.queryRenderedFeatures(box, queryParams).filter((feature) => {
    return META_TYPES.indexOf(feature.properties.meta) !== -1;
  });

  const featureIds = new StringSet();
  const uniqueFeatures = [];
  features.forEach((feature) => {
    const featureId = feature.properties.id;
    if (featureIds.has(featureId)) return;
    featureIds.add(featureId);
    uniqueFeatures.push(feature);
  });

  return sortFeatures(uniqueFeatures);
}

featuresAt.click = function (event, bbox, ctx) {
  return featuresAt(event, bbox, ctx, ctx.options.clickBuffer);
};
featuresAt.touch = function (event, bbox, ctx) {
  return featuresAt(event, bbox, ctx, ctx.options.touchBuffer);
};

/**
 * Exact copy of the same function in Mapbox Draw except it calls our own
 * featuresAt function rather than theirs, so we can use
 * existingFeatureLayerIds.
 */
function getFeaturesAndSetCursor(event, ctx) {
  let features = featuresAt.click(event, null, ctx);
  let classes = { mouse: Constants.cursors.NONE };

  if (features[0]) {
    classes.mouse = features[0].properties.active === Constants.activeStates.ACTIVE ? Constants.cursors.MOVE : Constants.cursors.POINTER;
    classes.feature = features[0].properties.meta;
  }

  if (ctx.events.currentModeName().indexOf("draw") !== -1) {
    classes.mouse = Constants.cursors.ADD;
  }

  ctx.ui.queueMapClasses(classes);
  ctx.ui.updateMapClasses();

  return features[0];
}

export default function (ctx) {
  const modes = Object.keys(ctx.options.modes).reduce((m, k) => {
    m[k] = objectToMode(ctx.options.modes[k]);
    return m;
  }, {});

  let mouseDownInfo = {};
  let touchStartInfo = {};
  const events = {};
  let currentModeName = null;
  let currentMode = null;

  events.drag = function (event, isDrag) {
    if (
      isDrag({
        point: event.point,
        time: new Date().getTime(),
      })
    ) {
      ctx.ui.queueMapClasses({ mouse: Constants.cursors.DRAG });
      currentMode.drag(event);
    } else {
      event.originalEvent.stopPropagation();
    }
  };

  events.mousedrag = function (event) {
    events.drag(event, (endInfo) => !isClick(mouseDownInfo, endInfo));
  };

  events.touchdrag = function (event) {
    events.drag(event, (endInfo) => !isTap(touchStartInfo, endInfo));
  };

  events.mousemove = function (event) {
    const button = event.originalEvent.buttons !== undefined ? event.originalEvent.buttons : event.originalEvent.which;
    if (button === 1) {
      return events.mousedrag(event);
    }
    const target = getFeaturesAndSetCursor(event, ctx);
    event.featureTarget = target;
    currentMode.mousemove(event);
  };

  events.mousedown = function (event) {
    mouseDownInfo = {
      time: new Date().getTime(),
      point: event.point,
    };
    const target = getFeaturesAndSetCursor(event, ctx);
    event.featureTarget = target;
    currentMode.mousedown(event);
  };

  events.mouseup = function (event) {
    const target = getFeaturesAndSetCursor(event, ctx);
    event.featureTarget = target;

    if (
      isClick(mouseDownInfo, {
        point: event.point,
        time: new Date().getTime(),
      })
    ) {
      currentMode.click(event);
    } else {
      currentMode.mouseup(event);
    }
  };

  events.mouseout = function (event) {
    currentMode.mouseout(event);
  };

  events.touchstart = function (event) {
    // Prevent emulated mouse events because we will fully handle the touch here.
    // This does not stop the touch events from propogating to mapbox though.
    event.originalEvent.preventDefault();
    if (!ctx.options.touchEnabled) {
      return;
    }

    touchStartInfo = {
      time: new Date().getTime(),
      point: event.point,
    };
    const target = featuresAt.touch(event, null, ctx)[0];
    event.featureTarget = target;
    currentMode.touchstart(event);
  };

  events.touchmove = function (event) {
    event.originalEvent.preventDefault();
    if (!ctx.options.touchEnabled) {
      return;
    }

    currentMode.touchmove(event);
    return events.touchdrag(event);
  };

  events.touchend = function (event) {
    event.originalEvent.preventDefault();
    if (!ctx.options.touchEnabled) {
      return;
    }

    const target = featuresAt.touch(event, null, ctx)[0];
    event.featureTarget = target;
    if (
      isTap(touchStartInfo, {
        time: new Date().getTime(),
        point: event.point,
      })
    ) {
      currentMode.tap(event);
    } else {
      currentMode.touchend(event);
    }
  };

  // 8 - Backspace
  // 46 - Delete
  const isKeyModeValid = (code) => !(code === 8 || code === 46 || (code >= 48 && code <= 57));

  events.keydown = function (event) {
    if ((event.srcElement || event.target).classList[0] !== "mapboxgl-canvas") return; // we only handle events on the map

    if ((event.keyCode === 8 || event.keyCode === 46) && ctx.options.controls.trash) {
      event.preventDefault();
      currentMode.trash();
    } else if (isKeyModeValid(event.keyCode)) {
      currentMode.keydown(event);
    } else if (event.keyCode === 49 && ctx.options.controls.point) {
      changeMode(Constants.modes.DRAW_POINT);
    } else if (event.keyCode === 50 && ctx.options.controls.line_string) {
      changeMode(Constants.modes.DRAW_LINE_STRING);
    } else if (event.keyCode === 51 && ctx.options.controls.polygon) {
      changeMode(Constants.modes.DRAW_POLYGON);
    }
  };

  events.keyup = function (event) {
    if (isKeyModeValid(event.keyCode)) {
      currentMode.keyup(event);
    }
  };

  events.zoomend = function () {
    ctx.store.changeZoom();
  };

  events.data = function (event) {
    if (event.dataType === "style") {
      const { setup, map, options, store } = ctx;
      const hasLayers = options.styles.some((style) => map.getLayer(style.id));
      if (!hasLayers) {
        setup.addLayers();
        store.setDirty();
        store.render();
      }
    }
  };

  function changeMode(modename, nextModeOptions, eventOptions = {}) {
    currentMode.stop();

    const modebuilder = modes[modename];
    if (modebuilder === undefined) {
      throw new Error(`${modename} is not valid`);
    }
    currentModeName = modename;
    const mode = modebuilder(ctx, nextModeOptions);
    currentMode = setupModeHandler(mode, ctx);

    if (!eventOptions.silent) {
      ctx.map.fire(Constants.events.MODE_CHANGE, { mode: modename });
    }

    ctx.store.setDirty();
    ctx.store.render();
  }

  const actionState = {
    trash: false,
    combineFeatures: false,
    uncombineFeatures: false,
  };

  function actionable(actions) {
    let changed = false;
    Object.keys(actions).forEach((action) => {
      if (actionState[action] === undefined) throw new Error("Invalid action type");
      if (actionState[action] !== actions[action]) changed = true;
      actionState[action] = actions[action];
    });
    if (changed) ctx.map.fire(Constants.events.ACTIONABLE, { actions: actionState });
  }

  const api = {
    start: function () {
      currentModeName = ctx.options.defaultMode;
      currentMode = setupModeHandler(modes[currentModeName](ctx), ctx);
    },
    changeMode,
    actionable,
    currentModeName: function () {
      return currentModeName;
    },
    currentModeRender: function (geojson, push) {
      return currentMode.render(geojson, push);
    },
    fire: function (name, event) {
      if (events[name]) {
        events[name](event);
      }
    },
    addEventListeners: function () {
      ctx.map.on("mousemove", events.mousemove);
      ctx.map.on("mousedown", events.mousedown);
      ctx.map.on("mouseup", events.mouseup);
      ctx.map.on("data", events.data);

      ctx.map.on("touchmove", events.touchmove);
      ctx.map.on("touchstart", events.touchstart);
      ctx.map.on("touchend", events.touchend);

      ctx.container.addEventListener("mouseout", events.mouseout);

      if (ctx.options.keybindings) {
        ctx.container.addEventListener("keydown", events.keydown);
        ctx.container.addEventListener("keyup", events.keyup);
      }
    },
    removeEventListeners: function () {
      ctx.map.off("mousemove", events.mousemove);
      ctx.map.off("mousedown", events.mousedown);
      ctx.map.off("mouseup", events.mouseup);
      ctx.map.off("data", events.data);

      ctx.map.off("touchmove", events.touchmove);
      ctx.map.off("touchstart", events.touchstart);
      ctx.map.off("touchend", events.touchend);

      ctx.container.removeEventListener("mouseout", events.mouseout);

      if (ctx.options.keybindings) {
        ctx.container.removeEventListener("keydown", events.keydown);
        ctx.container.removeEventListener("keyup", events.keyup);
      }
    },
    trash: function (options) {
      currentMode.trash(options);
    },
    combineFeatures: function () {
      currentMode.combineFeatures();
    },
    uncombineFeatures: function () {
      currentMode.uncombineFeatures();
    },
    getMode: function () {
      return currentModeName;
    },
  };

  /**
   * Our own modifications here
   */

  api.getCurrentMode = () => currentMode;

  return api;
}
