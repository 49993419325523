import * as React from "react";
import { useContext } from "react";
import { Add, LocationSearching, MyLocation, Remove } from "@mui/icons-material";
import { Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { ReactComponent as ZoomToDesignIcon } from "svg_icons/zoom-to-design.svg";

import mixpanel from "../mixpanel";
import { EditMode } from "../types";
import { SvgIcon } from "../widgets";

import MapButton from "./MapButton";
import { MapContext } from "./MapProvider";

export const customStyles = (theme: Theme) => {
  return createStyles({
    root: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(3),
      pointerEvents: "all",
    },
    innerBox: {
      "&:hover": {
        backgroundColor: theme.palette.biarri.primary.lightGrey,
        color: theme.palette.common.black,
      },
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  editMode: EditMode;
  onClickMeasure?: () => void;
  onClickZoomToDesign?: () => void;
  onClickZoomIn?: () => void;
  onClickZoomOut?: () => void;
  currentLocationButton?: boolean;
}

const BaseMapButtons: React.FC<IProps> = ({
  classes,
  editMode,
  onClickMeasure,
  onClickZoomToDesign,
  onClickZoomIn,
  onClickZoomOut,
  currentLocationButton,
}: IProps) => {
  const { userPosition, startPositionWatch, stopPositionWatch } = useContext(MapContext);

  const onClickCurrentLocationHandler = () => {
    if (!userPosition) {
      startPositionWatch();
    } else {
      stopPositionWatch();
    }
  };

  const onClickMeasureHandler = () => {
    mixpanel.track("Clicked measure distance button");
    onClickMeasure!();
  };

  const onClickZoomToDesignHandler = () => {
    mixpanel.track("Clicked zoom to design button");
    onClickZoomToDesign!();
  };

  const onClickZoomInHandler = () => {
    mixpanel.track("Clicked zoom in button");
    onClickZoomIn!();
  };

  const onClickZoomOutHandler = () => {
    mixpanel.track("Clicked zoom out button");
    onClickZoomOut!();
  };

  return (
    <Box className={classes.root}>
      {(currentLocationButton ?? true) && (
        <MapButton
          id="currentLocation"
          title={`${!userPosition ? "Show" : "Hide"} Current Location`}
          dataT="map-current-location"
          onClick={onClickCurrentLocationHandler}
          active={userPosition !== null}
        >
          {userPosition ? <MyLocation /> : <LocationSearching />}
        </MapButton>
      )}
      {onClickMeasure !== undefined && (
        <MapButton
          id="measure"
          title={editMode === "measure" ? "Stop measuring" : "Measure a distance"}
          dataT="map-button-measure"
          onClick={onClickMeasureHandler}
          active={editMode === "measure"}
          enabled={editMode === "none" || editMode === "measure"}
        >
          <SvgIcon icon="ruler" />
        </MapButton>
      )}
      {onClickZoomToDesign !== undefined && (
        <MapButton id="zoomTo" title="Zoom to design" dataT="map-button-zoom-to" onClick={onClickZoomToDesignHandler}>
          <ZoomToDesignIcon />
        </MapButton>
      )}
      {onClickZoomIn !== undefined && (
        <MapButton id="zoomIn" title="Zoom in" dataT="map-button-zoom-in" onClick={onClickZoomInHandler}>
          <Add />
        </MapButton>
      )}
      {onClickZoomOut !== undefined && (
        <MapButton id="zoomOut" title="Zoom out" dataT="map-button-zoom-out" onClick={onClickZoomOutHandler}>
          <Remove />
        </MapButton>
      )}
    </Box>
  );
};

export default withStyles(customStyles)(BaseMapButtons);
