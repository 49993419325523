import { useEffect, useState } from "react";
import * as React from "react";
import { Form as FinalForm, FormSpy } from "react-final-form";
import * as Sentry from "@sentry/react";
import arrayMutators from "final-form-arrays";
import { omit } from "lodash";
import { useDebouncedCallback } from "use-debounce";

import { selectVersionMlcId, useUpdateStyleConfigMutation, useValidateStyleMutation } from "fond/api";
import { FieldSelection, StyleHeader } from "fond/styleEditor";
import { config } from "fond/styleEditor/config/layout";
import { FondStyleType, StyleField, StyleFieldName } from "fond/types";
import { LayerStyle } from "fond/types/ProjectLayerConfig";
import { toPascalCase } from "fond/utils";
import { setValue } from "fond/utils/formMutators";
import { useAppSelector } from "fond/utils/hooks";

import { isValidStyle } from "../helper";
import { Value } from "../TextPartsField/TextPartsField";

import { Form } from "./styleEditor.styles";

type FormValues = {
  [key in StyleFieldName]: any;
};
export interface IFormData extends FormValues {
  Type: FondStyleType;
  TextFields: Value[];
}

interface IProps {
  style: LayerStyle;
}

const StyleEditor: React.FC<IProps> = ({ style }: IProps) => {
  const {
    ID,
    MapboxStyle: { type },
  } = style;
  const fondType: FondStyleType = style.RawStyles.Type;
  const defaultField = config[fondType][0].fields[0];
  const [selectedField, setSelectedField] = useState<StyleField | undefined>(defaultField);
  const mapLayerConfigId = useAppSelector(selectVersionMlcId);
  const [validateStyle] = useValidateStyleMutation();
  const [updateStyleConfig] = useUpdateStyleConfigMutation();

  useEffect(() => {
    return () => {
      setSelectedField(undefined);
    };
  }, []);

  useEffect(() => {
    setSelectedField(defaultField);
  }, [type]);

  /**
   * Submits the normalised form data to the backend to generate
   * valid mapbox styles.
   */
  const onChange = useDebouncedCallback((values: IFormData) => {
    const { MinZoom, MaxZoom, TextFields } = values;
    const legacyValues = {
      ...omit(values, "MinZoom", "MaxZoom", "TextFields"),
      Minzoom: MinZoom,
      Maxzoom: MaxZoom,
      LayerConfigID: style.ConfigurationType === "LAYER" ? style.ConfigurationID : null,
      SublayerConfigID: style.ConfigurationType === "SUBLAYER" ? style.ConfigurationID : null,
      ...(values.Type === "symbol"
        ? {
            TextFieldParts: TextFields.map(({ Position, ...textField }) => ({
              ...textField,
              Transformations: textField.Transformations.map(({ Position: transformationPosition, ...transformation }) => transformation),
            })),
          }
        : {}),
    };

    const transformLegacyValues = (response: any) => ({
      ...response,
      MinZoom: response.Minzoom,
      MaxZoom: response.Maxzoom,
      TextFields: response.TextFieldParts,
    });
    validateStyle(toPascalCase(legacyValues))
      .unwrap()
      .then((response) => {
        // Validate the layer Style
        const styleErrors = isValidStyle(response);
        if (!styleErrors && style) {
          const updatedStyle = { ...style, MapboxStyle: transformLegacyValues(response), RawStyles: values };
          updateStyleConfig({ mapLayerConfigId: mapLayerConfigId, style: updatedStyle })
            .unwrap()
            .catch(() => {
              Sentry.captureMessage("Error saving draft changes");
            });
        } else {
          Sentry.captureException(styleErrors);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  }, 500);

  const onSelect = (field: StyleField) => {
    setSelectedField(field);
  };

  return (
    <FinalForm<IFormData>
      key={ID}
      initialValues={style.RawStyles}
      onSubmit={onChange}
      subscription={{ submitting: true, pristine: true }}
      render={({ handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <StyleHeader style={style} />
            <FieldSelection onSelect={onSelect} selectedField={selectedField} type={fondType} />
            {/* We use FormSpy to submit the form values on every update */}
            <FormSpy
              subscription={{ values: true, valid: true, pristine: true }}
              onChange={({ valid, pristine }) => {
                if (valid && !pristine) handleSubmit();
              }}
            />
          </Form>
        );
      }}
      mutators={{ ...arrayMutators, setValue }}
    />
  );
};

export default StyleEditor;
