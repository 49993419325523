import React from "react";
import { IMessage, NotificationBell, NovuProvider, PopoverNotificationCenter } from "@novu/notification-center";

import { useGetNotificationSubscriberQuery } from "../api";

import "./Notifications.css";
import { notificationCenterStyles, notificationCenterTheme, WhiteNotificationBell } from "./NotificationInbox.styles";

const novuApplicationIdentifier = process.env?.REACT_APP_NOVU_APP_ID;

const Footer: React.FC = () => {
  return null;
};

const NotificationInbox: React.FC = () => {
  const { data } = useGetNotificationSubscriberQuery();
  if (!data || !novuApplicationIdentifier) {
    return null;
  }

  const onNotificationClick = (message: IMessage) => {
    if (message?.cta?.data?.url) {
      window.location.href = message.cta.data.url;
    }
  };

  // Called when the 'action' (read: button) is clicked on a notification. This behaves identically
  // to just clicking the notification itself, however we could implement more advanced logic here.
  const onActionClick = (templateIdentifier: string, type: string, message: IMessage) => {
    onNotificationClick(message);
  };

  return (
    <NovuProvider
      subscriberId={data.Subscriber.ID}
      subscriberHash={data.Subscriber.Hash}
      applicationIdentifier={novuApplicationIdentifier}
      styles={notificationCenterStyles}
    >
      <PopoverNotificationCenter
        colorScheme="light"
        onNotificationClick={onNotificationClick}
        onActionClick={onActionClick}
        footer={() => <Footer />}
        theme={{ light: notificationCenterTheme, dark: notificationCenterTheme }}
      >
        {({ unseenCount }) => (
          <WhiteNotificationBell>
            <NotificationBell unseenCount={unseenCount} />
          </WhiteNotificationBell>
        )}
      </PopoverNotificationCenter>
    </NovuProvider>
  );
};

export default NotificationInbox;
