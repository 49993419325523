import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import { Box, Button } from "@mui/material";
import * as Sentry from "@sentry/react";
import JSZip from "jszip";
import { useSnackbar } from "notistack";

import { useCreateServicesReportMutation, useImportReportMutation } from "fond/api";
import mixpanel from "fond/mixpanel";
import { useQueryParams } from "fond/utils/hooks";

import type { ReportImportFormData } from "../types";

import ImportSettingsForm from "./ImportSettingsForm";

const ImportReportForm: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const folderId = useQueryParams("folderId");
  const navigate = useNavigate();
  const [createReport] = useCreateServicesReportMutation();
  const [importReport] = useImportReportMutation();

  const initialValues: Partial<ReportImportFormData> = useMemo(
    () => ({
      Name: "",
      Description: "",
      Project: null,
      Version: null,
      CTOFiles: [],
      CFOFiles: [],
    }),
    []
  );

  const handleOnCancel = () => {
    navigate(-1);
  };

  /**
   * On submit function called when the form is submitted and valid
   */
  const handleOnSubmit = async (values: ReportImportFormData) => {
    try {
      const { CFOFiles, CTSFiles, Name, Description, Version } = values;

      // Create the base report entity so that we can then import the require files into
      const report = await createReport({
        Name: Name,
        Description: Description ?? "",
        FolderID: folderId,
        ...(Version?.ID ? { VersionID: Version.ID } : {}),
      }).unwrap();
      // Create a zip file for CTS and CFO files & post it as FormData
      const zip = new JSZip();
      [...CFOFiles, ...CTSFiles].forEach((file) => {
        zip.file(file.name, file);
      });
      const data = await zip.generateAsync({ type: "blob" });
      const formData = new FormData();
      formData.append("ReportContent", data);

      // Import the reports data
      await importReport({
        reportId: report.ID,
        formData: formData,
      });

      mixpanel.track("Report", "Import", "Report Imported", { reportId: report.ID }); // Track initial generation of a report
      navigate(`/reports/${report.ID}/generate`);
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar("Failed to import report", {
        action: (
          <Button onClick={() => handleOnSubmit(values)} color="inherit">
            Retry
          </Button>
        ),
      });
    }
  };

  return (
    <Box pt={4}>
      <ImportSettingsForm initialValues={initialValues} onSubmit={handleOnSubmit} onCancel={handleOnCancel} />
    </Box>
  );
};

export default ImportReportForm;
