import { useState } from "react";
import * as React from "react";
import { useNavigate } from "react-router";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

import { LoadingButton } from "ui";

import { CognitoResponseCode, isValidPassword } from "fond/utils";
import { PasswordField } from "fond/widgets";
import SystemErrorDialog from "fond/widgets/SystemErrorDialog";

import { Paper } from "./newPasswordForm.styles";

interface IProps {
  cognitoUser: CognitoUser;
}

/**
 * A form for asking a user to set a new password using @aws-amplify/auth. Pushes the user to
 * the '/' route when the new password is succesfully set.
 */
const NewPasswordForm: React.FC<IProps> = ({ cognitoUser }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [cognitoResponseCode, setCognitoResponseCode] = useState(null);

  const handleSignInClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);
    Auth.completeNewPassword(cognitoUser, password)
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        setLoading(false);
        setCognitoResponseCode(err.code);
      });
  };

  return (
    <>
      <SystemErrorDialog
        open={cognitoResponseCode === CognitoResponseCode.NETWORK_ERROR || cognitoResponseCode === CognitoResponseCode.UNKNOWN_ERROR}
        networkError={cognitoResponseCode === CognitoResponseCode.NETWORK_ERROR}
        onClose={() => {
          setCognitoResponseCode(null);
        }}
      />
      <Paper elevation={2}>
        <form>
          <Typography align="left">Please choose a new password.</Typography>
          <Box mt={2}>
            <PasswordField
              fullWidth
              name="newpassword"
              label="New password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setPassword(e.target.value);
              }}
              passwordStrength
            />
          </Box>
          <Box mt={2}>
            <LoadingButton
              disabled={!isValidPassword(password)}
              loading={loading}
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSignInClick}
            >
              Change password and sign in
            </LoadingButton>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default NewPasswordForm;
