/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import { selectDraftStyleById } from "fond/api";
import { InferredIcon } from "fond/map/Legend";
import { setSelectedId } from "fond/redux/styles";
import { Configuration, Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";

import { Container, Icon, Title } from "./styleRow.styles";

interface IProps extends Partial<Pick<DraggableProvided, "dragHandleProps" | "draggableProps">> {
  config: Configuration;
  styleID: string;
  isDragging?: boolean;
  indent?: number;
}

const StyleRow = React.forwardRef<HTMLDivElement, IProps>(
  ({ config, styleID, draggableProps, dragHandleProps, isDragging = false, indent = 2 }: IProps, ref: React.Ref<unknown>) => {
    const dispatch = useAppDispatch();
    const style = useSelector((state: Store) => selectDraftStyleById(state, styleID));
    const selectedId = useSelector((state: Store) => state.styles.settings.selectedId);

    const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
      if (style) {
        event.stopPropagation();
        dispatch(setSelectedId(style.ID));
      }
    };

    return (
      <Container
        ref={ref}
        onClick={handleOnClick}
        selected={styleID === selectedId}
        indent={indent}
        {...draggableProps}
        {...dragHandleProps}
        isDragging={isDragging}
      >
        <Icon>{style && <InferredIcon entity={style} config={config} />}</Icon>
        <Title>{style?.Name || "Loading..."}</Title>
      </Container>
    );
  }
);

StyleRow.displayName = "StyleRow";
export default StyleRow;
