import * as React from "react";
import { useSelector } from "react-redux";
import { Alert, Box, Grid, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import {
  selectCurrentAccount,
  selectCurrentSubscription,
  useGetAccountRenewStatusQuery,
  useGetAccountSubscriptionsQuery,
  useGetSubscriptionTiersQuery,
} from "fond/api";
import { SubscriptionTier } from "fond/types/stripe";
import { BlockSpinner } from "fond/widgets";

import type { IFormData } from "../CheckoutForm";

import LicenseField from "./LicenseField";
import { PlannerSelectField, SelectionBox, SelectionHeader } from "./SubscriptionSelection.style";

import { StyledGrid } from "../Checkout.styles";

interface SubscriptionSelectionProps {
  values: IFormData;
  updateField: <F extends keyof IFormData>(fieldName: F, value: IFormData[F]) => void;
}
const SubscriptionSelection: React.FC<SubscriptionSelectionProps> = ({ values, updateField }: SubscriptionSelectionProps) => {
  const currentAccount = useSelector(selectCurrentAccount);

  const { data: subscriptionTiers, isLoading } = useGetSubscriptionTiersQuery();
  const { isLoading: isLoadingSubscriptions } = useGetAccountSubscriptionsQuery(currentAccount?.ID ?? skipToken);
  const { data: renewStatus } = useGetAccountRenewStatusQuery(currentAccount?.ID ?? skipToken);
  const isRenewal = Boolean(renewStatus?.Renewal);
  const bonus = renewStatus?.Renewal?.Bonus;
  const currentSubscription = useSelector((state) => currentAccount && selectCurrentSubscription(state, currentAccount.ID));

  const selectedProductId = values.productId;
  let subscriptionTier = subscriptionTiers?.find((tier: SubscriptionTier) => tier.Product.ID === selectedProductId);

  if (!selectedProductId && currentSubscription && subscriptionTiers) {
    // If no product is selected, it's a renewal, try to find the tier that was selected
    // in the most latest subscription.
    subscriptionTier = subscriptionTiers?.find((tier: SubscriptionTier) => tier.ID === currentSubscription.Planner?.TierID);
    updateField("productId", subscriptionTier?.Product.ID || subscriptionTiers[0].Product.ID || "");
  }

  return (
    <Box data-testid="planner-options-section">
      <SelectionHeader>{isRenewal ? "Renew subscription" : "Subscribe to planner"}</SelectionHeader>
      {bonus && bonus.Quota > 0 && (
        <Alert severity="info">
          <Typography variant="body2">
            <strong>Bonus</strong>: To show our appreciation, you will get
            <strong> {bonus.Percentage}% </strong>of the base prem count of your current subscription.
          </Typography>
        </Alert>
      )}
      <SelectionBox marginY={2}>
        <Typography variant="h6">Planner tier</Typography>
        {subscriptionTiers && !isLoading && !isLoadingSubscriptions ? (
          <StyledGrid container spacing={4}>
            <Grid item xs={8} lg={6} xl={4}>
              <PlannerSelectField
                id="productId"
                name="productId"
                color="primary"
                fullWidth
                options={subscriptionTiers.map((tier) => ({
                  value: tier.Product.ID,
                  displayValue: tier.Product.Title,
                }))}
              />
            </Grid>
            {subscriptionTier && (
              <Grid item xs={4} lg={6} xl={8}>
                <Typography>{subscriptionTier.BaseQuota.toLocaleString()} prems/year</Typography>
              </Grid>
            )}
          </StyledGrid>
        ) : (
          <BlockSpinner />
        )}
      </SelectionBox>

      <SelectionHeader marginTop={4}>{isRenewal ? "Renew licenses" : "Buy licenses"}</SelectionHeader>
      <Alert severity="info">
        <Typography variant="body2">
          <strong>Special offer</strong>: We are including
          <strong> 1 free license</strong> with your subscription.
        </Typography>
      </Alert>
      <LicenseField />
    </Box>
  );
};

export default SubscriptionSelection;
