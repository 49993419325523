import * as React from "react";
import { Skeleton, TextField } from "@mui/material";

import { useUpdateProjectMutation } from "fond/api";
import { Project } from "fond/types";

interface ProjectRenameFieldProps {
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  /**
   * The target project. The text field will be repaced with a skeleton if undefined.
   */
  project: undefined | null | Project;
  /**
   * Additional text field styles.
   */
  style?: React.CSSProperties;
}

/**
 * ProjectRenameField component.
 * A text field that sends an API request to update the project name on blur or Enter press.
 */
const ProjectRenameField: React.FC<ProjectRenameFieldProps> = ({
  "data-testid": dataTestid = "project-rename-field",
  project,
  style,
}: ProjectRenameFieldProps) => {
  const [updateProject] = useUpdateProjectMutation();

  /**
   * Send an API request to update the project name if a value is set.
   */
  const renameProject = (event: React.FocusEvent<HTMLInputElement>) => {
    const projectName = event.target.value;
    if (project && projectName) {
      updateProject({ ID: project.ID, ProjectName: projectName });
    }
  };

  return (
    <>
      {project == null ? (
        <Skeleton variant="rectangular" height={style?.height || 32} width={style?.width} sx={{ margin: "auto" }} />
      ) : (
        <TextField
          data-testid={dataTestid}
          variant="standard"
          style={style}
          sx={{ "& .MuiFormLabel-root": { left: 0, right: 0, transformOrigin: "unset" } }}
          InputProps={{
            inputProps: {
              style: { textAlign: "center" },
            },
          }}
          defaultValue={project.ProjectName}
          onBlur={renameProject}
          onKeyDown={(e: React.KeyboardEvent) => e.key === "Enter" && (e.target as HTMLInputElement).blur()}
        />
      )}
    </>
  );
};

export default ProjectRenameField;
