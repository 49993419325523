import { useState } from "react";
import * as React from "react";
import { Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { useDeleteExportMutation } from "fond/api";
import mixpanel from "fond/mixpanel";
import { Export } from "fond/types";
import { Modal } from "fond/widgets";

interface DeleteExportModalIProps {
  exportItem: Export;
  closeModalCallback(): void;
}

const DeleteExportModal: React.FC<DeleteExportModalIProps> = ({ exportItem, closeModalCallback }: DeleteExportModalIProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const [deleteExport] = useDeleteExportMutation();

  /**
   * Handles the deletion of the project.
   */
  const handleOnDelete = () => {
    setSaving(true);

    deleteExport(exportItem)
      .unwrap()
      .then(() => {
        mixpanel.track("Deleted export", { exportId: exportItem.ID });
        enqueueSnackbar("Export deleted.", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Export deletion failed. Please try again...", { variant: "error" });
      })
      .finally(() => {
        setSaving(false);
        closeModalCallback();
      });
  };

  return (
    <Modal
      open
      header="Delete export?"
      DialogTitleProps={{ id: "alert-dialog-title" }}
      data-testid="delete-export-modal"
      DialogContentProps={{ id: "alert-dialog-description" }}
      content={
        <Typography>
          Are you sure you wish to delete the export <strong>{exportItem.Name}</strong>?
        </Typography>
      }
      actions={
        <>
          <Button color="primary" data-testid="cancel-export-deletion" onClick={closeModalCallback} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleOnDelete}
            autoFocus
            data-testid="confirm-export-deletion"
            loading={saving}
          >
            Delete
          </LoadingButton>
        </>
      }
    />
  );
};

export default DeleteExportModal;
