import * as React from "react";
import { Check } from "@mui/icons-material";
import { Fade, ListItemIcon, ListSubheader, Menu, MenuItem } from "@mui/material";

import { CommentSortOrder } from "fond/types";

interface IProps {
  /**
   * The anchor element the menu should be relatively positioned
   */
  anchorEl: HTMLButtonElement | null;
  sortOrder: CommentSortOrder;
  onChange(sortOrder: CommentSortOrder): void;
  onClose(): void;
}

const SortMenu: React.FC<IProps> = ({ anchorEl, sortOrder, onChange, onClose }: IProps) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open
      elevation={2}
      onClose={onClose}
      TransitionComponent={Fade}
      data-testid="comment-sort-menu"
      transformOrigin={{ horizontal: "left", vertical: -50 }}
    >
      <ListSubheader>Sort by</ListSubheader>
      <MenuItem
        aria-label="sort by comment"
        data-testid="sort-comments"
        onClick={() => {
          onChange("created");
          onClose();
        }}
      >
        <ListItemIcon>{sortOrder === "created" && <Check />}</ListItemIcon>
        Latest comment
      </MenuItem>
      <MenuItem
        aria-label="sort by latest activity"
        data-testid="sort-activity"
        onClick={() => {
          onChange("latest");
          onClose();
        }}
      >
        <ListItemIcon>{sortOrder === "latest" && <Check />}</ListItemIcon>
        Latest activity
      </MenuItem>
      <MenuItem
        aria-label="sort by importance"
        data-testid="sort-importance"
        onClick={() => {
          onChange("importance");
          onClose();
        }}
      >
        <ListItemIcon>{sortOrder === "importance" && <Check />}</ListItemIcon>
        Most important
      </MenuItem>
    </Menu>
  );
};

export default SortMenu;
