import * as React from "react";
import { useNavigate } from "react-router";
import { ListItemIcon, MenuItem } from "@mui/material";

interface IProps {
  currentFolderId: string | undefined;
}

const Close: React.FC<IProps> = ({ currentFolderId }: IProps) => {
  const navigate = useNavigate();

  /**
   * Handles closing the project.
   * Navigate to the parent folder or the projects landing page if there is no parent folder.
   */
  const handleCloseProject = () => {
    if (currentFolderId) {
      navigate(`/folders/${currentFolderId}`);
    } else {
      navigate("/");
    }
  };

  return (
    <MenuItem data-testid="close-button" onClick={handleCloseProject}>
      <ListItemIcon />
      Close
    </MenuItem>
  );
};

export default Close;
