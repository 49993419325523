import * as React from "react";
import { useSelector } from "react-redux";

import { selectCurrentAccount } from "fond/api";
import { useContents } from "fond/hooks/useContents";

import { BaseProjectList } from "./BaseProjectList";

/**
 * Main workspace content component
 */
const MainWorkspaceContent: React.FC = () => {
  const { items } = useContents();
  const accountId = useSelector(selectCurrentAccount)?.ID;
  const accountProjects = items.filter(
    (item) =>
      item.Account.ID === accountId &&
      ((item.EntityType === "folder" && !item.ParentID) ||
        (item.EntityType === "project" && !item.FolderID) ||
        (item.EntityType === "report" && !item.Folder))
  );

  return <BaseProjectList items={accountProjects} />;
};

export default MainWorkspaceContent;
