import { LayerIds } from "fond/layers";
import { Layer, User } from "fond/types";

export enum ExportStatus {
  Pending = "PENDING",
  InProgress = "IN_PROGRESS",
  Complete = "COMPLETE",
  Error = "ERROR",
}

export enum ExportFormats {
  Geojson = "geojson",
  Shapefile = "shp",
  Kml = "kml",
  Tab = "tab",
}

export const EXPORT_FORMATS = [ExportFormats.Geojson, ExportFormats.Shapefile, ExportFormats.Kml, ExportFormats.Tab];

export const getExportFormat = (format: ExportFormats): string => {
  switch (format) {
    case ExportFormats.Geojson:
      return "GeoJSON";

    case ExportFormats.Shapefile:
      return "ShapeFile";

    case ExportFormats.Kml:
      return "KML";

    case ExportFormats.Tab:
      return "Tab";

    default:
      return "GeoJSON";
  }
};

export enum SystemsOfMeasurement {
  Metric = "metric",
  Imperial = "imperial",
}

export type ExportStatusDetails = {
  Value: ExportStatus;
  Message?: string;
  Progress?: number;
  JobID?: string;
};

export type ExportLayer = {
  ID: string;
  LayerKey: string;
};

export type ExportLayerWithLabel = {
  Label: string;
} & ExportLayer;

export type LayerWithLabel = {
  Label: string;
} & Layer;

export interface Export {
  ID: string;
  VersionID: string;
  CreatedAt: string;
  Creator: User;
  LastModifiedAt: string;
  LastModifiedBy: User;
  CompletedAt?: string;
  Format: ExportFormats;
  Name?: string;
  Description?: string;
  SystemOfMeasurement: SystemsOfMeasurement;
  BOM?: boolean;
  SpliceTables?: boolean;
  FiberCableSplit?: boolean;
  HubSplit?: boolean;
  SpliceSplit?: boolean;
  Layers: ExportLayer[];
  Status: ExportStatusDetails;
  Url?: string;
  FileSizeBytes?: number;
}

export type CreateExportParams = Pick<
  Export,
  "VersionID" | "Description" | "BOM" | "SpliceTables" | "FiberCableSplit" | "HubSplit" | "SpliceSplit"
> & {
  Name: string;
  Format: string;
  SystemOfMeasurement: string;
  Layers: string[];
};

export type ExportsByStatus = {
  InProgress: Export[];
  Complete: Export[];
  Error: Export[];
};

export const SplitByTier = {
  [LayerIds.hub]: "hubSplit",
  [LayerIds.splice]: "spliceSplit",
  [LayerIds.fibreCable]: "fiberCableSplit",
};

export enum ExportAccordionPanel {
  InProgress = "exports-in-progress",
  Complete = "project-exports",
}
