import * as React from "react";
import { ContactSupport } from "@mui/icons-material";

import { Container, Icon, Text } from "./documentation.styles";

interface IProps {
  fieldSpec: any;
}

const Documentation: React.FC<IProps> = ({ fieldSpec }: IProps) => {
  const { doc } = fieldSpec;

  return (
    <Container>
      <Icon>
        <ContactSupport />
      </Icon>
      <Text>{doc}</Text>
    </Container>
  );
};

export default Documentation;
