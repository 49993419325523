import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { Box, Tooltip } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import * as Sentry from "@sentry/react";
import { FormApi } from "final-form";
import { useSnackbar } from "notistack";

import {
  selectCurrentAccount,
  selectUserId,
  useGetAccountSubscriptionsQuery,
  useGetAccountUsersQuery,
  UserAccountAllocationRequest,
  UserAccountAllocationResponse,
  useUpdateAllocationMutation,
} from "fond/api";
import { SwitchField } from "fond/form/fields";
import { getTotalActiveLicenses } from "fond/settings/logic/license";

import { UserDetail, UserDetailBox, UserDetailDescription } from "./EditUser.styles";

const TOOLTIP_HEIGHT_OFFSET = -15;

const initialValues = {
  active: true,
  allocateLicense: false,
  admin: false,
};
type IFormData = typeof initialValues;

interface IProps {
  formId: string;
  userAccountAllocation: UserAccountAllocationResponse;
}

const EditUserForm: React.FC<IProps> = ({ formId, userAccountAllocation }) => {
  const userValues = {
    ...initialValues,
    admin: userAccountAllocation.Role === "admin",
    allocateLicense: userAccountAllocation.License,
  };
  const [updateAllocation] = useUpdateAllocationMutation();
  const selectedAccount = useSelector(selectCurrentAccount);
  const { data: accountSubscriptions } = useGetAccountSubscriptionsQuery(userAccountAllocation.Account.ID);
  const { data: allocations } = useGetAccountUsersQuery(selectedAccount?.ID ?? skipToken);
  const { enqueueSnackbar } = useSnackbar();

  const userId = useSelector(selectUserId);
  const isCurrentUser = userAccountAllocation.User.ID === userId;
  const adminSwitchTooltip = isCurrentUser ? "Users cannot downgrade their own role" : "";

  const totalActiveLicenses = getTotalActiveLicenses(accountSubscriptions);
  const totalAssignedLicenses = allocations?.filter((allocation) => allocation.License).length ?? 0;

  const onSubmit = async (values: IFormData, form: FormApi<IFormData>) => {
    const newAllocationData: UserAccountAllocationRequest = { ID: userAccountAllocation.ID };

    const dirtiedFields = form.getState().dirtyFields;
    if (dirtiedFields?.admin) {
      newAllocationData.Role = values.admin ? "admin" : "member";
    }

    if (dirtiedFields?.allocateLicense) {
      newAllocationData.License = values.allocateLicense;
    }

    if (Object.keys(newAllocationData).length > 1) {
      try {
        await updateAllocation(newAllocationData).unwrap();
        enqueueSnackbar("Update complete.");
      } catch (error) {
        Sentry.captureException(error);
        enqueueSnackbar("Update failed.", { variant: "error" });
      }
    }
  };

  return (
    <Form<IFormData>
      initialValues={userValues}
      onSubmit={onSubmit}
      render={({ form, handleSubmit, values }) => {
        const dirtiedAllocateLicense = form.getState().dirtyFields?.allocateLicense;
        const licenseDiffComparator = dirtiedAllocateLicense ? -1 : 0;
        const licenseCanBeAssigned = totalActiveLicenses - totalAssignedLicenses > licenseDiffComparator;
        const licenseSwitchTooltip = licenseCanBeAssigned
          ? ""
          : "No licenses available. Please proceed to the account settings page to purchase one.";

        return (
          <form onSubmit={handleSubmit} id={formId}>
            <Box display="flex" gap="4px" flexDirection="column">
              <UserDetailBox display="flex" justifyContent="space-between">
                <Box>
                  <UserDetail>Administrator</UserDetail>
                  <UserDetailDescription>Make user an admin</UserDetailDescription>
                </Box>
                <Tooltip
                  title={adminSwitchTooltip}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, TOOLTIP_HEIGHT_OFFSET],
                        },
                      },
                    ],
                  }}
                >
                  <div>
                    <SwitchField
                      name="admin"
                      label=""
                      data-testid="toggle-admin-switch"
                      disabled={isCurrentUser}
                      fieldProps={{
                        type: "checkbox",
                        checked: values.admin,
                      }}
                    />
                  </div>
                </Tooltip>
              </UserDetailBox>
              <UserDetailBox display="flex" justifyContent="space-between">
                <Box>
                  <UserDetail>Allocate License</UserDetail>
                  <UserDetailDescription>Assign paid license to user</UserDetailDescription>
                </Box>
                <Tooltip
                  title={!values.allocateLicense && licenseSwitchTooltip}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, TOOLTIP_HEIGHT_OFFSET],
                        },
                      },
                    ],
                  }}
                >
                  <div>
                    <SwitchField
                      name="allocateLicense"
                      label=""
                      data-testid="allocate-license-switch"
                      disabled={!values.allocateLicense && !licenseCanBeAssigned}
                      fieldProps={{ type: "checkbox", checked: values.allocateLicense }}
                    />
                  </div>
                </Tooltip>
              </UserDetailBox>
              {/* To be added when endpoints are available */}
              {/*
            <UserDetailBox display="flex" justifyContent="space-between">
              <Box>
                <UserDetail>Activate user</UserDetail>
                <UserDetailDescription>Activate/deactivate user</UserDetailDescription>
              </Box>
              <SwitchField name="active" label="" data-testid="toggle-active-switch" fieldProps={{ type: "checkbox", checked: values.active }} />
            </UserDetailBox>
            */}
            </Box>
          </form>
        );
      }}
    />
  );
};

export default EditUserForm;
