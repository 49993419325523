import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import { Box, Button } from "@mui/material";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";

import { useCreateReportMutation } from "fond/api";
import mixpanel from "fond/mixpanel";
import { useQueryParams } from "fond/utils/hooks";

import ReportSettingsForm from "../ReportSettings/ReportSettingsForm";
import type { ReportFormData } from "../types";
import { transformReportFormToReportSettings } from "../util";

const CreateReportForm: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const folderId = useQueryParams("folderId");
  const navigate = useNavigate();
  const [createReport, { isLoading: isSaving }] = useCreateReportMutation();

  const initialValues: Partial<ReportFormData> = useMemo(
    () => ({
      Name: "",
      Description: "",
      Project: null,
      Version: null,
      ReportConfiguration: { StepFrequency: "quarterly", StartTime: dayjs().toString() },
    }),
    []
  );

  const handleOnCancel = () => {
    navigate(-1);
  };

  /**
   * On submit function called when the form is submitted and valid
   */
  const handleOnSubmit = async (values: ReportFormData) => {
    try {
      const { Name, Description, Version, ReportConfiguration } = transformReportFormToReportSettings(values);
      const report = await createReport({
        Name: Name,
        Description: Description ?? "",
        FolderID: folderId,
        VersionID: Version.ID,
        ReportConfiguration: ReportConfiguration,
      }).unwrap();
      mixpanel.track("Report", "Create", "Report Created", { reportId: report.ID }); // Track creation of a report

      navigate(`/reports/${report.ID}/generate`);
      mixpanel.track("Report", "Create", "Report Generated", { reportId: report.ID }); // Track initial generation of a report
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar("Failed to create report", {
        action: (
          <Button onClick={() => handleOnSubmit(values)} color="inherit">
            Retry
          </Button>
        ),
      });
    }
  };

  return (
    <Box pt={4}>
      <ReportSettingsForm initialValues={initialValues} onSubmit={handleOnSubmit} onCancel={handleOnCancel} isLoading={isSaving} />
    </Box>
  );
};

export default CreateReportForm;
