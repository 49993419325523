import * as React from "react";
import { useSelector } from "react-redux";
import { LinearScale, LocationOn, TrendingFlat } from "@mui/icons-material";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

import { getOne } from "fond/redux/comments";
import { Store } from "fond/types";
import { SvgIcon } from "fond/widgets";

const customStyles = (theme: Theme) => {
  return createStyles({
    root: {
      minWidth: 300,
      overflow: "auto",
      minHeight: 0,
      maxHeight: 300,
    },
    icon: {
      minWidth: 24,
    },
    item: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    text: {
      lineHeight: 1,
      fontSize: "0.75rem",
    },
    polygon: {
      color: theme.palette.secondary.main,
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  commentID: string;
  onClick(): void;
}

const ListItemComment: React.FC<IProps> = ({ classes, commentID, onClick }: IProps) => {
  const comment = useSelector((state: Store) => getOne(state)(commentID));
  return (
    <ListItem key={commentID} button onClick={onClick} className={classes.item} data-testid="feature-select-item">
      <ListItemIcon className={classes.icon}>
        {comment.Type === "point" && <LocationOn fontSize="small" color="secondary" />}
        {comment.Type === "polygon" && <SvgIcon icon="polygon" className={classes.polygon} />}
        {comment.Type === "lineString" && <LinearScale fontSize="small" color="secondary" />}
        {comment.Type === "arrowLine" && <TrendingFlat fontSize="small" color="secondary" />}
      </ListItemIcon>
      <ListItemText disableTypography className={classes.text}>
        {`Comment by ${comment.Creator.Email}`}
      </ListItemText>
    </ListItem>
  );
};

export default withStyles(customStyles)(ListItemComment);
