/* eslint-disable no-underscore-dangle */
import MapboxDraw, { DrawCustomModeThis, modes } from "@mapbox/mapbox-gl-draw";
import * as Constants from "@mapbox/mapbox-gl-draw/src/constants";
import doubleClickZoom from "@mapbox/mapbox-gl-draw/src/lib/double_click_zoom";
import isEventAtCoordinates from "@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates";
import { Feature, LineString, Point, Position } from "geojson";

import { prepareFeatureForDraw } from "fond/utils/geojson";

import snapTo, { withSnapping } from "./snap_to";

interface DrawCustomModeState {
  currentVertexPosition: number;
  layerId: string;
  line: ExtendedLineString;
  direction: string;
}

interface DrawCustomModeOptions {
  featureId?: string;
  from?: Feature<Point> | Point | number[];
  layerId: string;
}

interface DrawCustomModeExtension {
  onSetup(this: DrawCustomModeThis & this, options: DrawCustomModeOptions): DrawCustomModeState;
  commitLine(this: DrawCustomModeThis, state: DrawCustomModeState): void;
}

export interface ExtendedLineString extends MapboxDraw.DrawLineString {
  snaps: Array<{
    feature: Feature;
    coords: Position;
  }>;
}

const drawLineString = withSnapping<DrawCustomModeState, DrawCustomModeOptions, DrawCustomModeExtension>({
  ...modes.draw_line_string,

  onSetup: function (opts) {
    opts = opts || {};
    const { featureId } = opts;
    let line: ExtendedLineString;
    let currentVertexPosition;
    let direction = "forward";

    if (featureId) {
      line = this.getFeature(featureId) as ExtendedLineString;
      if (!line) {
        throw new Error("Could not find a feature with the provided featureId");
      }
      let { from } = opts;
      if (from && "type" in from && from.type === "Feature" && from.geometry && from.geometry.type === "Point") {
        from = from.geometry;
      }
      if (from && "type" in from && from.type === "Point" && from.coordinates && from.coordinates.length === 2) {
        from = from.coordinates;
      }
      if (!from || !Array.isArray(from)) {
        throw new Error("Please use the `from` property to indicate which point to continue the line from");
      }
      const lastCoord = line.coordinates.length - 1;
      if (line.coordinates[lastCoord][0] === from[0] && line.coordinates[lastCoord][1] === from[1]) {
        currentVertexPosition = lastCoord + 1;
        // add one new coordinate to continue from
        line.addCoordinate(currentVertexPosition, line.coordinates[lastCoord][0], line.coordinates[lastCoord][1]);
      } else if (line.coordinates[0][0] === from[0] && line.coordinates[0][1] === from[1]) {
        direction = "backwards";
        currentVertexPosition = 0;
        // add one new coordinate to continue from
        line.addCoordinate(currentVertexPosition, line.coordinates[0][0], line.coordinates[0][1]);
      } else {
        throw new Error("`from` should match the point at either the start or the end of the provided LineString");
      }
    } else {
      line = this.newFeature(
        prepareFeatureForDraw(
          {
            type: Constants.geojsonTypes.FEATURE,
            properties: {},
            geometry: {
              type: Constants.geojsonTypes.LINE_STRING,
              coordinates: [],
            },
          },
          opts.layerId
        )
      ) as ExtendedLineString;

      // Keep a list of {feature, closestCoord} objects. This is currently used
      // by draw_spans mode to split linestrings that were snapped to.
      line.snaps = [];

      currentVertexPosition = 0;
      this.addFeature(line);
    }

    this.clearSelectedFeatures();
    doubleClickZoom.disable(this);
    this.updateUIClasses({ mouse: Constants.cursors.ADD });
    this.activateUIButton(Constants.types.LINE);
    this.setActionableState({
      trash: true,
      combineFeatures: false,
      uncombineFeatures: false,
    });

    return {
      line,
      currentVertexPosition,
      direction,
      layerId: opts.layerId,
    };
  },

  commitLine: function (state) {
    this.map.fire(Constants.events.CREATE, {
      features: [state.line.toGeoJSON()],
    });
    this._ctx.api.pullInFeature(state.layerId, state.line.toGeoJSON() as Feature<LineString>);
    this.changeMode("simple_select", { featureIds: [state.line.id] });
  },

  onClick: function (state, e) {
    if (
      (state.currentVertexPosition > 0 && isEventAtCoordinates(e, state.line.coordinates[state.currentVertexPosition - 1])) ||
      (state.direction === "backwards" && isEventAtCoordinates(e, state.line.coordinates[state.currentVertexPosition + 1]))
    ) {
      state.line.removeCoordinate(`${state.currentVertexPosition}`);
      this.commitLine(state);
    } else {
      this.updateUIClasses({ mouse: Constants.cursors.ADD });
      state.line.updateCoordinate(state.currentVertexPosition.toString(), e.lngLat.lng, e.lngLat.lat);
      if (state.direction === "forward") {
        if (e.closestFeature != null) {
          state.line.snaps.push({
            feature: e.closestFeature,
            coords: e.closestCoord,
          });
        }
        state.currentVertexPosition += 1;
        state.line.updateCoordinate(state.currentVertexPosition.toString(), e.lngLat.lng, e.lngLat.lat);
      } else {
        state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
      }
    }
  },

  snap: function (this: DrawCustomModeThis, state, e) {
    return snapTo(e, this._ctx, state.line.id);
  },
});

export default drawLineString;
