import { useRef, useState } from "react";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  Autocomplete as MuiAutocomplete,
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { AutocompleteChangeReason, createFilterOptions, FilterOptionsState } from "@mui/material/useAutocomplete";

import { useGetAccountUsersQuery, userSlice } from "fond/api";
import { Account, AccountBase, AppThunkDispatch, ExtendedPermission, PermissionLevel, ResourceEntity, User, UserShareSuggestion } from "fond/types";
import { isValidEmailFormat, makeUuid } from "fond/utils";
import { isEmailDeliverable } from "fond/utils/email";
import { SupportLink } from "fond/widgets";

type Option = string | UserShareSuggestion | AccountBase;
const filter = createFilterOptions<Option>();

interface IProps {
  /**
   * The Name of the form value.
   */
  name: string;
  placeholder?: string;
  options: Array<{
    value: string;
    displayValue: string;
    inheritValue?: PermissionLevel | undefined;
    disabled: boolean;
  }>;
  /**
   * The Resource the permission relates to
   */
  resource: ResourceEntity;
  /**
   * Field Array push callback that allows a new user
   * to be added to the array of values.
   */
  push(
    fieldName: string,
    value: Omit<ExtendedPermission, "Identity"> &
      (
        | { Identity: Pick<Account, "ID"> }
        | {
            Identity: Pick<User, "Email"> & {
              ID: string | undefined;
            };
          }
      )
  ): void;
  /**
   * A list of suggested users and groups
   */
  suggestions: Option[];
  /**
   * The current fields value.
   */
  values: ExtendedPermission[];
  /**
   * Callback function for when a new user is added to the permission list
   */
  onAdd(): void;
}

const AddResource: React.FC<IProps> = ({
  name,
  placeholder = "Enter an email to share with",
  onAdd,
  options,
  suggestions,
  resource,
  push,
  values,
}: IProps) => {
  const [invalidEmail, setInvalidEmail] = useState<string | undefined>();
  const [undeliverableEmail, setUndeliverableEmail] = useState<string | undefined>();
  const [level, setLevel] = useState<PermissionLevel>("view");
  const [inputKey, setInputKey] = useState(makeUuid());
  const dispatch: AppThunkDispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>();
  const { data: accountUsers } = useGetAccountUsersQuery(resource.Account.ID);
  const existingValues = values.map((permission) => {
    if (permission.IdentityType === "user") {
      return permission.Identity.Email;
    }
    return permission.Identity.ID;
  });
  const { ID: resourceId, EntityType: resourceType } = resource;

  /**
   * Handles the parsing and adding of only new valid emails into the list
   */
  const handleAddUser = async (value: Option | string) => {
    const shouldDowngradeForGuests = level === "manage" || level === "write";
    const guestPermissionLevel = shouldDowngradeForGuests ? "read" : level;

    setUndeliverableEmail(undefined);

    if (isNewUser(value)) {
      if (typeof value === "string") {
        const normalizedValue = value.toLowerCase();

        let identity:
          | null
          | (Pick<User, "Email"> & {
              ID: string | undefined;
            }) = null;
        let isGuest = true;

        // User has not selected from suggested users.
        // See if can still find a matching user from suggestions with the entered string.
        const matchingUser =
          suggestions && suggestions.find((user: Option) => typeof user !== "string" && "User" in user && user.User.Email === normalizedValue);

        if (matchingUser && typeof matchingUser === "object" && "User" in matchingUser) {
          identity = matchingUser.User;
          isGuest = matchingUser.PermissionLimit !== null;
        } else {
          // check the server if the user is an existing FOND account
          const { data: user } = await dispatch(userSlice.endpoints.getUserByEmail.initiate(normalizedValue));
          // If the user is not an existing FOND account, check the deliverability of the email.
          if (!user) {
            const emailResult = await isEmailDeliverable(value);
            if (!emailResult.deliverable) {
              setUndeliverableEmail("The email address is not deliverable.");
            } else {
              identity = { ID: undefined, Email: normalizedValue };
            }
          } else {
            identity = user;
          }
        }

        if (identity) {
          push(name, {
            ID: undefined,
            Resource: { ID: resourceId },
            ResourceType: resourceType,
            IdentityType: "user",
            Identity: identity,
            Guest: isGuest,
            Level: isGuest ? guestPermissionLevel : level,
            Downgraded: isGuest ? shouldDowngradeForGuests : false,
            New: true,
          });
          resetInput({ scroll: true });
        }
      } else if ("User" in value) {
        // Add the selected User
        const isGuest = value.PermissionLimit !== null;
        const license = accountUsers?.find((allocation) => allocation.User.ID === value.User.ID)?.License;
        push(name, {
          ID: undefined,
          Resource: { ID: resourceId },
          ResourceType: resourceType,
          IdentityType: "user",
          Identity: value.User,
          Guest: isGuest,
          Level: isGuest ? guestPermissionLevel : level,
          Downgraded: isGuest ? shouldDowngradeForGuests : false,
          New: true,
          License: license,
        });
        resetInput({ scroll: true });
      } else if ("Name" in value) {
        // Add the selected Account
        push(name, {
          ID: undefined,
          Resource: { ID: resourceId },
          ResourceType: resourceType,
          IdentityType: "account",
          Identity: value,
          Level: level,
          New: true,
        });
        resetInput({ scroll: true });
      }
    } else {
      resetInput();
    }
  };

  /**
   * Handles the onChange event fired by the AutoComplete when a selection is made.
   * If the selection is an existing user or a valid email string the user is added
   * to the permissions list.
   */
  const handleOnChange = (event: React.ChangeEvent<any>, value: Option | string | null, reason: AutocompleteChangeReason) => {
    if (reason === "clear") {
      setInvalidEmail(undefined);
      setUndeliverableEmail(undefined);
    } else if (typeof value === "string" && !isValidEmailFormat(value)) {
      setInvalidEmail(`This is not a valid email address`);
    } else if (value !== null) {
      handleAddUser(value);
    }
  };

  /**
   * Resets the input after the user has addded a new user to the permissions list from the autocomplete
   */
  const resetInput = ({ scroll }: { scroll: boolean } = { scroll: false }) => {
    if (scroll) {
      // Scroll to the bottom of the list so that new users are visible
      setTimeout(() => {
        onAdd();
      }, 0);
    }

    // Update the key to force the Autocomplete to clear its current values
    // Then re-focus the input to allow for more names to be entered
    setInputKey(makeUuid());
    setInvalidEmail(undefined);
  };

  const isNewUser = (value: Option | string) => {
    // eslint-disable-next-line no-nested-ternary
    const identifier = typeof value === "string" ? value.toLowerCase() : "User" in value ? value.User.Email : value.ID;
    return !existingValues.includes(identifier);
  };

  /**
   * A filter function that determines the options that are eligible.
   * This allows us to include the "Add XXX" option.
   */
  const filterOptions = (opts: Option[], params: FilterOptionsState<Option>) => {
    const filtered = filter(opts, params);

    // Suggest the creation of a new value
    if (params.inputValue !== "") {
      filtered.push(params.inputValue);
    }
    return filtered.slice(0, 25);
  };

  /**
   * Used to determine the string value for a given option.
   */
  const getOptionLabel = (option: Option): string => {
    if (typeof option === "string") {
      return option;
    } else if ("User" in option) {
      return option.User.Email;
    } else if ("Name" in option) {
      return option.Name;
    }
    return "";
  };

  /**
   * Render the option within the dropdown
   */
  const renderOption = (props: any, option: Option): JSX.Element => {
    if (typeof option === "string") {
      const valid = isValidEmailFormat(option);
      if (valid) {
        return (
          <li {...props} key={option}>
            <Typography noWrap>{`Add "${option}"`}</Typography>
          </li>
        );
      } else {
        return (
          <li {...props} key={option}>
            <Typography noWrap>Please enter a valid email address</Typography>
          </li>
        );
      }
    } else if ("User" in option) {
      return (
        <li {...props} key={option.User.ID}>
          <Box display="flex" flexDirection="column">
            <Typography noWrap>{option.User.Email}</Typography>
          </Box>
        </li>
      );
    } else if ("Name" in option) {
      return (
        <li {...props} key={option.ID}>
          {option.Name}
        </li>
      );
    }
    return <></>;
  };

  return (
    <Box display="flex" flexDirection="row" mt={1}>
      <Box mr={2} width={150}>
        <Select
          margin="dense"
          variant="outlined"
          fullWidth
          size="small"
          defaultValue="view"
          data-testid="share-permissions-level"
          onChange={(event: SelectChangeEvent) => {
            setLevel(event.target.value as PermissionLevel);
          }}
        >
          {options.map((option) => (
            <MenuItem
              sx={{ fontSize: 16 }}
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              data-testid={`permission-level-item-${option.value}`}
            >
              {option.displayValue}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box flexGrow={1}>
        <FormControl fullWidth variant="filled">
          <MuiAutocomplete
            autoHighlight
            clearOnBlur
            data-testid="permission-autocomplete"
            key={inputKey}
            size="small"
            options={suggestions}
            filterOptions={filterOptions}
            freeSolo
            getOptionLabel={getOptionLabel}
            groupBy={(option) => (typeof option === "string" || "User" in option ? "Suggested users" : "Groups")}
            onChange={handleOnChange}
            renderInput={(params) => (
              <TextField autoFocus inputRef={inputRef} {...params} variant="outlined" placeholder={placeholder} data-testid={`${name}-input`} />
            )}
            renderOption={renderOption}
          />
          {invalidEmail && (
            <FormHelperText error data-testid="validation-message">
              {invalidEmail}
            </FormHelperText>
          )}
          {undeliverableEmail && (
            <FormHelperText error data-testid="deliverability-error">
              {undeliverableEmail}
              &nbsp;If you think this is wrong, please <SupportLink />
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    </Box>
  );
};

export default AddResource;
