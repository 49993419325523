import * as React from "react";
import { Divider, List, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { HelpIcon } from "fond/widgets";

import ArchitectureListItem from "./ArchitectureListItem";
import { selectArchitecture, useArchitectureEditorContext } from "./context";

const useCustomStyles = makeStyles((theme: Theme) => {
  return {
    leftPaneList: {
      padding: 0,
    },
    archHeader: {
      display: "flex",
      flex: 1,
      margin: theme.spacing(2),
    },
    archTitle: {
      fontSize: "1.3rem",
      marginRight: theme.spacing(1),
    },
  };
});

const ProjectArchitecture: React.FC = () => {
  const [{ selectedArchitectureID, projectArchitecture }, dispatch] = useArchitectureEditorContext();
  const classes = useCustomStyles();

  return (
    <div>
      <div className={classes.archHeader}>
        <Typography variant="h4" className={classes.archTitle}>
          Project architecture
        </Typography>
        <HelpIcon helpText="This is the architecture that is applied to your current project. Changing this will only affect the architecture of this project." />
      </div>
      <Divider component="div" />
      <List dense className={classes.leftPaneList}>
        <ArchitectureListItem
          arch={projectArchitecture}
          isSelected={selectedArchitectureID && selectedArchitectureID === projectArchitecture?.ID}
          onClick={() => {
            if (projectArchitecture) {
              dispatch(selectArchitecture(projectArchitecture.ID));
            }
          }}
        />
      </List>
      <Divider component="div" />
    </div>
  );
};

export default ProjectArchitecture;
