import { useEffect } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { ContentState, convertToRaw } from "draft-js";
import { useSnackbar } from "notistack";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { useGetPermissionsQuery } from "fond/api";
import { beginCommenting, endCommenting } from "fond/project/redux";
import { addComment, selectComment } from "fond/redux/comments";
import { getFeature, getOne } from "fond/redux/features";
import * as turf from "fond/turf";
import { Comment, Store } from "fond/types";
import { BlockSpinner } from "fond/widgets";

import AddCommentForm, { IFormData } from "./AddCommentForm";

interface IProps {
  /**
   * Callback function fired when the user cancels the comment
   */
  onCancel(): void;
  /**
   * Callback function fired when the popup calls handleClose
   */
  handleClose(event: any): void;
}

const QuickComment: React.FC<IProps> = ({ onCancel, handleClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  // For custom projects (using vector tiles) we use feature.id
  // For non custom projects (not using vector tiles), we use featureId because of the workaround here
  // https://bitbucket.org/biarrinetworks/fond_ui/src/da3cf94894aa089ce4ffc57cc4d924a3a59ad16c/src/fond/utils/geojson.js#lines-67:74
  const featureId = useSelector((state: Store) => state.project.selectedFeature.featureId || state.project.selectedFeature.feature.id);
  const feature = useSelector((state: Store) => getOne(state)(featureId as string));
  const projectId = useSelector((state: Store) => state.project.projectId);
  const versionId = useSelector((state: Store) => state.project.versionId);
  const dispatch: ThunkDispatch<Store, null, AnyAction> = useDispatch();
  const { refetch: refetchPermissions } = useGetPermissionsQuery({ id: projectId, type: "project" });

  useEffect(() => {
    dispatch(beginCommenting());
    return () => {
      dispatch(endCommenting());
    };
  }, []);

  // Features returned by `queryRenderedFeatures` don't always have accurate coordinates.
  // Features cross multiple tiles may be split at tile boundaries and only truncated geometry is returned.
  // Use API to retrieve the full precision geojson instead.
  useEffect(() => {
    if (featureId) {
      dispatch(getFeature(featureId as string));
    }
  }, [featureId]);

  const setInitialValue = () => {
    const featureProperties = feature.properties;

    if (featureProperties && featureProperties.FondID) {
      return { rawContent: convertToRaw(ContentState.createFromText(`FOND ID: ${featureProperties.FondID}`)) };
    } else if (featureProperties && featureProperties.BiarriID) {
      return { rawContent: convertToRaw(ContentState.createFromText(`Biarri ID: ${featureProperties.BiarriID}`)) };
    } else {
      return undefined;
    }
  };

  /**
   * Handles the submission of the new comment & the Point / Polygon feature
   */
  const handleOnSubmit = (values: IFormData) => {
    let newFeature;
    let featureType: Comment["Type"];

    if (feature.geometry.type === "Point") {
      newFeature = turf.point([feature.geometry.coordinates[0], feature.geometry.coordinates[1]]);
      featureType = "point";
    } else {
      // Create a buffer
      newFeature = turf.buffer(feature, 5, { units: "meters" });
      featureType = "polygon";
    }

    return dispatch(
      addComment({
        Version: versionId,
        RawContent: values.rawContent,
        Importance: values.importance,
        Features: [newFeature],
        Type: featureType,
      })
    )
      .then((comment: Comment) => {
        refetchPermissions();
        handleClose({ type: "manual-close" });
        dispatch(selectComment({ commentID: comment.ID, features: comment.Features, isFromFeaturesPopup: true }));

        return Promise.resolve();
      })
      .catch((e: any) => {
        console.error("Create Comment Failure", e);
        enqueueSnackbar("There was an issue creating your comment, please try again.", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      });
  };

  return (
    <Box>
      {feature ? (
        <AddCommentForm
          autoFocus
          // The below styling sizes are based on matching the max size of the popup when
          // it renders the Properties.  We want a consistent max size as the user switches between
          // viewing Properties and adding a comment.
          editorStyle={{ editorContent: { minHeight: 150, maxHeight: 246, overflowY: "auto", width: 300 } }}
          onCancel={onCancel}
          onSubmit={handleOnSubmit}
          initialValues={setInitialValue()}
          hasFeature
          projectId={projectId}
        />
      ) : (
        <BlockSpinner />
      )}
    </Box>
  );
};

export default QuickComment;
