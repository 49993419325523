import * as React from "react";
import { useState } from "react";
import { Cancel } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";

import { setFilter } from "fond/redux/styles";
import { useAppDispatch } from "fond/utils/hooks";

import { Container, FilterIcon, IconButton, TextField } from "./filterStyles.styles";

const FilterStyles: React.FC = () => {
  const [text, setText] = useState("");
  const dispatch = useAppDispatch();

  const setFilterDebounced = useDebouncedCallback((searchText: string) => {
    dispatch(setFilter(searchText));
  }, 200);

  const handleOnChange = (value: string) => {
    setText(value);
    setFilterDebounced(value);
  };

  return (
    <Container data-testid="filter-styles">
      <FilterIcon />
      <TextField
        value={text}
        fullWidth
        size="small"
        onChange={(e) => handleOnChange(e.target.value)}
        placeholder="Filter styles"
        inputProps={{
          "data-testid": "filter-input",
        }}
        InputProps={{
          endAdornment: text.length > 0 && (
            <InputAdornment position="end">
              <IconButton size="small" onClick={() => handleOnChange("")} data-testid="cancel">
                <Cancel />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Container>
  );
};

export default FilterStyles;
