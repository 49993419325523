import { Folder, Project, Report } from "fond/types";

export type EntityType = "folder" | "project" | "report";

export type ResourceEntity = Folder | Project | Report;

export const EntityLabel: Record<EntityType, string> = {
  folder: "folder",
  project: "project",
  report: "report",
} as const;
