import { useState } from "react";
import * as React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { Alert, Box, Button } from "@mui/material";

import { LoadingButton } from "ui";

import { TextField } from "fond/form/fields";
import { TextFieldValue } from "fond/form/fields/TextField";
import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import { getCurrentProject } from "fond/project";
import { AnyObject, Project, ProjectPreview, Report, Store } from "fond/types";
import { setValue } from "fond/utils/formMutators";
import { Actions } from "fond/utils/permissions";
import { compose, required } from "fond/utils/validation";
import { Modal } from "fond/widgets";

interface IFormData {
  name: string;
}

interface IProps {
  entity?: ProjectPreview | Report;
  /**
   * The default value of the name input.
   */
  defaultValue?: string;
  /**
   * An optional message to display below the modal title.
   */
  message?: string;
  /**
   * Callback function to handle the onClose of the modal
   */
  onClose(): void;
  /**
   * Callback function for when the form is submitted
   */
  onRename(values: IFormData): Promise<void>;
  /**
   * An optional validator that will run over the name field input.
   */
  onValidate?(value: TextFieldValue): string | undefined;
  /**
   * Modal title
   */
  title: string;
}

const RenameModal: React.FC<IProps> = ({
  entity,
  title,
  message = "",
  onClose,
  onRename,
  onValidate = () => undefined,
  defaultValue = "",
}: IProps) => {
  const [saving, setSaving] = useState(false);
  let submit: (event?: Partial<Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">>) => Promise<AnyObject | undefined> | undefined;
  const entityOrProject = useSelector((state: Store): ProjectPreview | Project | Report => entity || getCurrentProject(state.project));
  const entityRenameAction = entityOrProject?.EntityType === "report" ? Actions.REPORT_RENAME : Actions.PROJECT_RENAME;
  const canRename = usePermissionCheck(entityRenameAction, entityOrProject?.Permission?.Level);

  /**
   * On submit function called when the form is submitted and valid
   */
  const handleOnSubmit = async (formData: IFormData) => {
    setSaving(true);
    onRename(formData)
      .then(() => {
        setSaving(false);
        onClose();
      })
      .catch(() => {
        setSaving(false);
      });
  };

  return (
    <Modal
      open
      header={title}
      data-testid="rename-modal"
      content={
        canRename ? (
          <Form<IFormData>
            initialValues={{ name: defaultValue }}
            onSubmit={handleOnSubmit}
            render={({ handleSubmit }) => {
              submit = handleSubmit;

              return (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <TextField
                      data-testid="name-input"
                      name="name"
                      autoComplete="off"
                      required
                      label="Name"
                      helperText={message}
                      fullWidth
                      autoFocus
                      validate={compose(required, onValidate)}
                    />
                  </Box>
                </form>
              );
            }}
            mutators={{ setValue }}
          />
        ) : (
          <Box>
            <Alert severity="info">You do not have permission to rename this resource.</Alert>
          </Box>
        )
      }
      actions={
        <>
          <Button data-testid="rename-modal-cancel-button" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          {canRename && (
            <LoadingButton data-testid="rename-modal-save-button" color="primary" onClick={() => submit()} loading={saving}>
              Rename
            </LoadingButton>
          )}
        </>
      }
    />
  );
};

export default RenameModal;
