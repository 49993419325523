import { useState } from "react";
import * as React from "react";
import { Delete, Edit, Redo, Share as ShareIcon } from "@mui/icons-material";
import { ListItemIcon, Menu, MenuItem, MenuProps, Typography } from "@mui/material";

import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import Share from "fond/share/Share";
import { Folder } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";

import DeleteFolder from "../Folders/DeleteFolder";
import EditFolder from "../Folders/EditFolder";
import MoveFolderDialog from "../Folders/MoveFolder";

import StarMenuItem from "./StarMenuItem";

interface IProps extends Pick<MenuProps, "anchorEl"> {
  /**
   * The folder the menu actions relate to
   */
  folder: Folder;
  onMenuClose: () => void;
}

type FolderModal = "move" | "edit" | "delete" | "share";
const FolderRowMenu: React.FC<IProps> = ({ folder, onMenuClose, anchorEl }: IProps) => {
  const [showModal, setShowModal] = useState<FolderModal>();

  const canEditFolder = usePermissionCheck(Actions.FOLDER_EDIT, folder.Permission.Level);
  const canMoveFolder = usePermissionCheck(Actions.FOLDER_MOVE, folder.Permission.Level);
  const canDeleteFolder = usePermissionCheck(Actions.FOLDER_DELETE, folder.Permission.Level);
  /**
   * Generic Callback function that updates any of the modal open states and closes the menu
   */
  const openModal = (modalType: FolderModal) => () => {
    onMenuClose();
    setShowModal(modalType);
  };

  const closeModal = () => {
    setShowModal(undefined);
  };
  const resource_name = folder.Name.replace(/[^\w\s]/g, "").replace(/\s/g, "");
  return (
    <Menu
      data-testid={`project-list-item-menu-${resource_name}`}
      anchorEl={anchorEl}
      keepMounted
      open={!!anchorEl}
      onClose={onMenuClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem aria-label="Edit Folder" onClick={openModal("edit")} data-testid="edit-folder-button" disabled={!canEditFolder}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <Typography variant="inherit">Edit</Typography>
      </MenuItem>

      <MenuItem aria-label="Move Folder" data-testid="move-folder-button" onClick={openModal("move")} disabled={!canMoveFolder}>
        <ListItemIcon>
          <Redo fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Move</Typography>
      </MenuItem>

      <MenuItem
        aria-label="Share Folder"
        onClick={openModal("share")}
        data-testid="share-folder-button"
        disabled={!permissionCheck(folder.Permission.Level, Actions.FOLDER_SHARE_VIEW)}
      >
        <ListItemIcon>
          <ShareIcon />
        </ListItemIcon>
        <Typography variant="inherit">Share</Typography>
      </MenuItem>

      <MenuItem
        aria-label="Delete Folder"
        onClick={openModal("delete")}
        data-testid="delete-folder-button"
        disabled={folder.Subfolders?.length > 0 || !canDeleteFolder}
      >
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <Typography variant="inherit">Delete</Typography>
      </MenuItem>
      <StarMenuItem onMenuClose={onMenuClose} resource={folder} />
      {showModal === "edit" && <EditFolder onClose={closeModal} folder={folder} />}
      {showModal === "move" && <MoveFolderDialog folder={folder} onClose={closeModal} />}
      {showModal === "delete" && <DeleteFolder onClose={closeModal} folder={folder} />}
      {showModal === "share" && <Share resource={folder} onClose={closeModal} />}
    </Menu>
  );
};

export default FolderRowMenu;
