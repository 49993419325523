/*
 * A panel for selecting map styles from streets, satellite, hybrid, monochrome
 */
import * as React from "react";
import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import { blue } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { MapStyle } from "../types";

import HybridImage from "./images/HybridImage.png";
import MapImage from "./images/MapImage.png";
import MonochromeImage from "./images/Monochrome.png";
import SatelliteImage from "./images/SatelliteImage.jpeg";
import { MapContext } from "./MapProvider";

import "./BaseMapStyleToggle.scss";

const useStyles = makeStyles(() => ({
  stylePanel: {
    height: 60,
    outline: "2px solid white",
    borderRadius: 4,
    backgroundColor: "white",
  },
  styleOption: {
    "&:hover": {
      color: blue[600],
      "& $styleImage": {
        outlineColor: blue[600],
      },
    },
  },
  styleImage: {
    height: 36,
    borderRadius: 4,
    outline: "2px solid white",
  },
}));

export const options = [
  {
    value: "map",
    text: "Streets",
    img: MapImage,
  },
  {
    value: "satellite",
    text: "Satellite",
    img: SatelliteImage,
  },
  {
    value: "hybrid",
    text: "Hybrid",
    img: HybridImage,
  },
  {
    value: "monochrome",
    text: "Mono",
    img: MonochromeImage,
  },
];

interface IProps {
  onChange: (style: MapStyle) => void;
}

const BaseMapStyleToggle: React.FC<IProps> = ({ onChange }: IProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // Flag to control the delayed closing of the map style panel.
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setTimeout> | null>(null);
  const { mapStyle: style } = useContext(MapContext);

  const clearTimeInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    clearTimeInterval();
  };

  // Delay close the panel when the mouse is leaving
  const handleClose = () => {
    clearTimeInterval();
    const newIntervalId = setInterval(() => {
      setOpen(false);
    }, 1000);
    setIntervalId(newIntervalId);
  };

  const handleClick = (optionStyle: string) => {
    if (optionStyle !== style) {
      onChange(optionStyle as MapStyle);
    }
  };

  return (
    <Box className="map-style-panel" display="flex">
      <Box data-testid={`map-style-current-${style}`} onMouseOver={handleOpen} onMouseLeave={handleClose}>
        <img className="map-style-panel__image" src={options.find((option) => option.value === style)?.img} alt={`${style} View`} />
        <Box className="map-style-panel__text">
          <Typography variant="caption">{options.find((option) => option.value === style)?.text}</Typography>
        </Box>
      </Box>
      {open && (
        <Box
          ml={2}
          px={0.5}
          display="flex"
          alignItems="center"
          flexDirection="row"
          onMouseOver={handleOpen}
          onMouseLeave={handleClose}
          className={classes.stylePanel}
        >
          {options.map((option) => (
            <Box
              m={1}
              pt={0.5}
              width={40}
              display="flex"
              flexDirection="column"
              alignItems="center"
              alignContent="center"
              className={classes.styleOption}
              key={`map-style-${option.value}`}
              data-testid={`map-style-option-${option.value}`}
              onClick={() => handleClick(option.value)}
            >
              <img src={option.img} alt={`${option.text} View`} style={{ outlineColor: undefined }} className={classes.styleImage} />
              <Box>
                <Typography style={{ color: undefined }} variant="caption" data-testid={`map-style-label-${option.value}`}>
                  {option.text}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default BaseMapStyleToggle;
