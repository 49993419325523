import { useMemo } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
import { Box } from "@mui/material";
import stringSimilarity from "string-similarity";

import { selectAllDraftStyles, selectDraftData } from "fond/api";
import { StyleRow } from "fond/styleEditor";
import { Store } from "fond/types";
import { NonIdealState } from "fond/widgets";

/**
 * A flat list of stylers based on the current filter text
 */
const FlatStyleList: React.FC = () => {
  const filter = useSelector((state: Store) => state.styles.settings.filter);
  const allStyles = useSelector(selectAllDraftStyles);
  const config = useSelector((state: Store) => selectDraftData(state));

  const styles =
    useMemo(() => {
      return allStyles?.filter((style) => {
        const score = stringSimilarity.compareTwoStrings(filter, `${style.Name} ${style.MapboxStyle.type}`);
        return style.Name.toLowerCase().includes(filter.toLowerCase()) || score > 0.3;
      });
    }, [allStyles, filter]) || [];

  return (
    <Box data-testid="flat-style-list">
      {styles.length > 0 ? (
        <>{styles?.map(({ ID }) => <StyleRow key={ID} styleID={ID} config={config} />)}</>
      ) : (
        <Box height={150}>
          <NonIdealState size="small" title="Nothing..." description="We didn't find any items that match your search." icon={<Search />} />
        </Box>
      )}
    </Box>
  );
};

export default FlatStyleList;
