import * as React from "react";
import image1 from "svg_icons/ui-improvements-tour/slide-1.svg";
import image2 from "svg_icons/ui-improvements-tour/slide-2.svg";
import image3 from "svg_icons/ui-improvements-tour/slide-3.svg";
import image4 from "svg_icons/ui-improvements-tour/slide-4.svg";

import BasicTour from "./BasicTour";

import { BodyText } from "./BasicTour.styles";

type Slides = Array<{
  image: string;
  title: string;
  content: React.ReactNode;
}>;

const slides: Slides = [
  {
    image: image1,
    title: "New and Improved Experience!",
    content: (
      <>
        <BodyText component="p">
          We're thrilled to introduce a series of small but impactful enhancements designed to make your experience even better.
        </BodyText>
        <br />
        <BodyText>Let's take a quick tour.</BodyText>
      </>
    ),
  },
  {
    image: image2,
    title: "Cost to serve",
    content: (
      <>
        <BodyText component="p">Cost to serve is our initial financial analysis milestone!</BodyText>
        <br />
        <BodyText component="p">
          We apply your <strong>material costs</strong> to your network to design to visualise the cost to serve the addresses to make better
          decisions in your projects.
        </BodyText>
      </>
    ),
  },
  {
    image: image3,
    title: "Stacked navigation",
    content: (
      <>
        <BodyText component="p">We've transitioned to a stacked navigation design, providing you with a full view of the map.</BodyText>
        <br />
        <BodyText component="p">Explore the panel on the left side of the screen for quick access to all major sections.</BodyText>
      </>
    ),
  },
  {
    image: image4,
    title: "Improved features",
    content: (
      <>
        <BodyText component="h5" sx={{ fontWeight: 700 }}>
          Area Select for Aerial
        </BodyText>
        <BodyText component="p">Users can now use Area Select to approximate aerial as street centerlines.</BodyText>
        <br />
        <BodyText component="h5" sx={{ fontWeight: 700 }}>
          Bill of Materials
        </BodyText>
        <BodyText component="p">BOM improvements focusing on splice counts and demand calculation.</BodyText>
      </>
    ),
  },
];

interface IProps {
  onComplete(): void;
}

const UIImprovementsTour: React.FC<IProps> = ({ onComplete }) => <BasicTour onComplete={onComplete} slides={slides} name="ui-improvements" />;

export default UIImprovementsTour;
