import { useEffect } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { Auth } from "@aws-amplify/auth";
import { SxProps } from "@mui/material";

import { versionsSlice } from "fond/api";
import { addImport } from "fond/redux/imports";
import { ImportsState, ImportStatus, Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";

import VersionImportController from "../VersionImportController";

import { AbsoluteFlexColumnBox } from "./ImportControllers.styles";

interface ImportControllersProps {
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  /**
   * Additional text field styles.
   */
  style?: SxProps;
}

/**
 * ImportControllers component.
 *
 * This component sits at top level and renders a VersionImportController whenever a layer import is added.
 * Layer imports may be performed across multiple versions concurrently.
 * This component provides a status indication for each version.
 */
const ImportControllers: React.FC<ImportControllersProps> = ({ "data-testid": dataTestid = "import-controllers", style }: ImportControllersProps) => {
  /**
   * The imports state contains the import state and progress of all active layer imports, keyed by the layer version ID.
   */
  const dispatch = useAppDispatch();
  const imports: ImportsState = useSelector((state: Store) => state.imports);

  const [triggerImportStatusRequest] = versionsSlice.endpoints.getImportStatus.useLazyQuery();

  // Query and load import data on component mount.
  useEffect(() => {
    const getStatus = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        triggerImportStatusRequest({
          status: [ImportStatus.CONVERTING, ImportStatus.IMPORTING, ImportStatus.BUILDING_TILES],
        }).then((response) => {
          for (const layerImport of response?.data?.Layers || []) {
            dispatch(
              addImport({
                versionId: layerImport.Version.ID,
                layerId: layerImport.ID,
                layerKey: layerImport.LayerKey,
                displayName: layerImport.LayerKey,
                files: [],
                started: new Date(layerImport.ImportStatus.StartedAt),
                status: layerImport.ImportStatus.Status,
                progress: 0,
              })
            );
          }
        });
      } catch (e) {
        // user not logged in
      }
    };

    getStatus();
  }, []);

  return Object.keys(imports).length > 0 ? (
    <AbsoluteFlexColumnBox data-testid={dataTestid} sx={style}>
      {Object.entries(imports).map(([versionId, layerImports]) => (
        <VersionImportController key={`import-controller-${versionId}`} versionId={versionId} imports={layerImports} />
      ))}
    </AbsoluteFlexColumnBox>
  ) : null;
};

export default ImportControllers;
