import { useState } from "react";
import * as React from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { AccountBox, ExitToApp as ExitToAppIcon, QueryStats, Tune as SettingsIcon } from "@mui/icons-material";
import { Box, Button, Link, ListItemIcon, MenuItem, Tooltip } from "@mui/material";
import logoImage from "svg_icons/logo-white.svg";

import { selectAllAccounts, useGetReportQuery } from "fond/api";
import ImpersonateBanner from "fond/impersonate/ImpersonateBanner";
import { getCurrentProject } from "fond/project";
import ProjectName from "fond/project/ProjectName";
import GlobalSearch from "fond/projects/GlobalSearch/GlobalSearch";
import defaultTheme from "fond/theme";
import ViewEntityFolderButton from "fond/topBar/ViewEntityFolderButton";
import { Project } from "fond/types";
import { useAppSelector } from "fond/utils/hooks";
import { Actions, permissionCheck } from "fond/utils/permissions";
import ReportName from "fond/views/Report/ReportName";
import { MediumAvatar } from "fond/widgets";

import { FollowButton } from "../project/follows";

import NotificationInbox from "./NotificationInbox";
import ShareButton from "./ShareButton";
import StarButton from "./StarButton";
import SwitchAccountMenuItem from "./SwitchAccountMenuItem";
import UserManagementBanner from "./UserManagementBanner";

import { AccountIcon, IconButton, MainContainer, Menu, Topbar } from "./topbar.styles";

const TopBar: React.FC = () => {
  const cognito = useAppSelector((state) => state.cognito);
  const project = useAppSelector((state): Project => getCurrentProject(state.project));
  const accounts = useAppSelector(selectAllAccounts);
  const [profileAnchorEl, setProfileAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showShareModal, setShowShareModal] = useState(false);
  const canViewShareProject = permissionCheck(project?.Permission.Level, Actions.PROJECT_SHARE_VIEW);
  const { uuid: projectId, reportId } = useParams<"uuid" | "folderId" | "reportId">();
  const { data: report } = useGetReportQuery(reportId ?? "", { skip: !reportId });
  const canViewShareReport = permissionCheck(report?.Permission?.Level, Actions.REPORT_SHARE_VIEW);

  const toggleShareModal = () => {
    setShowShareModal(!showShareModal);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setProfileAnchorEl(null);
  };

  return (
    <MainContainer data-testid="top-bar">
      <ImpersonateBanner />
      <Topbar>
        <Box display="flex" alignItems="center" flexDirection="row">
          <Box pt={0.5} pl={0.5}>
            <IconButton data-testid="home-button">
              <Link component={RouterLink} to="/">
                <img alt="app-logo" src={logoImage} height={34} width={124} />
              </Link>
            </IconButton>
          </Box>
        </Box>
        {projectId && (
          <Box display="flex" alignItems="center" justifyContent="center" flex="1" overflow="hidden">
            <ProjectName />
            <ViewEntityFolderButton entity={project} />
            <StarButton entity={project} />
            <FollowButton id={projectId} name={project.ProjectName} type="project" />
          </Box>
        )}
        {reportId && report && (
          <Box display="flex" alignItems="center" justifyContent="center" flex="1" overflow="hidden">
            <QueryStats fontSize="small" sx={{ color: (theme) => theme.palette.common.white, mx: 1 }} />
            <ReportName reportId={reportId} />
            <ViewEntityFolderButton entity={report} />
            <StarButton entity={report} />
          </Box>
        )}
        <Box display="flex" alignItems="center" flexDirection="row" data-testid="top-bar-right">
          {projectId && canViewShareProject && <ShareButton entity={project} />}
          {reportId && report && canViewShareReport && <ShareButton entity={report} />}
          {!projectId && !reportId && <GlobalSearch />}
          {cognito?.user && (
            <>
              <Box ml={1}>
                <NotificationInbox />
              </Box>
              <Box display="flex">
                <Tooltip title="User profile">
                  <Button data-testid="top-bar-profile" aria-controls="top-bar-profile" aria-haspopup="true" onClick={handleOpen}>
                    {!(cognito.userAttrs.givenName || cognito.userAttrs.familyName) ? (
                      <AccountIcon />
                    ) : (
                      <MediumAvatar
                        name={`${cognito.userAttrs.givenName} ${cognito.userAttrs.familyName}`}
                        maxInitials={2}
                        sx={{ bgcolor: defaultTheme.palette.biarri.primary.red }}
                      />
                    )}
                  </Button>
                </Tooltip>
                <Menu
                  id="top-bar-profile"
                  open={Boolean(profileAnchorEl)}
                  anchorEl={profileAnchorEl}
                  keepMounted
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  onClose={handleClose}
                  disableScrollLock
                >
                  <MenuItem data-testid="user-profile" component={RouterLink} to="/settings/profile" onClick={handleClose}>
                    <ListItemIcon>
                      <SettingsIcon sx={{ transform: "rotate(90deg)" }} />
                    </ListItemIcon>
                    Settings
                  </MenuItem>
                  {!accounts.length && (
                    <MenuItem data-testid="create-account" component={RouterLink} to="/createaccount" onClick={handleClose}>
                      <ListItemIcon>
                        <AccountBox />
                      </ListItemIcon>
                      Create Account
                    </MenuItem>
                  )}
                  {accounts.length > 1 && <SwitchAccountMenuItem onClose={handleClose} />}
                  <MenuItem data-testid="logout-button" component={RouterLink} to="/signout" onClick={handleClose}>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    Sign Out
                  </MenuItem>
                </Menu>
              </Box>
            </>
          )}
        </Box>
      </Topbar>
      <UserManagementBanner />
    </MainContainer>
  );
};

export default TopBar;
