import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { useDeleteVersionMutation } from "fond/api";
import { Version } from "fond/types";
import { Message as MessageWidget, Modal } from "fond/widgets";

interface IProps {
  // Project version to be deleted
  version: Version;
  // Callback function to handle the onClose of the modal
  onClose(): void;
}

const DeleteVersionModal: React.FC<IProps> = ({ version, onClose }: IProps) => {
  const [deleteVersion, { isLoading: isSaving, isError }] = useDeleteVersionMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleOnClick = async (event: React.MouseEvent<EventTarget>) => {
    event.preventDefault();
    deleteVersion({ ID: version.ID, Project: version.Project })
      .unwrap()
      .then(() => {
        onClose();
        enqueueSnackbar("Version successfully deleted.");
      })
      .catch((error) => {
        console.error("Error deleting version:", error);
      });
  };

  return (
    <Modal
      open
      header="Delete version?"
      data-testid="delete-version-modal"
      content={
        <Box>
          {isError && (
            <Box mb={2}>
              <MessageWidget type="error">There was an issue deleting this version. Please try again.</MessageWidget>
            </Box>
          )}
          <Typography>
            This will permanently remove <strong>{version.Name}</strong> and all associated data, including comments. This action cannot be undone.
            <br />
            <br />
            Are you sure you want to proceed?
          </Typography>
        </Box>
      }
      actions={
        <>
          <Button data-testid="delete-version-cancel-button" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <LoadingButton data-testid="delete-version-confirm-button" color="primary" onClick={handleOnClick} loading={isSaving}>
            Delete
          </LoadingButton>
        </>
      }
    />
  );
};

export default DeleteVersionModal;
