import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import AttachmentIcon from "@mui/icons-material/AttachFile";
import { Box, Button } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import { getCurrentProject } from "fond/project";
import { uploadAttachmentAbort } from "fond/redux/attachments";
import { AttachmentEntityType, Project, Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { Actions } from "fond/utils/permissions";
import { Modal } from "fond/widgets";

import AttachmentList from "./AttachmentList/AttachmentList";
import AttachmentStaging from "./AttachmentStaging/AttachmentStaging";
import AttachmentUpload from "./AttachmentUpload/AttachmentUpload";

const customStyles = (theme: Theme) => {
  return createStyles({
    modal: {
      minHeight: "80vh",
    },
    modalContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    modalDataGrid: {
      overflow: "hidden",
      flex: "1",
      display: "flex",
      width: "100%",
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  onClose: () => void;
  isOpen: boolean;
  attachmentEntityType?: AttachmentEntityType;
}

/**
 * An Enumeration of views rendered in the modal. Only one view is active at any one time.
 */
enum ModalViews {
  ATTACHMENTS_LIST,
  ATTACHMENTS_STAGING,
  ATTACHMENTS_UPLOAD,
}

export const getFeatureId = (attachmentEntityType: string, project: Project, selectedFeature: any): string | null => {
  if (attachmentEntityType !== "Feature") {
    return null;
  }

  if (project?.HasCustomLayerConfig) {
    return selectedFeature?.feature?.id ?? null;
  }

  return selectedFeature?.featureId ?? null;
};

const AttachmentModal: React.FC<IProps> = ({ classes, onClose, isOpen, attachmentEntityType = "Project" }: IProps) => {
  const dispatch = useAppDispatch();
  const project = useSelector((state: Store): Project => getCurrentProject(state.project));
  const uploadAttachmentAction = attachmentEntityType === "Project" ? Actions.PROJECT_UPLOAD_ATTACHMENT : Actions.FEATURE_UPLOAD_ATTACHMENT;
  const canUpload = usePermissionCheck(uploadAttachmentAction, project.Permission.Level);
  const { selectedFeature } = useSelector((state: Store) => state.project);
  /*
   * Modal view control.
   */
  const [modalView, setModalView] = useState<ModalViews>(ModalViews.ATTACHMENTS_LIST);

  const openList = () => setModalView(ModalViews.ATTACHMENTS_LIST);
  const openStaging = () => setModalView(ModalViews.ATTACHMENTS_STAGING);
  const openUploads = () => setModalView(ModalViews.ATTACHMENTS_UPLOAD);

  const listView = modalView === ModalViews.ATTACHMENTS_LIST;
  const stagingView = modalView === ModalViews.ATTACHMENTS_STAGING;
  const uploadsView = modalView === ModalViews.ATTACHMENTS_UPLOAD;

  /*
   * File upload control.
   */
  const [stagedFiles, setStagedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const abortAllUploads = () => {
    for (const file of stagedFiles) {
      dispatch(uploadAttachmentAbort(file));
    }
  };

  const featureId = getFeatureId(attachmentEntityType, project, selectedFeature);
  return (
    <Modal
      data-testid="attachment-modal"
      variant="primary"
      open={isOpen}
      onClose={() => onClose()}
      header={`${attachmentEntityType} Attachments`}
      headerIcon={<AttachmentIcon />}
      disableBackdropClick={stagingView || uploadsView}
      headerSubtitle="COLLABORATE"
      DialogContentProps={{ className: classes.modalContent }}
      PaperProps={{ style: { minHeight: "80vh" } }}
      className={classes.modal}
      content={
        <>
          {listView && canUpload && (
            <Button data-testid="attachment-modal-staging-btn" variant="contained" color="primary" startIcon={<AddIcon />} onClick={openStaging}>
              Upload a file
            </Button>
          )}
          <Box className={classes.modalDataGrid}>
            <Box flex="1" width="100%">
              {listView && <AttachmentList project={project} attachmentEntityType={attachmentEntityType} featureId={featureId} />}
              {stagingView && <AttachmentStaging onAcceptedStage={setStagedFiles} />}
              {uploadsView && <AttachmentUpload files={stagedFiles} onIsUploading={setIsUploading} featureId={featureId} />}
            </Box>
          </Box>
        </>
      }
      actions={
        <>
          {listView && (
            <Button data-testid="attachment-modal-close-btn" onClick={() => onClose()}>
              Close
            </Button>
          )}
          {stagingView && (
            <>
              <Button color="inherit" data-testid="attachment-modal-staging-cancel-btn" onClick={openList}>
                Cancel
              </Button>
              <Button
                data-testid="attachment-modal-staging-upload-btn"
                variant="contained"
                color="primary"
                disabled={stagedFiles.length === 0}
                onClick={openUploads}
              >
                Upload
              </Button>
            </>
          )}
          {uploadsView && (
            <>
              <Button color="inherit" data-testid="attachment-modal-upload-cancel-all-btn" onClick={abortAllUploads} disabled={!isUploading}>
                Cancel All
              </Button>
              <Button data-testid="attachment-modal-upload-close-btn" onClick={openList} disabled={isUploading}>
                Close
              </Button>
            </>
          )}
        </>
      }
    />
  );
};

AttachmentModal.displayName = "AttachmentModal";
export default withStyles(customStyles)(AttachmentModal);
