import * as React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { FormControl, FormLabel, TextField as BaseTextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import { FieldValidator } from "final-form";

import { analysePassword } from "fond/utils/password";

import { FormHelperText } from "../common.styles";
import { ConformMessage, Labels, Meter, Strength } from "./textField.styles";

export type FieldValue = string | number | null;
export type TextFieldProps = Partial<Omit<MuiTextFieldProps, "onChange" | "defaultValue">> & {
  defaultValue?: string | number;
  fieldProps?: Partial<FieldProps<FieldValue, TextWrapperProps, HTMLElement, string>>;
  /**
   * Flag indicating if the validate error text should be shown
   */
  hideError?: boolean;
  labelClasses?: Partial<ClassNameMap<string>>;
  name: string;
  /**
   * Flag indicating that the component should analyse the password strength &
   * show the result.  Used in combination with `type="password"`
   */
  showPasswordStrength?: boolean;
  validate?: FieldValidator<FieldValue>;
};

const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const { defaultValue, name, type, hideError, fieldProps, validate, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input, meta }) => <TextFieldWrapper input={input} meta={meta} name={name} type={type} hideError={hideError} {...rest} />}
      validate={validate}
      defaultValue={defaultValue}
      {...fieldProps}
    />
  );
};

type TextWrapperProps = Pick<TextFieldProps, "type" | "hideError" | "showPasswordStrength"> &
  Partial<Omit<MuiTextFieldProps, "onChange">> &
  FieldRenderProps<string, HTMLElement>;

const TextFieldWrapper: React.FC<TextWrapperProps> = ({
  input: { name, onChange, value, ...restInput },
  meta,
  disabled,
  label,
  labelClasses,
  hiddenLabel = false,
  autoFocus,
  className,
  classes,
  multiline,
  placeholder,
  rows,
  required,
  showPasswordStrength,
  size,
  helperText,
  variant,
  inputProps,
  InputProps,
  hideError = false,
  fullWidth = true,
  margin = "dense",
  type = "text",
  ...rest
}: TextWrapperProps) => {
  const hasError = (meta.error || meta.submitError) && meta.touched;
  const passwordStrength = showPasswordStrength && value ? analysePassword(value) : undefined;
  const helperTextId = `${name}-helper-text`;

  return (
    <FormControl fullWidth={fullWidth} data-testid={`${name}-${type}-field`} className={className} hiddenLabel={hiddenLabel}>
      <Labels>
        {(label || required) && (
          <FormLabel required={required} error={hasError} classes={labelClasses} htmlFor={name}>
            {label}
          </FormLabel>
        )}
        {showPasswordStrength && passwordStrength !== undefined && passwordStrength <= 2 && <FormLabel error>Password is weak</FormLabel>}
      </Labels>

      {showPasswordStrength && passwordStrength !== undefined && (
        <>
          <ConformMessage>
            To conform with our Strong Password policy, you are required to use a sufficiently strong password. Password must be more than 8
            characters.
          </ConformMessage>

          <Strength>
            <Meter data-testid="strength-meter" strength={passwordStrength} />
          </Strength>
        </>
      )}

      <BaseTextField
        id={name}
        name={name}
        data-testid={`${name}-input`}
        autoFocus={autoFocus}
        margin={margin}
        error={hasError}
        inputProps={{
          "aria-describedby": helperTextId,
          ...inputProps,
          ...restInput,
        }}
        InputProps={InputProps}
        onChange={onChange}
        value={value}
        fullWidth={fullWidth}
        size={size || "small"}
        multiline={multiline}
        placeholder={placeholder !== undefined ? `${placeholder}` : undefined}
        rows={rows}
        disabled={disabled}
        variant={variant}
        classes={classes}
        type={type}
        {...rest}
      />
      {((hasError && !hideError) || helperText) && (
        <FormHelperText id={helperTextId} error={hasError && !hideError} data-testid={helperTextId}>
          {(!hasError || hideError) && helperText ? helperText : ""}
          {meta.touched && hasError && !hideError && (meta.error || meta.submitError)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default TextField;
