import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { BackgroundAreaLines, staticUrl, UploadIcon } from "fond/svg_icons";
import { Dropzone } from "fond/widgets";

import { CompactableFlexBox } from "./ImportDropzone.styles";

/**
 * The ImportDropZone component.
 * This dropzone passes geospatial passes from a file drop back to the implementing component through the onDrop callback.
 * Non-geospatial files are ignored and not passed through the callback.
 * The dropzone can be rendered in compact mode, minimising the vertical space.
 */
interface ImportDropZoneProps {
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  /**
   * Callback on file drop. Passes geospatial files only.
   */
  onDrop(files: File[]): void;
  /**
   * Vertically compact the drop zone as tightly as possible.
   */
  compact?: boolean | undefined;
}

const ImportDropZone: React.FC<ImportDropZoneProps> = ({
  "data-testid": dataTestid = "import-dropzone",
  onDrop,
  compact = false,
}: ImportDropZoneProps) => {
  const theme = useTheme();

  return (
    <Dropzone
      data-testid={dataTestid}
      height={compact ? "unset" : "100%"}
      onDropAccepted={onDrop}
      acceptedFiles={{
        "application/vnd.google-earth.kmz": [".kml"],
        "application/geo+json": [".geojson"],
        "application/x-dbf": [".dbf"],
        "application/unknown": [".prj", ".tab", ".dat", ".id", ".map", ".shp", ".shx"],
      }}
    >
      <CompactableFlexBox
        data-testid="compactable-flex-box-container"
        container
        compact={compact}
        style={{
          backgroundImage: staticUrl(<BackgroundAreaLines fill="rgba(245, 245, 245, 0.6)" />),
          backgroundSize: "cover",
          padding: theme.spacing(2),
        }}
      >
        <>
          {!compact && <Typography variant="h5">Upload GIS Project Files</Typography>}
          <CompactableFlexBox data-testid="compactable-flex-box" compact={compact}>
            <UploadIcon height={compact ? 59 : 74} />
          </CompactableFlexBox>
          <CompactableFlexBox data-testid="compactable-flex-box" compact={compact}>
            <Box>
              {compact && <Typography variant="h5">Upload GIS Project Files</Typography>}
              <Typography variant="h7" fontSize="0.875rem" component="h5" color={theme.palette.biarri.primary.darkGrey}>
                Select a file or drag and drop here
              </Typography>
              <Typography variant="caption" color="rgba(101, 101, 101, 0.8)">
                SHAPE, TAB, KML and GEOJSON files are allowed
              </Typography>
            </Box>
          </CompactableFlexBox>
          <CompactableFlexBox data-testid="compactable-flex-box" compact={compact} sx={compact ? { alignSelf: "flex-end" } : {}}>
            <Button
              sx={{ marginTop: compact ? "unset" : theme.spacing(2), marginBottom: compact ? theme.spacing(1) : 0 }}
              variant="contained"
              color="primary"
            >
              Browse Files
            </Button>
          </CompactableFlexBox>
        </>
      </CompactableFlexBox>
    </Dropzone>
  );
};

export default ImportDropZone;
