import * as React from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { Check, Map as MapIcon } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { MAP_STYLES, MAP_STYLES_OPTIONS } from "fond/map/styles";
import { getCurrentProject, getCurrentProjectView, updateProjectView } from "fond/project/redux";
import { MapStyle, Project, Store, View } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { NestedMenuItem } from "fond/widgets";

import { MapContext } from "../../../map/MapProvider";

const useStyles = makeStyles(() => ({
  menuList: {
    padding: 0,
  },
}));

interface IProps {
  parentMenuOpen: boolean;
  handleClose: () => void;
}

const MapAppearance: React.FC<IProps> = ({ parentMenuOpen, handleClose }: IProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const project = useSelector((state: Store): Project => getCurrentProject(state.project));
  const { mapStyle: style, setMapStyle } = useContext(MapContext);
  const { loading } = useSelector((state: Store): View => getCurrentProjectView(state.project));
  const view = useSelector((state: Store): View => getCurrentProjectView(state.project));

  /**
   * Handles updating the map style
   */
  const handleUpdateMapStyle = (optionStyle: string) => {
    /**
     * Only try to update the style when the map is not loading. This is to prevent updating the style multiple times
     * before the map finishes loading, as it may end up hiding the project design content from the map.
     */
    if (!loading && optionStyle !== style) {
      dispatch(updateProjectView(project?.ID, { ...view, loading: true }));
      setMapStyle(optionStyle as MapStyle);
    }
    handleClose();
  };

  return (
    <NestedMenuItem
      data-testid="project-menu-map-appearance"
      label="Map Appearance"
      parentMenuOpen={parentMenuOpen}
      leftIcon={
        <ListItemIcon>
          <MapIcon />
        </ListItemIcon>
      }
      MenuProps={{
        open: true,
        MenuListProps: { dense: true },
      }}
      menuClasses={{ list: classes.menuList }}
    >
      {MAP_STYLES_OPTIONS.map((option) => (
        <MenuItem
          key={`map-style-${option}`}
          data-testid={`map-style-${option}`}
          onClick={() => {
            handleUpdateMapStyle(option);
          }}
          style={{ textTransform: "capitalize" }}
        >
          <ListItemIcon>{style === option && <Check />}</ListItemIcon>
          {option === MAP_STYLES.map ? "street" : option}
        </MenuItem>
      ))}
    </NestedMenuItem>
  );
};

export default MapAppearance;
