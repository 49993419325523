/* eslint-disable import/prefer-default-export */
/**
 * Attempts to get an item from localStorage.  If that fails
 * the default value is returned.
 */
export const getItem = (key: string, defaultValue?: any): any => {
  const storedValue = window.localStorage.getItem(key);
  if (storedValue) {
    try {
      return JSON.parse(storedValue);
    } catch (e) {
      return defaultValue;
    }
  }
  return defaultValue;
};

export const setItem = (key: string, value: any): void => {
  window.localStorage.setItem(key, JSON.stringify(value));
};
