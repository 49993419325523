import React from "react";
import { AgChartOptions, AgSeriesTooltip, AgSeriesTooltipRendererParams } from "ag-charts-community";
import { AgChartsReact } from "ag-charts-react";

import { formatCurrency } from "fond/utils/currency";

interface IProps {
  data: any[];
}

const seriesTooltip = (keys: string[]): AgSeriesTooltip<AgSeriesTooltipRendererParams> => ({
  renderer: (params) => {
    let value = { ...params.datum };
    keys.forEach((key) => {
      value = value[key];
    });

    return {
      title: params.title,
      content: `${params.datum.Phase}: ${formatCurrency(value, { notation: "compact" })}`,
    };
  },
});

const OverallPositionChart: React.FC<IProps> = ({ data }) => {
  const options: AgChartOptions = {
    data: data,
    series: [
      {
        type: "line",
        xKey: "Phase",
        yKey: "CumulativeNetPosition",
        yName: "Cumulative Net Position",
        tooltip: seriesTooltip(["CumulativeNetPosition"]),
      },
    ],
    height: 340,
    padding: { top: 36, bottom: 36, left: 42, right: 42 },
    axes: [
      {
        type: "category",
        position: "bottom",
        label: {
          fontSize: 10,
        },
      },
      {
        type: "number",
        position: "left",
        title: {
          text: "Cumulative net position",
          fontSize: 12,
        },
        label: {
          format: "~s",
          formatter: (params) => params.formatter!(params.value).replace("G", "B"),
        },
      },
    ],
  };

  return <AgChartsReact options={options} />;
};

export default OverallPositionChart;
