import * as React from "react";
import { Button } from "@mui/material";

interface IProps {
  /*
   * The button title.
   */
  title: string;
  /*
   * The button on click action.
   */
  action: () => void;
  /*
   * Whether this button is disabled.
   */
  disabled?: boolean;
}

const AttachmentStagingListItemAction: React.FC<IProps> = ({ title, action, disabled = false }: IProps) => {
  return (
    <Button
      data-testid={`attachment-staging-list-item-${title.toLowerCase()}`}
      size="small"
      color="primary"
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        action();
      }}
    >
      {title}
    </Button>
  );
};

AttachmentStagingListItemAction.displayName = "AttachmentStagingListItemAction";
export default AttachmentStagingListItemAction;
