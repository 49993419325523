import { useContext, useEffect, useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { Alert, AlertTitle, Box, Button, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Actions } from "flexlayout-react";

import { selectLayerFeatureTotals } from "fond/api";
import { widgets } from "fond/layout";
import { LayoutContext } from "fond/layout/LayoutProvider";
import { SolveError, Store, Widget } from "fond/types";

import SolveErrorModal from "./SolveErrorModal";

const customStyles = (theme: Theme) => {
  return createStyles({
    header: {
      fontWeight: 500,
    },
    alert: {
      padding: `0 ${theme.spacing(2)}`,
      "&:not(:only-child)": {
        "&:first-child": {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        "&:not(:first-child):not(:last-child)": {
          borderRadius: 0,
        },
        "&:last-child": {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      },
    },
    errorText: {
      fontSize: 12,
      maxWidth: 220,
      textDecoration: "underline",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      cursor: "pointer",
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /*
   * A list of error configurations for display.
   */
  errors: SolveError[];
}

const SolveErrors: React.FC<IProps> = ({ classes, errors }: IProps) => {
  const { model, layoutRef } = useContext(LayoutContext);
  const featureTotals = useSelector((state: Store) => selectLayerFeatureTotals(state, { versionId: state.project.versionId }));

  const [activeError, setActiveError] = useState<SolveError | null>(null);
  const [hasErrorFeatures, setHasErrorFeatures] = useState(false);

  useEffect(() => {
    if (featureTotals) {
      let errorFeatureFound = false;
      for (const [layerId] of Object.entries(featureTotals)) {
        const { count, length } = featureTotals[layerId];
        if (layerId.startsWith("error/") && (count || length)) {
          errorFeatureFound = true;
          break;
        }
      }
      setHasErrorFeatures(errorFeatureFound);
    }
  }, [featureTotals]);

  const inspectorDisabled = !hasErrorFeatures;

  const handleButtonClick = () => {
    if (model) {
      let errorLogWidget = model.getNodeById(Widget.ErrorLog);
      if (!errorLogWidget) {
        layoutRef.current.addTabToTabSet("border_bottom", widgets.errorLog);
        errorLogWidget = model.getNodeById(Widget.ErrorLog);
      }
      if (!errorLogWidget?.isVisible()) {
        model.doAction(Actions.selectTab(Widget.ErrorLog));
      }
    }
  };

  return (
    <>
      {errors.length > 0 && (
        <Box className="design-terminated" my={1}>
          <Alert className={classes.alert} severity="error">
            <AlertTitle>Errors</AlertTitle>
            {errors.map((error: SolveError) => (
              <React.Fragment key={error.id}>
                <Box className={classes.errorText} onClick={() => setActiveError(error)}>
                  {`${error.id} - ${error.label}`}
                </Box>
              </React.Fragment>
            ))}
            <Box my={1}>
              <Tooltip title={inspectorDisabled ? "These errors do not have any error features to inspect." : ""}>
                <Button size="small" color="secondary" variant="outlined" disabled={inspectorDisabled} onClick={handleButtonClick}>
                  Open feature inspector
                </Button>
              </Tooltip>
            </Box>
          </Alert>

          {activeError && <SolveErrorModal error={activeError} onClose={() => setActiveError(null)} />}
        </Box>
      )}
    </>
  );
};

SolveErrors.displayName = "SolveErrorAlert";
export default withStyles(customStyles)(SolveErrors);
