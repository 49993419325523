/* eslint-disable import/prefer-default-export */
export enum Widget {
  Comments = "comments",
  DesignPanel = "design",
  ErrorLog = "errorLog",
  FeaturesList = "featuresList",
  Legend = "legend",
  Map = "map",
  MapLayout = "mapLayout",
  Versions = "versions",
}

export enum LayoutNodeType {
  Border = "border",
  Row = "row",
  Tab = "tab",
  TabSet = "tabset",
}

export enum LayoutNodeLocation {
  Bottom = "bottom",
  Top = "top",
  Left = "left",
  Right = "right",
}

export enum LayoutDeleteLevel {
  /**
   * Used as a parameter in the layout delete query.
   * If Project, delete all layouts relate to the user, projectId is required when deleting
   * layouts in this level.
   * If General, delete only global layouts.
   * If All, delete global layouts and maps layouts of the specified project.
   */
  Project = "project",
  General = "general",
  All = "all",
}

export type LayoutNode = {
  /**
   * Attributes of a layout node, some of them are only related to a particular node type.
   */
  id: string;
  type: LayoutNodeType;
  active?: boolean;
  width?: number;
  weight?: number;
  selected?: number;
  order?: number;
  maximized?: boolean;
  config?: any;
  name?: string;
  size?: number;
  location?: LayoutNodeLocation;
  parentId?: string;
  children?: string[] | LayoutNode[];
};

export type LayoutNodeResponse = {
  /**
   * Attributes of a layout node in the response.
   */
  ID: string;
  Type: LayoutNodeType;
  Active?: boolean;
  Width?: number;
  Weight?: number;
  Selected?: number;
  Order?: number;
  Maximized?: boolean;
  Configs?: any;
  Name?: string;
  Size?: number;
  Location?: LayoutNodeLocation;
  Parent?: string;
  Children?: string[];
};

export const UserLayoutOrder = ["border_bottom", "border_left", "border_right", "row_main"];
