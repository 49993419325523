import React from "react";
import { Segment } from "@mui/icons-material";

import { NonIdealState } from "fond/widgets";

export const Empty: React.FC = () => {
  return (
    <NonIdealState
      title="Nothing to show..."
      description="Select an attribute & generate some preview classifications."
      icon={<Segment />}
      size="small"
    />
  );
};
