import { useRef, useState } from "react";
import * as React from "react";
import { Grid, MenuItemProps, Popover, Typography } from "@mui/material";

import { GridItem, ListItemIcon, LockIcon, MenuItem, MenuItemContainer } from "./navigation.styles";

interface IProps extends MenuItemProps {
  /**
   * Title text to be displayed for the Navigation item.
   */
  title: string;
  /**
   * Icon to be displayed (left aligned) for the Navigation item.
   */
  icon: React.ReactNode;
  /**
   * Message to show when the navigation item is disabled.
   */
  disabledInfo?: React.ReactNode;
  /**
   * The `testId` prop is a unique string that appears as a data attribute `data-testid` in the rendered code, serving as a hook for automated tests.
   */
  testId?: string;
}

const NavigationItem: React.FC<IProps> = ({ title, icon, selected, disabled, disabledInfo, testId, onClick }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (disabled) {
      setOpen(!open);
    } else if (onClick) {
      onClick(event);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MenuItem dense selected={selected} onClick={handleClick} isDisabled={disabled} data-testid={testId}>
      <MenuItemContainer ref={anchorRef}>
        <Grid container alignItems="center">
          <GridItem item xs={8}>
            <ListItemIcon isDisabled={disabled}>{icon}</ListItemIcon>
            <Typography variant="inherit">{title}</Typography>
          </GridItem>
          {disabled && (
            <GridItem item xs={4}>
              <LockIcon />
            </GridItem>
          )}
        </Grid>
        {disabledInfo && (
          <Popover
            open={open}
            anchorEl={anchorRef.current}
            onClose={handleClose}
            anchorOrigin={{
              vertical: -20,
              horizontal: 200,
            }}
          >
            {disabledInfo}
          </Popover>
        )}
      </MenuItemContainer>
    </MenuItem>
  );
};

export default NavigationItem;
