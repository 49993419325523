import * as React from "react";

import { useContents } from "fond/hooks/useContents";

import { BaseProjectList } from "./BaseProjectList";

/**
 * Starred content component
 */
const StarredContent: React.FC = () => {
  const { starredItems } = useContents();

  return <BaseProjectList items={starredItems} />;
};

export default StarredContent;
