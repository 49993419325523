import { createRef, RefObject, useEffect, useState } from "react";
import * as React from "react";
import { FileRejection } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, List, ListSubheader } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import _ from "lodash";
import { useSnackbar } from "notistack";

import { extname } from "fond/fileValidation";
import { getAttachmentsWhitelist } from "fond/redux/attachments";
import { AppThunkDispatch, Store } from "fond/types";
import { Dropzone, ListItemScrollNotification, Modal } from "fond/widgets";

import AttachmentStagingListItem from "./AttachmentStagingListItem";
import { ValidatedFile, validateFiles } from "./attachmentValidation";

const customStyles = (theme: Theme) => {
  return createStyles({
    container: {
      height: "100%",
    },
    filesList: {
      height: "100%",
      maxHeight: 450,
      overflowY: "auto",
    },
    rejectedFileModalContent: {
      overflow: "hidden",
    },
    rejectedFileList: {
      maxHeight: "50vh",
      overflowY: "auto",
      marginTop: 10,
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * Callback function that returns the staged files when the stage is ready for upload.
   */
  onAcceptedStage(file: File[]): void;
}

const AttachmentStaging: React.FC<IProps> = ({ classes, onAcceptedStage }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch: AppThunkDispatch = useDispatch();
  const { items, whitelist } = useSelector((state: Store) => state.attachments);

  /*
   * ListItemScrollNotification control.
   * Track references to the file list and the first file to fail validation (if exists).
   * These references are passed to the ListItemScrollNotification to point the invalid file when off screen.
   */
  const [invalidFileRef, setInvalidFileRef] = useState<RefObject<HTMLElement>>(createRef<HTMLElement>());
  const listRef = createRef<HTMLUListElement>();
  const onRefChange = (node: HTMLElement | null) => {
    if (invalidFileRef?.current !== node) {
      setInvalidFileRef({ ...invalidFileRef, current: node });
    }
  };

  /*
   * File validation control.
   */
  const [stagedFiles, setStagedFiles] = useState<File[]>([]);
  const [dropzoneReady, setDropzoneReady] = useState(false);
  const [allowOverwrites, setAllowedOverwrites] = useState<File[]>([]);
  const [validatedFiles, setValidatedFiles] = useState<ValidatedFile[]>([]);

  // Load the file whitelist and mark the dropzone as ready when complete.
  useEffect(() => {
    let isMounted = true;
    setDropzoneReady(false);
    dispatch(getAttachmentsWhitelist()).then(
      () => setDropzoneReady(true),
      () => enqueueSnackbar("Failed to load required data. Please cancel and try again.")
    );
    return () => {
      isMounted = false;
    };
  }, []);

  // Run validation when staged files change or an overwrite is allowed.
  useEffect(() => {
    const validationOutput = validateFiles(stagedFiles, items, allowOverwrites);
    setValidatedFiles(validationOutput.validatedFiles);
    onAcceptedStage(validationOutput.isValid ? stagedFiles : []);
  }, [allowOverwrites, items, onAcceptedStage, stagedFiles]);

  /*
   * Dropzone file drop handlers.
   */
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[] | null>([]);

  const handleOnDropAccepted = (files: File[]) => setStagedFiles([...stagedFiles, ...files]);
  const handleOnDropRejected = (files: FileRejection[]) => setRejectedFiles(files.length > 0 ? files : null);
  const renderRejectedFilesModal = (files: FileRejection[]) => {
    return (
      <Modal
        data-testid="attachment-staging-reject-drop-modal"
        open
        header="Some files could not be accepted"
        onClose={() => setRejectedFiles(null)}
        DialogContentProps={{ className: classes.rejectedFileModalContent }}
        content={
          <Box>
            <Box>The following files are of types that can not be accepted.</Box>
            <Box>These files may be uploaded within a zip file.</Box>
            <Box className={classNames(classes.rejectedFileList, "customScrollbars")}>
              <ul>
                {files.map(({ file }) => (
                  <li key={file.name}>{file.name}</li>
                ))}
              </ul>
            </Box>
          </Box>
        }
        actions={
          <Button data-testid="dropzone-reject-file-modal-close-button" color="primary" onClick={() => setRejectedFiles(null)}>
            Close
          </Button>
        }
      />
    );
  };

  /*
   * User controls.
   */
  const handleOnRemove = (file: File) => {
    setStagedFiles(stagedFiles.filter((stagedFile) => stagedFile !== file));
    setAllowedOverwrites(allowOverwrites.filter((allowedOverwrite) => allowedOverwrite !== file));
    enqueueSnackbar(`Removed file: ${file.name}`);
  };
  const handleOnRename = async (file: File, newBasename: string) => {
    if (!newBasename) {
      return Promise.reject(new Error("Cannot accept an empty filename."));
    }
    const name = `${newBasename}.${extname(file.name)}`;
    const renamedFile = new File([file], name, { type: file.type });
    setStagedFiles([...stagedFiles.filter((stagedFile) => stagedFile !== file), renamedFile]);
    enqueueSnackbar(`Renamed file: ${file.name} -> ${renamedFile.name}`);
    return Promise.resolve();
  };
  const handleOnOverwrite = (file: File) => {
    setAllowedOverwrites([...allowOverwrites, file]);
    enqueueSnackbar(`Allowed overwrite of ${file.name}`);
  };

  return (
    <div className={classes.container}>
      {rejectedFiles != null && rejectedFiles.length > 0 && renderRejectedFilesModal(rejectedFiles)}
      <Box height="100%" maxHeight={stagedFiles.length > 0 ? 200 : "100%"}>
        <Dropzone
          data-testid="attachment-staging-dropzone"
          disabled={!dropzoneReady}
          onDropAccepted={handleOnDropAccepted}
          onDropRejected={handleOnDropRejected}
          acceptedFiles={whitelist}
          title="Attachment Dropzone"
          message={dropzoneReady ? "Drag and drop files or click to open explorer." : "Preparing dropzone..."}
        />
      </Box>
      {stagedFiles.length > 0 && (
        <>
          <ListItemScrollNotification
            color="secondary"
            message="A file is invalid and requires changes."
            listRef={listRef}
            listItemRef={invalidFileRef}
          />
          <ListSubheader color="inherit">Pending Files</ListSubheader>
          <List ref={listRef} className={classNames(classes.filesList, "customScrollbars")}>
            {_.sortBy(validatedFiles, ({ file }: ValidatedFile) => file.name).map(
              ({ file, status, isFirstInvalid }: ValidatedFile, index: number) => {
                return (
                  <AttachmentStagingListItem
                    data-testid="attachment-staging-list-item"
                    key={`${file.name}-${index}`}
                    ref={(node) => (isFirstInvalid ? onRefChange(node) : null)}
                    file={file}
                    status={status}
                    onRemove={handleOnRemove}
                    onRename={handleOnRename}
                    onOverwrite={handleOnOverwrite}
                  />
                );
              }
            )}
          </List>
        </>
      )}
    </div>
  );
};

AttachmentStaging.displayName = "AttachmentStaging";
export default withStyles(customStyles)(AttachmentStaging);
