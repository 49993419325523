import * as Sentry from "@sentry/react";

import { request } from "fond/api";
import mixpanel from "fond/mixpanel";

/* eslint-disable import/prefer-default-export */
export function extractEmailAddresses(string: string): string[] {
  /**
   * Email addresses pasted from Excel are sometimes delimited by
   * tabs and sometimes by spaces. Also we support commas and semicolons.
   */
  return string.split(/[,;\n \t]/).filter((s) => s.length > 0);
}

/**
 * All possible status returned by the email validation API.
 *
 * CATCH_ALL: An account that captures emails sent to any invalid email addresses of the domain.
 * UNKNOWN: Unrecognized email addresses
 * SPAM_TRAP: Spam traps are email addresses used by Internet Service Providers (ISPs) and blocklist
 *            operators to identify senders who aren't following email best practices.
 * ABUSE: Abuse emails belong to users who have a habit of marking email they receive as "Spams".
 * DO_NOT_MAIL: The email users do not want direct mail sent to their addresses.
 *
 */
const enum EmailDeliverability {
  VALID = "valid",
  INVALID = "invalid",
  CATCH_ALL = "catch-all",
  UNKNOWN = "unknown",
  SPAM_TRAP = "spamtrap",
  ABUSE = "abuse",
  DO_NOT_MAIL = "do_not_mail",
}

/**
 * Only considering VALID as the valid status may be too strict, there are some customer emails, e.g. @irby.com falls into "CATCH_ALL"
 */
export const ValidDeliverabilityStatus = [EmailDeliverability.VALID, EmailDeliverability.CATCH_ALL, EmailDeliverability.UNKNOWN];

/**
 * Checks that an email address is deliverable via zero bounce api. https://www.zerobounce.net/docs/email-validation-api-quickstart
 * @param {string} email - an email address
 * @returns {boolean} true if the email is deliverable, false if not
 */
export async function isEmailDeliverable(email: string): Promise<{ email: string; deliverable: boolean }> {
  /**
   * Consider the email to be valid when:
   * 1. successful response with a valid status.
   * 2. error response which can be caused by invalid API key or out of credit.
   */

  return fetch(
    await request({
      method: "PUT",
      path: `/v1/validate-email`,
      data: { email: email },
    })
  )
    .then((response) => response.json())
    .then((json) => {
      return { email: email, deliverable: !json.status || ValidDeliverabilityStatus.includes(json.status) };
    })
    .catch((error) => {
      mixpanel.track("Fail to validate emails via zero bounce", { response: error.message });
      Sentry.captureException(new Error(error.message));
      return { email: email, deliverable: true };
    });
}
