import React, { useRef, useState } from "react";
import { Warning } from "@mui/icons-material";
import { Box, Popover, Typography } from "@mui/material";

const NoDataTooltip: React.FC = () => {
  const iconEl = useRef<SVGSVGElement | null>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Warning
        ref={iconEl}
        data-testid="no-data-icon"
        sx={{ color: "rgba(0, 0, 0, 0.38)", fontSize: 18, "&:hover": { color: "rgba(0, 0, 0, 0.56)" } }}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      />
      <Popover
        open={open}
        anchorEl={iconEl.current}
        sx={{ pointerEvents: "none" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        <Box bgcolor="rgba(1, 22, 31, 0.90)" py={0.5} px={1} color={(theme) => theme.palette.common.white}>
          <Typography fontSize={10}>At least one path layer is required to proceed:</Typography>
          <Box component="ul" paddingInlineStart={3}>
            <li>
              <Typography fontSize={10}>Underground path</Typography>
            </li>
            <li>
              <Typography fontSize={10}>Aerial span</Typography>
            </li>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default NoDataTooltip;
