import * as React from "react";
import { Field, FieldInputProps, FieldRenderProps } from "react-final-form";
import { FormControl, FormControlLabel, FormHelperText, Switch, SwitchProps as MuiSwitchProps } from "@mui/material";
import { FieldValidator } from "final-form";

export type SelectProps = Partial<Omit<MuiSwitchProps, "onChange">> & {
  name: string;
  label: string;
  "data-testid"?: string;
  fieldProps?: Partial<FieldInputProps<boolean, HTMLInputElement>>;
  validate?: FieldValidator<boolean>;
};

const SwitchField: React.FC<SelectProps> = (props: SelectProps) => {
  const { color, fieldProps, label, name, validate, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input, meta }) => <SwitchWrapper input={input} meta={meta} name={name} color={color} label={label} {...rest} />}
      validate={validate}
      {...fieldProps}
    />
  );
};

type SwitchWrapperProps = Partial<Omit<MuiSwitchProps, "onChange">> & FieldRenderProps<boolean, HTMLElement>;

const SwitchWrapper: React.FC<SwitchWrapperProps> = ({
  color,
  input: { name, onChange, value, checked, ...restInput },
  label,
  disabled,
  "data-testid": dataT,
  helperText,
  size,
}: SwitchWrapperProps) => {
  return (
    <FormControl data-testid={`${name}-switch-field`}>
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(value) || checked}
            aria-checked={Boolean(value) || checked}
            name={name}
            aria-label={name}
            onChange={onChange}
            disabled={disabled}
            color={color}
            size={size}
          />
        }
        label={label}
        data-testid={dataT}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SwitchField;
