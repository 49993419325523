import * as React from "react";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { Tooltip } from "@mui/material";

import { IconButton } from "fond/topBar/topbar.styles";
import { FollowType } from "fond/types/followTarget";

import { useFollows } from "./useFollows";

interface IProps {
  type: FollowType;
  id: string;
  name: string;
}

export const FollowButton: React.FC<IProps> = (props: IProps) => {
  const { isFollowing, setFollowing, isLoading } = useFollows(props);
  const tooltip = isFollowing ? "Remove from Watched" : "Add to Watched";

  if (isLoading) {
    return null;
  }

  return (
    <Tooltip title={tooltip}>
      <IconButton data-testid="follow-button" size="small" onClick={() => setFollowing(!isFollowing)}>
        {isFollowing ? <BookmarkIcon /> : <BookmarkBorderOutlinedIcon />}
      </IconButton>
    </Tooltip>
  );
};
