import * as React from "react";
import image1 from "svg_icons/style-editor/tour-dialog-1.png";
import image2 from "svg_icons/style-editor/tour-dialog-2.png";
import image3 from "svg_icons/style-editor/tour-dialog-3.png";
import image4 from "svg_icons/style-editor/tour-dialog-4.png";

import BasicTour from "./BasicTour";

import { BodyText } from "./BasicTour.styles";

type Slides = Array<{
  image: string;
  title: string;
  content: React.ReactNode;
}>;

const slides: Slides = [
  {
    image: image1,
    title: "Welcome to Style Editor!",
    content: (
      <>
        <BodyText component="p">We’ve revolutionised the way you edit layer styles and configure attribute features in FOND.</BodyText>
        <br />
        <br />
        <BodyText>Let’s start exploring!</BodyText>
      </>
    ),
  },
  {
    image: image2,
    title: "Start Styling",
    content: (
      <>
        <BodyText component="p">You can manage Group, Layer, Sublayer, and Style in which you can</BodyText>
        <br />
        <BodyText component="ul">
          <li>Change Icon</li>
          <li>Style across zoom range</li>
          <li>Add more layer style</li>
        </BodyText>
        <br />
        <BodyText component="p">and so much more...</BodyText>
      </>
    ),
  },
  {
    image: image3,
    title: "Configure Features",
    content: (
      <>
        <BodyText component="p">Set the attribute data type of your layers:</BodyText>
        <br />
        <BodyText component="ul">
          <li>Text</li>
          <li>Integer</li>
          <li>Decimal</li>
        </BodyText>
        <br />
        <BodyText component="p">and define the unit of measurement for length field.</BodyText>
      </>
    ),
  },
  {
    image: image4,
    title: "Publish Changes",
    content: (
      <>
        <BodyText component="p">
          Your modifications are saved when you exit. If you want to see the new styles on the main project page, just hit Publish.
        </BodyText>
        <br />
        <BodyText component="p">...and you're done!</BodyText>
      </>
    ),
  },
];

interface IProps {
  onComplete(): void;
}

const StyleEditorTourDialog: React.FC<IProps> = ({ onComplete }) => <BasicTour onComplete={onComplete} slides={slides} name="style" />;

export default StyleEditorTourDialog;
