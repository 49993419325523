import { AttachmentWhitelist } from "fond/types";

import { apiSlice } from "./apiSlice";

export interface GetWhitelistResponse {
  Items: AttachmentWhitelist[];
}

export const transformWhitelistResponse = (response: GetWhitelistResponse): Record<string, string[]> => {
  // Transform the attachments to the {Accept} type required by Dropzone
  const accepted = new Map<string, string[]>();
  const defaultMimeType = "application/unknown";
  accepted.set(defaultMimeType, []);
  response.Items.forEach(({ Extension, Type, Subtype }) => {
    const key = Type ? `${Type}/${Subtype || "*"}` : defaultMimeType;
    const newValue = Extension ? [`.${Extension}`] : [];
    const currentValue = accepted.get(key) || [];
    accepted.set(key, [...currentValue, ...newValue]);
  });

  // Remove the default mime type if empty
  if (accepted.get("application/unknown")?.length === 0) {
    accepted.delete("application/unknown");
  }

  return Object.fromEntries(accepted.entries());
};

/**
 * Attributes API Slice
 */
export const attachmentsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAttachmentWhitelist: build.query<Record<string, string[]>, void>({
      query: () => `/v1/attachments/whitelist`,
      transformResponse: transformWhitelistResponse,
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useGetAttachmentWhitelistQuery } = attachmentsSlice;
