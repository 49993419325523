import { useEffect } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { usePrevious } from "react-use";

import { apiSlice, useGetVersionQuery, useGetVersionStatusQuery } from "fond/api";
import { AsyncOperationState } from "fond/async/redux";
import mixpanel from "fond/mixpanel";
import { getCurrentProject } from "fond/project";
import { getWorkflow, StatusTypes } from "fond/project/redux";
import { Project, Store, WorkflowStep } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { ErrorModal } from "fond/widgets";

import SolveErrors from "./errors/SolveErrors";
import { cancel, cancelDismissError, load, run, unload } from "./redux";
import SolveControl from "./SolveControl";
import SolveEditing from "./SolveEditing";
import SolveProgress from "./SolveProgress";
import SolveStatus from "./SolveStatus";

interface IProps {
  readOnly?: boolean;
}

const SolvePanel: React.FC<IProps> = ({ readOnly = false }: IProps) => {
  const dispatch = useAppDispatch();
  const project = useSelector((state: Store): Project => getCurrentProject(state.project));
  const versionId = useSelector((state: Store) => state.project.versionId);
  const { data: versionStatus } = useGetVersionStatusQuery(versionId, { skip: !versionId });
  const { data: version } = useGetVersionQuery(versionId, { skip: !versionId });

  const { runStatus, cancelStatus, updateDataStatus } = useSelector((state: Store) => state.solve);
  const status = updateDataStatus === AsyncOperationState.failure ? null : versionStatus?.Status;
  const prevStatus = usePrevious(status);

  useEffect(() => {
    // When a design transitions to complete (from another known status)
    // Update the layers & subscription information
    if (prevStatus && prevStatus !== status && status === "Complete") {
      dispatch(
        apiSlice.util.invalidateTags([
          { type: "Layers", id: versionId },
          { type: "Subscription", id: "LIST" },
        ])
      );
    }
  }, [dispatch, status, versionId]);

  useEffect(() => {
    dispatch(load());
    return () => {
      dispatch(unload());
    };
  }, [dispatch, versionId]);

  useEffect(() => {
    const jobIds = getWorkflow(versionStatus)?.map((w: WorkflowStep) => ({ ID: w.ID, JobID: w.JobID }));

    const projectStatusToEventName = {
      [StatusTypes.Complete]: "Solve completed",
      [StatusTypes.Cancelled]: "Solve cancelled",
      [StatusTypes.Terminated]: "Solve error",
    };
    if (versionStatus?.Status) mixpanel.track(projectStatusToEventName[versionStatus.Status], { jobIds });
  }, [project, versionStatus?.Status]);

  const onCancelClick = () => {
    mixpanel.track("Clicked cancel generate design");
    dispatch(cancel(versionId));
  };

  const hasEditedEquipment =
    ((versionStatus?.InputFiles?.cabinet || false) && (versionStatus.InputFiles?.closure || false)) ||
    ((versionStatus?.InputFiles?.t1_hub_geometry || false) && (versionStatus.InputFiles?.t2_hub_geometry || false));

  const showProgress = status && [StatusTypes.Running, StatusTypes.Cancelling, StatusTypes.Terminated].includes(status);
  const showHubEditing =
    versionStatus?.HasSolution && status && [StatusTypes.Complete, StatusTypes.Terminated].includes(status) && !version?.Architecture?.IsFlexNap;

  return (
    <>
      <SolveStatus status={status} readOnly={readOnly} cancelStatus={cancelStatus} onCancel={onCancelClick} />
      <SolveErrors errors={versionStatus?.Error ?? []} />
      {showProgress && <SolveProgress workflow={getWorkflow(versionStatus)} />}
      {showHubEditing && <SolveEditing hasEditedEquipment={hasEditedEquipment} />}

      <SolveControl readOnly={readOnly} status={status} initialQuality={versionStatus?.WorkflowQuality || "express"} />

      {runStatus === AsyncOperationState.failure && <ErrorModal onClose={() => dispatch(run.dismissError())} />}
      {cancelStatus === AsyncOperationState.failure && <ErrorModal onClose={() => dispatch(cancelDismissError())} />}
    </>
  );
};

SolvePanel.displayName = "SolvePanel";
export default SolvePanel;
