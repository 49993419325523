import * as React from "react";
import { Chat } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";

interface IProps {
  handleClose: () => void;
}

const Support: React.FC<IProps> = ({ handleClose }: IProps) => {
  return (
    <MenuItem
      data-testid="support-button"
      component="a"
      href="mailto:fondhelp@biarrinetworks.com"
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClose}
    >
      <ListItemIcon>
        <Chat />
      </ListItemIcon>
      Support Team
    </MenuItem>
  );
};

export default Support;
