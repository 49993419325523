import { useCallback } from "react";
import * as React from "react";
import { Circle, Clear, Fullscreen, Height, Help, Map, Remove } from "@mui/icons-material";
import { Box, InputAdornment, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as Bevel } from "svg_icons/bevel.svg";
import { ReactComponent as CapButt } from "svg_icons/cap-butt.svg";
import { ReactComponent as CapRound } from "svg_icons/cap-round.svg";
import { ReactComponent as CapSquare } from "svg_icons/cap-square.svg";
import { ReactComponent as Lowercase } from "svg_icons/lowercase.svg";
import { ReactComponent as Miter } from "svg_icons/miter.svg";
import { ReactComponent as Round } from "svg_icons/round.svg";
import { ReactComponent as Defaultcase } from "svg_icons/text.svg";
import { ReactComponent as Uppercase } from "svg_icons/uppercase.svg";

import { selectLayerConfigByChildId, useGetIconsQuery } from "fond/api";
import {
  ButtonGroupField,
  ColorField,
  FontField,
  IconField,
  NumArrayField,
  SelectField,
  SliderField,
  SwitchField,
  TextField,
} from "fond/form/fields";
import { TextPartsField } from "fond/styleEditor";
import { BaseIcon, StyleFieldName } from "fond/types";
import { useAppSelector } from "fond/utils/hooks";
import { compose, maximum, minimum, requireAllOrNone } from "fond/utils/validation";
import { SvgIcon } from "fond/widgets";

import { textAnchors } from "../config/anchors";
import { fontIcons, mapboxFonts } from "../config/fonts";
import { FieldNumArray } from "../FieldNumArray";
import { MinMaxZoomField } from "../MinMaxZoomField";

interface IProps {
  defaultValue: any;
  fieldName: StyleFieldName;
  formFieldName: string;
  fieldSpec?: any;
}

export const useCustomStyles = makeStyles((theme: Theme) => ({
  textField: {
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
  },
  textFieldRoot: {
    maxWidth: 170,
    backgroundColor: theme.palette.common.white,
    margin: 0,
    "& .MuiOutlinedInput-root": {
      height: 28,
      paddingRight: 0,
    },
    "& .MuiOutlinedInput-input": {
      fontSize: 12,
      padding: theme.spacing(0.5),
      color: theme.palette.text.primary,
    },
    "& .MuiInputAdornment-root p": {
      fontSize: 12,
    },
  },
  selectField: {
    fontSize: 12,
    maxWidth: 170,
    "& .MuiOutlinedInput-input": {
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: "28px",
    },
  },
  selectFieldWithIcon: {
    fontSize: 12,
    width: "100%",
    maxWidth: 170,
    background: theme.palette.common.white,
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: "28px",
    },
    "& .MuiSelect-select > svg": {
      height: 16,
      minWidth: 20,
      fill: theme.palette.primary.main,
      marginRight: theme.spacing(1),
    },
  },
  numArrayRoot: {
    flexDirection: "row",
    gap: theme.spacing(1),
  },
}));

const FieldFactory: React.FC<IProps> = ({ fieldName, formFieldName, fieldSpec, defaultValue }: IProps) => {
  const classes = useCustomStyles();
  const { data: icons } = useGetIconsQuery(undefined);
  const layer = useAppSelector((state) => selectLayerConfigByChildId(state, state.styles.settings.selectedId));
  const layerAttributes = layer?.Type === "LAYER" && layer.Attributes?.map((attr) => ({ name: attr.Name, type: attr.Type }));

  const getField = useCallback(() => {
    switch (fieldName) {
      case "FontIconCode": {
        return (
          <FontField
            key={fieldName}
            name={formFieldName}
            fieldProps={{
              parse: (val: any) => {
                return val.code;
              },
              format: (val: any) => (val ? fontIcons.glyphs.find((font) => font.code === val) : val),
            }}
          />
        );
      }
      case "IconImageID": {
        return (
          <IconField
            key={fieldName}
            name={formFieldName}
            icons={icons}
            fieldProps={{
              parse: (val: BaseIcon) => val?.ID,
              format: (val: string) => icons?.find((icon) => icon.ID === val),
            }}
          />
        );
      }
      case "FontIconSymbolPlacement":
      case "SymbolPlacement": {
        return (
          <ButtonGroupField
            key={fieldName}
            name={formFieldName}
            options={[
              {
                value: "point",
                icon: <Circle sx={{ width: 16 }} />,
                tooltipTitle: "Point",
                tooltipBody: "The label is placed at the point where the geometry is located.",
              },
              {
                value: "line",
                icon: <Remove />,
                tooltipTitle: "Line",
                tooltipBody: "The label is placed along the line of the geometry. Can only be used on LineString and Polygon geometries.",
              },
              {
                value: "line-center",
                icon: <SvgIcon icon="lineCenter" />,
                tooltipTitle: "Line-center",
                tooltipBody:
                  "The label is placed at the center of the line of the geometry. Can only be used on LineString and Polygon geometries. Note that a single feature in a vector tile may contain multiple line geometries.",
              },
            ]}
          />
        );
      }
      case "TextTransform": {
        return (
          <ButtonGroupField
            key={fieldName}
            name={formFieldName}
            options={[
              {
                value: "none",
                icon: <Defaultcase />,
                tooltipTitle: "None",
                tooltipBody: "The text is not altered.",
              },
              {
                value: "uppercase",
                icon: <Uppercase />,
                tooltipTitle: "Uppercase",
                tooltipBody: "Forces all letters to be displayed in uppercase.",
              },
              {
                value: "lowercase",
                icon: <Lowercase />,
                tooltipTitle: "Lowercase",
                tooltipBody: "Forces all letters to be displayed in lowercase.",
              },
            ]}
          />
        );
      }
      case "LineCap": {
        return (
          <ButtonGroupField
            key={fieldName}
            name={formFieldName}
            options={[
              {
                value: "butt",
                icon: <CapButt />,
                tooltipTitle: "Butt",
                tooltipBody: "A cap with a squared-off end which is drawn to the exact endpoint of the line.",
              },
              {
                value: "round",
                icon: <CapRound />,
                tooltipTitle: "Round",
                tooltipBody:
                  "A cap with a rounded end which is drawn beyond the endpoint of the line at a radius of one-half of the line's width and centered on the endpoint of the line.",
              },
              {
                value: "square",
                icon: <CapSquare />,
                tooltipTitle: "Square",
                tooltipBody:
                  "A cap with a squared-off end which is drawn beyond the endpoint of the line at a distance of one-half of the line's width.",
              },
            ]}
          />
        );
      }
      case "LineJoin": {
        return (
          <ButtonGroupField
            key={fieldName}
            name={formFieldName}
            options={[
              {
                value: "bevel",
                icon: <Bevel />,
                tooltipTitle: "Bevel",
                tooltipBody:
                  "A join with a squared-off end which is drawn beyond the endpoint of the line at a distance of one-half of the line's width.",
              },
              {
                value: "round",
                icon: <Round />,
                tooltipTitle: "Round",
                tooltipBody:
                  "A join with a rounded end which is drawn beyond the endpoint of the line at a radius of one-half of the line's width and centered on the endpoint of the line.",
              },
              {
                value: "miter",
                icon: <Miter />,
                tooltipTitle: "Miter",
                tooltipBody:
                  "A join with a sharp, angled corner which is drawn with the outer sides beyond the endpoint of the path until they meet.",
              },
            ]}
          />
        );
      }
      case "FontIconRotate":
      case "FontIconSize":
      case "FontIconHaloWidth":
      case "LineGapWidth":
      case "LineOffset":
      case "LineWidth":
      case "IconRotate":
      case "CircleRadius":
      case "CircleStrokeWidth":
      case "SymbolSpacing":
      case "TextPadding":
      case "TextSize":
      case "TextHaloBlur":
      case "TextHaloWidth":
      case "TextLineHeight":
      case "TextMaxWidth":
      case "TextLetterSpacing":
      case "TextRotate": {
        return (
          <TextField
            key={formFieldName}
            name={formFieldName}
            autoFocus
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="start">{fieldSpec?.units}</InputAdornment>,
            }}
            inputProps={{
              min: fieldSpec?.minimum,
              max: fieldSpec?.maximum,
            }}
            validate={compose(minimum(fieldSpec?.minimum), maximum(fieldSpec?.maximum))}
            className={classes.textField}
            classes={{
              root: classes.textFieldRoot,
            }}
            placeholder={defaultValue}
            fieldProps={{
              parse: (val) => (!val ? null : parseFloat(val)),
            }}
          />
        );
      }
      case "IconSize": {
        return (
          <TextField
            key={formFieldName}
            name={formFieldName}
            type="number"
            classes={{
              root: classes.textFieldRoot,
            }}
            placeholder={defaultValue}
            fieldProps={{
              parse: (val) => (!val ? null : parseFloat(val)),
            }}
          />
        );
      }
      case "TextFields": {
        return <TextPartsField key={formFieldName} name={formFieldName} attributes={layerAttributes || []} />;
      }
      case "CircleColor":
      case "CircleStrokeColor":
      case "FillColor":
      case "FillOutlineColor":
      case "FontIconColor":
      case "FontIconHaloColor":
      case "LineColor":
      case "TextColor":
      case "TextHaloColor": {
        return (
          <Box sx={{ maxWidth: 220 }}>
            <ColorField key={formFieldName} name={formFieldName} />
          </Box>
        );
      }
      case "CircleOpacity":
      case "CircleStrokeOpacity":
      case "IconOpacity":
      case "FillOpacity":
      case "FontIconOpacity":
      case "LineOpacity":
      case "TextOpacity": {
        return (
          <SliderField
            key={formFieldName}
            name={formFieldName}
            size="small"
            min={0}
            max={1}
            step={0.01}
            sx={{ width: 180, marginLeft: 1, marginRight: 1, marginTop: 3 }}
            track={false}
            marks={[
              {
                value: 0,
                label: "0%",
              },
              {
                value: 1,
                label: "100%",
              },
            ]}
            valueLabelDisplay="on"
            valueLabelFormat={(val: number) => `${Math.round(val * 100)}%`}
            fieldProps={{
              format: (val: number) => (val !== null ? val : 1),
            }}
          />
        );
      }
      case "FontIconAllowOverlap":
      case "FontIconKeepUpright":
      case "FontIconIgnorePlacement":
      case "IconAllowOverlap":
      case "IconIgnorePlacement":
      case "IconKeepUpright":
      case "TextIgnorePlacement":
      case "TextAllowOverlap": {
        return (
          <Box sx={{ ml: 1 }}>
            <SwitchField key={formFieldName} name={formFieldName} label="" size="small" />
          </Box>
        );
      }
      case "IconTextFit": {
        return (
          <ButtonGroupField
            key={formFieldName}
            name={formFieldName}
            options={[
              {
                value: "none",
                icon: <Clear />,
                tooltipTitle: "None",
                tooltipBody: "The icon is displayed at its intrinsic aspect ratio.",
              },
              {
                value: "width",
                icon: <Height sx={{ transform: "rotate(90deg)" }} />,
                tooltipTitle: "Width",
                tooltipBody: "The icon is scaled in the x-dimension to fit the width of the text.",
              },
              {
                value: "height",
                icon: <Height />,
                tooltipTitle: "Height",
                tooltipBody: "The icon is scaled in the y-dimension to fit the height of the text.",
              },
              {
                value: "both",
                icon: <Fullscreen />,
                tooltipTitle: "Both",
                tooltipBody: "The icon is scaled in both x- and y-dimensions.",
              },
            ]}
          />
        );
      }
      case "FontIconRotationAlignment":
      case "IconRotationAlignment": {
        return (
          <ButtonGroupField
            key={formFieldName}
            name={formFieldName}
            options={[
              {
                value: "map",
                icon: <Map />,
                tooltipTitle: "Map",
                tooltipBody:
                  "When symbol-placement is set to point, aligns icons east-west. When symbol-placement is set to line or line-center, aligns icon x-axes with the line.",
              },
              {
                value: "viewport",
                icon: <Fullscreen />,
                tooltipTitle: "Viewport",
                tooltipBody: "Produces icons whose x-axes are aligned with the x-axis of the viewport, regardless of the value of symbol-placement.",
              },
              {
                value: "auto",
                icon: <Help />,
                tooltipTitle: "Auto",
                tooltipBody:
                  "When symbol-placement is set to point, this is equivalent to viewport. When symbol-placement is set to line or line-center, this is equivalent to map.",
              },
            ]}
          />
        );
      }
      case "IconTextFitPadding": {
        return (
          <NumArrayField
            key={formFieldName}
            name={formFieldName}
            length={4}
            defaultValue={[0, 0, 0, 0]}
            fullWidth={false}
            className={classes.numArrayRoot}
            classes={{
              root: classes.textFieldRoot,
            }}
            inputProps={{
              sx: { width: 40 },
            }}
            validate={requireAllOrNone(4)}
            hideError
          />
        );
      }
      case "LineDasharray": {
        return (
          <FieldNumArray
            key={formFieldName}
            name={formFieldName}
            inputProps={{
              min: fieldSpec?.minimum,
              max: fieldSpec?.maximum,
            }}
          />
        );
      }
      case "FontIconOffset":
      case "IconOffset":
      case "TextOffset":
      case "CircleTranslate": {
        return (
          <NumArrayField
            key={formFieldName}
            name={formFieldName}
            length={2}
            defaultValue={[0, 0]}
            fullWidth={false}
            className={classes.numArrayRoot}
            classes={{
              root: classes.textFieldRoot,
            }}
            inputProps={{
              sx: { width: 40 },
            }}
          />
        );
      }
      case "TextFont": {
        return (
          <SelectField
            name={formFieldName}
            className={classes.selectField}
            options={mapboxFonts}
            fieldProps={{
              parse: (val: string) => (val ? [val] : null),
              format: (val: string[] | null) => (val && val.length > 0 ? val[0] : null),
            }}
            displayEmpty
            placeholder={defaultValue[0]}
          />
        );
      }
      case "TextAnchor": {
        return <SelectField name={formFieldName} className={classes.selectField} options={textAnchors} displayEmpty placeholder="Center" />;
      }
      case "MaxZoom":
      case "MinZoom": {
        return <MinMaxZoomField fieldSpec={fieldSpec} name={formFieldName} defaultValue={defaultValue} />;
      }
      default:
        return null;
    }
  }, [formFieldName]);

  return getField();
};

export default FieldFactory;
