import { IServerSideGetRowsRequest } from "@ag-grid-community/core";
import { createEntityAdapter, createSelector, EntityState } from "@reduxjs/toolkit";
import { omit } from "lodash";

import { commentLayers } from "fond/layers";
import { getCurrentProjectData } from "fond/project";
import { Attribute, Configuration, DetailedRequest, Layer, LayerClassification, Store } from "fond/types";
import { LayerConfig, LayerConfigHydrated, LayerStyle, SublayerConfig, SublayerConfigHydrated } from "fond/types/ProjectLayerConfig";
import { filterFeatureCollection } from "fond/utils";

import { apiSlice } from "./apiSlice";
import { draftConfigEntityAdapter, draftSlice, transformStyleToLayerStyle } from "./draftSlice";
import {
  buildSublayerFilterConfigurationByParentId,
  selectVersionConfig,
  transformLayerConfigResponse,
  transformSublayerResponse,
} from "./versionsSlice";

export interface GetLayersResponse {
  Items: Layer[];
}

export interface GetLayerClassificationResponse {
  Classifications: Array<Pick<LayerClassification, "FilterConfiguration" | "Label">>;
}
interface GetVersionFeatureTotalsRawResponse {
  Totals: { [key: string]: { FeatureCount: number | null; LengthMetres: number | null } };
}

interface LayerPropertiesSchema {
  [key: string]: Attribute[];
}

export const layersAdapter = createEntityAdapter<Layer>({
  selectId: (entity): string => entity.ID,
});
const layersInitialState = layersAdapter.getInitialState();

/**
 * Layers API Slice
 */

export type LayerCreateRequest = {
  // UUID of the target version.
  VersionID: string;
  /**
   * An arbitrary string that must be unique across all layers in a version.
   * It's recommended that it is set to the file basename where possible.
   */
  Key: string;
};

export type LayerImportRequest = {
  /**
   * The InputUnits will set the unit system on all length fields, overriding any attribute configurations.
   * Intended for legacy support only.
   */
  InputUnits?: "metric" | "imperial";
};

export const layersSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createLayer: build.mutation({
      query: (data: LayerCreateRequest) => ({
        url: "/v2/layers",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Layer", id: "LIST" }],
    }),
    deleteLayer: build.mutation<undefined, string>({
      query: (layerId) => ({
        url: `/v2/layers/${layerId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Layer", id: arg }],
    }),
    /**
     * TODO: DEVEX-7369
     * No longer invalidate the tags once the Service API is returning an asynchronous response.
     * The Service API currently returns a synchronous response, responding with the layer entity once import has completed.
     */
    importLayer: build.mutation({
      query: ({ id, ...data }) => ({
        url: `/v2/layers/${id}/import`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Layer", id: "LIST" }],
    }),
    getLayerFeatureTotals: build.query({
      query: ({ versionId, layerId }) => ({
        url: `/v2/versions/${versionId}/feature-totals`,
        params: layerId ? { layer_id: layerId } : undefined,
      }),
      providesTags: (result, error, { versionId }) => (result ? [{ type: "FeatureTotals", id: versionId }] : []),
      // Convert for internal backwards compatibility
      transformResponse: (response: GetVersionFeatureTotalsRawResponse) =>
        Object.fromEntries(
          Object.entries(response.Totals).map(([key, totals]) => [key, { count: totals.FeatureCount, length: totals.LengthMetres }])
        ),
    }),
    getLayer: build.query<Layer, string>({
      query: (layerId: string) => `/v2/layers/${layerId}`,
      providesTags: (result) => (result ? [{ type: "Layer", id: result.ID }] : []),
    }),
    getLayers: build.query<EntityState<Layer>, string>({
      query: (versionId: string | null) => {
        if (!versionId) {
          throw new Error("Cannot query layers without a version id. Did you mean to declare a skip?");
        }
        return `/v2/layers?version_id=${versionId}`;
      },
      providesTags: (result, error, versionId) => (result ? [{ type: "Layers", id: versionId }] : []),
      transformResponse: (response: GetLayersResponse) => {
        const layers: Layer[] = response.Items.map((layer) => ({ ...layer, Type: "Layer" }));
        // We inject the commentLayers into the collection
        return layersAdapter.setAll(layersInitialState, [...layers, ...commentLayers]);
      },
    }),
    getLayerClassification: build.query<
      GetLayerClassificationResponse,
      { layerConfigId: string; attributeConfigId: string; method?: string; groups?: number }
    >({
      query: ({ layerConfigId, attributeConfigId, method, groups }) => ({
        url: `/v2/layer-configurations/${layerConfigId}/classification`,
        params: { attribute_configuration_id: attributeConfigId, method: method, group_count: groups },
      }),
    }),
    getLayerFeatures: build.mutation<
      DetailedRequest,
      { versionId: string; layerIDs: string[]; sublayerIDs: string[]; search: string } & IServerSideGetRowsRequest
    >({
      query: ({ versionId, ...params }) => ({
        url: `/v1/versions/${versionId}/feature-query`,
        method: "POST",
        body: params,
      }),
    }),
    updateLayerConfig: build.mutation<LayerConfigHydrated, { mapLayerConfigId: string; layerConfig: LayerConfigHydrated }>({
      query: ({ layerConfig }) => ({
        url: `/v2/layer-configurations/${layerConfig.ID}`,
        method: "PUT",
        body: layerConfig,
      }),
      onQueryStarted: async ({ mapLayerConfigId, layerConfig }, { dispatch, queryFulfilled }) => {
        const { data: newLayerConfig } = await queryFulfilled;
        const patchResult = dispatch(
          draftSlice.util.updateQueryData("getDraft", mapLayerConfigId, (draft: Configuration) => {
            if (draft.Data) {
              const upserts: Array<LayerConfig | SublayerConfig | LayerStyle> = [];

              // The new layer Config to be upserted
              upserts.push(transformLayerConfigResponse(newLayerConfig));

              // updateLayerConfig can be used to bulk create & delete sublayers & styles (e.g. during layer classification).
              newLayerConfig.Children.forEach((sublayer) => {
                // Upsert any new Children and Styles.
                upserts.push(transformSublayerResponse(sublayer, Object.values(draft.Data.entities)));
                upserts.push(...sublayer.Styles.map((style) => transformStyleToLayerStyle(style)));
              });

              // Remove any outdated Child and Styles
              const newChildrenIds = new Set(newLayerConfig.Children.map(({ ID }) => ID));
              const currentLayerConfig = draft.Data.entities[layerConfig.ID] as LayerConfig;
              currentLayerConfig.Children.forEach((childId) => {
                if (!newChildrenIds.has(childId)) {
                  const styleIds = (draft.Data.entities[childId] as SublayerConfig).Styles;
                  draftConfigEntityAdapter.removeMany(draft.Data, [childId, ...styleIds]);
                }
              });

              // Finally apply the upserts
              draftConfigEntityAdapter.upsertMany(draft.Data, upserts);

              updateOtherSublayer(draft.Data, newLayerConfig.ID);
            }
          })
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
    createSublayerConfig: build.mutation<SublayerConfig, { mapLayerConfigId: string; newSublayerConfig: SublayerConfig }>({
      query: ({ newSublayerConfig }) => ({
        url: "/v2/sublayer-configurations",
        method: "POST",
        body: omit(newSublayerConfig, "Key", "GeometryType"),
      }),
      onQueryStarted: async ({ mapLayerConfigId, newSublayerConfig: { Key, GeometryType } }, { dispatch, queryFulfilled }) => {
        const { data: newSublayerConfig } = await queryFulfilled;
        const patchResult = dispatch(
          draftSlice.util.updateQueryData("getDraft", mapLayerConfigId, (draft: Configuration) => {
            if (draft.Data) {
              // Add the new sublayer
              // Insert Key and GeometryType from the request params the same way we transform sublayers when fetching them
              draftConfigEntityAdapter.upsertOne(draft.Data, { ...newSublayerConfig, Key, GeometryType });

              // Add the sublayer to the parent layers Children collection
              const parent = { ...draft.Data.entities[newSublayerConfig.ParentID] } as LayerConfig;
              if (parent) {
                draftConfigEntityAdapter.upsertOne(draft.Data, {
                  ...parent,
                  Children: [...parent.Children, newSublayerConfig.ID],
                });
              }

              updateOtherSublayer(draft.Data, newSublayerConfig.ParentID);
            }
          })
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
    updateSublayerConfig: build.mutation<SublayerConfigHydrated, { mapLayerConfigId: string; sublayer: SublayerConfigHydrated }>({
      query: ({ sublayer }) => ({
        url: `/v2/sublayer-configurations/${sublayer.ID}`,
        method: "PUT",
        body: omit(sublayer, "GeometryType", "Key"),
      }),
      onQueryStarted: async ({ mapLayerConfigId, sublayer }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          draftSlice.util.updateQueryData("getDraft", mapLayerConfigId, (draft: Configuration) => {
            if (draft.Data) {
              draftConfigEntityAdapter.upsertOne(draft.Data, transformSublayerResponse(sublayer, Object.values(draft.Data.entities)));

              updateOtherSublayer(draft.Data, sublayer.ParentID);
            }
          })
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
    deleteSublayerConfig: build.mutation<any, { mapLayerConfigId: string; sublayer: SublayerConfig }>({
      query: ({ sublayer }) => ({
        url: `/v2/sublayer-configurations/${sublayer.ID}`,
        method: "DELETE",
      }),
      onQueryStarted: async ({ mapLayerConfigId, sublayer }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          draftSlice.util.updateQueryData("getDraft", mapLayerConfigId, (draft: Configuration) => {
            if (draft.Data) {
              // Delete the sublayer
              draftConfigEntityAdapter.removeOne(draft.Data, sublayer.ID);

              // Remove the sublayer from the parent layers Children collection
              const parent = { ...draft.Data.entities[sublayer.ParentID] } as LayerConfig;
              if (parent) {
                draftConfigEntityAdapter.upsertOne(draft.Data, {
                  ...parent,
                  Children: [...parent.Children.filter((id: string) => id !== sublayer.ID)],
                });
              }

              updateOtherSublayer(draft.Data, sublayer.ParentID);
            }
          })
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const {
  useGetLayerQuery,
  useGetLayerClassificationQuery,
  useLazyGetLayerClassificationQuery,
  useGetLayersQuery,
  useDeleteLayerMutation,
  useCreateLayerMutation,
  useImportLayerMutation,
  useGetLayerFeatureTotalsQuery,
  useLazyGetLayerFeatureTotalsQuery,
  useGetLayerFeaturesMutation,
  useUpdateLayerConfigMutation,
  useCreateSublayerConfigMutation,
  useUpdateSublayerConfigMutation,
  useDeleteSublayerConfigMutation,
} = layersSlice;

/**
 * Selectors
 */

export const selectLayersByVersionId = createSelector(
  [(state) => state, (state: Store, versionId: string) => versionId],
  (state: Store, versionId) => {
    const data = createSelector(layersSlice.endpoints.getLayers.select(versionId), (layersResult) => layersResult.data)(state);
    return data?.ids.map((id) => data.entities[id] as Layer);
  }
);

export const selectLayerPropertiesSchema = createSelector(
  [(state) => state, (_: Store, versionId: string) => versionId],
  (state: Store, versionId) => {
    const layers = selectLayersByVersionId(state, versionId);

    const propertiesSchema: LayerPropertiesSchema = layers
      ? layers.reduce<LayerPropertiesSchema>((prev, layer) => ({ ...prev, [layer.LayerKey]: layer.Attributes }), {})
      : {};
    return propertiesSchema;
  }
);

export const selectLayerFeatureTotals = createSelector(
  [
    (state) => state,
    (state: Store, { versionId, layerId }: { versionId: string; layerId?: string }) =>
      layersSlice.endpoints.getLayerFeatureTotals.select({ versionId, layerId }),
  ],
  (state: Store, layerFeatureTotals) => layerFeatureTotals(state)?.data
);

/**
 * Generates an object containing the Feature Totals for Cost to Serve sublayers
 * based on the sublayer mapbox filter.
 */
export const selectLayerFeatureTotalsForCostToServer = createSelector(
  selectVersionConfig,
  (state: Store) => getCurrentProjectData(state.project)?.layers,
  (config, layers) => (layerConfig: LayerConfig) => {
    const features = layers[layerConfig.Key]?.features || [];
    const totals: { [key: string]: { count: number; length: null } } = {};

    layerConfig.Children.forEach((childId) => {
      const child = config.Data.entities[childId] as SublayerConfig;
      const filteredFeatures = filterFeatureCollection({ features: features }, child.FilterConfiguration?.Mapbox);

      totals[childId] = {
        count: filteredFeatures.length,
        length: null,
      };
    });

    return totals;
  }
);

export const selectLayerByVersionAndLayerKey = createSelector(
  [(state) => state, (state: Store, args: { versionId: string; layerId: string }) => args],
  (state: Store, { versionId, layerId }) => {
    const layers: Layer[] | undefined = selectLayersByVersionId(state, versionId);
    return layers?.find((layer) => layer.LayerKey === layerId);
  }
);

export const selectLayerByVersionAndConfigId = createSelector(
  [(state) => state, (state: Store, args: { versionId: string; configId: string }) => args],
  (state: Store, { versionId, configId }) => {
    const layers: Layer[] | undefined = selectLayersByVersionId(state, versionId);
    return layers?.find((layer) => layer.Configuration?.ID === configId);
  }
);

// If an sublayer of type "other" exists we need to update the FilterConfiguration
const updateOtherSublayer = (data: Configuration["Data"], parentId: string) => {
  const otherSublayer = buildSublayerFilterConfigurationByParentId(parentId, data.entities);
  if (otherSublayer) {
    draftConfigEntityAdapter.upsertOne(data, otherSublayer);
  }
};
