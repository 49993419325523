import * as React from "react";
import { TableCell, TableRow } from "@mui/material";

interface IProps {
  /**
   * The number of columns the sub heading should span
   */
  colSpan: number;
  /**
   * The title text content
   */
  title: string;
}

const DataGridRowGroup: React.FC<IProps> = ({ colSpan, title }: IProps) => {
  return (
    <TableRow className="datagrid-rowgroup">
      <TableCell scope="row" padding="normal" colSpan={colSpan}>
        {title}
      </TableCell>
    </TableRow>
  );
};

export default DataGridRowGroup;
