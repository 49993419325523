import React, { useEffect, useMemo, useState } from "react";
import { Box, Modal, Zoom } from "@mui/material";

import { NO_PHASE } from "fond/constants";
import mixpanel from "fond/mixpanel";
import { FullReport, type Schedules } from "fond/types";
import { generateBuildOrderMapConfiguration } from "fond/utils/buildOrderTransformers";
import { selectLayersFromConfig } from "fond/utils/configurations";

import ReportRevenueMapTile from "./ReportRevenueMapTile";

interface IProps {
  report: FullReport;
  visualizationType: keyof typeof Schedules;
}

const ReportRevenueMap: React.FC<IProps> = ({ report, visualizationType }: IProps) => {
  const [layerVisibilities, setLayerVisibilities] = useState<Record<string, boolean>>({});
  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);

  const buildOrderMapPhases = report?.BuildOrderMap.Phases?.filter((phase) => phase !== NO_PHASE) ?? [];
  const [phaseRange, setPhaseRange] = useState<[number, number]>([0, buildOrderMapPhases.length - 1]);

  // cost to serve style configurations for service locations are generated in the FE based on CostBinRanges;
  // this is in contrast to projects where styles are determined by the backend.
  const revenueMapConfiguration = useMemo(() => {
    if (!report?.BuildOrderMap?.Phases) return undefined;
    return generateBuildOrderMapConfiguration(report.BuildOrderMap.Phases);
  }, [report?.BuildOrderMap?.Phases]);

  const layerConfigs = useMemo(
    () => (revenueMapConfiguration && selectLayersFromConfig(revenueMapConfiguration, ["SUBLAYER"])) || [],
    [revenueMapConfiguration]
  );

  useEffect(() => {
    setLayerVisibilities(
      Object.fromEntries(
        layerConfigs.map((layerConfig) => {
          return [layerConfig.ID, true];
        })
      )
    );
  }, [layerConfigs, visualizationType]);

  const onSliderChange = (value: [number, number]) => {
    setPhaseRange(value);
    const dateRangeKeys = Array.from(Array(buildOrderMapPhases.length).keys());
    const selectedDateKeys = dateRangeKeys.slice(value[0], value[1] + 1);
    setLayerVisibilities((prev) => {
      const keys = Object.keys(prev);
      const result: Record<string, boolean> = {};
      keys.forEach((key, index) => {
        result[key] = selectedDateKeys.includes(index);
      });
      return result;
    });

    mixpanel.track("Report", "Revenue", "Changed build map layers visibility");
  };

  const toggleFullscreen = () => {
    setIsFullscreenOpen((prev) => !prev);
  };

  const toggleNoPhase = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLayerVisibilities((prevLayerVisibilities) => {
      const clone = { ...prevLayerVisibilities };
      const noPhaseKey = Object.keys(prevLayerVisibilities).find((phase) => phase.endsWith(NO_PHASE));
      if (noPhaseKey) clone[noPhaseKey] = event.target.checked;
      return clone;
    });
  };

  const mapTileProps = {
    toggleFullscreen,
    report,
    revenueMapConfiguration,
    layerVisibilities,
    layerConfigs,
    onSliderChange,
    buildOrderMapPhases,
    phaseRange,
    toggleNoPhase,
  };

  return (
    <>
      <ReportRevenueMapTile {...mapTileProps} />
      <Modal open={isFullscreenOpen}>
        <Zoom in={isFullscreenOpen}>
          <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <Box width="90vw" height="45vw">
              <ReportRevenueMapTile {...mapTileProps} fullscreen />
            </Box>
          </Box>
        </Zoom>
      </Modal>
    </>
  );
};

export default ReportRevenueMap;
