import { useEffect } from "react";
import * as React from "react";
import { useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Info } from "@mui/icons-material";
import { Box, Divider, FormHelperText, Switch } from "@mui/material";

import { SelectField } from "fond/form/fields";
import { AttributeType, ConfigAttribute } from "fond/types";
import { NonIdealState } from "fond/widgets";

import { Documentation } from "../Documentation";
import { useCustomStyles } from "../FieldFactory";
import { FieldValueWrapper, FormLabel } from "../styles";

import { FieldLabel, SelectedField } from "../DisplayValue/displayValue.styles";
import { Container, FieldForm, Fields, FieldValues } from "../FieldSelection/fieldSelection.styles";
import { Adornment } from "./layerAttributes.styles";

const typeLabel: { [key in AttributeType]: string } = {
  REAL: "Decimal",
  STRING: "Text",
  INTEGER: "Integer",
};

interface IProps {
  attributes: ConfigAttribute[];
  defaultSystemOfMeasurement: "imperial" | "metric";
  selectedAttributeId: string | undefined;
  onSelect(attributeId: string): void;
}

const LayerAttributes: React.FC<IProps> = ({ attributes, defaultSystemOfMeasurement, selectedAttributeId, onSelect }: IProps) => {
  const { change } = useForm();
  const { values } = useFormState<{ Attributes: ConfigAttribute[] }, string>();
  const fieldClasses = useCustomStyles();

  /**
   * Monitor for changes to the attributes & if a attribute is unset from a length
   * we have to reset system of measurement to null.
   */
  useEffect(() => {
    if (values.Attributes?.some((attr: ConfigAttribute) => attr.Type !== "REAL" && attr.SourceSystemOfMeasurement)) {
      change(
        "Attributes",
        values.Attributes.map((attr: ConfigAttribute) => ({
          ...attr,
          SourceSystemOfMeasurement: attr.Type !== "REAL" ? null : attr.SourceSystemOfMeasurement,
        }))
      );
    }
  }, [values.Attributes]);

  const getCurrentValue = (value?: AttributeType | null): string => {
    if (value) {
      return typeLabel[value];
    }

    return "set data type";
  };

  return (
    <Container>
      {attributes.length > 0 ? (
        <Fields>
          <FieldValues>
            {attributes.map((attribute, index) => (
              <SelectedField
                key={attribute.ID}
                onClick={() => {
                  onSelect(attribute.ID);
                }}
                isSelected={selectedAttributeId === attribute.ID}
                disabled={false}
              >
                <FieldLabel>{attribute.Name}</FieldLabel>
                <FieldValueWrapper isDefault={!values.Attributes?.[index]?.Type}>
                  {getCurrentValue(values.Attributes?.[index]?.Type)}
                </FieldValueWrapper>
              </SelectedField>
            ))}
          </FieldValues>
          <FieldForm>
            <FieldArray name="Attributes">
              {({ fields }) => {
                return fields.map((fieldName, index) => {
                  return (
                    <React.Fragment key={fieldName}>
                      {values.Attributes[index].ID === selectedAttributeId && (
                        <>
                          <FormLabel>Data Type</FormLabel>
                          <SelectField
                            name={`${fieldName}.Type`}
                            displayEmpty
                            className={fieldClasses.selectField}
                            placeholder="Select"
                            options={[
                              {
                                startAdornment: <Adornment>abc</Adornment>,
                                value: "STRING",
                                displayValue: "Text",
                              },
                              {
                                startAdornment: <Adornment>123</Adornment>,
                                value: "INTEGER",
                                displayValue: "Integer",
                              },
                              {
                                startAdornment: <Adornment>1.50</Adornment>,
                                value: "REAL",
                                displayValue: "Decimal",
                              },
                            ]}
                          />

                          {values.Attributes[index].Type === "REAL" && (
                            <>
                              <FormLabel sx={{ mt: 2 }}>Length</FormLabel>
                              <Box>
                                <Switch
                                  size="small"
                                  checked={values.Attributes[index].SourceSystemOfMeasurement !== null}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                    change(`${fieldName}.SourceSystemOfMeasurement`, checked ? defaultSystemOfMeasurement : null);
                                  }}
                                />
                              </Box>

                              <FormHelperText>Toggle if data is used to measure 'Length'</FormHelperText>
                            </>
                          )}

                          {values.Attributes[index].SourceSystemOfMeasurement && (
                            <>
                              <FormLabel sx={{ mt: 2 }}>Unit of measurement</FormLabel>
                              <SelectField
                                name={`${fieldName}.SourceSystemOfMeasurement`}
                                className={fieldClasses.selectField}
                                options={[
                                  {
                                    value: "metric",
                                    displayValue: "Metric",
                                  },
                                  {
                                    value: "imperial",
                                    displayValue: "Imperial",
                                  },
                                ]}
                              />
                            </>
                          )}
                          <Divider sx={{ my: 1 }} />
                          <FormHelperText component="div">
                            <Documentation fieldSpec={{ doc: "Define the attribute data type" }} />
                          </FormHelperText>
                        </>
                      )}
                    </React.Fragment>
                  );
                });
              }}
            </FieldArray>
          </FieldForm>
        </Fields>
      ) : (
        <Box sx={{ height: 200 }}>
          <NonIdealState icon={<Info />} size="small" description="This layer has no attributes to configure." />
        </Box>
      )}
    </Container>
  );
};

export default LayerAttributes;
