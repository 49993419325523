import * as React from "react";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.biarri.secondary.darkCarbon,
    boxShadow: theme.shadows[2],
    fontSize: 12,
    fontWeight: 400,
  },
}))(Tooltip);

interface IProps {
  helpText: string | React.ReactChild;
}

const HelpIcon: React.FC<IProps> = ({ helpText }: IProps) => {
  return (
    <LightTooltip title={helpText}>
      <InfoOutlined color="primary" />
    </LightTooltip>
  );
};

export default HelpIcon;
