import * as React from "react";
import { useState } from "react";
import { Form } from "react-final-form";
import { Computer, CopyAll } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { useCreateVersionMutation } from "fond/api";
import { TextField } from "fond/form/fields";
import { AnyObject } from "fond/types";
import { required } from "fond/utils/validation";
import { Message as MessageWidget, Modal } from "fond/widgets";

import CardButton from "../../import/modal/CardButton";

import { SetupContainer } from "../../import/modal/ImportModal/ImportModal.styles";

interface IFormData {
  Name: string;
}

interface IProps {
  /**
   * The ID of the project currently being viewed
   */
  projectId: string;
  /**
   * Whether the project being viewed is a planner project
   */
  planner: boolean;
  /**
   * The latest Version.ID for this project
   */
  versionId: string;
  /**
   * Callback function to handle the onClose of the modal
   */
  onClose(): void;
  /**
   * Optional Callback function for when the form is submitted
   */
  onSubmit?(values: IFormData): void;
}

enum Settings {
  BLANK = "BLANK",
  COPY = "COPY",
}

const CreateVersionModal: React.FC<IProps> = ({ projectId, planner, versionId, onClose, onSubmit }: IProps) => {
  const [createVersion, { isLoading: isSaving, isError }] = useCreateVersionMutation();
  const [settings, setSettings] = useState<Settings>(Settings.BLANK);
  let submit: (event?: Partial<Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">>) => Promise<AnyObject | undefined> | undefined;
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Callback function for the Create button within the modal actions
   */
  const handleOnClick = (event: React.MouseEvent<EventTarget>) => {
    // Submits the React-Final-Form (which handles validation & calling onSubmit is validation passes)
    submit();
  };

  /**
   * On submit function called when the form is submitted and valid
   */
  const handleOnSubmit = async ({ Name }: IFormData) => {
    createVersion({
      versionId: versionId,
      name: Name,
      projectId: projectId,
      method: planner && settings === Settings.COPY ? "copy_design" : undefined,
    })
      .unwrap()
      .then(() => {
        onClose();
        enqueueSnackbar("Version successfully created");
      })
      .catch(() => {
        enqueueSnackbar("Failed to create version", { variant: "error" });
      });
  };

  return (
    <Modal
      open
      header="Create version"
      data-testid="create-version-modal"
      content={
        <Box>
          {isError && (
            <Box mb={2}>
              <MessageWidget type="error">There was an issue creating a version. Please try again.</MessageWidget>
            </Box>
          )}
          <Form<IFormData>
            onSubmit={onSubmit || handleOnSubmit}
            render={({ handleSubmit }) => {
              submit = handleSubmit;
              return (
                <form onSubmit={handleSubmit}>
                  <SetupContainer>
                    <Box width={424}>
                      <TextField
                        inputProps={{ "data-testid": "new-version-name-input" }}
                        autoFocus
                        name="Name"
                        required
                        label="Name"
                        fullWidth
                        validate={required}
                      />
                    </Box>
                    {planner && (
                      <>
                        <CardButton
                          sx={{ width: 424, height: "4em" }}
                          data-testid="new-version-blank"
                          icon={<Computer color="primary" sx={{ margin: "auto" }} />}
                          title="Blank version"
                          subtitle="Start from scratch"
                          selected={settings === Settings.BLANK}
                          onClick={() => {
                            setSettings(Settings.BLANK);
                          }}
                        />
                        <CardButton
                          sx={{ width: 424, height: "4em" }}
                          data-testid="new-version-copy"
                          icon={<CopyAll color="primary" sx={{ margin: "auto" }} />}
                          title="Continue from most recent version"
                          subtitle="Copy Architecture, Input, and Design data"
                          selected={settings === Settings.COPY}
                          onClick={() => {
                            setSettings(Settings.COPY);
                          }}
                        />
                      </>
                    )}
                  </SetupContainer>
                </form>
              );
            }}
          />
        </Box>
      }
      actions={
        <>
          <Button data-testid="create-version-cancel-button" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <LoadingButton data-testid="create-version-save-button" color="primary" onClick={handleOnClick} loading={isSaving}>
            Submit
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateVersionModal;
