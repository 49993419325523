import { PropsWithChildren } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";

const customStyles = (theme: Theme) => {
  return createStyles({
    button: {
      minWidth: 0,
      padding: theme.spacing(0.5),
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      marginRight: 0,
      marginBottom: theme.spacing(0.5),
      "&.active": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
      },
      "&:hover:not(.active)": {
        backgroundColor: theme.palette.biarri.primary.lightGrey,
        color: theme.palette.common.black,
      },
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * Unique identifier for this button.
   */
  id: string;
  /**
   * Tooltip title for this button.
   */
  title: string;
  /**
   * A data-testid for this button.
   */
  dataT: string;
  /**
   * Called when the button is clicked.
   */
  onClick: () => void;
  /**
   * Whether the button is clicked down (default: false).
   */
  active?: boolean;
  /**
   * Whether the button is clickable (default: true).
   */
  enabled?: boolean;
}

const MapButton: React.FC<PropsWithChildren<IProps>> = ({
  classes,
  active,
  enabled,
  id,
  title,
  dataT,
  onClick,
  children,
}: PropsWithChildren<IProps>) => {
  return (
    <Box key={id} style={{ flex: "0 1 1" }}>
      <Tooltip title={title} placement="left">
        <span>
          <Button
            aria-label={title}
            variant="contained"
            disabled={!(enabled ?? true)}
            onClick={onClick}
            data-testid={dataT}
            className={classNames(classes.button, { active: active ?? false })}
          >
            {children}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};

export default withStyles(customStyles)(MapButton);
