import * as React from "react";
import { useSelector } from "react-redux";
import { Info as InfoIcon, Menu as MenuIcon, SignalCellularAlt as RundownIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { sumBy } from "lodash";
import { ReactComponent as CircleIcon } from "svg_icons/circle-with-dot.svg";
import { ReactComponent as HubIcon } from "svg_icons/hub-icon.svg";

import { selectDraftConfigEntities } from "fond/api";
import { GroupHeader } from "fond/styleEditor/GroupHeader";
import { GroupConfig, GroupRundown } from "fond/types";
import { LayerConfig, SublayerConfig } from "fond/types/ProjectLayerConfig";

import { Tab, Tabs } from "../FieldSelection/fieldSelection.styles";
import {
  Container,
  Header,
  InfoContent,
  InfoItem,
  InfoMessage,
  InfoTitle,
  RundownContainer,
  RundownItem,
  RundownLabel,
  RundownValue,
  TabPanel,
} from "./groupEditor.styles";

interface IProps {
  groupConfig: GroupConfig;
}

const GroupEditor: React.FC<IProps> = ({ groupConfig }: IProps) => {
  const draft = useSelector(selectDraftConfigEntities);

  // Generate group rundown
  const { layers, sublayers, styles } = groupConfig.Children.reduce<GroupRundown>(
    (prev, layerConfigId) => {
      const layerConfig = draft[layerConfigId] as LayerConfig;
      if (layerConfig.Type === "LAYER" && "Children" in layerConfig) {
        const groupSublayer = layerConfig.Children.length;
        const groupStyles =
          layerConfig.Styles.length + sumBy(layerConfig.Children, (childId: string) => (draft[childId] as SublayerConfig).Styles.length);
        return { layers: prev.layers + 1, sublayers: prev.sublayers + groupSublayer, styles: prev.styles + groupStyles };
      }
      return prev;
    },
    { layers: 0, sublayers: 0, styles: 0 }
  );

  return (
    <Container>
      <GroupHeader groupConfig={groupConfig} />
      <Tabs value="summary">
        <Tab value="summary" label="Summary" />
      </Tabs>
      <TabPanel>
        <Header>
          <RundownIcon fontSize="small" />
          <Typography variant="caption" fontWeight={600}>
            Group Rundown
          </Typography>
        </Header>
        <RundownContainer>
          <RundownItem>
            <RundownLabel>TOTAL LAYERS</RundownLabel>
            <RundownValue component="p" variant="h5">
              {layers}
            </RundownValue>
          </RundownItem>
          <RundownItem>
            <RundownLabel>STYLES</RundownLabel>
            <RundownValue component="p" variant="h5">
              {styles}
            </RundownValue>
          </RundownItem>
          <RundownItem>
            <RundownLabel>SUBLAYERS</RundownLabel>
            <RundownValue component="p" variant="h5">
              {sublayers}
            </RundownValue>
          </RundownItem>
        </RundownContainer>
        <Header>
          <InfoIcon fontSize="small" />
          <Typography variant="caption" fontWeight={600}>
            Useful Info
          </Typography>
        </Header>
        <InfoItem>
          <CircleIcon />
          <InfoContent>
            <InfoTitle>Edited Style</InfoTitle>
            <InfoMessage>Icon indicating you’ve edited a style.</InfoMessage>
          </InfoContent>
        </InfoItem>
        <InfoItem>
          <MenuIcon />
          <InfoContent>
            <InfoTitle>Reorder Layer</InfoTitle>
            <InfoMessage>You can reorder layers within a group.</InfoMessage>
          </InfoContent>
        </InfoItem>
        <InfoItem>
          <HubIcon />
          <InfoContent>
            <InfoTitle>Icons</InfoTitle>
            <InfoMessage>Derived from the styles within a layer or sublayer.</InfoMessage>
          </InfoContent>
        </InfoItem>
      </TabPanel>
    </Container>
  );
};

export default GroupEditor;
