import * as React from "react";

import { Navigation, Profile } from "fond/settings";
import { PageContent } from "fond/widgets";

/**
 * A top level component used for displaying Profile Settings.
 */
const ProfileManagement: React.FC = () => {
  return <PageContent title="Profile Settings" mainContent={<Profile />} navigation={<Navigation />} />;
};

export default ProfileManagement;
