import * as React from "react";

import { SEARCH_KEY } from "fond/constants";
import { useNoAccountRedirect } from "fond/hooks/useNoAccountRedirect";
import { Navigation } from "fond/projects";
import SearchResults from "fond/projects/GlobalSearch/SearchResults";
import RecentContent from "fond/projects/RecentContent";
import { useQueryParams } from "fond/utils/hooks";
import { PageContent } from "fond/widgets";

/**
 * A top level component used for displaying recent projects.
 */
const Recent: React.FC = () => {
  useNoAccountRedirect();

  // Global search results page
  const searchQuery = useQueryParams(SEARCH_KEY);
  if (searchQuery) {
    return <SearchResults />;
  }

  return <PageContent title="Recent" mainContent={<RecentContent />} navigation={<Navigation />} testId="filter-recent" />;
};

export default Recent;
