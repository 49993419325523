import * as React from "react";
import { useForm } from "react-final-form";
import ClearIcon from "@mui/icons-material/Clear";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { IconButton, Typography } from "@mui/material";

import { IFormData } from "../CheckoutForm";

import { Tag } from "./PromotionCodeTag.styles";

interface PromotionCodeTagProps {
  promoCode: string;
}

const PromotionCodeTag: React.FC<PromotionCodeTagProps> = ({ promoCode }) => {
  const form = useForm<IFormData>();

  const removePromotionCode = () => {
    form.change("promoCode", "");
  };

  return (
    <Tag>
      <LocalOfferIcon fontSize="small" color="action" sx={{ marginRight: 0.5 }} />
      <Typography>{promoCode}</Typography>
      <IconButton onClick={removePromotionCode} sx={{ padding: 0.5 }}>
        <ClearIcon fontSize="small" />
      </IconButton>
    </Tag>
  );
};

export default PromotionCodeTag;
