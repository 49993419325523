import * as React from "react";
import { Typography } from "@mui/material";

import { SummaryLine } from "fond/types/stripe";
import { getCurrencyFromStripe } from "fond/utils/currency";

import { SummaryRow } from "../Checkout.styles";
import { LineItemPrice } from "./InvoiceSummary.styles";

type LineItemProp = {
  lineItem: SummaryLine;
};

const LineItem: React.FC<LineItemProp> = ({ lineItem }) => (
  <SummaryRow paddingBottom={1}>
    <Typography width="70%">{lineItem.Description}</Typography>
    <LineItemPrice>{getCurrencyFromStripe(lineItem.AmountExcludingTax)}</LineItemPrice>
  </SummaryRow>
);

export default LineItem;
