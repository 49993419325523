import React, { useCallback, useMemo, useRef, useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { Cancel as CancelIcon, Edit as EditIcon } from "@mui/icons-material";
import { Box, Button, Divider, FormHelperText, Grid, InputAdornment, Typography } from "@mui/material";
import classNames from "classnames";
import { Config, FormApi, ValidationErrors } from "final-form";
import arrayMutators from "final-form-arrays";
import { orderBy } from "lodash";
import { coerce, lt } from "semver";

import { LoadingButton } from "ui";

import {
  selectAllFolders,
  selectPlannerProjectsWithinAccount,
  useGetProjectsQuery,
  useLazyGetProjectQuery,
  useLazyGetVersionQuery,
  versionsSlice,
} from "fond/api";
import { REPORT_BOM_MIN_TOOL_VERSION, REPORT_CTS_MIN_TOOL_VERSION } from "fond/constants";
import { DatePickerField, ProjectVersionField, SelectField, TextField } from "fond/form/fields";
import { NumericField } from "fond/form/fields/NumericField";
import { parseFieldAsNumber } from "fond/form/util";
import { FullReport, Report } from "fond/types";
import { setValue } from "fond/utils/formMutators";
import { useAppDispatch } from "fond/utils/hooks";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { required } from "fond/utils/validation";

import { ReportFormConfiguration, ReportFormData, RevenueFormConfiguration } from "../types";
import { mergeRevenueConfigurations, transformDemandToRevenue, transformReportConfigurationToReportForm } from "../util";

import { ImportReportDialog } from "./ImportReportDialog";
import ReportDesignWarning, { VersionError } from "./ReportDesignWarning";
import { ReportRevenueFormFields } from "./ReportRevenueFormFields";
import { ResourceAllocationFields } from "./ResourceAllocationFields";

import { Container } from "./reportSettings.styles";
import { Disclaimer, ReportConfigurationSection } from "./reportSettingsForm.styles";

interface IProps {
  initialValues: Partial<ReportFormData>;
  onSubmit: Config<ReportFormData>["onSubmit"];
  onUpdateDetails?(values: ReportFormData): void;
  onCancel?(): void;
  isLoading?: boolean;
  testId?: string;
  report?: Report;
}

/**
 * Check whether the user has permission to create a report for the given project
 * @param project - the project to check permissions for
 * @returns
 */
export function hasCreateProjectReportPermission(project: Project): boolean {
  return permissionCheck(project?.Permission.Level, Actions.PROJECT_CREATE_REPORT);
}

const ReportSettingsForm: React.FC<IProps> = ({ initialValues, onSubmit, onUpdateDetails, onCancel, isLoading, testId, report }) => {
  useGetProjectsQuery(undefined);
  let formApi: FormApi<ReportFormData, Partial<ReportFormData>>; // set in the form render fn, used to reset the form
  const allProjects = useSelector(selectPlannerProjectsWithinAccount);
  const folders = useSelector(selectAllFolders);
  const [isEditing, setIsEditing] = useState(!report); // if there is no report, we are creating a new one so already in edit mode
  const isSettingsPage = !!report;
  const projects = useMemo(() => orderBy(allProjects, "CreatedAt", "desc").filter(hasCreateProjectReportPermission), [allProjects]);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  // store the revenue configurations from the existing report to merge against when importing a report configuration
  const existingRevenueConfiguration = useRef<RevenueFormConfiguration[] | null>([]);
  const dispatch = useAppDispatch();
  const canEditReport = permissionCheck(report?.Permission?.Level, Actions.REPORT_EDIT);
  const canRegenerateReport = permissionCheck(report?.Permission?.Level, Actions.REPORT_GENERATE);
  const [getVersionQuery] = useLazyGetVersionQuery();
  const [getProjectQuery] = useLazyGetProjectQuery();

  const onEditClick = () => {
    if (isEditing) {
      // reset the form to the initial values
      formApi.reset();
    }
    setIsEditing((isEditingState) => !isEditingState);
  };

  const saveDetails = () => {
    onUpdateDetails?.(formApi.getState().values as ReportFormData);
  };

  const onImportClick = () => {
    setIsImportModalOpen(true);
  };

  const onImport = async (reportToImport?: FullReport) => {
    const formValues = formApi.getState().values;
    if (!reportToImport || !reportToImport?.ReportConfiguration) {
      return;
    }

    // if there is no project/version specified in the current report, get the version from the report to import
    const { data: Version, isError: isVersionError } = await getVersionQuery(formValues.Version?.ID ?? reportToImport?.Version?.ID ?? "");
    const { data: Project, isError: isProjectError } = await getProjectQuery(Version?.Project ?? "");
    if (isProjectError || isVersionError) {
      return;
    }

    const reportFormConfig = transformReportConfigurationToReportForm(reportToImport?.ReportConfiguration, Project?.SystemOfMeasurement);
    existingRevenueConfiguration.current = reportFormConfig?.Revenue?.RevenueConfigurations ?? [];

    if (formValues.Project == null || formValues.Version == null) {
      formApi.change("Project", Project);
      formApi.change("Version", Version);
    } else {
      // use the demand model from the existing report to merge the revenue configurations against
      const demandModelResult = Version?.Architecture?.Demand;
      existingRevenueConfiguration.current = transformDemandToRevenue(demandModelResult?.DemandConfigurations.filter(({ Ignore }) => !Ignore));
    }

    // Note that here we have reversed the order of the arguments of the function. Normally you would pass in
    // the existing config first followed by the new config, however in this case we want the new config to be
    // the first argument because we always want the merged configuration to match the demand model of the currently
    // selected project OR just match the selected report's revenue configuration if there is no project selected.
    const mergedRevenueConfigurations = mergeRevenueConfigurations(
      reportFormConfig?.Revenue?.RevenueConfigurations ?? null,
      existingRevenueConfiguration.current
    );

    const reportConfig: ReportFormConfiguration = {
      ...formValues.ReportConfiguration,
      ...reportFormConfig,
      Revenue: { RevenueConfigurations: mergedRevenueConfigurations || [] },
    };

    formApi.change("ReportConfiguration", reportConfig);
  };

  const formValidation = useCallback(
    async (values: ReportFormData): Promise<ValidationErrors> => {
      const revenueValue = values.ReportConfiguration?.Revenue?.RevenueConfigurations;

      if (values.Version) {
        // Validate version has completed design & is not an outdated project
        const versionStatus = values.Version.ID ? await dispatch(versionsSlice.endpoints.getVersionStatus.initiate(values.Version.ID)) : undefined;
        if (!versionStatus?.data?.HasSolution) {
          return { Version: VersionError.INCOMPLETE_DESIGN };
        } else {
          const outdated = versionStatus.data.Workflow?.some(({ ToolName, ToolVersion }) => {
            const version = coerce(ToolVersion);
            return (
              (version && ToolName === "cost_to_serve" && lt(version, REPORT_CTS_MIN_TOOL_VERSION)) ||
              (version && ToolName === "configurable_bom_orca" && lt(version, REPORT_BOM_MIN_TOOL_VERSION))
            );
          });
          if (outdated) return { Version: VersionError.OUTDATED_PROJECT };
        }

        // Validate version has BOM & costs
        const versionInfo = values.Version.ID ? await dispatch(versionsSlice.endpoints.getVersion.initiate(values.Version.ID)) : undefined;
        if (versionInfo?.data?.Architecture?.BOM) {
          const hasCosts = versionInfo.data?.Architecture.BOM.Categories.some(({ Rows }) => Rows.some(({ Cost }) => Cost > 0));
          if (!hasCosts) return { Version: VersionError.BOM_WITHOUT_COSTS };
        } else {
          return { Version: VersionError.NO_BOM };
        }

        // Validate version has a demand model. Note since the revenueValue is based on the
        // demand model we can use this instead of requesting the full demand model from the project.
        if (revenueValue === undefined || (Array.isArray(revenueValue) && revenueValue.length === 0)) {
          return { Version: VersionError.NO_DEMAND_MODEL };
        }

        // Validate the Revenue section
        if (Array.isArray(revenueValue)) {
          const invalidRows = revenueValue.some(({ TakeRateMin, TakeRateMax }: RevenueFormConfiguration) => TakeRateMin >= TakeRateMax);
          if (invalidRows) return { ReportConfigurationValidation: "Each address must have a minimum take rate less than its maximum value" };
        }
      }

      return undefined;
    },
    [dispatch]
  );

  return (
    <Box data-testid={testId}>
      <Form<ReportFormData>
        initialValues={initialValues}
        // We validate on blur due to the complexity of the form & the performance
        // hit if validating onChange
        validateOnBlur
        validate={formValidation}
        onSubmit={onSubmit}
        // We use an empty subscription to increase form performance - preventing
        // the form from re-rendering every time a value changes
        // See: https://final-form.org/docs/react-final-form/examples/subscriptions
        subscription={{}}
        render={({ handleSubmit, form }) => {
          formApi = form;
          return (
            <form onSubmit={handleSubmit}>
              <Typography color="GrayText">
                Ensure to input and review all data into the fields below before generating a report. These estimates serve as benchmarks for
                evaluating return on investments, but they do not ensure market success or specific revenue receipts. Use them as a tool to assess the
                costs and potential outcomes of your investments.
              </Typography>
              <Typography variant="h5" fontWeight={700} sx={{ mt: 8 }}>
                Report details
              </Typography>
              <Container>
                <Box maxWidth={300}>
                  <TextField name="Name" required label="Report name" disabled={isSettingsPage && !canEditReport} fullWidth validate={required} />
                </Box>
                <Box mt={2}>
                  <TextField name="Description" label="Description" disabled={isSettingsPage && !canEditReport} fullWidth multiline rows={3} />
                </Box>
              </Container>
              {isSettingsPage && (
                <Box display="flex" justifyContent="flex-end" gap={1} mb={4} mt={-3}>
                  <LoadingButton
                    type="button"
                    disabled={!canEditReport}
                    onClick={saveDetails}
                    data-testid="report-update-details-button"
                    color="primary"
                    loading={isLoading}
                  >
                    Update
                  </LoadingButton>
                </Box>
              )}
              <Divider />
              <Grid mt={4} container>
                <Grid item xs>
                  <Typography variant="h5" fontWeight={700}>
                    Configuration
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  {!isSettingsPage && (
                    <Button type="button" size="small" variant="outlined" onClick={onImportClick} sx={{ ml: "auto", px: 2 }}>
                      Import configuration from an existing report
                    </Button>
                  )}
                  {isSettingsPage && (
                    <Button
                      data-testid="report-edit-configuration-button"
                      type="button"
                      size="small"
                      variant="outlined"
                      disabled={!canEditReport}
                      onClick={onEditClick}
                      sx={{ ml: "auto", px: 2 }}
                      startIcon={isEditing ? <CancelIcon color="primary" /> : <EditIcon color={canEditReport ? "primary" : "disabled"} />}
                    >
                      {isEditing ? "Cancel edit" : "Edit"}
                    </Button>
                  )}
                </Grid>
              </Grid>
              <ReportConfigurationSection
                className={classNames("report-configuration-section", {
                  "is-editing": isEditing,
                })}
                disabled={!isEditing}
              >
                <Container>
                  <Typography variant="h6" fontWeight={700}>
                    Project source
                  </Typography>
                  <Box mt={2} sx={{ display: "flex", flexDirection: "row" }}>
                    <ProjectVersionField
                      projects={projects}
                      folders={folders}
                      disabled={isSettingsPage}
                      projectValidator={required}
                      versionValidator={required}
                    />
                  </Box>
                  <ReportDesignWarning />
                  {isSettingsPage && <FormHelperText>You may only change the project or version when creating a new report.</FormHelperText>}
                </Container>
                <Container>
                  <Typography variant="h6" fontWeight={700}>
                    General
                  </Typography>
                  <Box mt={2} display="flex" columnGap={4}>
                    <Box flex={1} display="flex" alignItems="baseline">
                      <Box flex={1} mr={1.5}>
                        <NumericField
                          name="ReportConfiguration.PlanningHorizon"
                          min={1}
                          max={24}
                          required
                          label="Period"
                          parse={parseFieldAsNumber}
                          fullWidth
                          validate={required}
                          disabled={!isEditing}
                        />
                      </Box>
                      <Box flex={1}>
                        <SelectField
                          name="ReportConfiguration.StepFrequency"
                          size="small"
                          label="Frequency"
                          fullWidth
                          disabled={!isEditing}
                          options={[
                            { value: "monthly", displayValue: "Months" },
                            { value: "quarterly", displayValue: "Quarters" },
                            { value: "yearly", displayValue: "Years" },
                          ]}
                          sxProps={{ label: { opacity: 0 } }}
                        />
                      </Box>
                    </Box>
                    <Box flex={1}>
                      <DatePickerField
                        name="ReportConfiguration.StartTime"
                        label="Start date"
                        required
                        size="small"
                        format="DD MMM YYYY"
                        disabled={!isEditing}
                      />
                    </Box>
                    <Box flex={1}>
                      <NumericField
                        name="ReportConfiguration.DiscountRate"
                        required
                        label="Discount rate"
                        fullWidth
                        min={0}
                        max={100}
                        parse={parseFieldAsNumber}
                        InputProps={{ endAdornment: <InputAdornment position="end">% p.a</InputAdornment> }}
                        disabled={!isEditing}
                      />
                    </Box>
                  </Box>
                </Container>
                <Container>
                  <Typography variant="h6" fontWeight={700}>
                    Subscriber revenue
                  </Typography>
                  <Box pt={1}>
                    <ReportRevenueFormFields disabled={!isEditing} />
                  </Box>
                </Container>
                <Container>
                  <Typography variant="h6" fontWeight={700}>
                    Resource allocation
                  </Typography>
                  <Box mt={2} display="flex" columnGap={6}>
                    <ResourceAllocationFields disabled={!isEditing} />
                  </Box>
                </Container>
              </ReportConfigurationSection>
              <Disclaimer>
                <Typography>
                  By clicking "{isSettingsPage ? "Regenerate report" : "Generate report"}", I agree that all data is correct and reviewed as
                  instructed above.
                </Typography>
                <Box>
                  {!isSettingsPage && (
                    <Button type="button" data-testid="report-cancel-button" color="primary" onClick={onCancel} sx={{ mr: 1 }}>
                      Cancel
                    </Button>
                  )}
                  <LoadingButton
                    type="submit"
                    data-testid="report-save-button"
                    color="primary"
                    disabled={isSettingsPage && !canRegenerateReport}
                    loading={isLoading}
                  >
                    {isSettingsPage ? "Regenerate report" : "Generate report"}
                  </LoadingButton>
                </Box>
              </Disclaimer>
            </form>
          );
        }}
        mutators={{ setValue, ...arrayMutators }}
      />
      {isImportModalOpen && <ImportReportDialog onImport={onImport} onClose={() => setIsImportModalOpen(false)} />}
    </Box>
  );
};

export default ReportSettingsForm;
