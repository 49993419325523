import * as React from "react";
import { SubMentionComponentProps } from "@draft-js-plugins/mention/lib/Mention";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

import { setSearchText } from "fond/redux/comments";
import { useAppDispatch } from "fond/utils/hooks";

const customStyles = (theme: Theme) => {
  return createStyles({
    tag: {
      color: theme.palette.common.white,
      cursor: "pointer",
      backgroundColor: theme.palette.primary.main,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      borderRadius: 2,
    },
  });
};

interface ComponentProps extends SubMentionComponentProps {
  children: React.ReactNode;
}

type IProps = ComponentProps & WithStyles<typeof customStyles>;

const HashtagComponent: React.FC<IProps> = ({ classes, children, mention: { name } }: IProps) => {
  const dispatch = useAppDispatch();

  const handleOnClick = () => {
    dispatch(setSearchText(`#${name}`));
  };

  return (
    <span>
      <span className={classes.tag} onClick={handleOnClick}>
        {children}
      </span>
    </span>
  );
};

export default withStyles(customStyles)(HashtagComponent);
