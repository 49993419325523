import * as React from "react";
import { Box, Typography } from "@mui/material";

import { Subscription } from "fond/types";

import PremsUsageChart from "../InsightsChart/PremsUsageChart";

import { CardHeader } from "./insightsOverview.styles";

interface InsightsOverviewProps {
  subscription: Subscription;
}
const InsightsOverview: React.FC<InsightsOverviewProps> = ({ subscription }: InsightsOverviewProps) => {
  return (
    <Box>
      <CardHeader>
        <Typography variant="body1" fontWeight={500}>
          Overview
        </Typography>
      </CardHeader>
      <PremsUsageChart subscription={subscription} />
    </Box>
  );
};

export default InsightsOverview;
