import { useState } from "react";
import * as React from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";

import { DialogActions, DialogContent, MainContainer, Slide, SlidePagination, StepImage, TextButton } from "./BasicTour.styles";

type Slides = Array<{
  image: string;
  title: string;
  content: React.ReactNode;
}>;

interface IProps {
  onComplete(): void;
  slides: Slides;
  name: string;
}

const BasicTour: React.FC<IProps> = ({ onComplete, slides, name }) => {
  const [isOpen, setOpen] = useState<boolean>(true);
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const closeDialog = () => {
    onComplete();
    setOpen(false);
  };

  return (
    <Dialog open={isOpen} maxWidth={false}>
      <MainContainer data-testid="tour">
        <DialogContent>
          {slides.map((slide, index) => (
            <Slide key={slide.title} active={index === activeSlide}>
              <StepImage image={slide.image} />
              <Box>
                <Typography variant="h5" component="h3" fontWeight={700} gutterBottom data-testid={`${name}-tour-title`}>
                  {slide.title}
                </Typography>
                <br />
                {slide.content}
              </Box>
            </Slide>
          ))}
        </DialogContent>
        <DialogActions>
          <Box paddingLeft={4} display="flex">
            {slides.map((slide, index) => (
              <SlidePagination key={slide.title} onClick={() => setActiveSlide(index)} active={index === activeSlide} />
            ))}
          </Box>
          <Box>
            {activeSlide === slides.length - 1 ? (
              <Button variant="contained" onClick={() => closeDialog()} data-testid="tour-close">
                Get Started
              </Button>
            ) : (
              <>
                <TextButton variant="text" onClick={() => closeDialog()} sx={{ marginRight: 2 }} data-testid="tour-skip">
                  Skip, I’ll explore on my own
                </TextButton>
                <Button variant="contained" onClick={() => setActiveSlide(activeSlide + 1)} data-testid="tour-next">
                  Continue
                </Button>
              </>
            )}
          </Box>
        </DialogActions>
      </MainContainer>
    </Dialog>
  );
};
export default BasicTour;
