import { useRef } from "react";
import * as React from "react";
import { Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

/**
 * An input component designed to replicate the behaviour of the Google docs renaming widget. For history, this is adapted from
 * an earlier version of the component we had called HoverInput.

 * Changes to the inputs value are committed when enter is pushed or the input loses focus
 * (i.e. user clicked elsewhere). If escape is pushed, any changes are undone and the input will lose focus.
 */

const customStyles = (theme: Theme) => {
  return createStyles({
    editableDiv: {
      borderRadius: 4,
      padding: `0 4px`,
      height: 32,
      color: theme.palette.common.white,
      fontSize: theme.typography.h5.fontSize,
      outline: "none",
      whiteSpace: "nowrap",
      overflow: "hidden",
      background: "transparent",
      "&[contenteditable='true']:hover, &[contenteditable='true']:focus": {
        backgroundColor: theme.palette.background.sidebar.hover,
      },
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  value: string;
  className?: string;
  onCommit: (str: string) => void;
  readOnly?: boolean;
}

const OnClickInput: React.FC<IProps> = ({ className, classes, value, onCommit, readOnly = false }) => {
  const projectNameInputRef = useRef<HTMLDivElement>(null);

  const handleBlur = () => {
    const newProjectName = projectNameInputRef.current?.innerText || "";
    if (value !== newProjectName) {
      onCommit(newProjectName);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      projectNameInputRef.current?.blur();
    }
  };

  return (
    <div
      className={className || classes.editableDiv}
      role="textbox"
      tabIndex={0}
      ref={projectNameInputRef}
      contentEditable={!readOnly}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      data-testid="rename-project-input"
      suppressContentEditableWarning
    >
      {value}
    </div>
  );
};

export default withStyles(customStyles)(OnClickInput);
