import * as React from "react";
import Box from "@mui/material/Box";

interface IProps {
  children: React.ReactNode;
}

const Section: React.FC<IProps> = ({ children }: IProps) => <Box mb={2}>{children}</Box>;

export default Section;
