import * as React from "react";
import { Typography, TypographyVariant } from "@mui/material";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const types = {
  success: {
    background: "#fcfff5",
    color: "#2c662d",
    borderColor: "#a3c293",
  },

  info: {
    background: "#f8ffff",
    color: "#276f86",
    borderColor: "#a9d5de",
  },

  warning: {
    background: "#fffaf3",
    color: "#573a08",
    borderColor: "#c9ba9b",
  },

  error: {
    background: "#fff6f6",
    color: "#9f3a38",
    borderColor: "#e0b4b4",
  },
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      background: (props: IProps) => types[props.type].background,
      color: (props: IProps) => types[props.type].color,
      borderColor: (props: IProps) => types[props.type].borderColor,
      padding: (props: IProps) => (props.dense ? "0 4px" : theme.spacing(2)),
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: 4,
    },
    icon: {
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: theme.spacing(1),
      flex: "0 0 auto",
    },
    text: {
      flex: "1",
    },
  };
});

interface IProps {
  className?: string;
  children: React.ReactNode;
  /**
   * Sets the margin denisty of the message
   * @default false
   */
  dense?: boolean;
  icon?: React.ReactElement;
  type: "success" | "info" | "warning" | "error";
  typography?: TypographyVariant;
}

/**
 * Usage:

    <Message type="success">
      If you don't specify the `typography` prop, put any content in here.
      Create your own `<Typography>` elements as appropriate.
    </Message>

    <Message type="success" typography="body1">
      If you specify the `typography` prop, your content is wrapped inside a
      `<Typography variant={typography}>`. Hence you can't include any children
      that aren't valid children of a Typography.
    </Message>
 */
const Message: React.FC<IProps> = (props: IProps) => {
  const styles = useStyles(props);
  const { className, children, icon, typography, ...rest } = props;

  return (
    <Box className={classNames(styles.root, className)} {...rest}>
      {icon != null && React.cloneElement(icon, { className: styles.icon })}
      {typography != null ? (
        <Typography variant={typography} className={styles.text}>
          {children}
        </Typography>
      ) : (
        children
      )}
    </Box>
  );
};

export default Message;
