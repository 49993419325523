import { RefObject, useState } from "react";
import * as React from "react";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

import { useOnScreen } from "fond/utils/hooks";

const customStyles = (theme: Theme) => {
  return createStyles({
    scrollNotification: {
      position: "absolute",
      zIndex: 99,
      right: theme.spacing(5),
      marginTop: theme.spacing(1),
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * The color of the notification button.
   */
  color: "primary" | "secondary";
  /**
   * A Ref to the list item that the button navigates toward.
   */
  listItemRef: RefObject<HTMLElement>;
  /**
   * A Ref to the list containing the list item.
   */
  listRef: RefObject<HTMLUListElement>;
  /**
   * A message to display to the user within the scroll notification button.
   */
  message: string;
}

/**
 * This component creates a floating button in the top right corner of a scrollable list (referenced by listRef).
 * The button navigates to an item (references by listItemRef) on click.
 * The button text contains an arrow icon, wihch points up or down when the reference list item is off screen.
 *
 * The purpose of this component is primarly to serve as a indicator toward an off screen action, warning or notification on a scrollable list.
 */
const ListItemScrollNotification: React.FC<IProps> = ({ classes, color, message, listRef, listItemRef }: IProps) => {
  const [icon, setIcon] = useState<React.ReactNode | null>(null);

  useOnScreen(listItemRef, (entry) => {
    const scrollTop = listRef?.current?.getBoundingClientRect().y;
    const fileY = entry.boundingClientRect.y;

    if (scrollTop && fileY && !entry.isIntersecting) {
      if (scrollTop < fileY) {
        setIcon(<ArrowDownward />);
      } else if (scrollTop > fileY) {
        setIcon(<ArrowUpward />);
      }
    } else {
      setIcon(null);
    }
  });

  const scrollToListItem = () => {
    listItemRef.current?.scrollIntoView(true);
    listRef.current?.scrollBy(0, -10);
  };

  return (
    <>
      {listItemRef?.current && (
        <Box className={classes.scrollNotification}>
          <Button color={color} variant="contained" endIcon={icon} onClick={scrollToListItem}>
            {message}
          </Button>
        </Box>
      )}
    </>
  );
};

export default withStyles(customStyles)(ListItemScrollNotification);
