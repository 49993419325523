/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { useSelector } from "react-redux";
import { Edit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { useGetProjectQuery, useGetVersionQuery } from "fond/api";
import AutoDesignPanel from "fond/project/panels/AutoDesignPanel";
import { Store } from "fond/types";
import { useAppSelector } from "fond/utils/hooks";
import { Actions, permissionCheck } from "fond/utils/permissions";

import { ITabProps } from "./factory";

/**
 * Main widget component that determines the content rendered within the tab
 */
export const Widget: React.FC<ITabProps> = () => {
  const projectId = useAppSelector((state) => state.project.projectId);
  const versionId = useAppSelector((state) => state.project.versionId);
  const { data: project } = useGetProjectQuery(projectId);
  const { data: version } = useGetVersionQuery(versionId, { skip: !versionId });
  const architecture = version?.Architecture ?? null;

  // other actions within the widget that require license are locked separately
  const canEdit = useSelector(
    (state: Store) => project && permissionCheck(project.Permission.Level, Actions.PROJECT_EDIT) && !project.HasCustomLayerConfig
  );

  if (!project || !version) return null;
  return <AutoDesignPanel project={project} architecture={architecture} readOnly={!canEdit} key={version.ID} />;
};

/**
 * Icon component used by the iconFactory to generate the tabs left icon adornment.
 */
export const Icon: React.FC<ITabProps> = () => (
  <Tooltip title="FOND Designer" arrow placement="right">
    <Edit />
  </Tooltip>
);
