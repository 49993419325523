import * as React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { Box, Button, FormControl, FormHelperText, FormLabel, Theme, Tooltip } from "@mui/material";
import { blue } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

export type ButtonGroupProps = {
  name: string;
  options: Array<{
    value: string;
    icon: React.ReactNode;
    tooltipTitle: string;
    tooltipBody: string;
    disabled?: boolean;
  }>;
  "data-testid"?: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  validate?: any;
};

const ButtonGroupField: React.FC<ButtonGroupProps> = (props: ButtonGroupProps) => {
  const { name, options, fieldProps, validate, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input, meta }) => <ButtonGroupWrapper input={input} meta={meta} name={name} options={options} {...rest} />}
      validate={validate}
      {...fieldProps}
    />
  );
};

type ButtonGroupWrapperProps = Pick<ButtonGroupProps, "options"> & FieldRenderProps<string, HTMLElement>;

const useCustomStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    "& > :not(:last-child)": {
      marginRight: theme.spacing(1),
    },
    "& button": {
      minWidth: 45,
      width: 45,
      height: 30,
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[300],
      boxShadow: "none",
      border: `1.5px solid ${theme.palette.grey[300]}`,
      "&.selected": {
        color: theme.palette.primary.main,
        border: `1.5px solid ${theme.palette.primary.main}`,
        backgroundColor: blue[50],
      },
    },
  },
}));

const ButtonGroupWrapper: React.FC<ButtonGroupWrapperProps> = ({
  input: { name, onChange, value, ...restInput },
  label,
  meta,
  options,
  disabled,
  required,
  helperText,
}: ButtonGroupWrapperProps) => {
  const hasError = meta.error && meta.touched;
  const classes = useCustomStyles();

  const handleOnClick = (newValue: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    onChange(newValue);
  };

  return (
    <FormControl fullWidth data-testid={`${name}-buttonGroup-field`}>
      {(label || required) && (
        <FormLabel required={required} error={hasError}>
          {label}
        </FormLabel>
      )}

      <Box className={classes.root}>
        {options.map((option) => (
          <Tooltip
            key={option.value}
            title={
              <>
                <strong>{option.tooltipTitle}</strong>
                <br />
                {option.tooltipBody}
              </>
            }
          >
            <Button
              size="small"
              variant="contained"
              color="inherit"
              disabled={disabled || option.disabled}
              onClick={handleOnClick(option.value)}
              data-testid={`button-group-item-${option.value}`}
              className={value === option.value ? "selected" : ""}
              fullWidth={false}
            >
              {option.icon}
            </Button>
          </Tooltip>
        ))}
      </Box>

      {(hasError || helperText) && <FormHelperText error={hasError}>{meta.touched ? meta.error : helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ButtonGroupField;
