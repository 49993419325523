import React from "react";
import { Box, Divider, MenuItem, Tab, Tabs, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import { useGetAccountModulesQuery } from "fond/api";
import { Section, SectionHeading } from "fond/form";
import { FlexNapFeature, PlannerFeatures, Tier1HubType, Tier2CableType } from "fond/types";
import { accountModuleCheck, Actions } from "fond/utils/permissions";

import BomTab from "./bom/BomTab";
import DemandTab from "./demand/DemandTab";
import ArchitectureField, { fieldDescriptions } from "./ArchitectureField";
import { getPreferredLabel } from "./architecturePreferredLabel";
import { editBomRow, selectBomCategory, selectTab, updateArchitecture, useArchitectureEditorContext } from "./context";
import TopologyFields from "./TopologyFields";

interface IProps {
  project: Project;
}

const useArchitectureEditorStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexFlow: "column",
    gridTemplateRows: "min-content min-content auto",
  },
  tabContent: {
    overflow: "auto",
    padding: theme.spacing(3),
  },
  fixedHeaderSection: {
    position: "sticky",
    top: 0,
    width: "inherit",
    backgroundColor: "white",
    padding: theme.spacing(3),
    paddingBottom: 0,
    zIndex: 1000,
  },
  nameRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  nameWidgetCell: {
    display: "block",
  },
  nameLabelCell: {
    display: "flex",
    flex: "1 0",
  },
  textArea: {
    minHeight: 75,
  },
}));

const ArchitectureEditor: React.FC<IProps> = ({ project }: IProps) => {
  const [
    {
      widgetArchitecture,
      selectedTabIndex,
      selectedBomCategoryID,
      editingBomRowID,
      highestConfiguredTabIndex,
      bomCategories,
      validationResults,
      systemOfMeasurement,
    },
    dispatch,
  ] = useArchitectureEditorContext();
  const classes = useArchitectureEditorStyles();
  const { data: accountModules } = useGetAccountModulesQuery(project.Account.ID);
  const canEditFlexNap = accountModuleCheck(accountModules, Actions.ARCHITECTURE_ENABLE_FLEXNAP);
  const preference: PlannerFeatures | undefined = canEditFlexNap ? FlexNapFeature : undefined;

  const setState = (path: string[], value: any) => {
    dispatch(updateArchitecture([{ path: path, value: value }]));
  };

  const tier1HubTypeOptions = [
    { value: Tier1HubType.Evolv, text: "Evolv" },
    { value: Tier1HubType.LongSpanEvolv, text: "Long Span Evolv" },
  ];

  const tier2CableTypeOptions = [
    { value: Tier2CableType.ArmoredAltos, text: "Armored ALTOS" },
    { value: Tier2CableType.DielectricAltos, text: "Dielectric ALTOS" },
    { value: Tier2CableType.Rpx, text: "RPX" },
    { value: Tier2CableType.Figure8, text: "Figure 8" },
  ];

  // Use hasUpdatedDemandModel
  const setDemandModelState = (path: string[], value: any) => {
    dispatch(
      updateArchitecture([
        { path, value },
        { path: ["hasUpdatedDemandModel"], value: true },
      ])
    );
  };

  // Use hasUpdatedFlexNap to indicate if the FlexNap value has been changed.
  const setFlexNapState = (path: string[], value: any) => {
    dispatch(
      updateArchitecture([
        { path: path, value: value },
        { path: ["hasUpdatedFlexNap"], value: !widgetArchitecture.hasUpdatedFlexNap },
      ])
    );

    if (value) {
      // Set a few flexnap fields to the default values.
      dispatch(
        updateArchitecture([
          { path: ["NumberOfTiers"], value: 2 },
          { path: ["Tier1", "Hubs", "Type"], value: Tier1HubType.Evolv },
          { path: ["Tier2", "Cables", "Type"], value: Tier2CableType.ArmoredAltos },
        ])
      );
    } else {
      // Clear flexnap fields.
      dispatch(
        updateArchitecture([
          { path: ["Tier1", "Hubs", "Type"], value: null },
          { path: ["Tier1", "Hubs", "AllowDirectHomeConnect"], value: false },
          { path: ["Tier2", "Cables", "Type"], value: null },
        ])
      );
    }
  };

  // `Name` and `Description` are the only fields that don't use the `Field` component
  // since they're the only fields that don't follow the pattern of having
  // a label on the left and a widget on the right.
  const handleSelectTab = (event: React.SyntheticEvent<Element, Event>, tabIndex: number) => {
    dispatch(selectTab(tabIndex));
  };

  const stateHook: [any, any] = [widgetArchitecture, setState];
  const stateFlexNapHook: [any, any] = [widgetArchitecture, setFlexNapState];
  const stateDemandModelHook: [any, any] = [widgetArchitecture, setDemandModelState];

  let tabs = [
    {
      label: "General",
      id: "General",
      render: () => (
        <div>
          <Section>
            <SectionHeading>Details</SectionHeading>
            <ArchitectureField hook={stateHook} label="Name" type="text" path={["Name"]} required variant="outlined" width={300} />
            <ArchitectureField hook={stateHook} type="textarea" label="Description" path={["Description"]} variant="outlined" />
            <ArchitectureField
              hook={stateHook}
              label="Number of tiers"
              path={["NumberOfTiers"]}
              type="dropdown"
              disabled={widgetArchitecture.IsFlexNap}
              options={[
                <MenuItem key={2} value={2}>
                  2
                </MenuItem>,
                <MenuItem key={3} value={3}>
                  3
                </MenuItem>,
              ]}
            />
            <ArchitectureField
              hook={stateHook}
              label="Placement preference"
              path={["DefaultPlacement"]}
              type="dropdown"
              options={[
                <MenuItem key="aerial" value="aerial">
                  Prefer to use aerial
                </MenuItem>,
                <MenuItem key="underground" value="underground">
                  Preference aerial and underground equally
                </MenuItem>,
              ]}
            />
            {canEditFlexNap && <ArchitectureField hook={stateFlexNapHook} label="Enable FlexNAP" type="switch" path={["IsFlexNap"]} />}
          </Section>
        </div>
      ),
    },
    {
      label: "Drop Tier",
      id: "Tier1",
      tierNum: 1,
      render: () => (
        <div>
          <Section>
            <SectionHeading>Drop Cables</SectionHeading>

            <ArchitectureField hook={stateHook} label="Cable sizes" type="chips" path={["Tier1", "Cables", "Sizes"]} required />

            <ArchitectureField hook={stateHook} label="Expand Address Types" type="switch" path={["Tier1", "Cables", "ExpandAddresses"]} />

            <ArchitectureField
              hook={stateHook}
              label="Maximum cable length"
              type="length"
              path={["Tier1", "DropRules", "DropLength"]}
              required
              width={192}
            />

            <ArchitectureField
              hook={stateHook}
              label="Maximum poles per drop cable"
              type="text"
              path={["Tier1", "DropRules", "PolesPerDrop"]}
              required
              width={96}
            />

            <ArchitectureField
              hook={stateHook}
              label="Maximum underground structures per drop cable"
              type="text"
              path={["Tier1", "DropRules", "PitsPerDrop"]}
              required
              width={96}
            />
          </Section>

          <Section>
            <SectionHeading>{getPreferredLabel(preference, ["Tier1", "Hubs", "Title"]) || "Drop Hubs"}</SectionHeading>

            <ArchitectureField
              hook={stateHook}
              label="Hub port counts"
              preference={preference}
              type="chips"
              path={["Tier1", "Hubs", "Sizes"]}
              required
              data-testid="drop-hub-port-counts-input"
            />

            {!widgetArchitecture.IsFlexNap && (
              <ArchitectureField
                hook={stateHook}
                label="Split ratio"
                preference={preference}
                path={["Tier1", "Hubs", "SplitRatio"]}
                type="dropdown"
                options={[
                  <MenuItem key={1} value={1}>
                    None
                  </MenuItem>,
                  <MenuItem key={2} value={2}>
                    1:2
                  </MenuItem>,
                  <MenuItem key={4} value={4}>
                    1:4
                  </MenuItem>,
                  <MenuItem key={8} value={8}>
                    1:8
                  </MenuItem>,
                  <MenuItem key={16} value={16}>
                    1:16
                  </MenuItem>,
                ]}
                width={96}
              />
            )}

            {widgetArchitecture.IsFlexNap && (
              <ArchitectureField
                hook={stateHook}
                label="Hub Type"
                preference={preference}
                path={["Tier1", "Hubs", "Type"]}
                type="dropdown"
                required
                options={tier1HubTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
                width={200}
              />
            )}

            <ArchitectureField hook={stateHook} label="Spare ports" type="spareports" path={["Tier1", "Hubs", "Spare"]} />

            <ArchitectureField hook={stateHook} label="Connect spare ports upstream" type="switch" path={["Tier1", "Hubs", "LightSparePorts"]} />

            {widgetArchitecture.IsFlexNap && (
              <ArchitectureField
                hook={stateHook}
                label="Allow direct home connection"
                type="switch"
                path={["Tier1", "Hubs", "AllowDirectHomeConnect"]}
              />
            )}
          </Section>
        </div>
      ),
    },
    {
      label: "Distribution Tier",
      id: "Tier2",
      tierNum: 2,
      render: () => (
        <div>
          {!widgetArchitecture.IsFlexNap && (
            <Section>
              <SectionHeading>
                {getPreferredLabel(preference, ["Tier2", "ConnectorizedDropTerminals", "Title"]) || "Connectorized Drop Hubs"}
              </SectionHeading>

              <ArchitectureField
                hook={stateHook}
                label="Use connectorized drop hubs"
                preference={preference}
                type="switch"
                path={["Tier2", "ConnectorizedDropTerminals", "Enabled"]}
              />

              {widgetArchitecture.Tier2.ConnectorizedDropTerminals.Enabled && (
                <>
                  {!canEditFlexNap && (
                    <ArchitectureField
                      hook={stateHook}
                      label="Daisy chain drop hubs"
                      type="switch"
                      path={["Tier2", "ConnectorizedDropTerminals", "DaisyChain"]}
                    />
                  )}
                  <ArchitectureField
                    hook={stateHook}
                    label="Tail cable sizes"
                    type="chips"
                    path={["Tier2", "ConnectorizedDropTerminals", "TailCableSizes"]}
                    required
                  />
                  <ArchitectureField
                    hook={stateHook}
                    label="Maximum tail length"
                    type="length"
                    path={["Tier2", "ConnectorizedDropTerminals", "MaxTailLength"]}
                    required
                    width={192}
                  />
                  <ArchitectureField
                    hook={stateHook}
                    label="Number of tails per splice"
                    preference={preference}
                    type="text"
                    path={["Tier2", "ConnectorizedDropTerminals", "TailsPerSplice"]}
                    required
                    width={96}
                  />
                </>
              )}
            </Section>
          )}

          <Section>
            <SectionHeading>Distribution Cables</SectionHeading>

            <ArchitectureField hook={stateHook} label="Cable sizes" type="chips" path={["Tier2", "Cables", "Sizes"]} required />

            {widgetArchitecture.IsFlexNap && (
              <ArchitectureField
                hook={stateHook}
                label="Cable type"
                path={["Tier2", "Cables", "Type"]}
                type="dropdown"
                required
                options={tier2CableTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
                width={200}
              />
            )}
            <ArchitectureField hook={stateHook} label="Spare fibers" path={["Tier2", "Cables", "Spare"]} type="sparefibers" />

            {!widgetArchitecture.IsFlexNap && (
              <ArchitectureField hook={stateHook} label="Taper cables" type="switch" path={["Tier2", "Cables", "TaperCables"]} />
            )}
          </Section>

          <Section>
            <SectionHeading>{getPreferredLabel(preference, ["Tier2", "Hubs", "Title"]) || "Distribution Hubs"}</SectionHeading>

            <ArchitectureField
              hook={stateHook}
              label="Hub port counts"
              preference={preference}
              type="chips"
              path={["Tier2", "Hubs", "Sizes"]}
              required
            />

            <ArchitectureField
              hook={stateHook}
              label="Split ratio"
              path={["Tier2", "Hubs", "SplitRatio"]}
              type="dropdown"
              options={[
                <MenuItem key={1} value={1}>
                  None
                </MenuItem>,
                ...[2, 4, 8, 16, 32, 64].map((n) => <MenuItem key={n} value={n}>{`1:${n}`}</MenuItem>),
              ]}
              width={96}
            />

            <ArchitectureField hook={stateHook} label="Spare ports" type="spareports" path={["Tier2", "Hubs", "Spare"]} />

            <ArchitectureField hook={stateHook} label="Connect spare ports upstream" type="switch" path={["Tier2", "Hubs", "LightSparePorts"]} />

            {!widgetArchitecture.IsFlexNap && (
              <ArchitectureField
                hook={stateHook}
                label="Unsplit fiber ports"
                preference={preference}
                type="text"
                path={["Tier2", "Hubs", "UnsplitPorts"]}
                required
                width={96}
              />
            )}
          </Section>

          {!widgetArchitecture.IsFlexNap && (
            <Section>
              <SectionHeading>Topology</SectionHeading>

              <TopologyFields hook={stateHook} path={["Tier2", "Topology"]} fieldDescriptions={fieldDescriptions} />
            </Section>
          )}
        </div>
      ),
    },
    {
      label: "Feeder Tier",
      id: "Tier3",
      tierNum: 3,
      render: () => (
        <div>
          <Section>
            <SectionHeading>Feeder Cables</SectionHeading>
            <ArchitectureField hook={stateHook} label="Cable sizes" type="chips" path={["Tier3", "Cables", "Sizes"]} required />
            <ArchitectureField hook={stateHook} label="Spare fibers" type="sparefibers" path={["Tier3", "Cables", "Spare"]} />
            <ArchitectureField hook={stateHook} label="Taper cables" type="switch" path={["Tier3", "Cables", "TaperCables"]} />
          </Section>

          <Section>
            <SectionHeading>Feeder Hubs</SectionHeading>
            <ArchitectureField
              hook={stateHook}
              label="Hub demand counts"
              type="chips"
              path={["Tier3", "Hubs", "Sizes"]}
              fieldDescriptions={fieldDescriptions}
              required
            />
          </Section>

          {!widgetArchitecture.IsFlexNap && (
            <Section>
              <SectionHeading>Topology</SectionHeading>
              <TopologyFields hook={stateHook} path={["Tier3", "Topology"]} fieldDescriptions={fieldDescriptions} />
            </Section>
          )}
        </div>
      ),
    },
    {
      label: "Demand Model",
      id: "Demand",
      render: () => (
        <Box className="customScrollbars">
          <DemandTab hook={stateDemandModelHook} />
        </Box>
      ),
    },
    {
      label: "BOM",
      id: "BOM",
      render: () => (
        <div>
          <BomTab
            arch={widgetArchitecture}
            hook={stateHook}
            path={["BOM"]}
            categories={bomCategories}
            selectedCategoryID={selectedBomCategoryID}
            onSelectCategory={(categoryID) => dispatch(selectBomCategory(categoryID))}
            editingRowID={editingBomRowID}
            onEditRow={(rowID) => dispatch(editBomRow(rowID))}
            validationResults={validationResults}
            systemOfMeasurement={systemOfMeasurement}
          />
        </div>
      ),
    },
  ];

  tabs = tabs.filter((t) => t.tierNum == null || t.tierNum <= widgetArchitecture.NumberOfTiers).filter((t) => t.id !== "BOM" || !canEditFlexNap);

  return (
    <div className={classes.root}>
      <div className={classes.fixedHeaderSection}>
        <Tabs
          data-testid="tabs"
          value={selectedTabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleSelectTab}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab, i) => {
            return (
              <Tab
                key={i}
                label={tab.label}
                data-testid={`tab-${tab.id}`}
                disabled={
                  // If the arch is configured, we can go anywhere.
                  !widgetArchitecture.IsConfigured &&
                  // Otherwise, we can go up to any tab that has been configured.
                  i > highestConfiguredTabIndex
                }
                aria-controls={`tab-${i}`}
              />
            );
          })}
        </Tabs>
        <Divider />
      </div>

      <div
        className={classNames(classes.tabContent, "customScrollbars")}
        // This is really important!
        // Without the 'widgetArchitecture.ID' part React may not re-render the ArchitectureField components
        // when a different architecture is selected from the list, even though it has
        // different values, since the value will already be in the component's state.
        //
        // Without the 'selectedTabIndex' part React may reuse ArchitectureField components
        // across multiple tabs, which can result in a field being rendered
        // with the wrong value since the value will already be in the component's state.
        key={`${widgetArchitecture.ID}-${selectedTabIndex}`}
        data-testid={`tab-content--${selectedTabIndex}`}
      >
        {tabs[selectedTabIndex].render()}
      </div>
    </div>
  );
};

export default ArchitectureEditor;
