import { createContext, useCallback, useMemo, useRef, useState } from "react";
import * as React from "react";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Feature, GeoJsonProperties, Geometry, Position } from "geojson";
import { FeatureIdentifier, Map } from "mapbox-gl";
import { useSnackbar } from "notistack";

import { MapStyle } from "../types";

import { getMapStyle } from "./styles";

/**
 * This type represents the additional methods that we add to a MapboxDraw instance in fond/draw/store.js
 * If methods are added/changed there you may need to update this type.
 */
type FondMapboxDraw = MapboxDraw & {
  getFeatureById: (id: string | number) => void;
  isDeletedFeature: (id: string | number) => void;
  isEditingFeature: (id: string | number) => void;
  iterFeatures: (layerId: string) => [Feature, boolean];
  pullInFeature: (layerId: string, feature: Feature) => void;
  pullInFeatures: (features: Array<{ layerId: string; feature: Feature }>) => void;
  revertAll: () => void;
  markFeatureDeleted: (featureId?: string | number) => void;
  _markFeatureDeleted: (featureId?: string | number) => void;
  pullInPole: (clickedPole: Feature) => void;
  commitPole: (args: {
    selectedPole: Feature<Point>;
    originalPole: Feature<Point> | null;
    connectedSpans: Feature<LineString>[] | [];
    snap: { feature: Feature; coords: number[] } | null;
  }) => void;
  mergePoles: (selectedPole: Feature<Point>) => void;
  commitSpans: (args: { lineString: GeoJSON; snaps: Array<{ feature: Feature<Geometry, GeoJsonProperties>; coords: Position }> }) => void;
  splitSpanAtCoords: (span: Feature<Geometry, GeoJsonProperties>, coords: Position) => void;
  refreshUnsnappedPoles: () => void;
  markPoleSnapped: (poleId: string | number | undefined, isSnapped: boolean) => void;
};

/**
 * The MapContext allows us to provide child components with access to the map instance
 * and other common functions used across several components.
 */

type UserPosition = {
  center: number[];
  accuracy: number;
};

export const MapContext = createContext<{
  /**
   * The mapbox gl map instance
   */
  map: Map | undefined;
  drawControl: FondMapboxDraw;
  setDrawControl: (mapboxDraw: FondMapboxDraw) => void;
  setMap(map?: Map): void;
  /**
   * Sets the draw mode on the MapboxDraw control.  Setting the draw mode to "no_feature"
   * will exit drawing mode and remove any current features being drawn.
   */
  setDrawMode(mode: "simple_select" | "draw_point" | "draw_polygon" | "no_feature" | "draw_line_string", options?: { featureIds: string[] }): void;
  /**
   * Handles setting the features state of the features passed.  Commonly used to
   * highlight features on selection.
   */
  setFeatureState({ features, state }: { features: FeatureIdentifier[] | mapboxgl.MapboxGeoJSONFeature[]; state: any }): void;
  /**
   * Values needed for the display of user location on map
   */
  userPosition: UserPosition | null;
  startPositionWatch: () => void;
  stopPositionWatch: () => void;
  /**
   * The style of the map's background.
   *
   * One of map (default), satellite, hybrid, or monochrome.
   */
  mapStyle: MapStyle;
  /**
   * Set a new map background style.
   */
  setMapStyle: (style: MapStyle) => void;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
}>(undefined!);

interface IProps {
  children: React.ReactNode;
  mapStyle?: MapStyle;
}

const MapProvider: React.FC<IProps> = ({ children, mapStyle }: IProps) => {
  const [map, setMap] = useState<Map | undefined>(undefined);
  const [style, setStyle] = useState<MapStyle>(mapStyle || "map");
  const { enqueueSnackbar } = useSnackbar();
  const [drawControl, setDrawControl] = useState<FondMapboxDraw>(
    new MapboxDraw({
      displayControlsDefault: false,
      controls: {},
    }) as FondMapboxDraw
  );
  const [userPosition, setUserPosition] = useState<UserPosition | null>(null);
  const positionWatchId = useRef<number>();

  /**
   * Sets the draw type mode
   */
  const setDrawMode = useCallback(
    (mode: "simple_select" | "draw_point" | "draw_polygon" | "no_feature" | "draw_line_string", options?: { featureIds: string[] }) => {
      if (mode === "no_feature") {
        if (map?.hasControl(drawControl)) {
          // No drawing control required for this mode
          drawControl.deleteAll();
          map?.removeControl(drawControl);
        }
      } else {
        // Add the control if it does not already exist
        if (!map?.hasControl(drawControl)) {
          map?.addControl(drawControl);
        }

        // Change the mode to the required drawing mode
        drawControl.changeMode(mode, options);
      }
    },
    [drawControl, map]
  );

  /**
   * Handles setting the features states
   * For example a featureState { isSelected: true } will hightlight the features
   * (based on mapbox styles)
   */
  const setFeatureState = useCallback(
    ({ features, state }: { features: FeatureIdentifier[] | mapboxgl.MapboxGeoJSONFeature[]; state: any }) => {
      features.forEach((feature) => {
        map?.setFeatureState(
          {
            source: feature.source,
            sourceLayer: feature.sourceLayer,
            id: feature.id,
          },
          state
        );
      });
    },
    [map]
  );

  /**
   * Handles getting of user location and setting of its watcher
   */
  const startPositionWatch = useCallback(() => {
    if (!navigator.geolocation) {
      console.warn("Geolocation is not supported");
      return;
    }

    const handleLocationSuccess = ({ coords: { longitude, latitude, accuracy } }: GeolocationPosition) => {
      setUserPosition({ center: [longitude, latitude], accuracy: accuracy * 0.001 });
    };

    const handleLocationError = (err: GeolocationPositionError) => {
      // Ignore timeout errors
      if (err.code !== 3) {
        enqueueSnackbar(`Error ${err.code}: ${err.message}`);
      }
    };

    navigator.geolocation.getCurrentPosition(
      (position) => {
        handleLocationSuccess(position);
        map?.flyTo({
          center: [position.coords.longitude, position.coords.latitude],
          zoom: Math.max(15, map?.getZoom()),
        });
      },
      handleLocationError,
      { maximumAge: 60000, timeout: 5000 }
    );

    positionWatchId.current = navigator.geolocation.watchPosition(handleLocationSuccess, handleLocationError, {
      maximumAge: 10000,
      enableHighAccuracy: true,
    });
  }, [enqueueSnackbar, map]);

  /**
   * Stops tracking of user location
   */
  const stopPositionWatch = useCallback(() => {
    if (typeof positionWatchId.current !== "undefined") {
      navigator.geolocation.clearWatch(positionWatchId.current);
      setUserPosition(null);
    }
  }, []);

  /**
   * Set a new map style.
   */
  const setMapStyle = useCallback(
    (newMapStyle: MapStyle) => {
      if (map) {
        setStyle(newMapStyle);
        map.setStyle(getMapStyle(newMapStyle).url);
        stopPositionWatch();
      }
    },
    [map, setStyle, stopPositionWatch]
  );

  const value = useMemo(
    () => ({
      map: map,
      drawControl: drawControl,
      setDrawControl: setDrawControl,
      setMap: setMap,
      setDrawMode: setDrawMode,
      setFeatureState: setFeatureState,
      userPosition: userPosition,
      startPositionWatch: startPositionWatch,
      stopPositionWatch: stopPositionWatch,
      mapStyle: style,
      setMapStyle: setMapStyle,
    }),
    [drawControl, map, setDrawMode, setFeatureState, startPositionWatch, stopPositionWatch, userPosition]
  );

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};

export default MapProvider;
