import { useEffect } from "react";
import * as React from "react";
import { useParams } from "react-router";

export const stopImpersonating = (): void => {
  window.localStorage.removeItem("impersonate");
};

export const impersonateUser = (email: string): void => {
  window.localStorage.setItem("impersonate", email);
};

export const Impersonate: React.FC = () => {
  const { email } = useParams();

  useEffect(() => {
    if (email === "off") {
      stopImpersonating();
    } else if (email) {
      impersonateUser(email.toLowerCase());
    }
    window.location.href = "/";
  }, []);
  return null;
};
