/* eslint-disable import/prefer-default-export */
import { Actions, BorderNode, DockLocation, Model, TabNode, TabSetNode } from "flexlayout-react";
import { IJsonBorderNode, IJsonModel, IJsonRowNode } from "flexlayout-react/declarations/model/IJsonModel";
import _ from "lodash";

import { widgets } from "fond/layout";
import { LayoutNode, LayoutNodeLocation, LayoutNodeResponse, LayoutNodeType, UserLayoutOrder, Widget } from "fond/types";

import { bordersDefaults, globalDefaults, layoutDefaults } from "../defaultLayout";

/**
 * Helper function for selecting a widget if it exists within the layout.
 * If the widget does not exist it will be added to the left BorderNode.
 */
export const selectWidget = (model: Model, widget: Widget): void => {
  const existingWidget = model.getNodeById(widget);

  if (existingWidget) {
    // Widget already exists - simply select it
    const isSelected = (existingWidget.getParent() as TabSetNode | BorderNode).getSelectedNode?.()?.getId() === widget;

    if (!isSelected) {
      model.doAction(Actions.selectTab(widget));
    }
  } else {
    // Adding the widget to the layout if required
    const borderId = model
      .getBorderSet()
      .getBorders()
      .find((border) => border.getLocation() === DockLocation.LEFT)
      ?.getId();

    if (borderId && widget !== Widget.MapLayout) {
      // Add the widget to the left BorderSet
      model.doAction(Actions.addNode(widgets[widget], borderId, DockLocation.LEFT, -1, true));
    }
  }
};

const setDefaultNodeProperties = (node: LayoutNode) => {
  if (node.type === LayoutNodeType.Border) {
    return Object.assign(node, {
      enableAutoHide: true,
    });
    // read the default tab attributes from widgets
  } else if (node.type === LayoutNodeType.Tab) {
    Object.assign(node, (widgets as any)[node.id]);
    if (node.id === "maps") {
      return Object.assign(node, {
        component: Widget.MapLayout,
      });
    }
  } else if (node.type === LayoutNodeType.TabSet) {
    return Object.assign(node, {
      enableTabStrip: node.id !== Widget.MapLayout,
      enableDrop: node.id !== Widget.MapLayout,
    });
  }
  return node;
};

/**
 *  Remove empty values and unused keys. It is required because not all fields are related to a particular
 *  node type and passing unrelated files into model can lead to unexpected behaviors.
 */
const formatNodeKeys = (node: LayoutNode) => {
  Object.keys(node).forEach((key) => {
    if (
      node[key as keyof LayoutNode] === undefined ||
      node[key as keyof LayoutNode] === null ||
      node[key as keyof LayoutNode].length === 0 ||
      key === "parent" ||
      key === "project"
    ) {
      delete node[key as keyof LayoutNode];
    }
  });
};

// Generates an IJsonModel from a collection of nodes.
export const convertFromModel = (model: Model): LayoutNode[] => {
  const nodes: LayoutNode[] = [];

  // Create a reference for each child node to find the order.
  let childrenOrder: Record<string, number> = {};
  model.visitNodes((node) => {
    node.getChildren().forEach((child, index) => {
      childrenOrder[child.getId()] = index;
    });
  });

  // Convert each node within the Model to a Node we will save in the backend
  model.visitNodes((node) => {
    nodes.push({
      // Common attributes
      id: node.getId(),
      parentId: node.getParent()?.getId(),
      children: node.getChildren().map((child) => child.getId()),
      type: node.getType() as LayoutNodeType,

      // TabSet attributes
      active: (node as TabSetNode).isActive?.(),
      width: (node as TabSetNode).getWidth?.(),
      weight: (node as TabSetNode).getWeight?.(),
      selected: (node as TabSetNode).getSelected?.(),
      maximized: (node as TabSetNode).isMaximized?.(),

      // Tab attributes
      config: (node as TabNode).getConfig?.(),
      name: (node as TabNode).getName?.(),
      order: childrenOrder[node.getId()],

      // Border attributes
      size: (node as BorderNode).getSize?.(),
      location: (node as BorderNode).getLocation?.().getName() as LayoutNodeLocation,
    });
  });

  return nodes;
};

// Convert keys to camel case.
const convertToCamelCase = (object: LayoutNodeResponse) => {
  return _.mapKeys(object, (value, key) => {
    // Map configs in response to config
    if (key === "Configs") {
      return "config";
    }
    return _.camelCase(key);
  });
};

export const convertToModel = (defaultLayout: IJsonModel, nodes?: LayoutNodeResponse[], showDesignPanel?: boolean): Model => {
  let model: Model;
  if (!nodes || nodes.length === 0) {
    model = Model.fromJson(defaultLayout);
  } else {
    // Convert keys to camel case.
    const camelizedNodes = nodes.map((node) => convertToCamelCase(node));

    // Mutate the nodes into a nested structure based on children & parentId
    const nestedNodes = construct(camelizedNodes as LayoutNode[]);

    // Sort layout to make sure borders are in a particular order.
    const orderedNodes = nestedNodes.sort(
      (a, b) => UserLayoutOrder.findIndex((order) => a.id === order) - UserLayoutOrder.findIndex((order) => b.id === order)
    );

    model = Model.fromJson({
      global: globalDefaults,
      borders: (orderedNodes.filter((node: LayoutNode) => node.type === LayoutNodeType.Border) || bordersDefaults) as IJsonBorderNode[],
      layout: (orderedNodes.find((node: LayoutNode) => node.type === LayoutNodeType.Row) || layoutDefaults) as IJsonRowNode,
    });
  }

  // For FOND projects without a completed design we add & select the
  // Auto Design Panel Widget to help inform the user about how to initiate
  // the design process.
  if (showDesignPanel) selectWidget(model, Widget.DesignPanel);
  return model;
};

// Helper function for converting a flat node structure to a nested one
const construct = (nodes: LayoutNode[]) => {
  const nest = (nodeLists: LayoutNode[]) => {
    nodeLists.forEach((node: LayoutNode) => {
      // Inject defaults
      setDefaultNodeProperties(node);

      // Remove empty values and unused keys.
      formatNodeKeys(node);

      // Move the child objects into the object and order them by order number.
      if (node.children?.length) {
        node.children = node.children
          .map(
            (child: LayoutNode | string) =>
              nodes.splice(
                nodes.findIndex((nestedChild: LayoutNode) => nestedChild.id === child),
                1
              )[0]
          )
          .sort((a, b) => Number(a.order) - Number(b.order));

        nest(node.children as LayoutNode[]);
      }
    });
  };
  nest(nodes);
  return nodes;
};
