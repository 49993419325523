import * as React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { Checkbox, CheckboxProps as MuiCheckboxProps, FormControl, FormControlLabel, FormHelperText, SxProps } from "@mui/material";
import { FieldValidator } from "final-form";

type CheckboxProps = Partial<Omit<MuiCheckboxProps, "onChange">> &
  Pick<React.ComponentProps<typeof FormControlLabel>, "label"> & {
    name: string;
    fieldProps?: Partial<FieldProps<boolean, FieldRenderProps<boolean>>>;
    sxProps?: SxProps;
    defaultChecked?: boolean;
    validate?: FieldValidator<boolean>;
  };

const CheckboxField: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  const { color, fieldProps, label, name, validate, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input, meta }) => <CheckboxWrapper input={input} meta={meta} name={name} color={color} label={label} {...rest} />}
      validate={validate}
      type="checkbox"
      {...fieldProps}
    />
  );
};

type CheckboxWrapperProps = Partial<Omit<MuiCheckboxProps, "onChange">> & FieldRenderProps<boolean, HTMLElement>;

const CheckboxWrapper: React.FC<CheckboxWrapperProps> = ({
  color,
  defaultChecked,
  checked,
  disabled,
  helperText,
  label,
  input: { name, onChange },
  meta,
  size,
  sxProps,
}: CheckboxWrapperProps) => {
  const hasError = (meta.error || meta.submitError) && meta.touched;

  // We need to pass either `defaultChecked` or `checked` to the Checkbox component, but not both
  const checkedStateProps: { defaultChecked?: boolean; checked?: boolean } = {};
  if (checked === undefined) {
    checkedStateProps.defaultChecked = defaultChecked;
  } else {
    checkedStateProps.checked = checked;
  }

  const checkedProp = defaultChecked === undefined ? checked : false;
  return (
    <FormControl data-testid={`${name}-form-field`} fullWidth>
      <FormControlLabel
        control={<Checkbox {...checkedStateProps} name={name} onChange={onChange} disabled={disabled} size={size} color={color} />}
        label={label}
        data-testid={`${name}-form-label`}
        sx={sxProps}
      />
      {(hasError || helperText) && (
        <FormHelperText error={hasError} sx={{ ml: 0 }}>
          {meta.touched ? meta.error || meta.submitError : helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CheckboxField;
