import { useEffect, useImperativeHandle, useRef, useState } from "react";
import * as React from "react";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { ListItemIcon, ListItemText } from "@mui/material";
import { grey } from "@mui/material/colors";
import Menu, { MenuProps as MenuDefaultProps } from "@mui/material/Menu";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { Theme } from "@mui/material/styles";
import { ClassNameMap, makeStyles } from "@mui/styles";
import classNames from "classnames";

/**
 * This file is copied from https://github.com/azmenak/material-ui-nested-menu-item/blob/master/src/index.tsx
 */

const useStyles = makeStyles((theme: Theme) => ({
  rightIcon: {
    position: "relative",
    left: 10,
    color: grey[400],
  },
  label: {
    "& .MuiTypography-root": {
      fontSize: 14,
    },
  },
}));
interface NestedMenuItemProps extends Omit<MenuItemProps, "button"> {
  /**
   * Open state of parent `<Menu />`, used to close decendent menus when the
   * root menu is closed.
   */
  parentMenuOpen: boolean;
  /**
   * Component for the container element.
   * @default 'div'
   */
  component?: React.ElementType;
  /**
   * Effectively becomes the `children` prop passed to the `<MenuItem/>`
   * element.
   */
  label?: React.ReactNode;
  /**
   * @default undefined
   */
  leftIcon?: React.ReactNode;
  /**
  /**
   * @default <ArrowRight />
   */
  rightIcon?: React.ReactNode;
  /**
   * Props passed to container element.
   */
  ContainerProps?: React.HTMLAttributes<HTMLElement> & { ref?: React.Ref<HTMLElement | null> };
  /**
   * Props passed to sub `<Menu/>` element
   */
  MenuProps?: Omit<MenuDefaultProps, "children">;
  /**
   * classes passed to sub `Menu/` element
   */
  menuClasses?: Partial<ClassNameMap<string>>;
  /**
   * Children element
   */
  children?: React.ReactNode;
  /**
   * Classname passed to MenuItem element
   */
  className?: string;
}

/**
 * Use as a drop-in replacement for `<MenuItem>` when you need to add cascading
 * menu elements as children to this component.
 */
const NestedMenuItem = React.forwardRef<HTMLLIElement | null, NestedMenuItemProps>(function NestedMenuItem(props: NestedMenuItemProps, ref) {
  const classes = useStyles();

  const {
    parentMenuOpen,
    label = "",
    leftIcon,
    rightIcon = <ArrowRight className={classes.rightIcon} />,
    children = null,
    className,
    menuClasses,
    MenuProps,
    ContainerProps: ContainerPropsProp = {},
    ...OtherMenuItemProps
  }: NestedMenuItemProps = props;

  const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

  const menuItemRef = useRef<HTMLLIElement>(null);
  useImperativeHandle(ref, () => menuItemRef.current as HTMLLIElement);

  const containerRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(containerRefProp, () => containerRef.current);

  const menuContainerRef = useRef<HTMLDivElement>(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setIsSubMenuOpen(true);

    if (ContainerProps?.onMouseEnter) {
      ContainerProps.onMouseEnter(event);
    }
  };
  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    setIsSubMenuOpen(false);

    if (ContainerProps?.onMouseLeave) {
      ContainerProps.onMouseLeave(event);
    }
  };

  // Check if any immediate children are active
  const isSubmenuFocused = () => {
    const active = containerRef.current?.ownerDocument?.activeElement;
    for (const child of menuContainerRef.current?.children ?? []) {
      if (child === active) {
        return true;
      }
    }
    return false;
  };

  const handleFocus = (event: React.FocusEvent<HTMLElement>) => {
    if (event.target === containerRef.current) {
      setIsSubMenuOpen(true);
    }

    if (ContainerProps?.onFocus) {
      ContainerProps.onFocus(event);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      return;
    }

    if (isSubmenuFocused()) {
      event.stopPropagation();
    }

    const active = containerRef.current?.ownerDocument?.activeElement;

    if (event.key === "ArrowLeft" && isSubmenuFocused()) {
      containerRef.current?.focus();
    }

    if (event.key === "ArrowRight" && event.target === containerRef.current && event.target === active) {
      const firstChild = menuContainerRef.current?.children[0] as HTMLElement | undefined;
      firstChild?.focus();
    }
  };

  const open = isSubMenuOpen && parentMenuOpen;

  // Close the sub menu when the parent menu is opened
  useEffect(() => {
    if (parentMenuOpen) {
      setIsSubMenuOpen(false);
    }
  }, [parentMenuOpen]);

  return (
    <div
      {...ContainerProps}
      ref={containerRef}
      onFocus={handleFocus}
      tabIndex={-1}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
    >
      <MenuItem {...OtherMenuItemProps} className={classNames(className, "root")} ref={menuItemRef}>
        {leftIcon && <ListItemIcon>{leftIcon}</ListItemIcon>}
        <ListItemText className={classes.label}>{label}</ListItemText>
        {rightIcon && <ListItemIcon>{rightIcon}</ListItemIcon>}
      </MenuItem>
      <Menu
        {...MenuProps}
        // Set pointer events to 'none' to prevent the invisible Popover div
        // from capturing events for clicks and hovers
        style={{ pointerEvents: "none" }}
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => {
          setIsSubMenuOpen(false);
        }}
        classes={menuClasses}
      >
        <div ref={menuContainerRef} style={{ pointerEvents: "auto" }}>
          {children}
        </div>
      </Menu>
    </div>
  );
});

export default NestedMenuItem;
