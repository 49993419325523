import * as React from "react";
import { Attachment } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

interface IProps {
  /**
   * Callback function fired when popup calls addAttachment
   */
  addAttachment(): void;
}

const AddQuickFeatureAttachment: React.FC<IProps> = ({ addAttachment }: IProps) => {
  return (
    <Tooltip title="Add attachment" arrow>
      <IconButton size="small" color="primary" onClick={addAttachment} data-testid="add-attachment-button">
        <Attachment />
      </IconButton>
    </Tooltip>
  );
};

export default AddQuickFeatureAttachment;
