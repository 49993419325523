import { useState } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import { detect } from "detect-browser";

/**
 * A component that renders a dialog alerting the user if their browser is not supported.
 * The browser is detected using the detect-browser package. The user can dismiss the dialog
 * which will be recorded in local storage. The dialog will not be shown again after being dismissed
 * (unless localstorage is cleared).
 */
function SupportedBrowser(): React.ReactElement | null {
  const [supported, setSupported] = useState(() => {
    const unsupportedBrowserDismissed = localStorage.getItem("unsupportedBrowserDismissed");
    if (unsupportedBrowserDismissed) return true;

    const browser = detect();
    return browser?.name === "chrome";
  });

  const handleDismiss = () => {
    setSupported(true);
    localStorage.setItem("unsupportedBrowserDismissed", "true");
  };

  if (supported) {
    return null;
  }

  return (
    <Dialog data-testid="unsupported-dialog" open>
      <div>
        <DialogTitle>Unsupported browser</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You're using a web browser we do not currently support. We recommend you use the latest version of&nbsp;
            <Link underline="always" href="https://www.google.com/chrome/" target="_blank">
              Google Chrome
            </Link>{" "}
            for the best possible experience with FOND.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="dismiss-button" onClick={handleDismiss}>
            Dismiss
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default SupportedBrowser;
