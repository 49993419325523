import { Box, styled } from "@mui/material";
import { INotificationCenterStyles } from "@novu/notification-center";

import theme from "../theme";

export const notificationCenterStyles: INotificationCenterStyles = {
  loader: {
    root: {
      stroke: theme.palette.primary.main,
    },
  },
  popover: {
    arrow: {
      marginRight: "5px",
      marginTop: "1px",
    },
  },
  header: {
    title: {
      // h3 based on https://stackoverflow.com/a/30981038
      display: "block",
      marginTop: "1em",
      marginBottom: "1em",
      marginLeft: 0,
      marginRight: 0,
      fontSize: "1.17em",
      fontWeight: "bold",
      color: theme.palette.biarri.secondary.darkGrey,
    },
    markAsRead: {
      color: theme.palette.biarri.secondary.grey,
    },
  },
  notifications: {
    listItem: {
      buttons: {
        primary: {
          // Duplicate Mui Button styling
          background: theme.palette.primary.main,
          borderRadius: theme.shape.borderRadius,
          boxShadow: theme.shadows[1],
          lineHeight: 1.75,
          letterSpacing: "0.02857em",
          "&:hover": {
            background: "#1e88e5",
          },
          transition:
            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
      },
      timestamp: {
        color: "rgba(0, 0, 0, 0.38)",
      },
    },
  },
  actionsMenu: {
    item: {
      color: theme.palette.biarri.secondary.darkGrey,
    },
  },
  preferences: {
    item: {
      title: {
        fontSize: ".83em",
        color: theme.palette.biarri.secondary.darkGrey,
      },
      channels: {
        color: theme.palette.biarri.secondary.grey,
      },
    },
  },
};

export const notificationCenterTheme = {
  header: {
    badgeColor: theme.palette.biarri.primary.red,
  },
  notificationItem: {
    unread: {
      notificationItemBeforeBrandColor: theme.palette.biarri.primary.red,
    },
    read: {
      background: theme.palette.biarri.secondary.whiteSmoke,
    },
  },
  userPreferences: {
    settingsButtonColor: theme.palette.biarri.secondary.activeGrey,
    accordionItem: {
      icon: {
        active: theme.palette.biarri.secondary.activeGrey,
        inactive: theme.palette.biarri.secondary.inactiveGrey,
      },
      fontColor: {
        active: theme.palette.biarri.secondary.darkGrey,
        inactive: theme.palette.biarri.secondary.inactiveGrey,
      },
      switch: {
        backgroundUnchecked: theme.palette.action.disabled,
        backgroundChecked: theme.palette.biarri.secondary.activeBlue,
      },
    },
  },
};

export const WhiteNotificationBell = styled(Box)(({ theme: { palette } }) => ({
  "&&& svg": {
    color: palette.biarri.secondary.whiteSmoke,
  },
}));
