import * as React from "react";
import { green, grey, red } from "@mui/material/colors";
import ToggleButton from "@mui/material/ToggleButton";

import { StyledToggleButton } from "./preferenceFieldButton.styles";

export const enum TrafficLightValue {
  avoid = "avoid",
  neutral = "neutral",
  prefer = "prefer",
}

interface TrafficLightData {
  hue: typeof red | typeof grey | typeof green;
  cost: number;
  text: string;
}

export const trafficLightData: { [key in TrafficLightValue]: TrafficLightData } = {
  [TrafficLightValue.avoid]: { hue: red, cost: 10, text: "Avoid" },
  [TrafficLightValue.neutral]: { hue: grey, cost: 1, text: "Neutral" },
  [TrafficLightValue.prefer]: { hue: green, cost: 0.25, text: "Prefer" },
};

interface PreferenceFieldButtonProps extends React.ComponentProps<typeof ToggleButton> {
  trafficLightValue: TrafficLightValue;
  value: string | number;
}

const PreferenceFieldButton: React.FC<PreferenceFieldButtonProps> = ({ trafficLightValue, value, ...props }: PreferenceFieldButtonProps) => {
  const { cost, hue, text } = trafficLightData[trafficLightValue];
  const isSelected = cost === value || cost.toString() === value;
  return (
    <StyledToggleButton {...props} value={cost.toString()} hue={hue} selected={isSelected}>
      {text}
    </StyledToggleButton>
  );
};

export default PreferenceFieldButton;
