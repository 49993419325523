import * as React from "react";
import { Close } from "@mui/icons-material";
import { IconButton, Toolbar, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useCustomStyles = makeStyles<Theme, { size: "small" | "regular" }>((theme: Theme) => ({
  root: {
    border: "none",
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: ({ size }) => (size === "small" ? 32 : 40),
    "& .MuiSvgIcon-root": {
      width: ({ size }) => (size === "small" ? 16 : undefined),
      height: ({ size }) => (size === "small" ? 16 : undefined),
    },
    "& .MuiButton-root": {
      color: theme.palette.common.white,
      borderRadius: 0,
      boxShadow: "none",
      minWidth: 0,
    },
  },
  highlight: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    flex: "1 1 100%",
    fontSize: ({ size }) => (size === "small" ? theme.typography.caption.fontSize : undefined),
  },
}));

interface IProps {
  /**
   * Actions based on selection to be displayed in the left of the toolbar.
   */
  actions?: React.ReactNode;
  /**
   * The number of rows selected within the datagrid
   */
  selected: number;
  /**
   * The Toolbar title text content
   */
  title?: string;
  /**
   * Size variant
   */
  size?: "small" | "regular";
  /**
   * Callback function to allow the user to clear all selections
   */
  onClear?(): void;
}

/**
 * A DataGrid Toolbar component that provides a set of actions when rows are selected.
 */
const DataGridToolbar: React.FC<IProps> = ({ onClear, selected, title = "", actions = null, size = "regular" }: IProps) => {
  const classes = useCustomStyles({ size });

  return (
    <Toolbar data-testid="datagrid-toolbar" className={classes.root} variant={size === "small" ? "dense" : "regular"}>
      {selected > 0 ? (
        <>
          {onClear && (
            <IconButton onClick={onClear} data-testid="clear-button">
              <Close />
            </IconButton>
          )}
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div" data-testid="datagrid-toolbar-title">
            {`${selected} selected`}{" "}
          </Typography>
        </>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      )}
      {selected > 0 && actions}
    </Toolbar>
  );
};

export default DataGridToolbar;
