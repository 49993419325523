import * as React from "react";
import { Box } from "@mui/material";

import LicenseField from "./LicenseField";
import { SelectionHeader } from "./SubscriptionSelection.style";

const LicenseSelection: React.FC = () => {
  return (
    <Box data-testid="collaboration-options-section">
      <SelectionHeader>Buy licenses</SelectionHeader>
      <LicenseField />
    </Box>
  );
};

export default LicenseSelection;
