import { useCallback, useContext, useEffect } from "react";
import * as React from "react";
import { usePrevious } from "react-use";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

import { MapContext } from "fond/map/MapProvider";
import { length } from "fond/turf";
import { EditMode, SystemOfMeasurement } from "fond/types";
import { toBest } from "fond/utils";

interface BestValue {
  plural: string;
  singular: string;
  unit: string;
  val: number;
}

interface IProps {
  /**
   * The current EditMode of the map.
   */
  editMode: EditMode;
  /**
   * Callback function for when the measured distance changes
   */
  onChange(distance?: string): void;

  /**
   * The ruler's system of measurement.
   */
  systemOfMeasurement?: SystemOfMeasurement;
}

const BaseRuler: React.FC<IProps> = ({ editMode, onChange, systemOfMeasurement }: IProps) => {
  const prevEditMode = usePrevious(editMode);
  const { map, drawControl, setDrawMode } = useContext(MapContext);

  useEffect(() => {
    return () => {
      tearDown();
    };
  }, []);

  // Monitor editMode & if it changes tearDown measuring
  // For example the user starts commenting
  useEffect(() => {
    if (prevEditMode === "measure" && editMode !== "measure") {
      tearDown();
    } else if (prevEditMode !== "measure" && editMode === "measure") {
      startMeasuring();
    }
  }, [editMode]);

  // Monitor for changes in the System of Measurements
  useEffect(() => {
    if (drawControl) {
      updateDistance();
    }
  }, [systemOfMeasurement]);

  /**
   * Toogles being measuring mode
   */
  const startMeasuring = () => {
    map?.on("draw.create", handleOnCreate);
    map?.on("draw.render", updateDistance);
    map?.on("draw.delete", updateDistance);
    map?.on("draw.update", updateDistance);

    setDrawMode("draw_line_string");
  };

  /**
   * When the drawn feature is created we monitor the map
   * for when the user wants to start another measurement
   */
  const handleOnCreate = useCallback((event: MapboxDraw.DrawCreateEvent) => {
    map?.on("click", handleOnMapClick);
  }, []);

  /**
   * When the user clicks on the map we check if the user
   * is attempting to start another measurement.  If so we
   * remove the old features and enter drawing mode again
   */
  const handleOnMapClick = useCallback((event: mapboxgl.MapMouseEvent) => {
    // Check if the user clicking off the drawn measurement
    if (drawControl.getSelected().features.length === 0) {
      map?.off("click", handleOnMapClick);
      drawControl.deleteAll().changeMode("draw_line_string");
    }
  }, []);

  /**
   * Callback for when mapbox draw registers a change in features.
   * When that occurs we determine the new length of the lineString drawn
   */
  const updateDistance = (event?: MapboxDraw.DrawEvent) => {
    if (map?.hasControl(drawControl)) {
      const lineString = drawControl.getAll?.();
      if (lineString) {
        const value = length(lineString, { units: systemOfMeasurement === "metric" ? "meters" : "feet" });
        const distance: BestValue = toBest(value, {
          from: systemOfMeasurement === "metric" ? "m" : "ft",
        });
        onChange(`${distance.val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}${distance.unit}`);
      }
    }
  };

  /**
   * Tear down all the additional map layers, events & source
   */
  const tearDown = () => {
    onChange();
    // If the user was currently drawing remove that
    setDrawMode("no_feature");
    map?.off("click", handleOnMapClick);
    map?.off("draw.create", handleOnCreate);
  };

  return null;
};

export default BaseRuler;
