import { mapboxFonts } from "fond/styleEditor/config/fonts";
import { LayerStyle } from "fond/types/ProjectLayerConfig";
import { normalize } from "fond/utils/number";

/**
 * To accommodate drawing icons that include offsets from the center point
 * we need to allow the user to draw on a canvas bigger than the icon size.
 *
 * Then once all the drawing has completed (multiple styles) we trim the canvas to a size
 * that incorporates all styles drawn.
 *
 * The size here can be adjusted to allow icons with greater offsets if required, but in practise
 * it is expected most styles relating to a feature will be relatively co-located.
 */
export const CANVAS_WIDTH = 200;
export const CANVAS_HEIGHT = 200;
export const ICON_SIZE = 48;
const DEFAULT_FONT_SIZE = 16;
const DEFAULT_COLOR = "#000";
const DEFAULT_OPACITY = 1;

export type SymbolIcon = {
  ID: string;
  Width: number;
  Height: number;
  Image: HTMLImageElement;
};

/**
 * Formats a mapbox font string[] and size into an format usable by the canvas
 *
 * @param font a string[] - note we only use the first item in the array.
 * @param size the font size in pixels
 * @returns string <font-weight> <font-size (px)> <font-family>
 *          e.g. `700 16px Arial`
 */
export const parseFont = (font: string[], size = DEFAULT_FONT_SIZE): string => {
  const mapboxFont = mapboxFonts.find(({ value }) => value === font?.[0]);

  if (mapboxFont) {
    return `${mapboxFont.styles.fontWeight} ${size}px ${mapboxFont.styles.fontFamily}`;
  }

  return `${size}px Open Sans`;
};

export const parseTranslateValue = (translate: [string, string]): { x: number; y: number } => {
  return { x: Number(translate?.[0]) || 0, y: Number(translate?.[1]) || 0 };
};

/**
 * Takes a Line style and renders it. Supported Mapbox styles include:
 * - LineColor
 * - LineWidth
 * - LineCap
 * - LineDasharray
 * - LineGapWidth
 * - LineOpacity
 * @param ctx canvas context
 * @param style LayerStyle being rendered
 */

/**
 * Draws a line that represents a mapbox feature.
 *
 * @param ctx The canvas context
 * @param style The LayerStyle to be rendered
 * @param allStyles A collection of styles for the parent entity (including the current style).
 *                  We require all styles to determine if the line also includes fill
 */
export const drawLineString = (ctx: CanvasRenderingContext2D, style: LayerStyle, allStyles: LayerStyle[]): void => {
  const {
    RawStyles: { LineColor, LineJoin, LineOpacity, LineWidth, LineCap, LineDasharray, LineGapWidth },
  } = style;

  ctx.save();

  const xOffset = ICON_SIZE / 4;
  const lineWidth = LineWidth || 1;
  const lineColor = LineColor;

  ctx.globalAlpha = LineOpacity || DEFAULT_OPACITY;
  ctx.strokeStyle = lineColor;
  ctx.lineWidth = lineWidth;
  ctx.lineCap = LineCap || "butt";
  ctx.lineJoin = LineJoin || "miter";

  // If allstyles includes a fill feature the line should stroke the fill.
  const isFillStroke = allStyles.find((obj) => obj.RawStyles.Type === "fill");

  if (LineDasharray) {
    // We pad out the actual number a little to make it more visible within the small icon
    ctx.setLineDash(LineDasharray.map((str: string) => Number(str) + 2));
  }

  if (isFillStroke) {
    ctx.roundRect(-ICON_SIZE / 2, -ICON_SIZE / 2, ICON_SIZE, ICON_SIZE, 3);
    ctx.stroke();
  } else if (LineGapWidth) {
    // We pad out the actual number a little to make it more visible within the small icon
    const gap = LineGapWidth + 1;
    // We need to draw two lines for Gap
    ctx.beginPath();
    ctx.moveTo(-xOffset, -gap);
    ctx.lineTo(xOffset, -gap);

    ctx.moveTo(-xOffset, 0 + gap);
    ctx.lineTo(xOffset, -0 + gap);

    ctx.stroke();
    ctx.closePath();
  } else {
    ctx.beginPath();
    ctx.moveTo(-xOffset, 0);
    ctx.lineTo(xOffset, 0);
    ctx.stroke();
    ctx.closePath();
  }

  ctx.restore();
};

/**
 * Takes a Font Icon style and renders it. Supported Mapbox styles include:
 * - FontIconCode
 * - FontIconColor
 * - FontIconSize
 * - FontIconHaloColor
 * - FontIconHaloWidth
 * - FontIconRotate
 * - FontIconOffset
 * - FontIconOpacity
 * @param ctx canvas context
 * @param style LayerStyle being rendered
 */
export const drawFontIcon = (ctx: CanvasRenderingContext2D, style: LayerStyle): void => {
  const {
    RawStyles: { FontIconCode, FontIconOpacity, FontIconColor, FontIconSize, FontIconHaloColor, FontIconHaloWidth, FontIconRotate, FontIconOffset },
  } = style;
  const fontSize = FontIconSize || DEFAULT_FONT_SIZE;
  const fontIconColor = FontIconColor || DEFAULT_COLOR;
  const fontIconHaloColor = FontIconHaloColor || DEFAULT_COLOR;

  ctx.save();
  ctx.globalAlpha = FontIconOpacity !== null ? FontIconOpacity : DEFAULT_OPACITY;
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.font = `${fontSize}px fontello Regular`;

  // Icon offset
  if (FontIconOffset) {
    const translate = parseTranslateValue(FontIconOffset);
    ctx.translate(translate.x * fontSize, translate.y * fontSize);
  }

  // Icon rotation
  ctx.rotate((FontIconRotate || 0) * (Math.PI / 180));

  // Icon Halo
  // note that a canvas stroke is centered so a portion of the stroke
  // will be obscured by the fill, therefore we slightly increase the width.
  const strokeWidth = (FontIconHaloWidth || 0) * 1.25;
  if (strokeWidth > 0) {
    ctx.strokeStyle = fontIconHaloColor;
    ctx.lineWidth = strokeWidth * 2;
    ctx.strokeText(String.fromCharCode(FontIconCode), 0, 0);

    ctx.fillStyle = fontIconHaloColor;
    ctx.fillText(String.fromCharCode(FontIconCode), 0, 0);
  }

  // Icon Fill
  ctx.fillStyle = fontIconColor;
  ctx.fillText(String.fromCharCode(FontIconCode), 0, 0);
  ctx.restore();
};

/**
 * Takes a Circle style and renders it. Supported Mapbox styles include:
 * - CircleColor
 * - CircleOpacity
 * - CircleRadius
 * - CircleTranslate
 * - CircleStrokeWidth
 * - CircleStrokeColor
 * @param ctx canvas context
 * @param style LayerStyle being rendered
 */
export const drawCircle = (ctx: CanvasRenderingContext2D, style: LayerStyle): void => {
  const {
    RawStyles: { CircleColor, CircleOpacity, CircleRadius, CircleTranslate, CircleStrokeWidth, CircleStrokeColor, CircleColorInterpolate },
  } = style;
  const circleColor = CircleColor || DEFAULT_COLOR;
  const circleStrokeWidth = CircleStrokeWidth || 0;
  const circleStrokeColor = CircleStrokeColor || DEFAULT_COLOR;
  const radius = CircleRadius || 5;

  ctx.save();
  ctx.globalAlpha = CircleOpacity !== null ? CircleOpacity : DEFAULT_OPACITY;

  // Circle Color Interpolate
  if (CircleColorInterpolate) {
    // Create a linear gradient with evenly spaced stops based on number of
    // stops defined within the CircleColorInterpolate value
    const gradient = ctx.createLinearGradient(-radius, 0, radius, 0);
    for (let i = 0; i < CircleColorInterpolate.length; i += 2) {
      const stop = normalize(i, 0, CircleColorInterpolate.length / 2);
      gradient.addColorStop(stop, CircleColorInterpolate[i + 1]);
    }
    ctx.fillStyle = gradient;
  } else {
    ctx.fillStyle = circleColor;
  }

  // Circle Offset
  if (CircleTranslate) {
    const translate = parseTranslateValue(CircleTranslate);
    ctx.translate(translate.x, translate.y);
  }

  // Circle Fill
  ctx.beginPath();
  ctx.arc(0, 0, radius, 0, 2 * Math.PI);
  ctx.fill();
  ctx.closePath();

  // Circle Stroke
  // Mapbox applies a stroke to the outside of the element, not on the centre line
  // as a canvas does. Therefore the stroke must be on a larger element to simulate
  // mapbox.
  if (circleStrokeWidth > 0) {
    ctx.globalAlpha = 1;
    ctx.beginPath();
    // Draw additional larger circle & stroke it
    ctx.arc(0, 0, radius + circleStrokeWidth / 2, 0, 2 * Math.PI);
    ctx.strokeStyle = circleStrokeColor;
    ctx.lineWidth = circleStrokeWidth;
    ctx.stroke();
    ctx.closePath();
  }
  ctx.restore();
};

/**
 * Takes a Symbol style and renders it. Supported Mapbox styles include:
 * - IconImageID
 * - IconSize
 * - IconRotate
 * - TextColor
 * - TextHaloColor
 * - TextHaloWidth
 * - TextOpacity
 * - TextSize
 * - TextRotate
 * @param ctx canvas context
 * @param style LayerStyle being rendered
 * @param images Colloring of all icons supported by Symbol Icon
 */
export const drawSymbol = (ctx: CanvasRenderingContext2D, style: LayerStyle, icons?: SymbolIcon[]): void => {
  const {
    RawStyles: {
      IconImageID,
      IconOpacity,
      IconSize,
      IconRotate,
      TextColor,
      TextHaloColor,
      TextHaloWidth,
      TextOpacity,
      TextSize,
      TextRotate,
      TextFont,
    },
  } = style;
  const iconOpacity = IconOpacity || DEFAULT_OPACITY;
  const textOpacity = TextOpacity || DEFAULT_OPACITY;
  const textColor = TextColor || DEFAULT_COLOR;

  ctx.save();

  // Draw symbol Image
  if (IconImageID) {
    // Image Opacity
    ctx.globalAlpha = iconOpacity;

    // Get the pre-loaded image information for this IconImageID
    const image = icons?.find(({ ID }) => ID === IconImageID);
    if (image) {
      const width = image.Width * (IconSize || 1);
      const height = image.Height * (IconSize || 1);

      // Image Rotation
      ctx.rotate((IconRotate || 0) * (Math.PI / 180));

      // Draw the image
      ctx.drawImage(image.Image, -width / 2, -height / 2, width, height);
    }
  } else {
    // Text Opacity
    ctx.globalAlpha = textOpacity;

    // Text Rotate
    ctx.rotate((TextRotate || 0) * (Math.PI / 180));
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = parseFont(TextFont, TextSize);

    // Text Stroke
    const strokeWidth = TextHaloWidth || 0;
    if (strokeWidth > 0) {
      ctx.strokeStyle = TextHaloColor || DEFAULT_COLOR;
      ctx.lineWidth = strokeWidth;
      ctx.strokeText("T", 0, 0);
    }

    // Text Fill
    ctx.fillStyle = textColor;
    ctx.fillText("T", 0, 0);
  }

  ctx.restore();
};

/**
 * Draws a rectagle that represents a mapbox Fill feature.
 *
 * @param ctx The canvas context
 * @param style The LayerStyle to be rendered
 */
export const drawFill = (ctx: CanvasRenderingContext2D, style: LayerStyle): void => {
  const {
    RawStyles: { FillColor, FillOpacity, FillOutlineColor },
  } = style;
  const xOffset = ICON_SIZE / 2;
  const yOffset = ICON_SIZE / 2;
  const fillColor = FillColor || DEFAULT_COLOR;
  const fillOutlineColor = FillOutlineColor || DEFAULT_COLOR;

  ctx.save();
  ctx.globalAlpha = FillOpacity || DEFAULT_OPACITY;

  ctx.roundRect(-xOffset, -yOffset, ICON_SIZE, ICON_SIZE, 3);
  ctx.fillStyle = fillColor;
  ctx.fill();

  // Fill Outline
  if (FillOutlineColor) {
    ctx.strokeStyle = fillOutlineColor;
    ctx.lineWidth = 0.5;
    ctx.stroke();
  }

  ctx.restore();
};

/**
 * Creates as HTMLCanvasElement of the required dimensions & utilises devicePixelRatio
 * in an attempt to fix blurry text rendering within the canvas
 */
export const createCanvas = (width: number, height: number, set2dTransform = true): HTMLCanvasElement => {
  const ratio = Math.ceil(window.devicePixelRatio);
  const canvas = document.createElement("canvas");
  canvas.width = width * ratio;
  canvas.height = height * ratio;

  if (set2dTransform) {
    canvas.getContext("2d")?.setTransform(ratio, 0, 0, ratio, 0, 0);
  }
  return canvas;
};
