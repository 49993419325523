import * as React from "react";
import { useSelector } from "react-redux";

import { useGetProjectQuery } from "fond/api";
import { Store } from "fond/types";

import BaseRuler from "./BaseRuler";

interface BestValue {
  plural: string;
  singular: string;
  unit: string;
  val: number;
}

interface IProps {
  /**
   * Callback function for when the measured distance changes
   */
  onChange(distance?: string): void;
}

const Ruler: React.FC<IProps> = ({ onChange }: IProps) => {
  const projectId = useSelector((state: Store) => state.project.projectId);
  const { data: project } = useGetProjectQuery(projectId);
  const editMode = useSelector((state: Store) => state.project.editMode);

  return <BaseRuler editMode={editMode} systemOfMeasurement={project?.SystemOfMeasurement} onChange={onChange} />;
};

export default Ruler;
