import { useMemo, useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { InfoOutlined } from "@mui/icons-material";
import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import classNames from "classnames";

import { InferredIcon } from "fond/map/Legend";
import { getCurrentProjectData } from "fond/project";
import { FeatureListTypes, FeaturesTable } from "fond/project/features";
import { Store } from "fond/types";
import { LayerConfig, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { BlockSpinner, NonIdealState } from "fond/widgets";

import { selectAllVersionLayerConfigsInOrder, selectVersionConfig, useGetVersionStatusQuery } from "../../api";

const customStyles = (theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      width: "100%",
      height: "100%",
      overflow: "auto",
    },
    layers: {
      width: "100%",
      height: "100%",
      maxWidth: 360,
      overflow: "auto",
    },
    list: {
      paddingTop: theme.spacing(0.5),
    },
    icon: {
      minWidth: 24,
    },
    item: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    sublayer: {
      paddingLeft: theme.spacing(2),
    },
    text: {
      lineHeight: 1,
      fontSize: 12,
    },
    polygon: {
      color: theme.palette.secondary.main,
    },
    itemNested: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(0.5),
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /*
   * Feature lists can be composed of all 'design' layers or all 'errors' layers.
   */
  type: FeatureListTypes;
}

const FeaturesList: React.FC<IProps> = ({ classes, type }: IProps) => {
  const data = useSelector((state: Store) => getCurrentProjectData(state.project));
  const versionId = useSelector((state: Store): string => state.project.versionId);
  const { data: status } = useGetVersionStatusQuery(versionId, { skip: !versionId });
  const hasErrors = (status?.Error?.length ?? 0) > 0;
  const [selection, setSelection] = useState<{ layer: LayerConfig; sublayer?: SublayerConfig }>();
  const configuration = useSelector((state: Store) => selectVersionConfig(state, versionId));
  const layerConfigs = useSelector((state: Store) => selectAllVersionLayerConfigsInOrder(state, versionId));

  // Filter the layerConfigs based on the FeatureListType
  const listItems = useMemo(() => {
    const items = layerConfigs.filter(
      (config) =>
        (type === FeatureListTypes.ERRORS && config.Key.startsWith("error/")) ||
        (type === FeatureListTypes.DESIGN && !config.Key.startsWith("error/"))
    );

    setSelection({
      layer: items[0] as LayerConfig,
    });

    return items;
  }, [layerConfigs, versionId]);

  /**
   * Handles the layer selection
   */
  const handleOnClick = (entity: LayerConfig | SublayerConfig) => () => {
    if (entity.Type === "LAYER") {
      setSelection({ layer: entity });
    } else if (entity.Type === "SUBLAYER") {
      const layer = configuration.Data.entities[entity.ParentID];
      if (layer && layer.Type === "LAYER") {
        setSelection({ layer: layer, sublayer: entity });
      }
    }
  };

  // If no layers data has not yet loaded
  if (!layerConfigs || !data) {
    return <BlockSpinner />;
  }

  if (type === FeatureListTypes.ERRORS && !hasErrors) {
    return <NonIdealState icon={<InfoOutlined />} size="small" description="There are currently no design errors relating to this project" />;
  }

  if (type === FeatureListTypes.DESIGN && layerConfigs.length === 0) {
    return <NonIdealState icon={<InfoOutlined />} size="small" description="There is currently no design information for this version" />;
  }

  return (
    <Box className={classes.root} data-testid="layer-attributes-panel">
      <Box width={200}>
        <Box className={classNames(classes.layers, "customScrollbars")}>
          <List dense component="nav" className={classes.list}>
            {listItems.map((layerConfig) => (
              <React.Fragment key={layerConfig.ID}>
                <ListItem
                  key={layerConfig.ID}
                  button
                  divider
                  className={classNames(classes.item, { [classes.sublayer]: layerConfig.Type === "SUBLAYER" })}
                  data-testid="layer-item"
                  onClick={handleOnClick(layerConfig)}
                  selected={(layerConfig.ID === selection?.layer?.ID && !selection?.sublayer) || layerConfig.ID === selection?.sublayer?.ID}
                >
                  <ListItemIcon className={classes.icon}>
                    <InferredIcon entity={layerConfig} config={configuration} />
                  </ListItemIcon>
                  <ListItemText disableTypography className={classes.text}>
                    {layerConfig.Label}
                  </ListItemText>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Box>
      <Box flexGrow={1} pl={3}>
        {selection?.layer != null && (
          <FeaturesTable layerConfig={selection.layer} sublayerConfig={selection.sublayer} showIds={type === FeatureListTypes.ERRORS} />
        )}
      </Box>
    </Box>
  );
};

export default withStyles(customStyles)(FeaturesList);
