import { Avatar, Box, Grid, LinearProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { UserAccountAllocationResponse } from "fond/api";
import { GenericContainer } from "fond/settings/AccountManagement/common.styles";
import { getTotalActiveLicenses } from "fond/settings/logic/license";
import { Subscription } from "fond/types";

interface LicenseUsageProps {
  accountSubscriptions: Subscription[] | undefined;
  allocations: UserAccountAllocationResponse[] | undefined;
}

const LicenseUsage: React.FC<LicenseUsageProps> = ({ accountSubscriptions, allocations }: LicenseUsageProps) => {
  const theme = useTheme();
  const totalActiveLicenses = getTotalActiveLicenses(accountSubscriptions);
  const totalAssignedLicenses = allocations?.filter((allocation) => allocation.License).length || 0;
  const licensesAssignedPercentage = totalActiveLicenses && Math.round((totalAssignedLicenses / totalActiveLicenses) * 100);

  return (
    <Grid container sx={{ padding: 2.5 }} data-testid="license-usage">
      <Grid item xs={6} display="flex">
        <Avatar sx={{ bgcolor: theme.palette.biarri.primary.red }} data-testid="license-usage-avatar" />
        <Typography variant="body1" fontWeight={500} ml={2}>
          Licenses
          <Typography variant="body2" data-testid="total-available-licenses">
            Total &nbsp;
            <b>{`${totalActiveLicenses}`}</b>
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box mt={1} pb={1}>
          <LinearProgress data-testid="license-usage-linear-progress" variant="determinate" color="primary" value={licensesAssignedPercentage} />
        </Box>
        <GenericContainer>
          <Typography variant="body2" data-testid="total-assigned-licenses">
            <b>{`${totalAssignedLicenses}`}</b>
            &nbsp; Assigned
          </Typography>
          <Typography variant="body2">{`${licensesAssignedPercentage}%`}</Typography>
        </GenericContainer>
      </Grid>
    </Grid>
  );
};

export default LicenseUsage;
