import * as React from "react";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import { ListItemIcon, MenuItem } from "@mui/material";

import BulkAttachmentModal from "fond/bulkAttachments/BulkAttachmentModal";
import mixpanel from "fond/mixpanel";
import { PermissionLevel } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";

interface IProps {
  permissionLevel: PermissionLevel;
  handleClose: () => void;
}

const BulkAttachment: React.FC<IProps> = ({ permissionLevel, handleClose }: IProps) => {
  const [isBulkAttachmentModalOpen, setBulkAttachmentModalOpen] = React.useState<boolean>(false);
  const canViewBulkAttachment = permissionCheck(permissionLevel, Actions.PROJECT_VIEW_ATTACHMENT);

  /**
   * Handles opening the Bulk Attachments modal
   */
  const handleBulkAttachment = () => {
    setBulkAttachmentModalOpen(true);
    mixpanel.track("Opened bulk attachments modal");
    handleClose();
  };

  const onModalClose = () => {
    setBulkAttachmentModalOpen(false);
    mixpanel.track("Closed bulk attachments modal");
  };

  return (
    <>
      <MenuItem data-testid="files-button" onClick={handleBulkAttachment} disabled={!canViewBulkAttachment}>
        <ListItemIcon>
          <BurstModeIcon />
        </ListItemIcon>
        Bulk Import
      </MenuItem>
      <BulkAttachmentModal onClose={onModalClose} isOpen={isBulkAttachmentModalOpen} />
    </>
  );
};

export default BulkAttachment;
