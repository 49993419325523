// This file provides types that FOND service translates and returns as a response
// NOT actual Stripe object types

export type SetupIntentClientSecretResponse = {
  ClientSecret: string;
};

export const enum ProductCategory {
  subscription = "subscription",
  license = "license",
  data_pack = "data_pack",
}

export const enum PurchaseType {
  first_time_purchase = "purchase",
  renew = "renew",
}

export const PaymentFrequencies = ["monthly", "quarterly", "yearly"] as const;
export type PaymentFrequency = (typeof PaymentFrequencies)[number];
export type ProductCategories = keyof typeof ProductCategory;

export type ProductPrice = {
  ID: string;
  StripePriceID: string;
  CostCents: number;
  PaymentFrequency: PaymentFrequency;
  OrderID: number;
};

export type Product = {
  ID: string;
  Title: string;
  Prices: ProductPrice[];
  Category: ProductCategories;
};

export type SubscriptionTier = {
  ID: string;
  BaseQuota: number;
  Product: Product;
};

export type LineItem = {
  PriceID: string;
  Quantity: number;
};

type TaxAmount = {
  Amount: number;
  Inclusive: boolean;
  TaxabilityReason: string;
  TaxableAmount: number;
};

type Discount = {
  Coupon: {
    Name: string;
  } & ({ AmountOff: number; PercentOff: null } | { AmountOff: null; PercentOff: number });
};

type TotalDiscountAmounts = {
  Amount: number;
  Discount: Discount;
};

export type SummaryLine = {
  AmountExcludingTax: number;
  Description: string;
  Quantity: number;
};

type SummaryLines = {
  Data: SummaryLine[];
};

type Coupon = {
  Name: string;
};

export type PurchaseSummary = {
  Lines: SummaryLines;
  Subtotal: number;
  Total: number;
  TotalExcludingTax: number;
  TotalTaxAmounts: TaxAmount[];
  TotalDiscountAmounts: TotalDiscountAmounts[];
  CouponApplied: Coupon | null;
};
