/* eslint-disable import/prefer-default-export */
import { Widget } from "fond/types";

import * as commentWidget from "./Comments";
import * as designWidgets from "./Design";
import * as errorWidget from "./ErrorLog";
import { ITabProps } from "./factory";
import * as featuresListWidget from "./FeaturesList";
import * as legendWidget from "./Legend";
import * as mapWidgets from "./Map";
import * as mapSubLayoutWidget from "./SubLayout";
import * as versionsWidget from "./Versions";

// Define a Map collection of Widgets
const Components = new Map<Widget, { Widget: React.FC<ITabProps>; Title?: React.FC<ITabProps>; Icon: React.FC<ITabProps> }>();

Components.set(Widget.Comments, commentWidget);
Components.set(Widget.DesignPanel, designWidgets);
Components.set(Widget.ErrorLog, errorWidget);
Components.set(Widget.FeaturesList, featuresListWidget);
Components.set(Widget.Legend, legendWidget);
Components.set(Widget.Map, mapWidgets);
Components.set(Widget.MapLayout, mapSubLayoutWidget);
Components.set(Widget.Versions, versionsWidget);

export { Components };
export * from "./helper";
