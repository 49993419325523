import * as React from "react";
import { Clear } from "@mui/icons-material";
import { Box, IconButton, Link } from "@mui/material";

import { CountryAlert } from "./PaymentMethod.styles";

interface CountryAlertContainerProps {
  onClick: () => void;
}

const CountryAlertContainer: React.FC<CountryAlertContainerProps> = ({ onClick }) => (
  <CountryAlert
    severity="warning"
    action={
      <Box display="flex" alignItems="center">
        <Link color="primary" href="mailto:bn.sales@biarrinetworks.com" target="_blank" rel="noopener noreferrer">
          CONTACT SALES
        </Link>
        <IconButton sx={{ marginLeft: 0.5 }} size="small" onClick={onClick}>
          <Clear />
        </IconButton>
      </Box>
    }
  >
    To ensure compliance with tax regulations, we are currently only able to accept addresses within the United States. If you have any questions or
    need assistance, please don't hesitate to reach out to our sales team.
  </CountryAlert>
);

export default CountryAlertContainer;
