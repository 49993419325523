import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Button } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";

import { selectUserId, useUpdateInvitationMutation } from "fond/api";
import { ACCOUNT_CONTEXT, SEARCH_KEY } from "fond/constants";
import { useNoAccountRedirect } from "fond/hooks/useNoAccountRedirect";
import { useUpgradePlanner } from "fond/hooks/useUpgradePlanner";
import { Navigation } from "fond/projects";
import SearchResults from "fond/projects/GlobalSearch/SearchResults";
import MainWorkspaceContent from "fond/projects/MainWorkspaceContent";
import { useQueryParams } from "fond/utils/hooks";
import { getItem, setItem } from "fond/utils/localStorage";
import { PageContent } from "fond/widgets";

import PricingTableButton from "../Payment/PricingTable/PricingTableButton";

import NewItemButton from "./NewItemButton";

/**
 * A top level component used for displaying project.
 */
const MainWorkspace: React.FC = () => {
  useNoAccountRedirect();
  const [updateInvitation] = useUpdateInvitationMutation();
  const navigate = useNavigate();
  const searchQuery = useQueryParams(SEARCH_KEY);
  const { pathname } = useLocation();
  const { state: locationState } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const userId = useSelector(selectUserId);
  const { canUpgrade } = useUpgradePlanner();

  useEffect(() => {
    if (!locationState?.invitationId) return;

    const handleInviteAcceptance = async () => {
      const { invitationId } = locationState;
      try {
        const data = await updateInvitation({ invitation: { ID: invitationId, InvitationStatus: "accepted" }, userType: "existing" }).unwrap();
        if (userId && data.User.ID === userId) {
          enqueueSnackbar("Switching account...");
          const currentAccountContext = getItem(ACCOUNT_CONTEXT);
          setItem(ACCOUNT_CONTEXT, currentAccountContext ? { ...currentAccountContext, [userId]: data.AccountID } : { [userId]: data.AccountID });
        } else {
          Sentry.captureMessage("Invited user doesn't match current logged in user");
          navigate("/invitations/invalid");
        }
        navigate(0);
      } catch (error) {
        Sentry.captureException(error);
        navigate("/invitations/invalid", { state: { multipleAccountsError: true } });
      }
    };

    handleInviteAcceptance();
  }, [enqueueSnackbar, locationState, locationState?.invitationId, navigate, updateInvitation, userId]);

  // workaround to redirect / to /workspace
  useEffect(() => {
    if (pathname === "/") navigate("/workspace/");
  }, [navigate, pathname]);

  /**
   * Global search results page
   */
  if (searchQuery) {
    return <SearchResults />;
  }

  return (
    <PageContent
      title="Main Workspace"
      action={
        <>
          <NewItemButton />
          {canUpgrade && (
            <PricingTableButton
              renderButton={(onClick) => (
                <Button variant="contained" onClick={onClick} sx={{ marginLeft: 1 }} data-testid="upgrade-planner-button">
                  Upgrade to planner
                </Button>
              )}
            />
          )}
        </>
      }
      mainContent={<MainWorkspaceContent />}
      navigation={<Navigation />}
      testId="filter-workspace"
    />
  );
};

export default MainWorkspace;
