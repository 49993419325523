import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Theme, Typography } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import _ from "lodash";

import { LoadingButton } from "ui";

import {
  selectCurrentAccount,
  useGetAccountModulesQuery,
  useGetAccountSubscriptionsQuery,
  useGetVersionQuery,
  useGetVersionStatusQuery,
  versionsSlice,
} from "fond/api";
import { AsyncOperationState } from "fond/async/redux";
import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import mixpanel from "fond/mixpanel";
import { getCurrentProject } from "fond/project";
import { canUpdateDesign, StatusTypes } from "fond/project/redux";
import { Project, SolvesControl, Status, Store, SubscriptionStatus, WorkflowQuality } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { accountModuleCheck, Actions } from "fond/utils/permissions";

import MissingFlexNapModuleError from "./errors/MissingFlexNapModuleError";
import { cancel, run } from "./redux";
import SolveQuality from "./SolveQuality";
import UsageProgress from "./UsageProgress";

const customStyles = (theme: Theme) => {
  return createStyles({
    container: {
      textAlign: "center",
      marginTop: theme.spacing(1),
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  readOnly: boolean | undefined;
  status: keyof typeof StatusTypes | null | undefined;
  initialQuality?: WorkflowQuality;
}

const SolveControl: React.FC<IProps> = ({ classes, readOnly, status, initialQuality }: IProps) => {
  const dispatch = useAppDispatch();
  const project = useSelector((state: Store): Project => getCurrentProject(state.project));
  const versionId = useSelector((state: Store): string => state.project.versionId);
  const { data: version } = useGetVersionQuery(versionId, { skip: !versionId });
  const { runStatus, cancelStatus, updateDataStatus } = useSelector((state: Store) => state.solve);
  const { data: versionStatus } = useGetVersionStatusQuery(versionId ?? skipToken);
  const { data: accountModules } = useGetAccountModulesQuery(project?.Account.ID ?? skipToken);

  const hasFlexNapModule = accountModuleCheck(accountModules, Actions.ARCHITECTURE_ENABLE_FLEXNAP);
  const hasRequiredModule = !version?.Architecture?.IsFlexNap || hasFlexNapModule;

  const [workflowQuality, setWorkflowQuality] = useState<WorkflowQuality>(initialQuality ?? "express");

  const canUpdateDesigns = useSelector((state: Store) => canUpdateDesign(state.project, versionStatus, workflowQuality));
  const canEditProject = usePermissionCheck(Actions.PROJECT_EDIT, project.Permission.Level);
  const canUpdate = canUpdateDesigns && canEditProject;

  const selectedAccount = useSelector(selectCurrentAccount);
  const { data: subscriptions } = useGetAccountSubscriptionsQuery(selectedAccount?.ID ?? skipToken);

  const activeSubscription = useMemo(() => subscriptions?.find((sub) => sub.Status === SubscriptionStatus.Active && !!sub.Planner), [subscriptions]);
  const hasQuota = activeSubscription?.Planner && activeSubscription.Planner.QuotaUsage < activeSubscription.Planner.Quota;

  const [control, setControl] = useState<SolvesControl | null>(null);

  const onGenerateDesignClick = useCallback(() => {
    mixpanel.track("Clicked generate design", { quality: workflowQuality });
    dispatch(run(versionId, workflowQuality));
  }, [dispatch, versionId, workflowQuality]);

  const onCancelClick = async () => {
    mixpanel.track("Clicked cancel generate design");
    await dispatch(
      versionsSlice.util.updateQueryData("getVersionStatus", versionId, (draftStatus) => {
        draftStatus.Status = StatusTypes.Cancelling as Status;
      })
    );
    dispatch(cancel(versionId));
  };

  useEffect(() => {
    const controls = {
      generate: {
        dataT: "solve-control-generate-design",
        onClick: onGenerateDesignClick,
        disabled: !canEditProject || !hasQuota || !hasRequiredModule,
        loading: runStatus === AsyncOperationState.executing,
        message: "Generate design",
      },
      update: {
        dataT: "solve-control-update-design",
        onClick: onGenerateDesignClick,
        disabled: !canUpdate || !hasQuota || !hasRequiredModule,
        loading: runStatus === AsyncOperationState.executing,
        message: "Update design",
      },
      cancel: {
        dataT: "solve-control-cancel-design",
        onClick: onCancelClick,
        disabled: cancelStatus === AsyncOperationState.executing,
        loading: false,
        message: "Cancel",
      },
    };

    if (readOnly) {
      setControl(null);
    } else if (_.includes([StatusTypes.Idle, StatusTypes.Cancelled, StatusTypes.Terminated], status)) {
      setControl(controls.generate);
    } else if (status === StatusTypes.Running) {
      setControl(controls.cancel);
    } else if (status === StatusTypes.Complete) {
      setControl(controls.update);
    } else {
      setControl(null);
    }
  }, [readOnly, status, canUpdate, runStatus, cancelStatus, updateDataStatus, activeSubscription, hasRequiredModule, onGenerateDesignClick]);

  return (
    <Box className={classes.container}>
      {readOnly && <Typography variant="body2">You do not have permission to generate a design for this project.</Typography>}

      {!readOnly && control && (
        <>
          {!hasRequiredModule && <MissingFlexNapModuleError />}
          {["solve-control-generate-design", "solve-control-update-design"].includes(control.dataT) && (
            <SolveQuality disabled={control.loading} quality={workflowQuality} onChange={(quality) => setWorkflowQuality(quality)} />
          )}
          <LoadingButton
            data-testid={control.dataT}
            fullWidth
            variant="contained"
            color="primary"
            onClick={control.onClick}
            disabled={control.disabled}
            loading={control.loading}
          >
            {control.message}
          </LoadingButton>
          {activeSubscription?.Planner && <UsageProgress subscription={activeSubscription.Planner} />}
        </>
      )}
    </Box>
  );
};

SolveControl.displayName = "SolveControl";
export default withStyles(customStyles)(SolveControl);
