import { createEntityAdapter, createSelector, Dictionary } from "@reduxjs/toolkit";
import { isEqual, omit } from "lodash";
import { createSelectorCreator, defaultMemoize } from "reselect";

import { Configuration, GroupConfig, GroupConfigHydrated, MapLayerConfig, Store, Style, StyleWithoutID } from "fond/types";
import { LayerConfig, LayerConfigHydrated, LayerStyle, SublayerConfig, SublayerConfigHydrated } from "fond/types/ProjectLayerConfig";

import { apiSlice } from "./apiSlice";
import { selectVersionMlcId, transformGetVersionConfigResponse } from "./versionsSlice";

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const draftConfigEntityAdapter = createEntityAdapter<GroupConfig | LayerConfig | SublayerConfig | LayerStyle>({
  selectId: (item) => item.ID,
});

const initialConfigData: Configuration = {
  ID: "",
  Key: "",
  SourceID: "",
  MapChildren: [],
  Data: draftConfigEntityAdapter.getInitialState(),
  Type: "MapLayerConfig",
};

/**
 * Transforms the @LayerStyle type used within the FOND UI to the @Style type
 * required by the serve routes.
 */
export const transformStylePayload = (style: LayerStyle, index?: number): Style =>
  ({
    ...omit(style, "RawStyles", "GlobalPosition"),
    ...style.RawStyles,
    ...(index !== undefined ? { Position: index } : {}),
  }) as Style;

export const transformStyleToLayerStyle = (style: Style | StyleWithoutID, index?: number): LayerStyle => {
  const { ID, ConfigurationID, ConfigurationType, GlobalPosition, Name, MaxZoom, MapboxStyle, MinZoom, Position, ...rawStyles } = style;

  return {
    ConfigurationID: ConfigurationID,
    ConfigurationType: ConfigurationType,
    GlobalPosition: GlobalPosition,
    ID: ID,
    MapboxStyle: MapboxStyle,
    Name: Name,
    Position: Position,
    Type: "STYLE",
    RawStyles: rawStyles,
  } as LayerStyle;
};

/**
 * Transforms the @LayerConfig | @SublayerConfig types used within the FOND UI to the @LayerConfigHydrated | @SublayerConfigHydrated types
 * required by the serve routes.
 */
export const hydrateLayer = (
  draft: Dictionary<GroupConfig | LayerConfig | SublayerConfig | LayerStyle>,
  layer: LayerConfig | SublayerConfig,
  layerIndex?: number
): LayerConfigHydrated | SublayerConfigHydrated => {
  if (layer.Type === "LAYER") {
    return {
      ...layer,
      ...(layerIndex !== undefined ? { Position: layerIndex } : {}),
      Children: layer.Children.map((childId, childIndex) => {
        return hydrateLayer(draft, draft[childId] as LayerConfig | SublayerConfig, childIndex) as SublayerConfigHydrated;
      }),
      Styles: layer.Styles.map((styleId, styleIndex) => transformStylePayload(draft[styleId] as LayerStyle, styleIndex)),
    } as LayerConfigHydrated;
  } else {
    return {
      ...omit(
        { ...layer },
        "GeometryType",
        "Key",
        // For sublayers using the dynamic filtering option the API will not accept
        // the Mapbox property being included (other Sublayers require this).
        ...(layer.Type === "SUBLAYER" && layer.FilterConfiguration?.Type === "other" ? ["FilterConfiguration.Mapbox"] : [])
      ),
      ...(layerIndex !== undefined ? { Position: layerIndex } : {}),
      Styles: layer.Styles.map((styleId, styleIndex) => transformStylePayload(draft[styleId] as LayerStyle, styleIndex)),
    } as SublayerConfigHydrated;
  }
};

/**
 * Transforms the @GroupConfig type used within the FOND UI to the @LayerConfigHydrated | @SublayerConfigHydrated types
 * required by the serve routes.
 */
export const hydrateGroup = (draft: Dictionary<GroupConfig | LayerConfig | SublayerConfig | LayerStyle>, group: GroupConfig): GroupConfigHydrated =>
  ({
    ...group,
    Children: group.Children.map((layerId, childIndex) => hydrateLayer(draft, draft[layerId] as LayerConfig | SublayerConfig, childIndex)),
  }) as GroupConfigHydrated;

export const draftSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getDraft: build.query<Configuration, string>({
      query: (versionConfigId) => `/v2/root-configurations/${versionConfigId}/draft`,
      transformResponse: transformGetVersionConfigResponse,
      providesTags: (result, error, arg) => (result ? [{ type: "Draft", id: arg }] : []),
    }),
    createDraft: build.mutation({
      query: (versionConfigId) => ({
        url: "/v2/root-configurations/draft",
        method: "POST",
        body: { SourceID: versionConfigId },
      }),
      invalidatesTags: (result, error, arg) => (result ? [{ type: "Draft", id: arg }] : []),
    }),
    publishDraft: build.mutation({
      query: (draftId) => ({
        url: `/v2/root-configurations/${draftId}/publish`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => (result ? [{ type: "Draft", id: arg }] : []),
    }),
    deleteDraft: build.mutation({
      query: (draftId) => ({
        url: `/v2/root-configurations/${draftId}`,
        method: "DELETE",
      }),
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useGetDraftQuery, useCreateDraftMutation, usePublishDraftMutation, useDeleteDraftMutation } = draftSlice;

/**
 * Selectors
 */
export const selectDraftData = createDeepEqualSelector([(state) => state, selectVersionMlcId], (state: Store, mapLayerConfigId) => {
  if (!mapLayerConfigId) return initialConfigData;
  const data = createSelector(draftSlice.endpoints.getDraft.select(mapLayerConfigId), (draftResult) => draftResult.data)(state);
  return data ?? initialConfigData;
});

export const {
  selectAll: selectAllDraftConfigs,
  selectById: selectAllDraftConfigEntitiesById,
  selectEntities: selectDraftConfigEntities,
} = draftConfigEntityAdapter.getSelectors((state: Store) => selectDraftData(state).Data);

export const selectAllDraftGroups = createSelector(selectAllDraftConfigs, (entities) => {
  return entities.filter((item) => item.Type === "GROUP") as GroupConfig[];
});

export const selectAllLayerConfigs = createSelector(selectAllDraftConfigs, (entities) => {
  return entities.filter((item) => item.Type === "LAYER") as LayerConfig[];
});

export const selectAllSublayerLayerConfigs = createSelector(selectAllDraftConfigs, (entities) => {
  return entities.filter((item) => item.Type === "SUBLAYER") as LayerConfig[];
});

export const selectAllDraftStyles = createSelector(selectAllDraftConfigs, (entities) => {
  return entities.filter((item) => item.Type === "STYLE") as LayerStyle[];
});

export const selectLayerConfigById = createSelector([selectAllLayerConfigs, (state: Store, id?: string) => id], (layerConfigs, id) => {
  return layerConfigs.find((layerConfig) => layerConfig.ID === id) as LayerConfig;
});

export const selectSublayerConfigById = createSelector([selectAllSublayerLayerConfigs, (state: Store, id?: string) => id], (layerConfigs, id) => {
  return layerConfigs.find((layerConfig) => layerConfig.ID === id) as LayerConfig;
});

export const selectDraftStyleById = createSelector([selectAllDraftStyles, (state: Store, id?: string) => id], (styles, id) => {
  return styles.find((style) => style.ID === id) as LayerStyle;
});

/**
 * Returns the Sublayer within a layerConfig that has a FilterConfiguration Type set to "other"
 */
export const selectSublayerOtherSiblingByParentId = createSelector([selectAllDraftConfigs, (state: Store, id: string) => id], (all, id) => {
  const parent = all.find((layerConfig) => layerConfig.ID === id) as LayerConfig;
  const otherSublayer: SublayerConfig | null = parent?.Children.reduce<SublayerConfig | null>((value, childId) => {
    const child = all.find(({ ID }) => ID === childId) as SublayerConfig;
    return value || (child.FilterConfiguration?.Type === "other" ? child : null);
  }, null);

  return otherSublayer;
});

/**
 * Selector that returns the current entities ancestors
 */
export const selectAncestors = createSelector(
  [selectDraftData, (state: Store, entityId: string) => entityId],
  ({ Data, ID: mclConfigID }, entityId) => {
    const ancestors: Array<MapLayerConfig | GroupConfig | LayerConfig | SublayerConfig | LayerStyle> = [];
    const getAncestorId = (item: GroupConfig | LayerConfig | SublayerConfig | LayerStyle) => {
      switch (item.Type) {
        case "LAYER":
        case "CommentConfig":
          return item.ParentID || mclConfigID;
        case "SUBLAYER":
          return item.ParentID;
        case "STYLE":
          return item.ConfigurationID;
        case "GROUP":
          return item.RootID;
        default:
          throw new Error("Invalid item type");
      }
    };
    const getAncestor = (itemId: string) => {
      const item = Data.entities[itemId];
      if (!item) return;
      const ancestorId = getAncestorId(item);
      if (ancestorId) {
        if (itemId !== entityId) ancestors.push(item);
        getAncestor(ancestorId);
      }
    };
    getAncestor(entityId);

    return ancestors;
  }
);

export const selectLayerConfigByChildId = createSelector(selectAncestors, (ancestors) => ancestors.find((item) => item.Type === "LAYER"));

/**
 * Returns a flat list of layers and sublayers in the correct order based
 * on their root, group & layer ordering.
 *
 */
export const selectAllDraftConfigsInOrder = createSelector(selectDraftData, (configuration) => orderLayersByConfiguration(configuration));

export const orderLayersByConfiguration = ({ Data, MapChildren }: Configuration): Array<LayerConfig | SublayerConfig> => {
  const result: Array<LayerConfig | SublayerConfig> = [];

  // Iterate through the LayerConfigs children & include them into the result
  const iterLayerConfig = (layer: LayerConfig) => {
    if (layer) {
      result.push(layer);
      layer.Children.forEach((childId) => {
        const sublayer = Data.entities[childId] as SublayerConfig;
        if (sublayer) result.push(sublayer);
      });
    }
  };

  // Iterate through the groupConfigs (supporting nested groups that is possible via fond_cli commands)
  const iterGroupConfig = (group: GroupConfig) => {
    group.Children.forEach((childId) => {
      const childEntity = Data.entities[childId] as LayerConfig | GroupConfig;
      if (childEntity && childEntity.Type === "LAYER") iterLayerConfig(childEntity);
      if (childEntity && childEntity.Type === "GROUP") iterGroupConfig(childEntity);
    });
  };

  MapChildren.forEach((rootChildId) => {
    const rootChild = Data.entities[rootChildId];
    if (rootChild?.Type === "GROUP" && "Children" in rootChild) {
      rootChild.Children.forEach((childId) => {
        const child = Data.entities[childId] as LayerConfig | GroupConfig;
        if (child.Type === "LAYER") {
          iterLayerConfig(child);
        } else if (child.Type === "GROUP") {
          iterGroupConfig(child);
        }
      });
    } else if (rootChild?.Type === "LAYER") {
      const layer = Data.entities[rootChildId] as LayerConfig;
      iterLayerConfig(layer);
    }
  });

  return result;
};
