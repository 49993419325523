import { useState } from "react";
import * as React from "react";
import { BarChart } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";

import BomModal from "fond/project/bom/BomModal";
import { Architecture, Project } from "fond/types";

interface IProps {
  project: Project;
  architecture: Architecture | null;
  handleClose: () => void;
}

const Bom: React.FC<IProps> = ({ project, architecture, handleClose }: IProps) => {
  const [bomModal, showBomModal] = useState(false);
  const canViewBom = !project?.HasCustomLayerConfig;

  const handleClick = () => {
    if (canViewBom) {
      showBomModal(true);
      handleClose();
    }
  };

  return (
    <>
      <MenuItem data-testid="bom-button" onClick={handleClick} disabled={!canViewBom}>
        <ListItemIcon>
          <BarChart />
        </ListItemIcon>
        Bills of Materials
      </MenuItem>
      {bomModal && <BomModal project={project} architecture={architecture} onClose={() => showBomModal(false)} />}
    </>
  );
};

export default Bom;
