import { useMemo } from "react";
import * as React from "react";
import { useFormState } from "react-final-form";
import { has, isNull } from "lodash";

import { FondStyleType, StyleField, StyleFieldName } from "fond/types";

import { FieldValue } from "../FieldValue";

import { FieldLabel, SelectedField } from "./displayValue.styles";

interface IProps {
  field: StyleField;
  type: FondStyleType;
  /**
   * Flag indicating if the current value is being edited
   */
  isSelected: boolean;
  /**
   * Callback to handle the selection of the value for editing
   */
  onClick(field: StyleField): void;
}

/**
 * Displays the current value selected for this style or indicates
 * the value is using the default provided by mapbox.
 */
const DisplayValue: React.FC<IProps> = ({ field, isSelected, type, onClick }: IProps) => {
  const { values } = useFormState();
  const { mapboxKey, label, requirements } = field;

  /**
   * Determines if a field has not met the requirements for value setting.
   * i.e. a dependant field has no value, or a dependant field must have a specific value
   */
  const disabled = useMemo((): string[] => {
    const errors: string[] = [];
    if (!requirements) return errors;

    Object.keys(requirements).forEach((key) => {
      const requirement = requirements[key as StyleFieldName];
      if (!has(values, key) || isNull(values[key])) {
        errors.push(`${key} must have a value.`);
      }
      if (Array.isArray(requirement) && !requirement.includes(values[key])) {
        errors.push(`${key} must be set to ${requirement.join(" or ")}.`);
      }
    });

    return errors;
  }, [values]);

  return (
    <SelectedField
      onClick={() => disabled.length === 0 && onClick(field)}
      isSelected={isSelected}
      disabled={disabled.length > 0}
      data-testid="display-value"
    >
      <FieldLabel>{label}</FieldLabel>
      <FieldValue fieldName={field.fieldName} mapboxKey={mapboxKey} type={type} />
    </SelectedField>
  );
};

export default DisplayValue;
