import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as React from "react";
import { Portal } from "react-portal";
import { usePrevious } from "react-use";
import DraftEditor, { EditorPlugin } from "@draft-js-plugins/editor";
import createMentionPlugin, { defaultSuggestionsFilter, MentionData } from "@draft-js-plugins/mention";
import { PositionSuggestionsParams } from "@draft-js-plugins/mention/lib/utils/positionSuggestions";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { EditorState, RawDraftContentState, RichUtils } from "draft-js";
import { isEqual } from "lodash";

import { isValidEmailFormat, makeUuid } from "fond/utils";
import { getEditorState, getStyles, StyleOverrideProp } from "fond/utils/draftEditor";

import HashtagComponent from "./Hashtag";
import MentionComponent from "./Mention";

import "draft-js/dist/Draft.css";
import "@draft-js-plugins/mention/lib/plugin.css";

// Override the default regex that prevents "." and "@"
// This version allows for emails to be typed in
const RICH_TEXT_EDITOR_MENTION_REGEX = "[\\w@.]";
const RICH_TEXT_EDITOR_HASHTAG_REGEX = "[\\w@.&+]";

export interface EditorProps {
  /**
   * Determines if the Editor should take focus automatically on mount
   */
  autoFocus?: boolean;
  /**
   * data-t field used for testing
   */
  "data-testid"?: string;
  /**
   * Css style overrides
   */
  style?: StyleOverrideProp;
  /**
   * Placeholder content for the Editor;
   */
  placeholder?: string;
  /**
   * A collection of users that are able to be @mentioned
   */
  mentionsData?: MentionData[];
  /**
   * A collection of hashtags that are able to be #tagged
   */
  hashtagData?: MentionData[];
  /**
   * Raw JSON
   */
  rawContent?: RawDraftContentState;
  /**
   * Flag indicating if the Editor should render in read only mode
   * Used for "View Mode"
   */
  readOnly?: boolean;
  /**
   * Callback function for when the editor's content changes
   */
  onChange?(state: EditorState): void;
  /**
   * Callback function for when the editor gains focus
   */
  onFocus?(event: React.FocusEvent<HTMLElement>): void;
}

/**
 * A Basic implementation of Draft-JS editor that supports
 * - Keyboard shortcuts for BOLD / ITALIC / UNDERLINE
 * - Mentions and #Tags
 */
const Editor: React.FC<EditorProps> = ({
  autoFocus = false,
  rawContent,
  style,
  mentionsData = [],
  hashtagData = [],
  placeholder = "",
  readOnly = false,
  onChange,
  onFocus,
  "data-testid": dataT,
}: EditorProps) => {
  const [open, setOpen] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [filteredMentions, setFilteredMentions] = useState<MentionData[]>(mentionsData);
  const [filteredHashtags, setFilteredHashtags] = useState<MentionData[]>(hashtagData);
  const [editorState, setEditorState] = useState<EditorState>(getEditorState(rawContent));
  const previousRawContent = usePrevious(rawContent);
  const [key, setKey] = useState(makeUuid());
  const editorRef = useRef<DraftEditor>(null);

  useEffect(() => {
    // Bit of an ugly way to gain focus.  Timeout required to await plugin initialization
    // See: https://github.com/draft-js-plugins/draft-js-plugins/issues/800
    if (autoFocus) setTimeout(editorRef.current?.editor?.focus as TimerHandler, 0);
  }, []);

  /**
   * Monitor the rawContent value passed to the editor
   * and if it changes while in readOnly mode update the value.
   *
   * An example of this is the Comment Popup & Panel being open
   * at the same time & the same comment is being viewed / edited
   * in both locations.
   */
  useEffect(() => {
    if (readOnly && previousRawContent && !isEqual(previousRawContent, rawContent)) {
      setEditorState(getEditorState(rawContent));
      // Force the DraftEditor to re-render
      // (required due to plugins)
      setKey(makeUuid());
    }
  }, [rawContent]);

  /**
   * By default we need to override the popup settings to allow it to sit outside
   * scrollbars / overflow areas
   */
  const popupConfig = (settings: PositionSuggestionsParams): React.CSSProperties => ({
    left: `${settings.decoratorRect.left}px`,
    top: `${settings.decoratorRect.top + 20}px`,
    display: "block",
    transform: "scale(1) translateY(0)",
    transformOrigin: "1em 0% 0px",
    transition: "all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)",
    position: "fixed",
  });

  /**
   * Initialise the @mentions plugin
   */
  const { MentionSuggestions, HashtagSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionTrigger: ["@", "+"],
      mentionRegExp: RICH_TEXT_EDITOR_MENTION_REGEX,
      mentionComponent: MentionComponent,
      positionSuggestions: popupConfig,
    });
    const { MentionSuggestions: Mentions } = mentionPlugin;

    const hashtagsPlugin = createMentionPlugin({
      mentionTrigger: "#",
      mentionPrefix: "#",
      mentionRegExp: RICH_TEXT_EDITOR_HASHTAG_REGEX,
      mentionComponent: HashtagComponent,
      positionSuggestions: popupConfig,
    });
    const { MentionSuggestions: Hashtags } = hashtagsPlugin;

    const pluginList: EditorPlugin[] = [mentionPlugin, hashtagsPlugin];
    return { plugins: pluginList, MentionSuggestions: Mentions, HashtagSuggestions: Hashtags };
  }, []);

  /**
   * Callback function called when the @ trigger is fired
   */
  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      setOpen(isOpen);
    },
    [mentionsData, hashtagData]
  );

  /**
   * Provides the filtered mentions list based on the users search criteria
   */
  const onSearchMentionsChange = useCallback(
    ({ value }: { value: string }) => {
      // Based on what has been typed filter the mentions to only matching suggestions
      const suggestions = defaultSuggestionsFilter(value, mentionsData);
      // If the user has typed a value that is not within the existing results
      // we need to validate it as an email otherwise the user will be presented
      // with a message indicating a valid email must be entered
      if (suggestions.length > 0) {
        setFilteredMentions(suggestions);
      } else if (isValidEmailFormat(value)) {
        // Valid email address - use value as an option
        setFilteredMentions([{ name: value }]);
      } else {
        setFilteredMentions([]);
      }
    },
    [mentionsData]
  );

  /**
   * Provides the filtered mentions list based on the users search criteria
   */
  const onSearchHashtagsChange = useCallback(
    ({ value }: { value: string }) => {
      // Based on what has been typed filter the mentions to only matching suggestions
      const suggestions = defaultSuggestionsFilter(value, hashtagData);
      if (suggestions.length > 0) {
        setFilteredHashtags(suggestions);
      } else if (value !== "") {
        setFilteredHashtags([{ name: value }]);
      } else {
        setFilteredHashtags([]);
      }
    },
    [hashtagData]
  );

  /**
   * inline formatting key commands handles bold, italic, code, underline
   */
  const handleKeyCommand = (command: string) => {
    const state = RichUtils.handleKeyCommand(editorState, command);

    if (state) {
      setEditorState(state);
      onChange?.(state);
      return "handled";
    }

    return "not-handled";
  };

  /**
   * Callback function for managing the EditorState change
   */
  const handleOnChange = useCallback(
    (state: EditorState) => {
      setEditorState(state);
      onChange?.(state);
    },
    [rawContent]
  );

  const handleOnFocus = useCallback((event: any) => {
    if (readOnly) return;
    setHasFocus(true);
    onFocus?.(event);
  }, []);

  const handleOnBlur = useCallback((event: any) => {
    if (readOnly) return;
    setHasFocus(false);
  }, []);

  /**
   * Generate the classname style that overrides editor Css Properties
   */
  const styles = makeStyles((theme: Theme) => ({
    wrapper: {
      ...getStyles(style),
      // Mentions plugin does not provide a efficent way to target specific
      // elements that can be styled so we need to target the id for the mentions-list
    },
    mentions: {
      "& div[id*='mentions-list']:empty::before": {
        fontSize: 11,
        padding: `0 ${theme.spacing(1)}`,
        cursor: "default",
        content: '"Please enter a valid email address"',
      },
    },
    hashtags: {
      "& div[id*='mentions-list']:empty::before": {
        fontSize: 11,
        padding: `0 ${theme.spacing(1)}`,
        cursor: "default",
        content: '"Type to add a new hashtag"',
      },
    },
  }))();

  return (
    <div className={styles.wrapper} data-testid={dataT}>
      <DraftEditor
        key={key}
        ref={editorRef}
        editorState={editorState}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        handleKeyCommand={handleKeyCommand}
        plugins={plugins}
        readOnly={readOnly}
      />
      {/*
       * We only need to create the MentionSuggestions when the editor
       * is in edit mode & has focus.
       */}
      {mentionsData && hasFocus && (
        <Portal>
          <div className={styles.mentions}>
            <MentionSuggestions open={open} onOpenChange={onOpenChange} suggestions={filteredMentions} onSearchChange={onSearchMentionsChange} />
          </div>
        </Portal>
      )}
      {/*
       * We only need to create the HashtagSuggestions when the editor
       * is in edit mode & has focus.
       */}
      {mentionsData && hasFocus && (
        <Portal>
          <div className={styles.hashtags}>
            <HashtagSuggestions open={open} onOpenChange={onOpenChange} suggestions={filteredHashtags} onSearchChange={onSearchHashtagsChange} />
          </div>
        </Portal>
      )}
    </div>
  );
};

Editor.displayName = "Editor";
// Memoize the Editor as the cost of unnecessary re-renders is significant
export default memo(Editor);
