/**
 * Capitalize the first letter of a string.
 *
 *
 * @param str The string to process.
 * @returns The string with its first character capitalized.
 * @example
 *   capitalizeFirstLetter("abc") // "Abc"
 *   capitalizeFirstLetter("")    // ""
 *   capitalizeFirstLetter("a")   // "A""
 */
export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
