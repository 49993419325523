import { makeAsyncActions } from "fond/async/redux";

// The actions are shared between the project reducer and the project polygon reducer.
export const ProjectAction = {
  LOAD_PROJECT: "LOAD_PROJECT",
  LOAD_PROJECT_SUCCESS: "LOAD_PROJECT_SUCCESS",
  LOAD_PROJECT_SUCCESS_CACHED: "LOAD_PROJECT_SUCCESS_CACHED",
  LOAD_PROJECT_NOT_FOUND: "LOAD_PROJECT_NOT_FOUND",
  LOAD_PROJECT_FAILURE: "LOAD_PROJECT_FAILURE",
  LOAD_PROJECT_DATA_SUCCESS: "LOAD_PROJECT_DATA_SUCCESS",
  LOAD_PROJECT_DATA_FAILURE: "LOAD_PROJECT_DATA_FAILURE",
  LOAD_LAYER_PROPERTIES_SCHEMA_SUCCESS: "LOAD_LAYER_PROPERTIES_SCHEMA_SUCCESS",
  LOAD_LAYER_PROPERTIES_SCHEMA_FAILURE: "LOAD_LAYER_PROPERTIES_SCHEMA_FAILURE",
  CLOSE_PROJECT: "CLOSE_PROJECT",

  RUN_SOLVE: makeAsyncActions("SOLVE/RUN"),
  CANCEL_SOLVE: makeAsyncActions("SOLVE/CANCEL"),
  UPDATE_DATA: makeAsyncActions("SOLVE/UPDATE_DATA"),

  DISMISS_LOAD_PROJECT_ERROR: "DISMISS_ERROR", // Works both for project and data errors

  UPDATE_PROJECT_VIEW: "UPDATE_PROJECT_VIEW",
  TOGGLE_LAYER_VISIBILITY: "TOGGLE_LAYER_VISIBILITY",
  SET_LAYER_VISIBILITY: "SET_LAYER_VISIBILITY",
  SET_LAYERS_VISIBILITY: "SET_LAYERS_VISIBILITY",

  OPEN_UPLOAD_PANEL: "OPEN_UPLOAD_PANEL",
  CLOSE_UPLOAD_PANEL: "CLOSE_UPLOAD_PANEL",
  DISMISS_UPLOAD_ERROR: "DISMISS_UPLOAD_ERROR",

  BEGIN_POLYGON_SELECT: "BEGIN_POLYGON_SELECT",
  POLYGON_SELECT_UPLOAD: "POLYGON_SELECT_UPLOAD",
  CLOSE_POLYGON_SELECT: "CLOSE_POLYGON_SELECT",

  OPEN_CONFIRM_MODAL: "OPEN_CONFIRM_MODAL",

  UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
  RESET_UPLOAD: "RESET_UPLOAD",

  REVERT_HUBS: "REVERT_HUBS",

  BEGIN_EDITING: "BEGIN_EDITING",
  EDITING_SET_DIRTY: "EDITING_SET_DIRTY",
  EDITING_REVERT: "EDITING_REVERT",
  SAVE_EDITING: makeAsyncActions("SAVE_EDITING"),
  CLOSE_EDITING: "CLOSE_EDITING",

  BEGIN_COMMENTING: "BEGIN_COMMENTING",
  END_COMMENTING: "END_COMMENTING",

  BEGIN_MEASURING: "BEGIN_MEASURING",
  END_MEASURING: "END_MEASURING",

  UPLOAD_EVENT: "UPLOAD_EVENT",

  OPEN_ARCHITECTURE_MODAL: "OPEN_ARCHITECTURE_MODAL",
  CLOSE_ARCHITECTURE_MODAL: "CLOSE_ARCHITECTURE_MODAL",

  OPEN_IMPORT_MODEL: "OPEN_IMPORT_MODEL",
  CLOSE_IMPORT_MODEL: "CLOSE_IMPORT_MODEL",

  CONFIRM_LAYER_OPERATION: "CONFIRM_LAYER_OPERATION",
  CANCEL_LAYER_OPERATION: "CANCEL_LAYER_OPERATION",

  UPDATE_SYSTEM_OF_MEASUREMENT: makeAsyncActions("UPDATE_SYSTEM_OF_MEASUREMENT"),

  SELECT_FEATURE: "SELECT_FEATURE",
  UNSELECT_FEATURE: "UNSELECT_FEATURE",
  SELECT_COMMENT: "SELECT_COMMENT",
  UNSELECT_COMMENT: "UNSELECT_COMMENT",

  SET_HIGHLIGHT_FEATURES: "SET_HIGHLIGHT_FEATURES",

  OPEN_FEATURE_SELECTION_POPUP: "OPEN_FEATURE_SELECTION_POPUP",
  UPDATE_FEATURE_PROPERTIES: "UPDATE_FEATURE_PROPERTIES",
  UPDATE_FEATURE_PROPERTIES_SUCCESS: "UPDATE_FEATURE_PROPERTIES_SUCCESS",
  UPDATE_FEATURE_PROPERTIES_FAILURE: "UPDATE_FEATURE_PROPERTIES_FAILURE",

  REPOSITION_HUBS: "REPOSITION_HUBS",

  SET_DRAGGED_POPUP_POSITION: "SET_DRAGGED_POPUP_POSITION",

  SET_VERSION: "SET_VERSION",

  SET_FEATURE_EDITING_CONFIRM: "SET_FEATURE_EDITING_CONFIRM",

  SET_ACTIVE_LAYER_GROUP_ID: "SET_ACTIVE_LAYER_GROUP_ID",
};
