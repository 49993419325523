import { createEntityAdapter } from "@reduxjs/toolkit";

import { MapLayerConfig, MapLayerConfigBulk } from "fond/types";

import { apiSlice } from "./apiSlice";

export const mlcAdapter = createEntityAdapter<MapLayerConfigBulk>({
  selectId: (entity: MapLayerConfigBulk): string => entity.ID,
});

/**
 * MapLayerConfig API Slice
 */
export const mlcSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMapLayerConfig: build.query<MapLayerConfig, string>({
      query: (versionId: string) => `/v1/versions/${versionId}/map-layer-config`,
      transformResponse: (response: MapLayerConfig) => {
        return {
          ...response,
          Type: "MapLayerConfig",
        };
      },
    }),
    getMlcBulk: build.query<MapLayerConfigBulk, string>({
      query: (mapLayerConfigId: string) => `/v1/map-layer-configs/${mapLayerConfigId}/bulk`,
      providesTags: (result) => (result ? [{ type: "MapLayerConfig", id: result.ID }] : []),
    }),
    updateMlcBulk: build.mutation<MapLayerConfigBulk, { ID: string; data: MapLayerConfigBulk }>({
      query: ({ ID, data }) => ({
        url: `/v1/map-layer-configs/${ID}/bulk`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "MapLayerConfig", id: arg.ID }],
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useLazyGetMapLayerConfigQuery, useGetMapLayerConfigQuery, useGetMlcBulkQuery, useUpdateMlcBulkMutation } = mlcSlice;
