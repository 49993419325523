import { useMemo, useState } from "react";
import * as React from "react";
import { Add as AddIcon, Delete, TextFields, Wysiwyg } from "@mui/icons-material";
import { FormControl, FormHelperText, IconButton, MenuItem, Select, SelectChangeEvent, TextField as BaseTextField } from "@mui/material";

import { AttributeType } from "fond/types/attributes";

import { useCustomStyles } from "../FieldFactory";
import { FormLabel } from "../styles";

import { Attribute, Value } from "./TextPartsField";
import Transformation, { Error as TransformationError, Transformation as ITransformation } from "./Tranformation";

import {
  Button,
  ErrorMessage,
  FieldItemContainer,
  FieldWrapper,
  InlineFieldWrapper,
  MenuItemWithIcon,
  TitleWithAction,
} from "./textPartsField.styles";

type Error = {
  AttributeName?: string;
  Literal?: string;
  Transformations?: Array<TransformationError>;
};

interface IProps {
  data: Value;
  attributes: Attribute[];
  onChange: (value: Value) => void;
  deleteLabelValue: () => void;
  error?: Error;
}

const iconByAttributeType = (type: AttributeType | null) => {
  if (type === "INTEGER") return "123";
  if (type === "REAL") return "1.50";
  return "abc"; // if string or has no attribute type
};

const TextPartsItem: React.FC<IProps> = ({ data, onChange, attributes, deleteLabelValue, error }) => {
  const classes = useCustomStyles();
  const [labelValue, setLabelValue] = useState(data.AttributeName ? "attribute" : "freeText");

  const handleOnLabelChange = (event: SelectChangeEvent) => {
    setLabelValue(event.target.value);

    onChange({
      Literal: null,
      AttributeName: null,
      Transformations: [],
    });
  };

  const handleOnTextChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value: newValue } = event.target;

    onChange({
      Literal: newValue,
      AttributeName: null,
      Transformations: [],
    });
  };

  const handleOnSelectChange = (event: SelectChangeEvent<string>) => {
    const { value: newValue } = event.target;

    onChange({
      Literal: null,
      AttributeName: newValue,
      Transformations: [],
    });
  };

  const addTransformation = () => {
    onChange({
      Literal: null,
      AttributeName: data.AttributeName,
      Transformations: [...data.Transformations, { Operation: "" }],
    });
  };

  const removeTransformation = (index: number) => {
    const updatedTransformations = [...data.Transformations];
    updatedTransformations.splice(index, 1);

    onChange({ ...data, Transformations: updatedTransformations });
  };

  const handleTransformationChange = (index: number, newValue: ITransformation) => {
    const updatedTransformations = [...data.Transformations];
    updatedTransformations[index] = newValue;
    onChange({
      Literal: null,
      AttributeName: data.AttributeName,
      Transformations: updatedTransformations,
    });
  };

  const selectedAttributeType = useMemo(() => attributes.find((attr: Attribute) => attr.name === data.AttributeName)?.type, [data.AttributeName]);

  return (
    <FieldItemContainer data-testid="label-value-item">
      <FieldWrapper>
        <TitleWithAction>
          <FormLabel sx={{ marginBottom: 0 }}>Label Value</FormLabel>
          <IconButton data-testid="delete-label-value-btn" size="small" onClick={() => deleteLabelValue()}>
            <Delete sx={{ fontSize: 16 }} />
          </IconButton>
        </TitleWithAction>
        <Select
          inputProps={{ id: "label-value-select", "data-testid": "label-value-select" }}
          size="small"
          margin="none"
          value={labelValue}
          onChange={handleOnLabelChange}
          className={classes.selectFieldWithIcon}
        >
          <MenuItemWithIcon value="freeText">
            <TextFields />
            Free Text
          </MenuItemWithIcon>
          <MenuItemWithIcon value="attribute">
            <Wysiwyg />
            Attribute
          </MenuItemWithIcon>
        </Select>
      </FieldWrapper>
      {labelValue === "freeText" ? (
        <FormControl error={!!error?.Literal}>
          <InlineFieldWrapper data-testid="free-text-field">
            <FormLabel>Text</FormLabel>
            <BaseTextField
              error={!!error?.Literal}
              inputProps={{ "data-testid": "label-freetext-input" }}
              onChange={handleOnTextChange}
              value={data.Literal || ""}
              classes={{
                root: classes.textFieldRoot,
              }}
            />
          </InlineFieldWrapper>
          <ErrorMessage>{error?.Literal}</ErrorMessage>
        </FormControl>
      ) : (
        <FormControl error={!!error?.AttributeName}>
          <InlineFieldWrapper data-testid="attribute-select-field">
            <FormLabel>Value</FormLabel>
            <Select
              inputProps={{ "data-testid": "attribute-select" }}
              size="small"
              margin="none"
              displayEmpty
              value={data.AttributeName || ""}
              onChange={handleOnSelectChange}
              className={classes.selectFieldWithIcon}
            >
              <MenuItem value="">
                <em>Select attribute</em>
              </MenuItem>
              {attributes.map((attr: Attribute) => (
                <MenuItemWithIcon key={attr.name} value={attr.name}>
                  <svg width="24" height="16">
                    <text x="0" y="12" style={{ fontSize: 10 }}>
                      {iconByAttributeType(attr.type)}
                    </text>
                  </svg>
                  {attr.name}
                </MenuItemWithIcon>
              ))}
            </Select>
          </InlineFieldWrapper>
          <ErrorMessage>{error?.AttributeName}</ErrorMessage>
        </FormControl>
      )}
      {labelValue === "attribute" && selectedAttributeType && selectedAttributeType !== "STRING" && (
        <FieldWrapper data-testid="attribute-transformations-field">
          <FormLabel sx={{ marginBottom: 0 }}>Advanced settings (Optional)</FormLabel>
          <FormHelperText sx={{ fontSize: 10, marginTop: 0, marginBottom: 1 }}>Attribute value transformations</FormHelperText>
          {data.Transformations.map((transformation, ind) => (
            <Transformation
              // eslint-disable-next-line react/no-array-index-key
              key={`${transformation.Operation}_${ind}`}
              data={transformation}
              onChange={(newValue) => handleTransformationChange(ind, newValue)}
              deleteTransformation={() => removeTransformation(ind)}
              error={error?.Transformations && error.Transformations[ind]}
            />
          ))}
          <Button data-testid="add-transformation-btn" startIcon={<AddIcon />} size="small" onClick={() => addTransformation()}>
            Add new transformation
          </Button>
        </FieldWrapper>
      )}
    </FieldItemContainer>
  );
};

export default TextPartsItem;
