/* eslint-disable import/prefer-default-export */
import { useContext } from "react";
import * as React from "react";
import { Launch } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { Actions, TabNode } from "flexlayout-react";

import { Widget } from "fond/types";
import { NonIdealState } from "fond/widgets";

import { LayoutContext } from "../LayoutProvider";

import { Components } from ".";

export interface ITabProps {
  node: TabNode;
  config: any;
}

const Floating: React.FC<ITabProps> = ({ node, config }: ITabProps) => {
  const { windowIds, setWindowIds } = useContext(LayoutContext);
  const nodeId = node.getId();
  /**
   * Handles redocking a floating widget
   */
  const handleOnDock = () => {
    setWindowIds(windowIds.filter((id) => id !== nodeId));
    node.getModel().doAction(Actions.selectTab(nodeId));
  };

  /**
   * Handles focusing the floating widget
   */
  const handleOnShow = () => {
    node.getExtraData().window?.focus();
  };

  return (
    <NonIdealState
      icon={<Launch />}
      size="small"
      description="This panel is shown in a floating window"
      action={
        <Box gap={8} display="flex">
          <Button color="primary" size="small" variant="outlined" onClick={handleOnShow}>
            Show
          </Button>
          <Button color="primary" size="small" variant="outlined" onClick={handleOnDock}>
            Dock
          </Button>
        </Box>
      }
    />
  );
};

/**
 * A component factory for the Widget component used when rendering content for a tab
 */
export const widgetFactory = (node: TabNode, isPopout = false): React.ReactNode => {
  const component = node.getComponent() as Widget;
  const config = node.getConfig();

  return (
    <LayoutContext.Consumer>
      {({ windowIds }) => {
        const WidgetComponent = !windowIds.includes(node.getId()) || isPopout ? Components.get(component)?.Widget : Floating;
        return WidgetComponent && <WidgetComponent node={node} config={config} />;
      }}
    </LayoutContext.Consumer>
  );
};

/**
 * A component factory for the Title component used when rendering a tab
 */
export const titleFactory = (node: TabNode): React.ReactNode => {
  const component = node.getComponent() as Widget;
  const config = node.getConfig();
  const TitleComponent = Components.get(component)?.Title;

  return (
    <Box data-testid={`layout-widget-${component}`}>{TitleComponent ? <TitleComponent node={node} config={config} /> : (node.getName() ?? "")}</Box>
  );
};

/**
 * A component factory for the Icon component used when rendering a tab
 */
export const iconFactory = (node: TabNode): React.ReactNode => {
  const component = node.getComponent() as Widget;
  const config = node.getConfig();
  const IconComponent = Components.get(component)?.Icon;

  return IconComponent && <IconComponent node={node} config={config} />;
};
