import { memo } from "react";
import * as React from "react";
import { ICellRendererParams } from "@ag-grid-community/core";

import { MimeTypeIcon } from "fond/widgets";

import { MimeTypeIconWrapper } from "./styles";

const MimeTypeCellRenderer: React.FC<ICellRendererParams> = memo((params: ICellRendererParams) => {
  return (
    <MimeTypeIconWrapper>
      <MimeTypeIcon mimeType={params.value} />
    </MimeTypeIconWrapper>
  );
});

MimeTypeCellRenderer.displayName = "MimeTypeCellRenderer";

export default MimeTypeCellRenderer;
