import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { Box, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import _ from "lodash";
import { useSnackbar } from "notistack";

import { selectLayerByVersionAndLayerKey, selectLayerPropertiesSchema, useGetVersionQuery } from "fond/api";
import AddQuickFeatureAttachment from "fond/attachments/AddQuickFeatureAttachment";
import AttachmentModal from "fond/attachments/AttachmentModal";
import { LayerIds } from "fond/layers";
import MapPopup from "fond/map/MapPopup";
import mixpanel from "fond/mixpanel";
import { getArchitectureAddressTypes } from "fond/project/addressTypes";
import AddQuickFeatureComment from "fond/project/comments/AddQuickComment";
import QuickComment from "fond/project/comments/QuickComment";
import { getCurrentProject, isSolveActive, updateFeatureProperties } from "fond/project/redux";
import { getFeature, getOne } from "fond/redux/features";
import { Attribute, Store } from "fond/types";
import { convertMetersToFeet, formatUnit, projectUsesVectorTiles, roundLength } from "fond/utils";
import { useAppDispatch } from "fond/utils/hooks";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { BlockSpinner } from "fond/widgets";

import { trafficLightData } from "../Field/PreferenceFieldButton";

import AddressPreferenceEdit from "./AddressPreferenceEdit";
import PreferencePathEdit from "./PreferencePathEdit";

import { PropertyNameCell } from "./Features.styles";

/**
 * `fields` is a mapping of field names to field descriptors. A field
 * descriptor has:
 *
 * A `validate` function -- returns true if the field value is valid, else false.
 *
 * Note that the field value is anything that can be represented in the
 * corresponding widget. For example, if the field is supposed to be a number
 * but is represented by a text field on the screen, the field value here will
 * be a string, because it is still possible for the user to type non-numeric
 * characters in the input. Even if the widget is a strict `<input type="number">`
 * the user will still be able to things like "0000", "." or "e".
 *
 * A `sanitize` function -- convert the value from the widget to the value that
 * should be sent to the server. This function may assume `validate` has
 * already returned true for the value passed in.
 *
 * Optionally, a `label` property. If provided, it's used as the field value. If absent,
 * the field key is used.
 */
const fields: { [key: string]: { label?: string; validate: (value: string) => boolean; sanitize: (value: string) => number | string } } = {
  AddressType: {
    validate: (value: string) => true,
    sanitize: (value: string) => value,
  },
  CostFactor: {
    label: "Preference",
    validate: (value: string) => {
      return value !== "" && Number.isFinite(Number(value));
    },
    sanitize: parseFloat,
  },
};

const customStyles = (theme: Theme) => ({
  root: {
    overflow: "auto",
    minHeight: 0,
    maxHeight: 300,
    minWidth: 300,
    maxWidth: 300,
  },
  staticField: {
    // To line up with the TextField if any.
    fontSize: 12,
  },
  lengthField: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 12,
  },
});

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * Callback function for when the popup closes
   */
  onClose(): void;
}

/**
 * A popup that appears when the user clicks a feature on the map, displaying
 * the feature's properties, and perhaps allowing some features to be edited.
 * Uses some of Mapbox's logic to auto-position the element so that it follows
 * the feature in question.
 */
const FeaturePopup: React.FC<IProps> = ({ classes, onClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [addNewComment, setAddNewComment] = useState(false);
  const [propertyHasChanged, setPropertyHasChanged] = useState(false);
  const project = useSelector((state: Store) => getCurrentProject(state.project));
  const versionId = useSelector((state: Store) => state.project.versionId);
  const { data: version } = useGetVersionQuery(versionId, { skip: !versionId });
  const { selectedFeature } = useSelector((state: Store) => state.project);
  const { layerId } = selectedFeature;
  const isFetching = useSelector((state: Store) => state.features.isFetching);
  const solveActive = useSelector((state: Store) => isSolveActive(state));
  const canEdit = permissionCheck(project.Permission.Level, Actions.PROJECT_EDIT);
  const draggedPopupPosition = useSelector((state: Store) => state.project.draggedPopupPosition);
  const layerConfig = useSelector((state: Store) => selectLayerByVersionAndLayerKey(state, { versionId, layerId }));
  const layerPropertiesSchemas = useSelector((state: Store) => selectLayerPropertiesSchema(state, versionId));
  const architectureAddressTypes = version?.Architecture
    ? getArchitectureAddressTypes({ architecture: version.Architecture, excludeIgnore: true })
    : null;

  const lengthFields =
    layerConfig?.Attributes.filter((attribute) => attribute.SourceSystemOfMeasurement !== null).map((attribute) => attribute.Name) || [];
  const title = layerConfig?.Configuration.Label;

  const editableProperties =
    {
      [LayerIds.inAddress]: ["AddressType"],
      [LayerIds.inStreet]: ["CostFactor"],
      [LayerIds.inSpan]: ["CostFactor"],
    }[layerId] || [];

  const initialFeatureProperties: { [key: string]: any } = useSelector((state: Store) => {
    // TODO: Once all projects use vector tiles properties should always come from the getOne redux selector
    let properties = backendToUI({
      ...(getOne(state)(state.project.selectedFeature.featureProperties.id)?.properties || state.project.selectedFeature.featureProperties),
    });

    // Handle length conversions
    lengthFields.forEach((field) => {
      if (Object.keys(properties).includes(field)) {
        properties = {
          ...properties,
          [field]: project.SystemOfMeasurement === "imperial" ? roundLength(convertMetersToFeet(properties[field])) : roundLength(properties[field]),
        };
      }
    });

    // Set default Cost Factor
    if (editableProperties.includes("CostFactor") && properties.CostFactor == null) {
      properties = { ...properties, CostFactor: trafficLightData.neutral.cost };
    }

    return properties;
  });

  /**
   * We keep a copy of the initialFeatureProperties in state to support mutating the data
   * e.g. the user edits the properties (e.g. Address Preference)
   */
  const [featureProperties, setFeatureProperties] = useState(initialFeatureProperties);

  useEffect(() => {
    // If the initial features from the redux store differ from state
    // update state.  For example the properties have finished loading
    // from the server.
    if (!_.isEqual(initialFeatureProperties, featureProperties) && !propertyHasChanged) {
      setFeatureProperties(initialFeatureProperties);
    }
  }, [initialFeatureProperties]);

  /**
   * Monitor the popup for changes to the feature id it is referencing.
   * If the feature changes we need to request new information.
   */
  useEffect(() => {
    /**
     * TODO Once all projects use vector tiles always request the information unless already loaded
     * If using vector tiles we need to request the data if it does not already exist
     *
     * Note: If the properties between the FeatureProperties & LayerProperties scheme differ we request
     * from the server.
     */
    if (projectUsesVectorTiles(project) && layerPropertiesSchemas) {
      if (!isFetching && !equalProperties(layerPropertiesSchemas[layerId], initialFeatureProperties)) {
        dispatch(getFeature(selectedFeature.feature.id as string));
      }
    }
  }, [selectedFeature.feature.id]);

  /**
   * Callback function called when the popup closes
   */
  const handleClose = useCallback(
    ({ type }: { type: string }) => {
      if (propertyHasChanged) {
        handleSave();
      } else if (type === "manual-close") {
        onClose();
      }
    },
    [propertyHasChanged, featureProperties]
  );

  /**
   * Callback function to handle saving of editable features on popup close
   */
  const handleSave = () => {
    if (canSave()) {
      mixpanel.track("Saved feature");
      const properties = uiToBackend(editableProperties, featureProperties);
      dispatch(updateFeatureProperties(layerId, selectedFeature.featureId, properties));
    } else {
      mixpanel.track("Unable to save feature");
      enqueueSnackbar("Unable to save new update");
    }
  };

  /**
   * Validates the editable properties for valid values.
   */
  const isValid = () => {
    return editableProperties.every((key) => {
      return fields[key].validate(featureProperties[key]);
    });
  };

  /**
   * Updates the Field Value within the feature properties
   */
  const setFieldValues = (updates: { [key: string]: string | number }) => {
    mixpanel.track("Edited feature value", updates);

    setPropertyHasChanged(true);

    setFeatureProperties({
      ...featureProperties,
      ...updates,
    });
  };

  /**
   * Indicates if the current features can be saved
   */
  const canSave = () => isValid() && !solveActive;

  /**
   * Sanitize the feature property value to string. The backend allows
   * for JSON to be set as the value which needs to be handled.
   */
  const sanitizedToString = (value: unknown): string => {
    if (value === undefined || value === null) return "-";

    if (typeof value === "object") return JSON.stringify(value);

    return value.toString();
  };

  return (
    <MapPopup
      className="feature-popup"
      lngLat={draggedPopupPosition || selectedFeature.lngLat}
      title={title}
      onClose={handleClose}
      rightContent={
        <>
          <AddQuickFeatureAttachment addAttachment={() => setShowAttachmentModal(true)} />
          <AddQuickFeatureComment addComment={() => setAddNewComment(true)} />
        </>
      }
    >
      {showAttachmentModal && (
        <AttachmentModal onClose={() => setShowAttachmentModal(false)} isOpen={showAttachmentModal} attachmentEntityType="Feature" />
      )}
      {layerPropertiesSchemas ? (
        <>
          {addNewComment ? (
            <QuickComment handleClose={handleClose} onCancel={() => setAddNewComment(false)} />
          ) : (
            <Box className={classNames(classes.root, "customScrollbars")} data-testid="feature-details">
              <Typography variant="overline">Properties</Typography>
              <Table size="small">
                <TableBody>
                  {canEdit && editableProperties.length > 0 && (
                    <>
                      {layerId === LayerIds.inAddress ? (
                        <AddressPreferenceEdit
                          demandConfigurations={version?.Architecture?.Demand?.DemandConfigurations}
                          addressTypeField={version?.AddressTypeField}
                          properties={featureProperties}
                          addressTypes={architectureAddressTypes}
                          onChange={setFieldValues}
                        />
                      ) : (
                        (layerId === LayerIds.inSpan || layerId === LayerIds.inStreet) && (
                          <PreferencePathEdit properties={featureProperties} onChange={setFieldValues} onEnterPress={onClose} />
                        )
                      )}
                    </>
                  )}
                  {_.map(layerPropertiesSchemas?.[layerId], (propertySchema) => {
                    // Address is handled by AddressPreferenceEdit above
                    if (layerId === LayerIds.inAddress && propertySchema.Name === version?.AddressTypeField) return null;
                    const value = sanitizedToString(featureProperties?.[propertySchema.Name]);
                    return (
                      <TableRow key={propertySchema.Name}>
                        <PropertyNameCell>{propertySchema.Name}</PropertyNameCell>
                        <TableCell>
                          {lengthFields.includes(propertySchema.Name) ? (
                            <div className={classes.lengthField}>
                              <span data-testid="length-value">{value}</span>
                              <span data-testid="length-unit" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                {formatUnit(project.SystemOfMeasurement)}
                              </span>
                            </div>
                          ) : (
                            <span className={classes.staticField} data-testid="table-property">
                              {isFetching ? <>Loading...</> : <>{value}</>}
                            </span>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          )}
        </>
      ) : (
        <BlockSpinner />
      )}
    </MapPopup>
  );
};

export default withStyles(customStyles)(FeaturePopup);

export const backendToUI = (featureProperties: any): { [key: string]: any } => {
  let modifiedFeatureProps = featureProperties;

  // If "null" is somehow saved in the backend, this ensures that it is converted to `null`
  if (Object.values(modifiedFeatureProps).includes("null")) {
    modifiedFeatureProps = _.mapValues(modifiedFeatureProps, (value) => (value === "null" ? null : value));
  }
  return modifiedFeatureProps;
};

export const uiToBackend = (editableProperties: any, featureProperties: any) => {
  let properties = { ...featureProperties };

  for (let prop of editableProperties) {
    properties[prop] = fields[prop].sanitize(featureProperties[prop]);
  }

  const { Type, NumFibers } = featureProperties;
  if (Type !== "T2_EXP_DEMAND") {
    properties.SubTypes = null;
  }
  properties.NumFibers = parseInt(NumFibers, 10); // NumFibers must be an integer
  return properties;
};

/**
 * Determines if the properties within the layer properties schema are
 * the same as the feature properties being displayed (ignoring order)
 */
const equalProperties = (
  layerPropertiesSchema: Attribute[],
  featureProperties: {
    [name: string]: any;
  }
) => {
  const schemaPropertyNames = layerPropertiesSchema?.map((property) => property.Name).sort();
  const featurePropertyNames = Object.keys(featureProperties).sort();

  return _.isEqual(schemaPropertyNames, featurePropertyNames);
};
