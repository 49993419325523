import React, { useMemo } from "react";
import { LinearScale, LocationOn, TrendingFlat } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import Color from "color";

import { LayerConfigIDs } from "fond/layers";
import { Configuration } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { selectAllStylesByLayer } from "fond/utils/configurations";
import SvgIcon from "fond/widgets/SvgIcon";

import { StyleIcon } from "../StyleIcon";

import { AllFillStack, CircleStack, CommentIcon, Line, NullGeomIcon, Swab, UnknownGeomIcon } from "../InferredIcon.styles";

interface IProps {
  layer: LayerConfig;
  config?: Configuration;
}

/**
 * Returns an inferred icon that represents the style layers visual
 * representation on the map where possible.
 */
const IconStack: React.FC<IProps> = ({ layer, config }: IProps) => {
  const { ID, GeometryType, Type } = layer;
  const styles: LayerStyle[] = useMemo(() => (config ? selectAllStylesByLayer(layer, config) : []), [layer, config]);

  if (styles.length === 0 || !config) return null;

  /**
   * Handle comment layers which always have a set icon based
   * on the layer.ID
   */
  if (Type === "CommentConfig") {
    let icon: React.ReactNode | null = null;
    if (ID === LayerConfigIDs.HIGHLIGHT) {
      icon = <SvgIcon icon="polygon" iconProps={{ color: "secondary" }} />;
    } else if (ID === LayerConfigIDs.POINT) {
      icon = <LocationOn color="secondary" />;
    } else if (ID === LayerConfigIDs.LINE) {
      icon = <LinearScale color="secondary" />;
    } else if (ID === LayerConfigIDs.ARROW) {
      icon = <TrendingFlat color="secondary" />;
    }

    return <CommentIcon>{icon}</CommentIcon>;
  }

  // LineString Geometry Type
  if (GeometryType === "LineString") {
    if (Type === "LAYER" && layer.Children.length > 0) {
      const firstLineStyle = styles.find((style) => style.RawStyles.Type === "line");
      if (firstLineStyle) {
        const firstColor = Color(firstLineStyle.RawStyles.LineColor).hex();
        const sameColor = styles.every((style) => style.RawStyles.Type !== "line" || Color(style.RawStyles.LineColor).hex() === firstColor);

        // We base the style on the line styles only within the first child.
        // Any other style types are ignored at the layer level
        return (
          <StyleIcon
            styles={[
              {
                ...firstLineStyle,
                RawStyles: {
                  ...firstLineStyle.RawStyles,
                  LineColor: sameColor ? firstColor : "#000",
                },
              },
            ]}
          />
        );
      }
    }

    return <Line />;
  }

  // Point Geometry Type
  else if (GeometryType === "Point") {
    // Get Sublayers & their styles
    if (layer.Type === "LAYER") {
      const sublayerStyles: LayerStyle[][] = [];
      const layerStyles = layer.Styles.map((styleId) => config.Data.entities[styleId] as LayerStyle);

      layer.Children.forEach((sublayerId) => {
        const sublayer = config?.Data.entities[sublayerId] as SublayerConfig;
        const subStyles = sublayer.Styles.map((styleId) => config?.Data.entities[styleId]) as LayerStyle[];
        sublayerStyles.push([...layerStyles, ...subStyles]);
      });

      return (
        <CircleStack>
          {[...Array(3)].map((_, index) => {
            const s = sublayerStyles?.[index] || sublayerStyles[0];
            if (s.length === 0) return null;

            const key = `circle-stack_${index}`;
            return <StyleIcon key={key} styles={s} />;
          })}
        </CircleStack>
      );
    }

    return <SvgIcon icon="points" iconProps={{ style: { width: 16, height: 16 } }} />;
  }

  // Polygon Geometry Type
  else if (GeometryType === "Polygon") {
    // Get Sublayers & their styles
    if (layer.Type === "LAYER") {
      const sublayerStyles: LayerStyle[][] = [];
      const layerStyles = layer.Styles.map((styleId) => config.Data.entities[styleId] as LayerStyle);

      layer.Children.forEach((sublayerId) => {
        const sublayer = config?.Data.entities[sublayerId] as SublayerConfig;
        const subStyles = sublayer.Styles.map((styleId) => config?.Data.entities[styleId]) as LayerStyle[];
        sublayerStyles.push([...layerStyles, ...subStyles]);
      });

      const count = Math.min(sublayerStyles.length, 3);

      return (
        <AllFillStack count={count}>
          {[...Array(count)].map((_, index) => {
            const s = sublayerStyles?.[index];
            if (s.length === 0) return null;

            const id = s.map((style) => style.ID).join("_");
            return <StyleIcon key={`${id}-fill-stack`} styles={s} />;
          })}
        </AllFillStack>
      );
    }

    return <Swab color="transparent" strokeColor="black" />;
  } else if (GeometryType === "Null") {
    return <NullGeomIcon />;
  } else if (GeometryType === null) {
    return (
      <Tooltip title="This layer is empty.  Features of any geometry type may be imported into this layer.">
        <UnknownGeomIcon color="secondary" />
      </Tooltip>
    );
  } else {
    return null;
  }
};

export default IconStack;
