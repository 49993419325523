import { useEffect, useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { Check as CheckIcon, Delete as DeleteIcon, Error as ErrorIcon, Layers as LayersIcon } from "@mui/icons-material";
import { Box, IconButton, Skeleton, Table, TableBody, TableContainer, TableHead, Typography } from "@mui/material";
import { blue, grey, red } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import classNames from "classnames";
import FuzzySet from "fuzzyset";
import { omit } from "lodash";

import { selectLayersByVersionId, selectVersionConfig } from "fond/api";
import { extname } from "fond/fileValidation";
import { InferredIcon } from "fond/map/Legend";
import { ImportStatus, LayerConfiguration, Store } from "fond/types";
import { LayerConfig } from "fond/types/ProjectLayerConfig";
import { pickIDs } from "fond/utils";

import { ResourceAutocomplete } from "../ProjectVersionSelection/ProjectVersionSelection";

import { BodyCell, CheckCircleIcon, CompactFormControl, HeaderCell, ModifiedTableRow } from "./ImportLayersTable.styles";

// The maximum number of layers that can be imported into a version as dictated by the Service API.
const VERSION_LAYER_LIMIT = 50;

/**
 * The ImportLayersTable component.
 * This table provides the user with controls to edit the layer names and apply copied configurations. Not implemented (DEVEX-7751).
 * Each row of the table has an option to send a callback onRemoveLayer to the implementing component, passing the name of the layer being removed.
 */
interface ImportLayersTableProps {
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  /**
   * The layer files, a mapping of layer name to file bundle.
   */
  files: { [fileKey: string]: File[] };
  /**
   * The layer configurations available to file configuration mapping.
   */
  configurations: LayerConfiguration[];
  /**
   * Callback on file bundle removal, passes the file key of the file bundle with removal requested.
   */
  onRemoveFile(layerKey: string): void;
  /**
   * Callback on mapping a configuration to a fileKey, passes the fileKey and mapped layerKey.
   */
  onSelectConfigurations({ mappings }: { mappings: { [fileKey: string]: undefined | string } }): void;
  /**
   * Displays the 'loading styles' indicator.
   */
  loading?: boolean;
  /**
   * The height of the table (px). This property overrides any additional style properties.
   */
  height?: string | number;
  /**
   * Additional box container styles.
   */
  style?: React.CSSProperties;
  /**
   * A callback that fires a boolean indicating whether errors have been raised.
   */
  hasErrors(ready: boolean): void;
}

const ImportLayersTable: React.FC<ImportLayersTableProps> = ({
  "data-testid": dataTestid = "import-layers-table",
  files,
  configurations,
  onRemoveFile,
  onSelectConfigurations,
  loading = false,
  height = 350,
  style,
  hasErrors,
}: ImportLayersTableProps) => {
  const theme = useTheme();
  const [selections, setSelections] = useState<{ [fileKey: string]: null | LayerConfiguration }>({});
  const [errors, setErrors] = useState<{ [fileKey: string]: string }>({});
  const versionId = useSelector((state: Store) => state.project.versionId);
  const layers = useSelector((state: Store) => selectLayersByVersionId(state, versionId));
  const imports = useSelector((state: Store) => state.imports[versionId]);
  const config = useSelector((state: Store) => selectVersionConfig(state, versionId));

  const nErrors = Object.keys(errors).length;

  // Count the number of layers and files.
  let nLayers = 0;
  let nFiles = 0;
  for (const [, layerFiles] of Object.entries(files)) {
    nLayers += 1;
    nFiles += layerFiles.length;
  }

  /**
   * Check the file drop for errors.
   */
  useEffect(() => {
    const fileExts = [["shp", "dbf", "prj", "shx"], ["tab", "id", "dat"], ["kml"], ["geojson"]];

    // Errors are collected before a state update.
    const newErrors: { [fileKey: string]: string } = {};

    // Check for version layer limit and missing sidecar errors in a single loop.
    // Count the non-empty layer number.
    const layerCount = layers?.filter((layer) => layer && layer.Attributes.length > 0).length || 0;
    // Count the on-going layer imports.
    const importCount = imports
      ? Object.values(imports).filter((existingImport) => ![ImportStatus.COMPLETE, ImportStatus.ERROR].includes(existingImport.status)).length
      : 0;
    let versionLayerCount = layerCount + importCount;
    for (const [fileKey, fileBundle] of Object.entries(files)) {
      // Count if there is not a configuration match for that file.
      if (!selections || !selections[fileKey]) {
        versionLayerCount += 1;
      }

      if (versionLayerCount > VERSION_LAYER_LIMIT) {
        // Store an error if there are too many layer.
        newErrors[fileKey] = `Too many layers (max ${VERSION_LAYER_LIMIT})`;
      } else {
        // Store an error if there are missing sidecars.
        const extnames = fileBundle.map((file) => extname(file.name).toLowerCase());
        for (const exts of fileExts) {
          if (extnames.some((ext) => exts.includes(ext))) {
            const missingFiles = exts.filter((ext) => !extnames.includes(ext));
            if (missingFiles.length > 0) {
              newErrors[fileKey] = `missing: ${missingFiles.join(", ")}`;
            }
          }
        }
      }
    }
    setErrors(newErrors);
  }, [files, selections]);

  useEffect(() => {
    hasErrors(nErrors > 0);
  }, [errors]);

  /**
   * Generate suggetions using a fuzzy match between the dropped file names and available configuration labels.
   */
  useEffect(() => {
    const fuzzyset = FuzzySet();

    // Add all of the file keys to the fuzzy set. Each configuration label will be compared with the set for similarity.
    for (const fileKey of Object.keys(files)) {
      fuzzyset.add(fileKey);
    }

    // Find all matches and order by similarity.
    const matches: [number, string, string][] = [];
    const configsByLabel: { [label: string]: LayerConfiguration } = {};
    for (const layerConfig of configurations) {
      const label = layerConfig.Label;
      configsByLabel[label] = layerConfig;
      for (const [similarity, fileKey] of fuzzyset.get(label) || []) {
        if (similarity > 0.5) {
          matches.push([similarity, fileKey, label]);
        }
      }
    }
    matches.sort((prev, next) => next[0] - prev[0]);

    // Get the best match for each label. Each label may only be mapped to one file key.
    const suggestions: { [fileKey: string]: LayerConfiguration } = {};
    const usedLabels = new Set<string | number>([]);
    for (const [, fileKey, label] of matches) {
      if (!usedLabels.has(label)) {
        usedLabels.add(label);
        suggestions[fileKey] = configsByLabel[label];
      }
    }

    // Set the selections to the calculated suggestions.
    setSelections({ ...suggestions, ...selections });
  }, [configurations, files]);

  /**
   * Fire a callback when the mappings are updated.
   */
  useEffect(() => {
    const mappings: { [fileKey: string]: undefined | string } = {};

    for (const [fileKey, configuration] of Object.entries(selections)) {
      mappings[fileKey] = configuration?.LayerKey;
    }
    onSelectConfigurations({ mappings });
  }, [selections]);

  const configIds = pickIDs(configurations);
  const suggestionsLoaded = !loading && Object.values(selections).every((layerConfig) => layerConfig == null || configIds.includes(layerConfig.ID));
  const newLayerOption = { ID: undefined, LayerKey: undefined, Label: "New Layer" } as unknown as LayerConfiguration;

  return (
    <Box data-testid={dataTestid} style={style} height={height} sx={{ borderRadius: "5px" }}>
      <Box
        height={42}
        padding={`${theme.spacing(1)} ${theme.spacing(2)}`}
        sx={{
          backgroundColor: grey[100],
          border: `1px solid ${grey[300]}`,
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.14)",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
        display="flex"
        justifyContent="space-between"
      >
        <Box display="flex" margin="auto 0">
          <LayersIcon style={{ marginRight: theme.spacing(1), fill: theme.palette.biarri.primary.red }} />
          <Typography variant="h6" component="p">
            {nLayers}
            &nbsp;Layers
            <Typography variant="h6" component="span" fontSize={13}>
              &nbsp;(
              {nFiles}
              &nbsp;Files)
            </Typography>
          </Typography>
        </Box>
        {!loading && (
          <Box display="flex" margin="auto 0" alignItems="center">
            {nErrors === 0 && (
              <>
                <CheckCircleIcon>
                  <CheckIcon sx={{ color: theme.palette.common.white, height: 15, width: 15 }} />
                </CheckCircleIcon>
                <Typography variant="h6" component="span" fontSize={14}>
                  Complete Layers
                </Typography>
              </>
            )}
            {nErrors > 0 && (
              <>
                <ErrorIcon data-testid="error-icon" style={{ marginRight: theme.spacing(1), fill: red[500] }} />
                <Typography variant="h6" component="span" fontSize={14}>
                  {`${nErrors} Error${nErrors > 1 ? "s" : ""}`}
                </Typography>
              </>
            )}
          </Box>
        )}
      </Box>

      <TableContainer
        sx={{
          border: `1px solid ${grey[300]}`,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          borderTop: "unset",
        }}
      >
        <Table stickyHeader size="small">
          <TableHead sx={{ display: "block" }}>
            <ModifiedTableRow>
              <HeaderCell>Layer</HeaderCell>
              <HeaderCell width={300} align="left">
                {configurations.length > 0 && "Import file as"}
              </HeaderCell>
              <HeaderCell width={80} align="center">
                Manage
              </HeaderCell>
              {nFiles > 6 && <HeaderCell sx={{ width: 16, padding: 0 }} />}
            </ModifiedTableRow>
          </TableHead>
          <TableBody
            className={classNames("customScrollbars")}
            sx={{ display: "block", overflow: "auto", maxHeight: `calc(${height} - ${theme.spacing(10)})` }}
          >
            {Object.keys(files).map((fileKey: string) => (
              <ModifiedTableRow key={fileKey}>
                <BodyCell>{fileKey}</BodyCell>
                <BodyCell>
                  {/* An autocomplete selection providing the copied configurations that can be applied to the layer upon import. Not implemented (DEVEX-7751). */}
                  {errors[fileKey] && (
                    <Typography data-testid="error-message" sx={{ textAlign: "left", color: "red" }}>
                      {errors[fileKey]}
                    </Typography>
                  )}
                  {!errors[fileKey] && configurations.length > 0 && (
                    <CompactFormControl width={194} size="small">
                      {!suggestionsLoaded && <Skeleton />}
                      {suggestionsLoaded && (
                        <ResourceAutocomplete
                          sx={{ backgroundColor: "white" }}
                          data-testid="configuration-selection"
                          value={selections[fileKey] || newLayerOption}
                          disableClearable={false}
                          onChange={(event: React.SyntheticEvent, selected: LayerConfiguration) =>
                            setSelections({ ...selections, [fileKey]: selected?.ID == null ? null : selected })
                          }
                          loading={loading as boolean}
                          options={[newLayerOption, ...configurations]}
                          getOptionLabel={(option: LayerConfiguration) => option.Label}
                          getOptionDisabled={(option: LayerConfiguration) => Object.values(selections).includes(option)}
                          placeholder={newLayerOption.Label}
                          renderOption={(option: LayerConfiguration) => {
                            const mappedConfig = config.Data.entities[option.ID] as LayerConfig;
                            return (
                              <Box data-testid="option-configuration" display="flex">
                                {mappedConfig ? (
                                  <Box mr={1} height="22px">
                                    <InferredIcon entity={mappedConfig} config={config} />
                                  </Box>
                                ) : (
                                  <Box mr={1} height="22px">
                                    <LayersIcon />
                                  </Box>
                                )}
                                <Typography data-testid="option-label">{option.Label}</Typography>
                              </Box>
                            );
                          }}
                          renderInput={(params: TextFieldProps) => {
                            const layerConfigID = selections[fileKey]?.ID;
                            const mappedConfig = (layerConfigID ? config.Data.entities[layerConfigID] : null) as LayerConfig | null;
                            return (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: mappedConfig ? (
                                    <Box mr={1} height="22px">
                                      <InferredIcon entity={mappedConfig} config={config} />
                                    </Box>
                                  ) : (
                                    <Box mr={1} height="22px">
                                      <LayersIcon />
                                    </Box>
                                  ),
                                }}
                                placeholder={newLayerOption.Label}
                                variant="outlined"
                              />
                            );
                          }}
                        />
                      )}
                    </CompactFormControl>
                  )}
                </BodyCell>
                <BodyCell width={80} align="center">
                  <IconButton
                    onClick={() => {
                      setSelections(omit(selections, [fileKey]));
                      onRemoveFile(fileKey);
                    }}
                  >
                    <DeleteIcon sx={{ color: blue[500] }} />
                  </IconButton>
                </BodyCell>
              </ModifiedTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ImportLayersTable;
