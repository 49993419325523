/* eslint-disable react/jsx-one-expression-per-line */
import * as React from "react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddComment, Close, FilterList, ImportExport, LinearScale, LocationOn, MapOutlined, Search, TrendingFlat } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Paper, TextField, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import classNames from "classnames";
import dayjs from "dayjs";
import { Feature } from "geojson";
import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDebounce } from "use-debounce";

import { selectVersionsByProjectId, useGetPermissionsQuery } from "fond/api";
import { TabHeader } from "fond/layout";
import { MapContext } from "fond/map/MapProvider";
import { beginCommenting, endCommenting } from "fond/project/redux";
import { addComment, getAll, setFilters, setMapFilter, setSearchText, setSortOrder } from "fond/redux/comments";
import { Comment, commentImportanceLevel, CommentType, Store } from "fond/types";
import { BlockSpinner, NonIdealState, SvgIcon } from "fond/widgets";

import AddCommentForm, { IFormData } from "./AddCommentForm";
import AddMenu from "./AddMenu";
import CommentsList from "./CommentsList";
import FilterMenu from "./FilterMenu";
import Header from "./Header";
import SnackbarContent from "./SnackbarContent";
import SortMenu from "./SortMenu";

const customStyles = (theme: Theme) => {
  return createStyles({
    addComment: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      marginBottom: theme.spacing(3),
    },
    icon: {
      color: theme.palette.primary.main,
    },
    comments: {
      overflow: "auto",
    },
    clearSearch: {
      color: theme.palette.action.active,
      cursor: "pointer",
      marginRight: theme.spacing(-1),
      padding: theme.spacing(0.5),
    },
    select: {
      fontSize: "0.8rem",
    },
    searchIcon: {
      background: theme.palette.common.white,
    },
    iconGroup: {
      borderRadius: 4,
      "&.active": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
      },
      "&:hover.active": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    inputRoot: {
      margin: 0,
    },
    input: {
      padding: theme.spacing(1),
      maxHeight: 16,
      fontSize: 13,
    },
    spinner: {
      height: "18px !important",
      marginRight: theme.spacing(-1),
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {}

const CommentsPanel: React.FC<IProps> = ({ classes }: IProps) => {
  const dispatch: ThunkDispatch<Store, null, AnyAction> = useDispatch();
  const { map, drawControl, setDrawMode } = useContext(MapContext);
  const projectId = useSelector((state: Store) => state.project.projectId);
  const versionId = useSelector((state: Store) => state.project.versionId);
  const loaded = useSelector((state: Store) => state.comments.loadStatus === "success");
  const mapFilter = useSelector((state: Store) => state.comments.mapFilter);
  const sortOrder = useSelector((state: Store) => state.comments.sortOrder);
  const defaultSearchText = useSelector((state: Store) => state.comments.searchText);
  const filters = useSelector((state: Store) => state.comments.filters);
  const [showAddComment, setShowAddComment] = useState(false);
  const [commentHasFeature, setCommentHasFeature] = useState(false);
  const [commentType, setCommentType] = useState<CommentType>();
  const [error, setError] = useState<string | undefined>(undefined);

  const [searchInputValue, setSearchInputValue] = useState(defaultSearchText ?? "");
  const [debouncedSearchText, { isPending }] = useDebounce(searchInputValue, 500);

  const currentUsername = useSelector((state: Store) => state.cognito.user?.username);
  const comments: Comment[] = useSelector((state: Store) => getAll(state));
  const [sortedComments, setSortedComments] = useState(comments);
  const editMode = useSelector((state: Store) => state.project.editMode);
  const commentsRef = useRef<HTMLDivElement>(null);
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState<HTMLButtonElement>();
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<HTMLButtonElement>();
  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState<HTMLButtonElement>();
  const [beforeTimestamp, setBeforeTimestamp] = useState(Date.now());
  const { refetch: refetchPermissions } = useGetPermissionsQuery({ id: projectId, type: "project" });
  const versions = useSelector((state: Store) => selectVersionsByProjectId(state, projectId));

  /**
   * Calculate the filtered & sorted comments that need to be displayed
   */
  useEffect(() => {
    const filtered = comments.filter(
      (comment) =>
        (filters.State.length === 0 || filters.State.includes(comment.State)) &&
        (filters.Importance.length === 0 || filters.Importance.includes(comment.Importance)) &&
        (filters.Version.length === 0 || filters.Version.includes(comment.Version))
    );
    if (sortOrder === "created") {
      // Sort by comment createdAt
      setSortedComments(_.orderBy(filtered, ["CreatedAt"], "desc"));
    } else if (sortOrder === "latest") {
      // Sort by the most recent change in a comment or reply
      setSortedComments(
        _.orderBy(
          filtered,
          (comment) => {
            // Only consider replies prior to the replyTimestamp as we
            // don't want to re-order when new replies are added.
            const existingReplies = comment.Replies.filter((reply) => dayjs(reply.CreatedAt).isBefore(beforeTimestamp));
            return comment.Replies.length > 0 ? existingReplies[existingReplies.length - 1]?.CreatedAt : comment.CreatedAt;
          },
          "desc"
        )
      );
    } else {
      // Sort by the most important comments
      setSortedComments(
        filtered.sort((a, b) => {
          // For comments with the same importance, sort by the latest creation date.
          // Null always has a lower priority.
          // If neither of them is null, compare them based on their importance.
          if (a.Importance === b.Importance) {
            return new Date(a.CreatedAt) > new Date(b.CreatedAt) ? -1 : 1;
          } else if (a.Importance === null) {
            return 1;
          } else if (b.Importance === null) {
            return -1;
          } else {
            return commentImportanceLevel[a.Importance] - commentImportanceLevel[b.Importance];
          }
        })
      );
    }
  }, [filters, sortOrder, comments]);

  useEffect(() => {
    setBeforeTimestamp(Date.now());
  }, [filters, sortOrder, searchInputValue]);

  useEffect(() => {
    map?.on("draw.create", onCreate);

    return () => {
      // If the user was currently drawing remove that
      setDrawMode("no_feature");
      map?.off("draw.create", onCreate);
    };
  }, [map]);

  // On unmount if the user was adding a comment exit
  // "comment" edit mode
  useEffect(() => {
    return () => {
      if (editMode === "comment") {
        dispatch(endCommenting());
      }
    };
  }, [editMode]);

  /**
   * Monitor the searchText value within the store that can
   * change if a user clicks on a #tag.  When a change is detected
   * change the current search value / input to that value.
   */
  useEffect(() => {
    if (defaultSearchText !== debouncedSearchText) {
      setSearchInputValue(defaultSearchText);
    }
  }, [defaultSearchText]);

  /**
   * Sync the redux search text with the debounced version
   */
  useEffect(() => {
    if (defaultSearchText !== debouncedSearchText) {
      dispatch(setSearchText(debouncedSearchText));
    }
  }, [debouncedSearchText]);

  /**
   * Callback function that is fired when the mapbox draw.create
   * is fired. We implement useCallback so that the map event can be
   * removed add re-added when required (otherwise duplicate events would be fired)
   */
  const onCreate = useCallback(() => {
    setCommentHasFeature(true);
  }, []);

  /**
   * Enters drawing mode & disables selecting of existing map features
   */
  const handleOnAddFeature = (mode: "draw_point" | "draw_polygon" | "no_feature" | "draw_line_string", type: CommentType) => {
    setCommentType(type);
    setDrawMode(mode);

    if (mode !== "no_feature") {
      // When drawing we disable map click events that would otherwise open feature popups
      dispatch(beginCommenting());
    }

    setShowAddComment(true);
    // Take the user to the top of the comments list & the Add comment form
    commentsRef.current?.scrollTo(0, 0);
  };

  /**
   * Callback function for handling the submission of the Add Comment form
   */
  const handleOnSubmit = (values: IFormData) => {
    const newFeatures: Feature[] = commentType !== "project" ? drawControl?.getAll().features : [];

    return dispatch(
      addComment({
        Version: versionId,
        RawContent: values.rawContent,
        Importance: values.importance,
        Features: newFeatures,
        Type: commentType || "project",
      })
    )
      .then((comment: Comment) => {
        reset();
        refetchPermissions();
        return Promise.resolve();
      })
      .catch((e) => {
        console.error("Create Comment Failure", e);
        setError("There was an issue creating your comment, please try again.");
      });
  };

  /**
   * Callback function for handling the cancellation of adding a new comment.
   * Removes all unsaved features drawn on the map & hides the form
   */
  const handleOnCancel = () => {
    reset();
  };

  /**
   * Callback function that is passed the filter text as it changes
   */
  const handleOnFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchInputValue(event.currentTarget.value);
  };

  /**
   * When commenting is completed or cancel reset
   */
  const reset = () => {
    setDrawMode("no_feature");

    setShowAddComment(false);
    setCommentHasFeature(false);
    setCommentType(undefined);
    dispatch(endCommenting());
  };

  /**
   * Determines the endAdornment icon for the Search input field
   */
  const getEndAdornment = () => {
    if (isPending()) return <CircularProgress size={24} className={classes.spinner} />;
    if (searchInputValue !== "") return <Close className={classes.clearSearch} onClick={() => setSearchInputValue("")} />;
    return null;
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" data-testid="content-collaboration-panel">
      {loaded ? (
        <>
          <TabHeader
            leftAdornments={
              <>
                <Tooltip title="Change filtering" PopperProps={{ disablePortal: true }}>
                  <IconButton
                    aria-label="change filtering"
                    data-testid="change-filter"
                    size="small"
                    className={classes.iconGroup}
                    onClick={(event) => setFilterMenuAnchorEl(event.currentTarget)}
                  >
                    <FilterList />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Change sort order" PopperProps={{ disablePortal: true }}>
                  <IconButton
                    aria-label="change sorting"
                    data-testid="change-sort"
                    size="small"
                    className={classes.iconGroup}
                    onClick={(event) => setSortMenuAnchorEl(event.currentTarget)}
                  >
                    <ImportExport />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={`${mapFilter ? "Remove filtering by current map view" : "Filter by current map view"}`}
                  PopperProps={{ disablePortal: true }}
                >
                  <IconButton
                    size="small"
                    aria-label="filter by map view"
                    data-testid="change-map-filter"
                    className={classNames(classes.iconGroup, { active: mapFilter })}
                    onClick={() => dispatch(setMapFilter(!mapFilter))}
                  >
                    <MapOutlined />
                  </IconButton>
                </Tooltip>
              </>
            }
            rightAdornments={
              <Tooltip title="Add comment" PopperProps={{ disablePortal: true }}>
                <IconButton
                  aria-label="add comment"
                  data-testid="add-comment"
                  color="primary"
                  size="small"
                  className={classes.iconGroup}
                  onClick={(event) => setAddMenuAnchorEl(event.currentTarget)}
                >
                  <AddComment />
                </IconButton>
              </Tooltip>
            }
          />
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" pt={1} px={1} pb={0}>
            <TextField
              value={searchInputValue}
              variant="outlined"
              data-testid="search-input"
              margin="dense"
              name="search"
              fullWidth
              onChange={handleOnFilterChange}
              placeholder="Search comments and replies..."
              className={classes.inputRoot}
              inputProps={{ className: classes.input }}
              InputProps={{
                className: classes.searchIcon,
                startAdornment: <Search color="action" />,
                endAdornment: getEndAdornment(),
              }}
            />
          </Box>
          <Box ref={commentsRef} className={classNames(classes.comments, "customScrollbars")} pb={1}>
            {showAddComment && (
              <Paper className={classes.addComment}>
                <Header user={currentUsername} commentType={commentType} />
                <Box p={1} data-testid="comment-panel-add">
                  <AddCommentForm
                    autoFocus
                    editorStyle={{ editorContent: { minHeight: 100 } }}
                    onCancel={handleOnCancel}
                    onSubmit={handleOnSubmit}
                    hasFeature={commentHasFeature || commentType === "project"}
                    projectId={projectId}
                  />
                </Box>
              </Paper>
            )}

            {sortedComments.length === 0 && !showAddComment && (
              <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" minHeight={300}>
                <NonIdealState
                  size="small"
                  icon={<AddComment />}
                  title="This project currently has no comments."
                  description={
                    <>
                      Click the <strong>Add Comment</strong> button above to select a point or area on the map to comment on.
                    </>
                  }
                />
              </Box>
            )}

            <CommentsList comments={sortedComments} searchText={debouncedSearchText} beforeTimestamp={beforeTimestamp} />
          </Box>

          {addMenuAnchorEl && <AddMenu anchorEl={addMenuAnchorEl} onClick={handleOnAddFeature} onClose={() => setAddMenuAnchorEl(undefined)} />}

          {sortMenuAnchorEl && (
            <SortMenu
              anchorEl={sortMenuAnchorEl}
              onClose={() => setSortMenuAnchorEl(undefined)}
              onChange={(order) => dispatch(setSortOrder(order))}
              sortOrder={sortOrder}
            />
          )}

          {filterMenuAnchorEl && (
            <FilterMenu
              anchorEl={filterMenuAnchorEl}
              onClose={() => setFilterMenuAnchorEl(undefined)}
              onChange={(state) => dispatch(setFilters(state))}
              filterBy={filters}
              versions={versions || []}
            />
          )}

          {/*
            While the user is creating a comment of type Polygon or Point
            we display an information panel to make it clear they need a feature created
          */}
          {(commentType === "point" || commentType === "polygon" || commentType === "lineString" || commentType === "arrowLine") && (
            <>
              {commentType === "point" && (
                <SnackbarContent
                  severity="info"
                  icon={<LocationOn />}
                  onClick={handleOnCancel}
                  title="Pin a location"
                  content="Pin the point on the map that you would like to comment on."
                />
              )}
              {commentType === "polygon" && (
                <SnackbarContent
                  severity="info"
                  icon={<SvgIcon icon="polygon" />}
                  onClick={handleOnCancel}
                  title="Highlight an area"
                  content="Highlight an area on the map that you would like to comment on."
                />
              )}
              {commentType === "lineString" && (
                <SnackbarContent
                  severity="info"
                  icon={<LinearScale />}
                  onClick={handleOnCancel}
                  title="Draw a line"
                  content="Draw a line on the map that you would like to comment on."
                />
              )}
              {commentType === "arrowLine" && (
                <SnackbarContent
                  severity="info"
                  icon={<TrendingFlat />}
                  onClick={handleOnCancel}
                  title="Draw an arrow"
                  content="Draw an arrow on the map that you would like to comment on."
                />
              )}
            </>
          )}
          {error && <SnackbarContent severity="error" content={error} onClick={() => setError(undefined)} />}
        </>
      ) : (
        <BlockSpinner />
      )}
    </Box>
  );
};

export default withStyles(customStyles)(CommentsPanel);
