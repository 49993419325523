import { latest, validate } from "@mapbox/mapbox-gl-style-spec";

import { STYLE_EDITOR_MAX_ZOOM } from "fond/constants";
import { FondStyleType, MapboxKey, StyleFieldName, StyleType } from "fond/types";

export const getDefaultSpecValue = (spec: any, fieldName?: StyleFieldName): any => {
  if (!spec) return undefined;

  if (spec.hasOwnProperty("default")) {
    return spec.default;
  }

  const defaults: any = {
    color: "#000000",
    string: "",
    boolean: false,
    number: 0,
    array: [],
  };

  let value = defaults[spec.type];

  if (fieldName === "MaxZoom") {
    value = STYLE_EDITOR_MAX_ZOOM;
  }

  return value !== undefined ? value : "";
};

export const getGroupName = (layerType: "fill" | "line" | "symbol" | "circle", field: MapboxKey): "paint" | "layout" | undefined => {
  if (field in latest[`paint_${layerType}`]) {
    return "paint";
  } else if (field in latest[`layout_${layerType}`]) {
    return "layout";
  }

  return undefined;
};

export const getFieldSpec = (layerType: FondStyleType, field: MapboxKey): any => {
  const type = fromFondStyleType(layerType);
  const groupName = getGroupName(type, field);
  let fieldSpec;
  if (groupName) {
    const group = latest[`${groupName}_${type}`];
    fieldSpec = group[field];
  } else {
    fieldSpec = latest.layer[field as "maxzoom" | "minzoom"];
  }

  return fieldSpec;
};

/**
 * Creates a zoom function value.
 *
 * Note that we convert the interpolate expression into the stop functions format.
 */
export const makeCameraExpression = (value: any, fieldSpec: any) => [
  6,
  value || getDefaultSpecValue(fieldSpec),
  10,
  value || getDefaultSpecValue(fieldSpec),
];

/**
 * Determines if we are supporting zoom expressions for the field.
 * Note that we also check fieldName as the mapboxKey can be used across
 * multiple style types.
 *
 * For example the Font Icon uses text-halo-width as does Symbol.
 */
export const allowInterpolateExpression = (mapboxKey: MapboxKey, fieldName: StyleFieldName): boolean => {
  const supportedKeys = new Map<MapboxKey, StyleFieldName[]>();
  supportedKeys.set("circle-stroke-width", ["CircleStrokeWidth"]);
  supportedKeys.set("circle-opacity", ["CircleOpacity"]);
  supportedKeys.set("circle-radius", ["CircleRadius"]);
  supportedKeys.set("icon-opacity", ["IconOpacity"]);
  supportedKeys.set("icon-size", ["IconSize"]);
  supportedKeys.set("fill-opacity", ["FillOpacity"]);
  supportedKeys.set("line-offset", ["LineOffset"]);
  supportedKeys.set("line-opacity", ["LineOpacity"]);
  supportedKeys.set("line-width", ["LineWidth"]);
  supportedKeys.set("line-gap-width", ["LineGapWidth"]);
  supportedKeys.set("text-size", ["TextSize", "FontIconSize"]);
  supportedKeys.set("text-halo-width", ["FontIconHaloWidth"]);

  return supportedKeys.get(mapboxKey)?.includes(fieldName) || false;
};

/**
 * Valid Mapbox field validator. Uses mapbox's validate() which validates and
 * entire style to determine errors.
 */
export const isValidStyle = (value: any): string[] | undefined => {
  if (value === undefined) return undefined;

  const style = {
    version: 8,
    name: "Empty Style",
    metadata: {},
    sources: { "output/slice": { type: "geojson", data: [] } },
    sprite: "",
    glyphs: "mapbox://fonts/biarrinetworks/{fontstack}/{range}.pbf",
    layers: [
      {
        id: "output/splice/1",
        source: "output/slice",
        ...value,
      },
    ],
  };

  const errors = validate(style);

  if (errors.length > 0) {
    return errors;
  }

  return undefined;
};

export const fromFondStyleType = (type: FondStyleType): StyleType => {
  if (type === "icon") return "symbol";
  return type;
};
