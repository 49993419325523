import { memo, useEffect, useRef, useState } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Close, MoreVert } from "@mui/icons-material";
import { Alert, Box, IconButton, Snackbar, Tooltip, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import dayjs from "dayjs";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { useGetPermissionsQuery } from "fond/api";
import AddCommentForm, { IFormData } from "fond/project/comments/AddCommentForm";
import ReplyMenu from "fond/project/comments/ReplyMenu";
import { updateReply } from "fond/redux/comments";
import { Reply, Store } from "fond/types";
import { Editor, ShowMore } from "fond/widgets";

const customStyles = (theme: Theme) => {
  return createStyles({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      backgroundColor: "#f8fcff",
    },
    reply: {
      whiteSpace: "pre-wrap",
    },
    primaryIcons: {
      opacity: 0.25,
      "&:hover": {
        opacity: 1,
        color: theme.palette.primary.main,
      },
    },
    lastModified: {
      fontSize: "0.7rem",
      opacity: 0.5,
    },
    alert: {
      boxShadow: theme.shadows[1],
    },
    close: {
      padding: theme.spacing(0.5),
    },
    username: {
      fontWeight: 500,
      fontSize: "0.85rem",
      maxWidth: 250,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * Flag indicating if the comment is being rendered with
   * the MapPopup
   */
  inPopup?: boolean;
  /**
   * The reply being displayed
   */
  reply: Reply;
}

const ReplyComponent: React.FC<IProps> = ({ classes, inPopup = false, reply }: IProps) => {
  const dispatch: ThunkDispatch<Store, null, AnyAction> = useDispatch();

  const currentUsername = useSelector((state: Store) => state.cognito.user?.username);
  const [isEditing, setIsEditing] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const projectId = useSelector((state: Store) => state.project.projectId);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<HTMLDivElement>(null);
  const { refetch: refetchPermissions } = useGetPermissionsQuery({ id: projectId, type: "project" });

  useEffect(() => {
    if (inPopup) formRef.current?.scrollIntoView();
  }, [isEditing]);

  /**
   * Handles opening the options menu
   */
  const handleOnMore = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setShowOptions(true);
  };

  /**
   * Handles the submission of the edited Reply
   */
  const handleOnSubmit = (values: IFormData) => {
    return dispatch(
      updateReply({
        rawContent: values.rawContent,
        reply: reply,
      })
    )
      .then(() => {
        refetchPermissions();
        setIsEditing(false);
        return Promise.resolve();
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error("Update Reply Failure", e);
        setError("There was an issue updating your reply, please try again.");
      });
  };

  const handleOnEdit = () => {
    setIsEditing(true);
  };

  /**
   * Handles the cancellation of reply editing
   */
  const handleOnCancel = () => {
    setIsEditing(false);
  };

  return (
    <Box className={classes.root} data-testid="reply-item">
      <Box display="flex" alignItems="end" justifyContent="space-between">
        <Typography className={classes.username}>{reply.Creator.Email}</Typography>
        <Box display="flex">
          {reply.Creator.Email === currentUsername && (
            <Tooltip title="More options" arrow>
              <IconButton size="small" onClick={handleOnMore} ref={anchorRef} data-testid="reply-menu-options">
                <MoreVert color="action" className={classes.primaryIcons} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box>
        {isEditing ? (
          <Box pb={0.5} ref={formRef}>
            <AddCommentForm
              autoFocus
              initialValues={{ rawContent: reply.RawContent }}
              hasFeature
              onCancel={handleOnCancel}
              onSubmit={handleOnSubmit}
              submitText="Update"
              isReply
              editorStyle={{ editorContent: { minHeight: 150, maxHeight: 246, overflowY: "auto" } }}
              projectId={projectId}
            />
          </Box>
        ) : (
          <>
            <ShowMore>
              <Editor readOnly rawContent={reply.RawContent} data-testid="reply-content" />
            </ShowMore>
            <Box pb={1}>
              <Tooltip title={dayjs(reply.CreatedAt).format("h:mm A D MMM")} arrow>
                <Typography variant="caption" className={classes.lastModified}>
                  {reply.CreatedAt ? dayjs(reply.CreatedAt).fromNow() : "Now"}
                </Typography>
              </Tooltip>
              <Typography variant="caption" className={classes.lastModified}>
                {dayjs(reply.CreatedAt).isBefore(dayjs(reply.LastModifiedAt)) && (
                  <>{` (Edited: ${dayjs(reply.LastModifiedAt).format("h:mm A D MMM")})`}</>
                )}
              </Typography>
            </Box>
          </>
        )}

        {reply.Creator.Email === currentUsername && (
          <ReplyMenu anchorEl={anchorRef.current} reply={reply} open={showOptions} onClose={() => setShowOptions(false)} onStartEdit={handleOnEdit} />
        )}
      </Box>
      {error && (
        <Snackbar open anchorOrigin={{ vertical: "top", horizontal: "center" }} className={classes.alert}>
          <Alert
            severity="error"
            action={
              <IconButton aria-label="close" color="inherit" className={classes.close} onClick={() => setError(undefined)} size="large">
                <Close />
              </IconButton>
            }
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

// Memoize the Comment as the cost of unnecessary re-renders is significant
export default memo(withStyles(customStyles)(ReplyComponent));
