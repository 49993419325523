import { useState } from "react";
import { GridApi } from "@ag-grid-community/core";
import { Box } from "@mui/material";

import { UserAccountAllocationResponse } from "fond/api";
import { useLicenseAssignmentList } from "fond/settings/AccountManagement/useLicenseAssignmentList";
import { User } from "fond/types";
import { AgGrid } from "fond/widgets";

interface LicenseAssignmentListProps {
  allocations: UserAccountAllocationResponse[] | undefined;
}

const LicenseAssignmentList: React.FC<LicenseAssignmentListProps> = ({ allocations }: LicenseAssignmentListProps) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [selected, setSelected] = useState<User[]>();
  const { columns, gridOptions } = useLicenseAssignmentList();

  const onSelectionChanged = () => {
    const selectedUsers = gridApi?.getSelectedRows();
    setSelected(selectedUsers);
  };

  return (
    <Box flexGrow={1} data-testid="license-assignment-list">
      <AgGrid
        columnDefs={columns}
        rowData={allocations?.filter((allocation: UserAccountAllocationResponse) => allocation?.License) ?? null}
        gridOptions={gridOptions}
        onGridReady={(params) => setGridApi(params.api)}
        onSelectionChanged={onSelectionChanged}
        autoSizeColumns={false}
        variant="outlined"
      />
    </Box>
  );
};

export default LicenseAssignmentList;
