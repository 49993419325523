import * as React from "react";
import { Box, BoxProps, Tooltip, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import classNames from "classnames";
import dayjs from "dayjs";

import { CommentImportance } from "fond/types";

import { renderIcon, renderImportanceColor, renderImportanceIcon } from "./Comment";

const customStyles = (theme: Theme) => {
  return createStyles({
    clickable: {
      cursor: "pointer",
    },
    username: {
      fontWeight: 500,
      fontSize: "0.85rem",
      maxWidth: 150,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    icons: {
      color: theme.palette.secondary.main,
      paddingRight: theme.spacing(0.5),
    },
    importanceBox: {
      borderRadius: "3px 3px 0 0",
    },
    importanceLabel: {
      fontSize: "0.7rem",
      color: theme.palette.common.white,
      textTransform: "capitalize",
    },
    importanceIcon: {
      fontSize: "0.75em",
      color: theme.palette.common.white,
    },
    selected: {
      backgroundColor: theme.palette.background.active,
    },
    timestamp: {
      fontSize: "0.7rem",
      opacity: 0.5,
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * The type of comment
   */
  commentType?: "project" | "point" | "polygon" | "lineString" | "arrowLine";
  /**
   * The username the comment was created by
   */
  user: string;
  /**
   * Timestamp of when the comment was created
   */
  timestamp?: string;
  /**
   *
   * Importance level of the comment
   */
  importance?: CommentImportance | null;
  /**
   * Flag indicating if the header is part of a selected comment
   */
  selected?: boolean;
  /**
   * Actions to be displayed to the right of the header
   */
  rightActions?: React.ReactNode;
  rootProps?: BoxProps;
  /**
   * Callback event for when the comment is clicked
   */
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

const Header: React.FC<IProps> = ({
  classes,
  commentType,
  user,
  timestamp,
  importance,
  rightActions = null,
  selected = false,
  rootProps,
  onClick,
}: IProps) => {
  return (
    <Box className={classNames({ [classes.selected]: selected, [classes.clickable]: onClick })} onClick={onClick}>
      {importance && (
        <Box py={0.5} display="flex" justifyContent="flex-start" className={classes.importanceBox} bgcolor={renderImportanceColor(importance)}>
          <Box display="flex" alignItems="center" className={classes.importanceIcon} ml={1}>
            {renderImportanceIcon(importance, { color: "white", fontSize: "0.75rem" })}
          </Box>
          <Box ml={0.5} display="flex" alignItems="center">
            <Typography data-testid="header-importance-label" className={classes.importanceLabel}>
              {importance || "None"}
            </Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ pt: 1 }} display="flex" overflow="hidden" alignItems="start" justifyContent="space-between" {...rootProps}>
        <Box display="flex">
          <Box className={classes.icons} ml={1}>
            {renderIcon(commentType)}
          </Box>
          <Box ml={0.5}>
            <Typography className={classes.username}>{user}</Typography>
            <Tooltip title={dayjs(timestamp).format("h:mm A D MMM")} arrow>
              <Typography variant="caption" className={classes.timestamp}>
                {timestamp ? dayjs(timestamp).fromNow() : "Now"}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
        {rightActions}
      </Box>
    </Box>
  );
};

export default withStyles(customStyles)(Header);
