import * as React from "react";
import { useState } from "react";
import { useForm } from "react-final-form";
import { Alert, Box, InputLabel } from "@mui/material";

import { LoadingButton } from "ui";

import { useCreatePurchaseSummaryMutation, useGetProductsQuery } from "fond/api";

import { IFormData } from "../CheckoutForm";

import { PromoCodeInput } from "./InvoiceSummary.styles";

type PromotionCodeInputProps = { promoCode: string };

const PromotionCodeInput: React.FC<PromotionCodeInputProps> = ({ promoCode }) => {
  const form = useForm<IFormData>();
  const [promoCodeInput, setPromoCodeInput] = useState(promoCode);
  const [errorMessage, setErrorMessage] = useState("");
  const { data: products } = useGetProductsQuery();
  const [createPurchaseSummary, { isLoading: isPurchaseSummaryLoading }] = useCreatePurchaseSummaryMutation();

  const applyPromoCode = async (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault(); // don't submit form yet!

    // check if promo code is valid from summary
    const { address, billingPeriod, productId } = form.getState().values;
    const selectedProduct = products?.find((product) => product.ID === productId);
    const selectedPrice = selectedProduct?.Prices.find((price) => price.PaymentFrequency === billingPeriod);
    if (!selectedPrice) return;

    try {
      await createPurchaseSummary({
        LineItems: [{ PriceID: selectedPrice?.StripePriceID, Quantity: 1 }],
        PromoCode: promoCodeInput,
        Address: address,
      }).unwrap();

      // actions if promo code is valid
      form.change("promoCode", promoCodeInput);
      if (errorMessage) setErrorMessage("");
    } catch (err) {
      if (err instanceof Object && "message" in err && err.message === "Invalid coupon") {
        setErrorMessage("This promo code is invalid. Please check the code and try again.");
      }
    }
  };

  return (
    <Box paddingBottom={2}>
      <InputLabel>
        Have a promo code?
        <Box display="flex">
          <PromoCodeInput
            id="promo-code"
            type="text"
            margin="none"
            inputProps={{ sx: { padding: 1 } }}
            onChange={(e) => setPromoCodeInput(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && applyPromoCode(e)} // don't submit form!
          />
          <LoadingButton data-testid="apply-promo-button" variant="contained" onClick={applyPromoCode} loading={isPurchaseSummaryLoading}>
            Apply
          </LoadingButton>
        </Box>
        {!!errorMessage && (
          <Alert sx={{ marginTop: 1, whiteSpace: "pre-line" }} severity="info">
            {errorMessage}
          </Alert>
        )}
      </InputLabel>
    </Box>
  );
};

export default PromotionCodeInput;
