import * as React from "react";

import { Navigation, Users } from "fond/settings";
import { PageContent } from "fond/widgets";

import InviteMembersButton from "./InviteMembersButton";

/**
 * A top level component used for displaying Users Settings.
 */
const UserManagement: React.FC = () => {
  return <PageContent title="User Management" action={<InviteMembersButton />} mainContent={<Users />} navigation={<Navigation />} />;
};

export default UserManagement;
