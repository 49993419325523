import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import * as Sentry from "@sentry/react";

import {
  selectCurrentAccount,
  selectCurrentAllocation,
  selectCurrentSubscription,
  useCreateProjectMutation,
  useGetAccountSubscriptionsQuery,
} from "fond/api";
import { BackgroundLines, CollaborationElement, CollaborationLogo, PlannerElement, PlannerLogo } from "fond/svg_icons";
import { Store, SubscriptionStatus } from "fond/types";
import { Actions, licenseCheck, subscriptionCheck } from "fond/utils/permissions";
import { ButtonPanel, Message, Modal } from "fond/widgets";

interface IProps {
  /**
   * The parent folders Id
   */
  folderId?: string | undefined;
  /**
   * Callback function to handle the onClose of the modal
   */
  onClose(): void;
  /**
   * Callback function to close modal and open error message modal
   * when a user without planner access tried creating one
   */
  openPlannerProjectErrorModal(): void;
}

/*
 * Add a margin to the ButtonPanel for flex display.
 */
const MarginedButtonPanel = styled(ButtonPanel)(({ theme }) => ({
  margin: `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
}));

type ProjectType = "planner" | "collaboration";

/*
 * The new project modal.
 * A user selects between a PLANNER and COLLABORATION project.
 * Creates the new project selection and navigates to the project page.
 * The new project will be created in 'folderId' or root.
 */
const NewProjectModal: React.FC<IProps> = ({ folderId, onClose, openPlannerProjectErrorModal }: IProps) => {
  const theme = useTheme();
  const highlight = theme.palette.biarri.primary.red;
  const selectedColour = {
    borderColor: highlight,
    color: highlight,
    fill: highlight,
  };

  const navigate = useNavigate();
  const [createProject, { isError, isLoading }] = useCreateProjectMutation();
  const [selectedProject, setSelectedProject] = useState<ProjectType>();

  const currentAccount = useSelector(selectCurrentAccount);
  const { data: subscriptions } = useGetAccountSubscriptionsQuery(currentAccount?.ID ?? skipToken);
  const hasActiveSubscription = subscriptions?.some((subscription) => subscription.Status === SubscriptionStatus.Active);
  const canWritePlannerProjects = useSelector(
    (state: Store) =>
      currentAccount &&
      hasActiveSubscription &&
      licenseCheck(selectCurrentAllocation(state), Actions.PLANNER_PROJECT_ADD) &&
      subscriptionCheck(selectCurrentSubscription(state, currentAccount.ID), Actions.PLANNER_PROJECT_ADD)
  );

  /**
   * Request project creation and navigate to project page on success.
   */
  const createAndOpenProject = async (projectType: ProjectType): Promise<void> => {
    setSelectedProject(projectType);
    try {
      const project = await createProject({ FolderID: folderId, HasCustomLayerConfig: projectType === "collaboration" }).unwrap();
      navigate(`/project/${project.ID}/`);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  /*
   * Construct the ButtomPanel icon for this project type.
   * The icon is contructed as an overlay of three SVGs, generic BackgroundLines and the project type Element and Logo.
   */
  const buttonPanelIcon = (projectType: ProjectType): React.ReactNode => {
    let Element;
    let Logo;
    if (projectType === "planner") {
      Element = PlannerElement;
      Logo = PlannerLogo;
    } else if (projectType === "collaboration") {
      Element = CollaborationElement;
      Logo = CollaborationLogo;
    } else {
      throw new Error(`ButtonPanelIcon not implemented for unknown project type ${projectType}`);
    }

    return (
      <Box height="100px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <BackgroundLines style={{ zIndex: 1, position: "absolute" }} width="100px" />
        <Element style={{ zIndex: 2, position: "absolute" }} height="60px" />
        <Logo style={{ zIndex: 3, position: "absolute" }} height="60px" />
      </Box>
    );
  };

  return (
    <Modal
      data-testid="new-project-modal"
      variant="default"
      open
      header="New Project"
      content={
        <>
          <Box display="flex" justifyContent="center">
            <Backdrop open={isLoading} sx={{ position: "absolute", zIndex: "drawer", backgroundColor: "rgba(255,255,255, 0.5)" }}>
              <CircularProgress />
            </Backdrop>

            <MarginedButtonPanel
              data-testid="create-planner-panel"
              icon={buttonPanelIcon("planner")}
              title="planner"
              highlight={highlight}
              sx={selectedProject === "planner" ? selectedColour : undefined}
              onClick={() => {
                if (canWritePlannerProjects) {
                  createAndOpenProject("planner");
                } else {
                  openPlannerProjectErrorModal();
                }
              }}
              disabled={isLoading || Boolean(selectedProject)}
              height="170px"
              width="170px"
            />
            <MarginedButtonPanel
              data-testid="create-collaboration-panel"
              icon={buttonPanelIcon("collaboration")}
              title="collaboration"
              highlight={highlight}
              sx={selectedProject === "collaboration" ? selectedColour : undefined}
              onClick={() => createAndOpenProject("collaboration")}
              disabled={isLoading || Boolean(selectedProject)}
              height="170px"
              width="170px"
            />
          </Box>

          {isError && (
            <Box>
              <Message type="error">There was an issue creating this project. Please try again.</Message>
            </Box>
          )}
        </>
      }
      actions={
        <Button data-testid="close-button" color="primary" onClick={onClose}>
          Close
        </Button>
      }
    />
  );
};

export default NewProjectModal;
