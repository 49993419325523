import { CSSProperties, useEffect } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { InsertChart as InsertChartIcon } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, Paper, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";

import { selectProjectAccount, useGetVersionStatusQuery } from "fond/api";
import { makeRule } from "fond/architecture/bomRules";
import mixpanel from "fond/mixpanel";
import DesignNeedsUpdating from "fond/project/DesignNeedsUpdating";
import { showUpdateDesignBanner } from "fond/project/helpers";
import { Architecture, AsyncOperationState, BomGroup, BomRule, Project, Store } from "fond/types";
import { feetToMetersDisplay, formatUnit, metersToFeetDisplay } from "fond/utils";
import { useAppDispatch } from "fond/utils/hooks";
import { BlockSpinner, Message, Modal, NonIdealState } from "fond/widgets";

import { columns } from "./index";
import { load } from "./redux";
import { RegenerateBomBanner } from "./RegenerateBomBanner";

/** *
 * We used to have BOM as a separate page and can be access via link /project/[project-id]/bom.
 * We then moved it to a modal to better support the dynamic layout of Fond UI.
 * Refer to this PR for more details: https://bitbucket.org/biarrinetworks/fond_ui/pull-requests/884.
 */
const useStyles = makeStyles((theme: Theme) => ({
  paragraph: {
    marginBottom: theme.spacing(2),
  },
  tableSectionHeading: {
    margin: "2em 0 1em 0",
  },
  table: {
    margin: "1em 0",
    "&.ui.table:last-child": {
      marginBottom: "2em",
    },
  },
  noBom: {
    margin: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
  helpMessage: {
    padding: "24px 0",
  },
  sumBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(4),
  },
  sumBarTitle: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  sumBarTotal: {
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1),
  },
}));

interface IProps {
  /**
   * Modal title
   */
  title?: string;
  /**
   * Project
   */
  project: Project;

  /**
   * Architecture
   */
  architecture: Architecture | null;
  /**
   * Callback function to handle the onClose of the modal
   */
  onClose(): void;
}

interface contextProps {
  project: Project;
  architecture: Architecture | null;
  bom: any;
  bomArchRestrictions: any;
  loadStatus: AsyncOperationState | null;
}

interface bomColumn {
  label: string;
  prop: string;
  type: string;
  width: string;
}

const Content = ({ project, architecture, bom, loadStatus, bomArchRestrictions }: contextProps) => {
  const classes = useStyles();

  // Confirm it bom is successfully loaded from store.
  if (bom != null && (loadStatus === "success" || loadStatus === "dismissed")) {
    const projectArch = architecture;

    // We currently only do arch-BOM validation for projects without custom
    // BOMs (ie. using Biarri-created rulesets).
    let errors = [];
    if (projectArch != null && projectArch.BOM == null && bomArchRestrictions != null) {
      for (let restriction of bomArchRestrictions) {
        const rule = makeRule(restriction);
        const val = _.get(projectArch, restriction[0]);
        if (val !== undefined && rule.condition(val, projectArch, null, "metric")) {
          errors.push(rule.bomMessage(val, project.SystemOfMeasurement));
        }
      }
    }

    // Filter out all zero count entries.
    const filteredBomGroups = bom.groups
      .map((group: BomGroup) => ({ ...group, ruleOutput: group.ruleOutput.filter((rule: BomRule) => Number(rule.count) > 0) }))
      .filter((group: BomGroup) => group.ruleOutput.length > 0);

    return (
      <div>
        <Box className={classes.sumBar}>
          <Box>
            <Typography variant="h6" color="inherit" className={classes.sumBarTitle}>
              {project.ProjectName}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <Typography variant="h6" color="inherit" className={classes.sumBarTotal}>
              Total cost:
            </Typography>
            <Typography variant="h6" color="inherit" data-testid="grand-total">
              {formatNumericCellValue(bom.total)}
            </Typography>
          </Box>
        </Box>
        <Typography className={classes.paragraph}>
          The materials and costs can be configured in the Architecture Panel. If there is no BOM configured for this architecture, the default BOM
          for your FOND account is displayed here.
        </Typography>
        <Typography className={classes.paragraph}>
          {"Read our article on how to "}
          <a href="https://fondhelp.biarrinetworks.com/configuring-your-bom" target="_blank" rel="noopener noreferrer">
            configure a BOM
          </a>
          {" for more information."}
        </Typography>

        {errors.length > 0 && (
          <Message type="warning" data-testid="bom-arch-validation-warning">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                This Bill of Materials has not been configured with your architecture in mind. Your architecture contains parameters that result in
                equipment being placed that will not be counted. The equipment that won't be counted are:
              </div>

              <ul>
                {errors.map((e) => (
                  <li key={e.key}>{e}</li>
                ))}
              </ul>
            </div>
          </Message>
        )}

        {filteredBomGroups.map((group: BomGroup) => {
          return (
            <div key={group.name}>
              <Typography component="h3" className={classes.tableSectionHeading} variant="h5">
                {group.name}
              </Typography>
              <Paper square>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {columns.map((col: bomColumn) => {
                        return (
                          <TableCell key={col.label} style={getCellStyle(col)}>
                            {col.label}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {group.ruleOutput.map((row: any, j: number) => {
                      if (row.unit === "meters" && project.SystemOfMeasurement === "imperial") {
                        // unitCost uses the convertFeetToMeters function in order for the cost to be approx 1/3 of what is being used for meters.
                        // We expect the value to come through as cost per meter. Using the reverse function (convertFeetToMeters) would divide this
                        // value rather than multiply it. We would want this to happen because the cost should come through per foot.
                        // eslint-disable-next-line no-param-reassign
                        row = {
                          ...row,
                          count: metersToFeetDisplay(row.count),
                          unitCost: feetToMetersDisplay(Number(row.unitCost)),
                        };
                      }
                      return (
                        <TableRow
                          key={row.id}
                          // The generated English version of the rule is available in row.textRule, but we are not
                          // currently using that because it will be confusing.  Sometimes lengths in the text will be in feet,
                          // and sometimes in meters, depending on whether the project was run before or after the
                          // Support for meters migrations.
                        >
                          {columns.map((col, k) => {
                            return (
                              <TableCell key={k} style={getCellStyle(col)} data-testid={`${col.prop}_${j}`}>
                                {formatCell(row[col.prop], col.type, col.prop, project.SystemOfMeasurement)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={columns.length - 1}>Total</TableCell>
                      <TableCell style={getCellStyle(_.last(columns))}>{formatNumericCellValue(group.total)}</TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </Paper>
            </div>
          );
        })}
      </div>
    );
  } else {
    return (
      <Message data-testid="no-bom" className={classes.noBom} type="info" typography="h6" icon={<InfoIcon />}>
        To see the bill of materials for this project, first generate a design.
      </Message>
    );
  }
};

const formatCell = (value: string, type: string, columnProp: string, systemOfMeasurement: string) => {
  // With the mapping through both rows and columns, to get the values needed to make this
  // change, type remains the same (either text or number). columnProp is the key of each object in
  // row (id, description, unit, etc). value is the value of each column. In order to only change
  // feet or meters to be the correct SoM, both values needed to be checked to avoid changing "count", or
  // the value of the description.

  if (type === "text") {
    if (columnProp === "unit" && value === "meters") {
      return formatUnit(systemOfMeasurement);
    } else {
      return value;
    }
  } else if (type === "number") {
    return formatNumericCellValue(value);
  } else {
    throw new Error(`Unrecognised type ${type}`);
  }
};

const formatNumericCellValue = (val: string) => {
  if (val === "No rule defined") {
    // Make sure we don't `parseFloat` the string, also shorten this text
    // slightly so we don't wrap.
    return "Not defined";
  } else {
    return parseFloat(val).toLocaleString(undefined, { maximumFractionDigits: 2 });
  }
};

const getCellStyle = (column: bomColumn | undefined): CSSProperties | undefined => {
  if (column) {
    const textAlign = column.type === "number" ? "right" : "center";
    return {
      textAlign: `${textAlign}`,
      width: column.width,
      padding: "4px 20px 4px 15px",
    };
  }
  return {};
};

const BomModal: React.FC<IProps> = ({ title = "Materials and Costs", project, architecture, onClose }: IProps) => {
  const dispatch = useAppDispatch();
  const bom = useSelector((state: Store) => state.bom.bom);
  const loadStatus = useSelector((state: Store) => state.bom.loadStatus);
  const generateStatus = useSelector((state: Store) => state.bom.generateStatus);
  const bomArchRestrictions = useSelector((state: Store) => selectProjectAccount(state, project)?.BomArchRestrictions ?? []);
  const versionId = useSelector((state: Store) => state.project.versionId);
  const { data: versionStatus } = useGetVersionStatusQuery(versionId, { skip: !versionId });
  const showDesignBanner = versionStatus ? showUpdateDesignBanner(project, versionStatus) : false;

  useEffect(() => {
    mixpanel.track("load project bom.");
    dispatch(load(versionId));
  }, [versionId, dispatch]);

  return (
    <Modal
      open
      onClose={onClose}
      variant="primary"
      header={title}
      headerIcon={<InsertChartIcon />}
      headerSubtitle="COLLABORATE"
      data-testid="bom-modal"
      content={
        project == null || versionStatus?.Status === "Running" || loadStatus === "executing" || generateStatus === "executing" ? (
          <NonIdealState
            icon={<BlockSpinner />}
            title="Project is currently running"
            description="The bill of materials will load once the project has finished executing. Please return to the previous page."
          />
        ) : (
          <>
            {showDesignBanner && <DesignNeedsUpdating />}
            {!showDesignBanner && versionStatus?.BomHasChanged && <RegenerateBomBanner />}
            <Content project={project} architecture={architecture} bom={bom} loadStatus={loadStatus} bomArchRestrictions={bomArchRestrictions} />
          </>
        )
      }
      actions={
        <Button data-testid="bom-modal-close-button" onClick={onClose}>
          Close
        </Button>
      }
    />
  );
};

export default BomModal;
