import * as React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import classNames from "classnames";

import { FieldState } from "fond/types";

const customStyles = (theme: Theme) => {
  const errorColor = theme.palette.error.main;
  const warningColor = theme.palette.warning.main;

  return createStyles({
    warningMessage: {
      color: warningColor,
    },
    errorMessage: {
      color: errorColor,
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * The validation state of the message (determines visual respresentation of the message)
   */
  state: FieldState;
  /**
   * The validation message outlining the validation error to display
   */
  message?: string;
}

const ValidationMessage: React.FC<IProps> = ({ classes, state, message }: IProps) => (
  <FormHelperText
    error={state === "error"}
    className={classNames({
      [classes.errorMessage]: state === "error",
      [classes.warningMessage]: state === "warning",
    })}
    data-testid="validationMessage"
  >
    {message}
  </FormHelperText>
);

export default withStyles(customStyles)(ValidationMessage);
